import 'bootstrap/dist/css/bootstrap.min.css';
import MultiChart from '../shared/Charts/index';
import PieChart from '../../components/shared/PieChart/index';
import { Link } from "react-router-dom";
import Moment from 'moment';
import './index.css';

function ProgressChart({ data, isPieChart, piechart, boxColor, gridcolor }) {
    const gridclass = 'white-bg shadow-box pt-1 ' + gridcolor;
    const timestamp = data.timestamp
    //console.log(data);
    return (
        <div className="col-md-3" style={{backgroundColor:boxColor}}>
            <Link className='boxlink' to={'/department-details/' + data.cost_center_number}>
            <div className={gridclass}>
                <div className="row fields justify-content-center">
                    <div className="col-md-12 text-center">
                        <strong className="m-f14 f-12 primary-font">{data.cost_center_name} </strong>
                        <div style={{ marginTop: '-15px' }}><MultiChart chartdata={data} /></div>
                    </div>
                    <div className="col-md-9 col-lg-12 mt-minus-25">
                        <div className="d-flex my-2 f-14 justify-content-between position-relative 1">
                            <div className='tooltip right-tool position-relative fw-bold c-grey primary-font'>{data.total_fte}<span className="tooltiptext">Total FTEs</span></div>
                            <div className="color-orange tooltip big-data position-relative fw-bold c-grey primary-font">{data.average_acutiy_score_for_occupied_beds} <span className="tooltiptext">Avg Acuity Score</span></div>
                            <div className='tooltip  position-relative fw-bold c-grey primary-font'>{data.occupied_beds} <span className="tooltiptext">Occupied Beds</span></div>
                            <div className='tooltip left-tool b-100 position-relative fw-bold c-grey primary-font'>{data.total_available_beds} <span className="tooltiptext">Available Beds</span></div> 

                            {/* <div className='position-relative'>{data.total_fte} <br/>
                            <span className="labeltext">Total FTEs</span>
                            </div>
                            <div className="color-orange position-relative">{data.average_acutiy_score_for_occupied_beds} <br/>
                            <span className="labeltext">Avg Acuity Score</span>
                            </div>
                            <div className='position-relative'>{data.occupied_beds} <br/>
                            <span className="labeltext">Occupied Beds</span>
                            </div>
                            <div className='b-100 position-relative'>{data.total_available_beds}<br/>
                            <span className="labeltext">Available Beds</span>
                            </div> */}
                        </div>
                    </div>
                    {/* <div className="col-md-7"><span className="date f-12">{timestamp}</span></div> 
                    <div className="col-md-5 text-end"><span className="f-12"> {data.shift}</span></div> */}
                </div>
            </div>
            </Link>
        </div>
    );
}

export default ProgressChart;