import React, { useEffect, useState, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { API_CALL_URL_PUBLIC } from "../../constants/";
import { API_CALL_URL_PRIVATE } from "../../constants/compensate";
import axios from "../../axios";
import Moment from "moment";
import "./index.css";
import LeftIcon from "../../assets/img/left.png";
import RightIcon from "../../assets/img/right.png";
import BedIcon from "../../assets/img/bed-tb.png";
import AcuityIcon from "../../assets/img/acuity.png";
import ChargeIcon from "../../assets/img/charge.png";
import CnaIcon from "../../assets/img/cna.png";
import OrientationIcon from "../../assets/img/orientation.png";
import NurseIcon from "../../assets/img/nurse.png";
import ReseIcon from "../../assets/img/res-brk-en.png";
import UnitIcon from "../../assets/img/unit_sec.png";
import SitterIcon from "../../assets/img/sitter.png";
import AdmitsIcon from "../../assets/img/admits.png";
import DischargesIcon from "../../assets/img/discharges.png";
import TpatientsIcon from "../../assets/img/telemetry_patients.png";
import DripsIcon from "../../assets/img/drips.png";

// ----------------------Dark View Icon
import BedIcon1 from "../../assets/img/bed-tb1.png";
import AcuityIcon1 from "../../assets/img/acuity1.png";
import ChargeIcon1 from "../../assets/img/charge1.png";
import CnaIcon1 from "../../assets/img/cna1.png";
import OrientationIcon1 from "../../assets/img/orientation1.png";
import NurseIcon1 from "../../assets/img/nurse1.png";
import ReseIcon1 from "../../assets/img/res-brk-en1.png";
import UnitIcon1 from "../../assets/img/unit_sec1.png";
import SitterIcon1 from "../../assets/img/sitter1.png";
import AdmitsIcon1 from "../../assets/img/admits1.png";
import DischargesIcon1 from "../../assets/img/discharges1.png";
import TpatientsIcon1 from "../../assets/img/telemetry_patients1.png";
import DripsIcon1 from "../../assets/img/drips1.png";

import { ThemeContext } from "../../providers/ThemeProvider";
import { THEME_TYPE } from "../../constants/theme";



import ErrorModal from "../../components/shared/SuccessModal/ErrorModal";

// import { SocketContext } from "../../socket";
const init = {
  acuity: null,
  actual_admit: null,
  ant_admit: null,
  charge: null,
  cna: null,
  comment: null,
  cost_center_number: null,
  currentShiftNumber: null,
  one_five: null,
  one_four: null,
  one_six: null,
  id: null,
  nurses: null,
  one_one: null,
  two_one: null,
  orientation: null,
  res_brk_rn: null,
  shift_time: null,
  sitter: null,
  telemetry: null,
  one_three: null,
  shift_date: null,
  total_patients: null,
  one_two: null,
  unit_sec: null,
  vaso_active_drips: null,
};

function AddModal({
  isShow,
  callbackfunc,
  reloaddata,
  cost_center_number,
  contentId,
  previousShiftData,
  getprevAndCurrentShift,
  addgridData,
  cost_center_name,
  numbers,
  getPreAndCurr,
  getPrevShiftData
}) {
  const [themeMode, setTheme] = useContext(ThemeContext);
  const selector = useSelector((state) => state);
  // const socket = useContext(SocketContext);
  const detail_data = selector.compensate.departmentdetail;
  const gridDataApiData = selector.compensate.getPreviousShiftData?.gridData;
  const directDataApiData =
    selector.compensate.getPreviousShiftData?.directData;

  // console.log(getPrevShiftData, 'momo Prev data');

  const contentone = detail_data?.content ? detail_data.content.content_1 : {};
  const [formdata, setFormdata] = useState(init);
  // const autoFillformdata = autoFillformdata
  const [successMsg, setSuccessmsg] = useState("");
  const [directData, setDirectData] = useState({});
  const [variance, setVariance] = useState({});
  const [patientData, setPatientData] = useState({});
  const [totalvariance, setTotalVar] = useState(0);
  const [totalPatientvariance, setTotalPatientVar] = useState(0);
  const [gridData, setGridData] = useState({});
  const activedatefilter = selector.compensate?.activedatefilter;
  const [showError, setShowError] = useState(false);

  const [previousPatientData, setpreviousPatientData] =
    useState(directDataApiData);
  const [previousAdditionalDataTracking, setPreviousAdditionalDataTracking] =
    useState(directDataApiData);
  const [previousFormData, setPreviousFormData] = useState(directDataApiData);
  //console.log({ previousPatientData, directDataApiData }, 'data shift_date')
  const param = {
    cost_center_number: cost_center_number,
    fromdate: { date: "21-04-2023", shift_time: "13:00" },
    todate: { date: "21-04-2023", shift_time: "16:00" },
  };
  const dateselected = directDataApiData?.shift_date
    ? directDataApiData.shift_date
    : "";
  //console.log("dateselected", dateselected);

  const timestamp = Date(dateselected);
  const date = Moment(Date(dateselected)).format("DD/MM/YYYY");

  const handleChange = (event) => {
    //console.log(rest, 'rest');
    //setTotalPatientVar(Object.values(patientData).reduce((a, b) => Number(a) + Number(b), 0))
    setFormdata({
      ...formdata,
      [event.target.name]: event.target.value,
    });
    let el = document.querySelectorAll(".apicall");
    setpartValue(event);
    setvarianceValue(event);
  };

  useEffect(() => {
    setGridData(gridDataApiData);
  }, [gridDataApiData]);

  // useEffect(() => {
  //     setGridData(addgridData);
  // }, [addgridData])

  // "dc_nurse": 0,
  // "orientation": 4,
  // "required_charge": 34,
  // "required_cna": 4,
  // "required_nurses": 45,
  // "required_res_brk_rn": 8,
  // "sitter": 0,
  // "unit_sec": 1,
  const handleThemeChange = async (e) => {
    if (themeMode.theme === THEME_TYPE.LIGHT) {
        setTheme({ theme: THEME_TYPE.DARK });
    }
    else {
        setTheme({ theme: THEME_TYPE.LIGHT });
    }
    // localStorage.setItem("psLang", themeMode.theme);
    const themeType = themeMode.theme === THEME_TYPE.LIGHT ? 1 : 0;
    console.log("themeType", themeType);

    try {
        const { data } = await axios.post(
            `${API_CALL_URL_PRIVATE}/user/updateThemeMode`,
            { theme_mode: themeType }
        );
      localStorage.setItem("psAdmin", JSON.stringify(data));
    } catch (error) {
        console.log(error);
    }

};
  const setpartValue = (event) => {

      if (event.target.name == "two_one") {
      setPatientData({
        ...patientData,
        [event.target.name]: event.target.value,
      });
      const { two_one, ...rest } = patientData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setTotalPatientVar(sum);
      //setFormdata(...formdata, one_one);
      getDirectDataById("Grid", sum, event);
    } else if (event.target.name == "one_one") {
      setPatientData({
        ...patientData,
        [event.target.name]: event.target.value,
      });
      const { one_one, ...rest } = patientData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setTotalPatientVar(sum);
      //setFormdata(...formdata, one_one);
      getDirectDataById("Grid", sum, event);
    } else if (event.target.name == "one_two") {
      setPatientData({
        ...patientData,
        [event.target.name]: event.target.value,
      });
      const { one_two, ...rest } = patientData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setTotalPatientVar(sum);
      getDirectDataById("Grid", sum, event);
    } else if (event.target.name == "one_three") {
      setPatientData({
        ...patientData,
        [event.target.name]: event.target.value,
      });
      const { one_three, ...rest } = patientData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setTotalPatientVar(sum);
      getDirectDataById("Grid", sum, event);
    } else if (event.target.name == "one_four") {
      setPatientData({
        ...patientData,
        [event.target.name]: event.target.value,
      });
      const { one_four, ...rest } = patientData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setTotalPatientVar(sum);
      getDirectDataById("Grid", sum, event);
    } else if (event.target.name == "one_five") {
      setPatientData({
        ...patientData,
        [event.target.name]: event.target.value,
      });
      const { one_five, ...rest } = patientData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setTotalPatientVar(sum);
      getDirectDataById("Grid", sum, event);
    } else if (event.target.name == "one_six") {
        setPatientData({
          ...patientData,
          [event.target.name]: event.target.value,
        });
        const { one_six, ...rest } = patientData;
        const sum =
          Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
          Number(event.target.value);
        setTotalPatientVar(sum);
        getDirectDataById("Grid", sum, event);
     }
  };

  const setvarianceValue = (event) => {
    if (event.target.name == "charge") {
      setVariance({
        ...variance,
        [event.target.name]: gridData?.required_charge - event.target.value,
      });
      const { charge, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (gridData?.required_charge - event.target.value);
      setTotalVar(sum);
    } else if (event.target.name == "cna") {
      setVariance({
        ...variance,
        [event.target.name]: gridData?.required_cna - event.target.value,
      });
      const { cna, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (gridData?.required_cna - event.target.value);
      setTotalVar(sum);
    } else if (event.target.name == "orientation") {
      setVariance({
        ...variance,
        [event.target.name]: gridData?.orientation - event.target.value,
      });
      const { orientation, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (gridData?.orientation - event.target.value);
      setTotalVar(sum);
    } else if (event.target.name == "nurses") {
      setVariance({
        ...variance,
        [event.target.name]: gridData?.required_nurses - event.target.value,
      });
      const { nurses, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (gridData?.required_nurses - event.target.value);
      setTotalVar(sum);
    } else if (event.target.name == "res_brk_rn") {
      setVariance({
        ...variance,
        [event.target.name]: gridData?.required_res_brk_rn - event.target.value,
      });
      const { res_brk_rn, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (gridData?.required_res_brk_rn - event.target.value);
      setTotalVar(sum);
    } else if (event.target.name == "unit_sec") {
      setVariance({
        ...variance,
        [event.target.name]: gridData?.unit_sec - event.target.value,
      });
      const { unit_sec, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (gridData?.unit_sec - event.target.value);
      setTotalVar(sum);
    } else if (event.target.name == "sitter") {
      setVariance({
        ...variance,
        [event.target.name]: gridData?.sitter - event.target.value,
      });
      const { sitter, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (gridData?.sitter - event.target.value);
      setTotalVar(sum);
    }
  };

  const previousdataload = () => {
    //console.log("updated data", contentone);
    setFormdata(contentone);
  };

  const submitHandle = async () => {
    let submitdata = formdata;
    let ischecked = [];
    submitdata["id"] = "";

    let timestamp = activedatefilter?.shift_date
      ? activedatefilter.shift_date.split("/")
      : [];
    //console.log(timestamp, 'converttimestamp');
    timestamp =
      timestamp.length > 0
        ? `${timestamp[1]}-${timestamp[0]}-${timestamp[2]}`
        : "";
    //console.log(converttimestamp, 'converttimestamp');
    submitdata["cost_center_number"] = cost_center_number;
    submitdata["shift_date"] = timestamp; //getprevAndCurrentShift.currentShiftDateDash;
    submitdata["shift_time"] = activedatefilter?.shift_time + ":00"; //getprevAndCurrentShift.currentShiftTime;
    //submitdata['currentShiftNumber'] = getprevAndCurrentShift.currentShiftNumber;
    const checkval = Object.keys(submitdata).map((key) => {
      if (key != "id" && key != "currentShiftNumber") {
        if (submitdata[key] === null || submitdata[key] === "") {
          ischecked.push(false);
          if (key == "comment") {
            var el = document.querySelector("textarea[name=" + key + "]");
          } else {
            var el = document.querySelector("input[name=" + key + "]");
          }
          //console.log(el, "checked");
          if (el != null) {
            el.classList.add("redborder");
          }
        } else {
          if (key == "comment") {
            var el = document.querySelector("textarea[name=" + key + "]");
          } else {
            var el = document.querySelector("input[name=" + key + "]");
          }
          //console.log(el, "checked");
          if (el != null) {
            el.classList.remove("redborder");
          }
          ischecked.push(true);
        }
      }
    });
    //console.log(ischecked, 'ischecked');
    const checked = ischecked.every((element) => element === true);
    //console.log(checked);
    if (!checked) {
      return false;
    }

    console.log("submitdata", submitdata);

    try {
      //console.log({ formdata })
      // return
      const resp = await axios.post(
        API_CALL_URL_PRIVATE + "/department/addupdateDirectEntery",
        submitdata
      );
      const { data } = await resp;
      //console.log("response data", data);
      setSuccessmsg(data.message);
      // socket.emit("csvAdd", { csvAdd: true, csvUpdate: false });
      //   if (data.message == "Data Saved Succesfully") {

      reloaddata(data?.DirectEntery?._id);
      setFormdata(init);
      setTotalPatientVar(0);
      setVariance({});
      setTotalVar(0);
      callbackfunc(false, true, data.message);
      //   }
    } catch (error) {
      console.log(error);
      setShowError(true);
      setSuccessmsg(error.response.data.message)
    }
  };

  const getDirectDataById = async (title, sum, event) => {
    console.log("tgrid", totalPatientvariance);
    console.log("tsun", sum);
    console.log("title", title);
    try {
      //alert(title)
      // console.log(previousShiftData._id, "contentId");
      const resp = await axios.post(
        API_CALL_URL_PRIVATE + "/department/getDirectDataById",
        { id: directDataApiData._id }
      );
      const { data } = await resp;
      const comment = data?.data?.directData?.comment;

      if (title !== "Grid") {
        if (title == "Staffing") {
          setDirectData(data.data);
          let StaffingDataObj = {
            charge:
              (gridData?.required_charge ? gridData?.required_charge : 0) -
              data?.data?.directData?.charge,
            cna:
              (gridData?.required_cna ? gridData?.required_cna : 0) -
              data?.data?.directData?.cna,
            orientation:
              (gridData?.orientation ? gridData?.orientation : 0) -
              data?.data?.directData?.orientation,
            nurses:
              (gridData?.required_nurses ? gridData?.required_nurses : 0) -
              data?.data?.directData?.nurses,
            res_brk_rn:
              (gridData?.required_res_brk_rn
                ? gridData?.required_res_brk_rn
                : 0) - data?.data?.directData?.res_brk_rn,
            sitter:
              (gridData?.sitter ? gridData?.sitter : 0) -
              data?.data?.directData?.sitter,
            unit_sec:
              (gridData?.unit_sec ? gridData?.unit_sec : 0) -
              data?.data?.directData?.unit_sec,
          };
          let VarianceDataObj = {
            charge: data?.data?.directData?.charge,
            cna: data?.data?.directData?.cna,
            orientation: data?.data?.directData?.orientation,
            nurses: data?.data?.directData?.nurses,
            res_brk_rn: data?.data?.directData?.res_brk_rn,
            sitter: data?.data?.directData?.sitter,
            unit_sec: data?.data?.directData?.unit_sec,
          };
          setFormdata({
            ...formdata,
            ...VarianceDataObj,
            total_patients: totalPatientvariance,
          });
          setVariance(StaffingDataObj);
          setTotalVar(
            Object.values(StaffingDataObj).reduce((a, b) => a + b, 0)
          );
        }

        if (title == "PatientData") {
          setPatientData(data?.data?.directData);
          let patientDataObj = {
            //total_patients: data?.data?.directData?.total_patients,
            acuity: data?.data?.directData?.acuity,
            two_one: data?.data?.directData?.two_one,
            one_one: data?.data?.directData?.one_one,
            one_two: data?.data?.directData?.one_two,
            one_three: data?.data?.directData?.one_three,
            one_four: data?.data?.directData?.one_four,
            one_five: data?.data?.directData?.one_five,
            one_six: data?.data?.directData?.one_six,
          };
          const { acuity, ...radio } = patientDataObj;
          setPatientData(radio);
          setTotalPatientVar(
            data?.data?.directData?.two_one +
            data?.data?.directData?.one_one +
              data?.data?.directData?.one_two +
              data?.data?.directData?.one_three +
              data?.data?.directData?.one_four +
              data?.data?.directData?.one_five +
            data?.data?.directData?.one_six
          );
          setFormdata({
            ...formdata,
            ...patientDataObj,
            total_patients: totalPatientvariance,
          });
        }

        if (title == "AdditionalDataTracking") {
          let obj = {
            ant_admit: data?.data?.directData?.ant_admit,
            actual_admit: data?.data?.directData?.actual_admit,
            telemetry: data?.data?.directData?.telemetry,
            vaso_active_drips: data?.data?.directData?.vaso_active_drips,
          };
          setFormdata({
            ...formdata,
            ...obj,
            total_patients: totalPatientvariance,
          });
        }
      } else {
        // let el = document.querySelectorAll('.apicall');
        // console.log(event);
        // if(event != undefined){
        //     if(event.target.value != ''){
        //         console.log(el, 'querySelectorAll');
        //         el.forEach(item => item.setAttribute('disabled', true));
        //     }
        // }
        const getgridparams = {
          cost_center_number: cost_center_number,
          shift_time: activedatefilter.shift_time + ":00", //getPreAndCurr.currentShiftTime,
          census: sum != null ? sum : totalPatientvariance,
        };

        let addgriddata = await axios.post(
          API_CALL_URL_PRIVATE + "/department/getGridData",
          getgridparams
        );
        let addgridresult = await addgriddata;
        const gridresult = addgridresult.data.data.gridData;
        setGridData(gridresult);
        setDirectData(data.data);
        let VarianceDataObj = {};
        let StaffingDataObj = {
          charge: gridresult?.required_charge
            ? gridresult?.required_charge - gridresult?.required_charge
            : 0,
          cna: gridresult?.required_cna
            ? gridresult?.required_cna - gridresult?.required_cna
            : 0,
          orientation: gridresult?.orientation
            ? gridresult?.orientation - gridresult?.orientation
            : 0,
          nurses: gridresult?.required_nurses
            ? gridresult?.required_nurses - gridresult?.required_nurses
            : 0,
          res_brk_rn: gridresult?.required_res_brk_rn
            ? gridresult?.required_res_brk_rn - gridresult?.required_res_brk_rn
            : 0,
          sitter: gridresult?.sitter
            ? gridresult?.sitter - gridresult?.sitter
            : 0,
          unit_sec: gridresult?.unit_sec
            ? gridresult?.unit_sec - gridresult?.unit_sec
            : 0,
        };
        if (event) {
          VarianceDataObj = {
            charge: "",
            cna: "",
            orientation: "",
            nurses: "",
            res_brk_rn: "",
            sitter: "",
            unit_sec: "",
          };
        } else {
          VarianceDataObj = {
            charge: gridresult?.required_charge
              ? gridresult?.required_charge
              : 0,
            cna: gridresult?.required_cna ? gridresult?.required_cna : 0,
            orientation: gridresult?.orientation ? gridresult?.orientation : 0,
            nurses: gridresult?.required_nurses
              ? gridresult?.required_nurses
              : 0,
            res_brk_rn: gridresult?.required_res_brk_rn
              ? gridresult?.required_res_brk_rn
              : 0,
            sitter: gridresult?.sitter ? gridresult?.sitter : 0,
            unit_sec: gridresult?.unit_sec ? gridresult?.unit_sec : 0,
          };
        }

        if (event) {
          setFormdata({
            ...formdata,
            ...VarianceDataObj,
            total_patients: sum,
            [event.target.name]: event.target.value,
          });
        } else {
          setFormdata({
            ...formdata,
            ...VarianceDataObj,
            total_patients: sum,
          });
        }
        setVariance(StaffingDataObj);
        setTotalVar(Object.values(StaffingDataObj).reduce((a, b) => a + b, 0));
        //el.forEach(item => item.removeAttribute('disabled'));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const PreviousgetDirectData = async (title) => {
    try {
      // alert(title)
      //console.log(contentId, "contentId |||")
      const resp = await axios.post(
        API_CALL_URL_PRIVATE + "/department/getDirectDataById",
        contentId
      );
      const { data } = await resp;
      if (title == "PatientData") {
        setpreviousPatientData(data?.data?.directData);
      }

      if (title == "formData") {
        // setPreviousFormData(data?.data?.directData)
      }

      if (title == "AdditionalDataTracking") {
        setPreviousAdditionalDataTracking(data?.data?.directData);
      }

      //console.log("response getDirectDataById data", directData);
      setSuccessmsg(data.message);
      //   if (data.message == "Data Saved Succesfully") {
      callbackfunc(false, true, data.message);
      //   }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // PreviousgetDirectData("PatientData")
    // PreviousgetDirectData("formData")
    // PreviousgetDirectData("AdditionalDataTracking")
  }, []);

  const modalsshide = () => {
    setFormdata(init);
    setTotalPatientVar(0);
    setVariance({});
    setTotalVar(0);
    reloaddata();
    callbackfunc(false, false, successMsg);
  };

  
  const callBacksuccess = () => {};
  return (
    <Modal show={isShow} onHide={modalsshide} centered>
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content pb-4">
          <div className="modal-header border-0 pb-3 justify-content-center position-relative pe-5">
            <h5 className="modal-title fw-400">
              Submitting - {cost_center_name}&nbsp;-&nbsp;
              <span className="">
                {activedatefilter?.shift_date} {activedatefilter?.shift_time}
                {/* getprevAndCurrentShift?.currentDateShift */}{" "}
                {/* getprevAndCurrentShift?.currentShift */}
              </span>
            </h5>
            {/* <!-- <button type="button" className="ms-auto btn btn-blue text-capitalize f-12 fw-500"><i className="fa fa-plus-circle" aria-hidden="true"></i> Pull From Previous Shift (7AM)</button> --> */}
            <button
              type="button"
              onClick={modalsshide}
              className="btn-close ms-5 position-absolute right-20 bg-transparent"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              X
            </button>
          </div>
          <div className="modal-body inputnumbericonhide py-0 para-5">
            <div className="row">
              <div className="col-md-3 border-end w1">
                <div className=" text-center">
                  <h6 className="fw-400 fw lh-1 primary-font">Patient Data</h6>
                </div>
                <table className="w-100 mb-2 m-g-2">
                  <tbody>
                    <tr className="hd">
                      <th></th>
                      <th></th>
                      <th>
                        {/* Previous */}
                        {/* <br /> {getprevAndCurrentShift?.previousShift} */}
                      </th>
                      <th>{activedatefilter?.shift_time} Shift</th>{" "}
                      {/* {getprevAndCurrentShift?.currentShift}  */}
                    </tr>
                    <tr>
                      <td className="t">Acuity</td>
                      <td class="img">
                        {/* <img src={AcuityIcon} alt="" /> */}
                        <img src={themeMode.theme === "DARK" ? AcuityIcon1 : AcuityIcon} alt="" />
                      </td>
                      {/* kapil chauhan - according to previous code previous shift is previousShiftData and new shift is directDataApiData */}
                      <td>
                        {/* <input
                          className="form-control"
                          name="acuity1"
                          value={directDataApiData?.acuity}
                          type="text"
                          disabled
                          aria-label=" input example"
                          readonly
                        /> */}
                      </td>
                      <td>
                        <input
                          className={`form-control ${
                            formdata?.acuity === "" ? "redborder" : ""
                          }`}
                          name="acuity"
                          value={
                            formdata?.acuity != null ? formdata.acuity : ""
                          }
                          type="number"
                          onChange={handleChange}
                          aria-label=" input example"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td class="t">Patient Acuity</td>
                      <td class="img">
                        <span class="circle-i">2:1</span>
                      </td>
                      <td>
                        {/* <input
                          className="form-control"
                          name="one_one1"
                          value={directDataApiData?.one_one}
                          type="text"
                          disabled
                          onChange={handleChange}
                          aria-label=" input example"
                          readonly
                        /> */}
                      </td>
                      <td>
                        <input
                          className={`form-control apicall ${
                            formdata?.two_one === "" ? "redborder" : ""
                          }`}
                          name="two_one"
                          value={
                            formdata?.two_one !== null ? formdata.two_one : ""
                          }
                          type="number"
                          onChange={handleChange}
                          aria-label=" input example"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td class="t">Patient Acuity</td>
                      <td class="img">
                        <span class="circle-i">1:1</span>
                      </td>
                      <td>
                        {/* <input
                          className="form-control"
                          name="one_one1"
                          value={directDataApiData?.one_one}
                          type="text"
                          disabled
                          onChange={handleChange}
                          aria-label=" input example"
                          readonly
                        /> */}
                      </td>
                      <td>
                        <input
                          className={`form-control apicall ${
                            formdata?.one_one === "" ? "redborder" : ""
                          }`}
                          name="one_one"
                          value={
                            formdata?.one_one !== null ? formdata.one_one : ""
                          }
                          type="number"
                          onChange={handleChange}
                          aria-label=" input example"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td class="t">Patient Acuity</td>
                      <td class="img">
                        <span class="circle-i">1:2</span>
                      </td>
                      <td>
                        {/* <input
                          className="form-control"
                          name="one_two1"
                          value={directDataApiData?.one_two}
                          type="text"
                          disabled
                          onChange={handleChange}
                          aria-label=" input example"
                          readonly
                        /> */}
                      </td>
                      <td>
                        <input
                          className={`form-control apicall ${
                            formdata?.one_two === "" ? "redborder" : ""
                          }`}
                          name="one_two"
                          value={
                            formdata?.one_two != null ? formdata.one_two : ""
                          }
                          type="number"
                          onChange={handleChange}
                          aria-label=" input example"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td class="t">Patient Acuity</td>
                      <td class="img">
                        <span class="circle-i">1:3</span>
                      </td>
                      <td>
                        {/* <input
                          className="form-control"
                          name="one_three1"
                          value={directDataApiData?.one_three}
                          type="text"
                          disabled
                          onChange={handleChange}
                          aria-label=" input example"
                          readonly
                        /> */}
                      </td>
                      <td>
                        <input
                          className={`form-control apicall ${
                            formdata?.one_three === "" ? "redborder" : ""
                          }`}
                          name="one_three"
                          value={
                            formdata?.one_three !== null
                              ? formdata?.one_three
                              : ""
                          }
                          type="number"
                          onChange={handleChange}
                          aria-label=" input example"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td class="t">Patient Acuity</td>
                      <td class="img">
                        <span class="circle-i">1:4</span>
                      </td>
                      <td>
                        {/* <input
                          className="form-control"
                          name="one_four1"
                          value={directDataApiData?.one_four}
                          type="text"
                          disabled
                          onChange={handleChange}
                          aria-label=" input example"
                          readonly
                        /> */}
                      </td>
                      <td>
                        <input
                          className={`form-control apicall ${
                            formdata?.one_four === "" ? "redborder" : ""
                          }`}
                          name="one_four"
                          value={
                            formdata?.one_four != null ? formdata?.one_four : ""
                          }
                          type="number"
                          onChange={handleChange}
                          aria-label=" input example"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td class="t">Patient Acuity</td>
                      <td class="img">
                        <span class="circle-i">1:5</span>
                      </td>
                      <td>
                        {/* <input
                          className="form-control"
                          name="one_five1"
                          value={directDataApiData?.one_five}
                          type="text"
                          disabled
                          onChange={handleChange}
                          aria-label=" input example"
                          readonly
                        /> */}
                      </td>
                      <td>
                        <input
                          className={`form-control apicall ${
                            formdata?.one_five === "" ? "redborder" : ""
                          }`}
                          name="one_five"
                          value={
                            formdata?.one_five != null ? formdata?.one_five : ""
                          }
                          type="number"
                          onChange={handleChange}
                          aria-label=" input example"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td class="t">Patient Acuity</td>
                      <td class="img">
                        <span class="circle-i">1:6</span>
                      </td>
                      <td>
                        {/* <input
                          className="form-control"
                          name="one_five1"
                          value={directDataApiData?.one_five}
                          type="text"
                          disabled
                          onChange={handleChange}
                          aria-label=" input example"
                          readonly
                        /> */}
                      </td>
                      <td>
                        <input
                          className={`form-control apicall ${
                            formdata?.one_six === "" ? "redborder" : ""
                          }`}
                          name="one_six"
                          value={
                            formdata?.one_six != null ? formdata?.one_six : ""
                          }
                          type="number"
                          onChange={handleChange}
                          aria-label=" input example"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="t">Total Patients</td>
                      <td class="img">
                        {/* <img src={BedIcon} alt="" /> */}
                        <img src={themeMode.theme === "DARK" ? BedIcon1 : BedIcon} alt="" />
                      </td>
                      <td>
                        {/* <button
                          type="button"
                          className="ms-auto btn grey-btn arrow-btn text-capitalize tooltip left-tool position-relative"
                        >
                          <div
                            onClick={() => {
                              getDirectDataById("PatientData");
                            }}
                            className="arrowicon"
                          >
                            <img src={RightIcon} />
                          </div>
                          <span className="tooltiptext">
                            Fill from previous shift?
                          </span>
                        </button> */}
                      </td>
                      <td>
                        <hr className="totalline my-1" />
                        <div
                          type="button"
                          className={`tooltip left-tool position-relative ${
                            totalPatientvariance < 0 ? "textdanger" : ""
                          }`}
                        >
                          {totalPatientvariance}
                          <span class="tooltiptext">Total</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="col mt-4">
                  <div class="f-14 text-uppercase text-start">
                    Submitted By: PerfectShift
                  </div>
                </div>
              </div>
              <div className="col-md-5 w2">
                <div className=" text-center">
                  <h6 className="fw-400 fw lh-1">Staffing</h6>
                </div>
                <table className="w-100 mb-2 m-g-2">
                  <tbody>
                    <tr className="hd">
                      <th></th>
                      <th></th>
                      <th>
                        {/* Previous */}
                        {/* <br /> {getprevAndCurrentShift?.previousShift} */}
                      </th>
                      <th>{activedatefilter?.shift_time} Shift</th>{" "}
                      {/* getprevAndCurrentShift?.currentShift */}
                      <th>Grid</th>
                      <th>Variance</th>
                    </tr>
                    <tr>
                      <td className="t">Charge:</td>
                      <td class="img">
                        {/* <img src={ChargeIcon} alt="" /> */}
                        <img src={themeMode.theme === "DARK" ? ChargeIcon1 : ChargeIcon} alt="" />
                      </td>
                      <td>
                        {/* <input
                          className="form-control"
                          type="text"
                          value={directDataApiData?.charge}
                          disabled
                          aria-label=" input example"
                          readonly
                        /> */}
                      </td>
                      <td>
                        <input
                          name="charge"
                          value={
                            formdata?.charge != null ? formdata?.charge : ""
                          }
                          className={`form-control ${
                            formdata?.charge === "" ? "redborder" : ""
                          }`}
                          onChange={handleChange}
                          type="number"
                          aria-label="input example"
                        />
                      </td>
                      <td>
                        <input
                          className="form-control"
                          type="text"
                          value={
                            gridData?.required_charge
                              ? gridData?.required_charge
                              : 0
                          }
                          disabled
                          aria-label=" input example"
                          readonly
                        />
                      </td>
                      <td>
                        <div
                          type="button"
                          class="tooltip left-tool position-relative"
                        >
                          <input
                            className={`form-control ${
                              variance?.charge < 0 ? "red-text" : ""
                            }`}
                            type="text"
                            value={variance?.charge}
                            disabled
                            aria-label=" input example"
                            readonly
                          />
                          <span class="tooltiptext">Charge</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="t">CNA:</td>
                      <td class="img">
                        {/* <img src={CnaIcon} alt="" /> */}
                        <img src={themeMode.theme === "DARK" ? CnaIcon1 : CnaIcon} alt="" />
                      </td>
                      <td>
                        {/* <input
                          className="form-control"
                          type="text"
                          value={directDataApiData?.cna}
                          disabled
                          aria-label=" input example"
                          readonly
                        /> */}
                      </td>
                      <td>
                        <input
                          className={`form-control ${
                            formdata?.cna === "" ? "redborder" : ""
                          }`}
                          name="cna"
                          value={formdata?.cna != null ? formdata?.cna : ""}
                          type="number"
                          onChange={handleChange}
                          aria-label=" input example"
                        />
                      </td>
                      <td>
                        <input
                          className="form-control"
                          type="text"
                          value={
                            gridData?.required_cna ? gridData?.required_cna : 0
                          }
                          disabled
                          aria-label=" input example"
                          readonly
                        />
                      </td>

                      <td>
                        <div
                          type="button"
                          class="tooltip left-tool position-relative"
                        >
                          <input
                            className={`form-control ${
                              variance?.cna < 0 ? "red-text" : ""
                            }`}
                            type="text"
                            value={variance?.cna}
                            disabled
                            aria-label=" input example"
                            readonly
                          />
                          <span class="tooltiptext">CNA</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="t">Orientation:</td>
                      <td class="img">
                        {/* <img src={OrientationIcon} alt="" /> */}
                        <img src={themeMode.theme === "DARK" ? OrientationIcon1 : OrientationIcon} alt="" />
                      </td>
                      <td>
                        {/* <input
                          className="form-control"
                          type="text"
                          value={directDataApiData?.orientation}
                          disabled
                          aria-label=" input example"
                          readonly
                        /> */}
                      </td>
                      <td>
                        <input
                          className={`form-control ${
                            formdata?.orientation === "" ? "redborder" : ""
                          }`}
                          name="orientation"
                          type="number"
                          value={
                            formdata?.orientation != null
                              ? formdata?.orientation
                              : ""
                          }
                          onChange={handleChange}
                          aria-label=" input example"
                        />
                      </td>
                      <td>
                        <input
                          className="form-control"
                          type="text"
                          value={
                            gridData?.orientation ? gridData?.orientation : 0
                          }
                          disabled
                          aria-label=" input example"
                          readonly
                        />
                      </td>

                      <td>
                        <div
                          type="button"
                          class="tooltip left-tool position-relative"
                        >
                          <input
                            className={`form-control ${
                              variance?.orientation < 0 ? "red-text" : ""
                            }`}
                            type="text"
                            value={variance?.orientation}
                            disabled
                            aria-label=" input example"
                            readonly
                          />
                          <span class="tooltiptext">Orientation</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="t">RN:</td>
                      <td class="img">
                        {/* <img src={NurseIcon} alt="" /> */}
                        <img src={themeMode.theme === "DARK" ? NurseIcon1 : NurseIcon} alt="" />
                      </td>
                      <td>
                        {/* <input
                          className="form-control"
                          type="text"
                          value={directDataApiData?.nurses}
                          disabled
                          aria-label=" input example"
                          readonly
                        /> */}
                      </td>
                      <td>
                        <input
                          className={`form-control ${
                            formdata?.nurses === "" ? "redborder" : ""
                          }`}
                          name="nurses"
                          type="number"
                          value={
                            formdata?.nurses != null ? formdata?.nurses : ""
                          }
                          onChange={handleChange}
                          aria-label=" input example"
                        />
                      </td>
                      <td>
                        <input
                          className="form-control"
                          type="text"
                          value={
                            gridData?.required_nurses
                              ? gridData?.required_nurses
                              : 0
                          }
                          disabled
                          aria-label=" input example"
                          readonly
                        />
                      </td>

                      <td>
                        <div
                          type="button"
                          class="tooltip left-tool position-relative"
                        >
                          <input
                            className={`form-control ${
                              variance?.nurses < 0 ? "red-text" : ""
                            }`}
                            type="text"
                            value={variance?.nurses}
                            disabled
                            aria-label=" input example"
                            readonly
                          />
                          <span class="tooltiptext">RN</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="t">Res/Brk RN:</td>
                      <td class="img">
                        {/* <img src={ReseIcon} alt="" /> */}
                        <img src={themeMode.theme === "DARK" ? ReseIcon1 : ReseIcon} alt="" />
                      </td>
                      <td>
                        {/* <input
                          className="form-control"
                          type="text"
                          value={directDataApiData?.res_brk_rn}
                          disabled
                          aria-label=" input example"
                          readonly
                        /> */}
                      </td>
                      <td>
                        <input
                          className={`form-control ${
                            formdata?.res_brk_rn === "" ? "redborder" : ""
                          }`}
                          name="res_brk_rn"
                          type="number"
                          value={
                            formdata?.res_brk_rn != null
                              ? formdata?.res_brk_rn
                              : ""
                          }
                          onChange={handleChange}
                          aria-label=" input example"
                        />
                      </td>
                      <td>
                        <input
                          className="form-control"
                          type="text"
                          value={
                            gridData?.required_res_brk_rn
                              ? gridData?.required_res_brk_rn
                              : 0
                          }
                          disabled
                          aria-label=" input example"
                          readonly
                        />
                      </td>

                      <td>
                        <div
                          type="button"
                          class="tooltip left-tool position-relative"
                        >
                          <input
                            className={`form-control ${
                              variance?.res_brk_rn < 0 ? "red-text" : ""
                            }`}
                            type="text"
                            value={variance?.res_brk_rn}
                            disabled
                            aria-label=" input example"
                            readonly
                          />
                          <span class="tooltiptext">Res/Brk RN</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="t">Unit Sec:</td>
                      <td class="img">
                        {/* <img src={UnitIcon} alt="" /> */}
                        <img src={themeMode.theme === "DARK" ? UnitIcon1 : UnitIcon} alt="" />
                      </td>
                      <td>
                        {/* <input
                          className="form-control"
                          type="text"
                          value={directDataApiData?.unit_sec}
                          disabled
                          aria-label=" input example"
                          readonly
                        /> */}
                      </td>
                      <td>
                        <input
                          className={`form-control ${
                            formdata?.unit_sec === "" ? "redborder" : ""
                          }`}
                          name="unit_sec"
                          type="number"
                          value={
                            formdata?.unit_sec != null ? formdata?.unit_sec : ""
                          }
                          onChange={handleChange}
                          aria-label=" input example"
                        />
                      </td>
                      <td>
                        <input
                          className="form-control"
                          type="text"
                          value={gridData?.unit_sec ? gridData?.unit_sec : 0}
                          disabled
                          aria-label=" input example"
                          readonly
                        />
                      </td>

                      <td>
                        <div
                          type="button"
                          class="tooltip left-tool position-relative"
                        >
                          <input
                            className={`form-control ${
                              variance?.unit_sec < 0 ? "red-text" : ""
                            }`}
                            type="text"
                            value={variance?.unit_sec}
                            disabled
                            aria-label=" input example"
                            readonly
                          />
                          <span class="tooltiptext">Unit Sec</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="t">Sitter:</td>
                      <td class="img last-i">
                        {/* <img src={SitterIcon} alt="" /> */}
                        <img src={themeMode.theme === "DARK" ? SitterIcon1 : SitterIcon} alt="" />
                      </td>
                      <td>
                        {/* <input
                          className="form-control"
                          type="text"
                          value={directDataApiData?.sitter}
                          disabled
                          aria-label=" input example"
                          readonly
                        /> */}
                      </td>
                      <td>
                        <input
                          className={`form-control ${
                            formdata?.sitter === "" ? "redborder" : ""
                          }`}
                          name="sitter"
                          type="number"
                          value={
                            formdata?.sitter != null ? formdata?.sitter : ""
                          }
                          onChange={handleChange}
                          aria-label=" input example"
                        />
                      </td>
                      <td>
                        <input
                          className="form-control"
                          type="text"
                          value={gridData?.sitter ? gridData?.sitter : 0}
                          disabled
                          aria-label=" input example"
                          readonly
                        />
                      </td>

                      <td>
                        <div
                          type="button"
                          class="tooltip left-tool position-relative"
                        >
                          <input
                            className={`form-control ${
                              variance?.sitter < 0 ? "red-text" : ""
                            }`}
                            type="text"
                            value={variance?.sitter}
                            disabled
                            aria-label=" input example"
                            readonly
                          />
                          <span class="tooltiptext">Sitter</span>
                        </div>
                      </td>
                    </tr>

                    {/* <tr>
                                        <td className="t">Unit Sec:</td>
                                        <td><input className="form-control" type="text" value="" aria-label=" input example" readonly /></td>
                                        <td><input className="form-control" type="text" value="" aria-label=" input example" /></td>
                                        <td><input className="form-control" type="text" value={gridData?.orientation} disabled  aria-label=" input example" readonly /></td>
                                        <td><input className="form-control" type="text" value="" aria-label=" input example" readonly /></td>
                                    </tr> */}
                    <tr>
                      <td className="t"></td>
                      <td></td>
                      <td className="pt-2">
                        {/* <button
                          type="button"
                          className="ms-auto btn grey-btn arrow-btn text-capitalize tooltip left-tool position-relative"
                        >
                          <div
                            onClick={() => {
                              getDirectDataById("Staffing");
                            }}
                            className="arrowicon"
                          >
                            <img src={RightIcon} />
                          </div>
                          <span className="tooltiptext">
                            Fill from previous shift?
                          </span>
                        </button> */}
                      </td>
                      <td></td>
                      <td className="pt-2">
                        {/* {totalPatientvariance > 0 ? (
                          <button
                            type="button"
                            className="ms-auto btn grey-btn arrow-btn text-capitalize tooltip left-tool position-relative"
                          >
                            <div
                              onClick={() => {
                                getDirectDataById("Grid");
                              }}
                              className="arrowicon"
                            >
                              <img src={LeftIcon} />
                            </div>
                            <span className="tooltiptext">
                              Use Grid for Actuals?
                            </span>
                          </button>
                        ) : null} */}
                      </td>
                      <td>
                        <hr className="totalline my-1" />
                        <div
                          type="button"
                          className={`tooltip left-tool position-relative ${
                            totalvariance < 0 ? "textdanger" : ""
                          }`}
                        >
                          {totalvariance}
                          <span class="tooltiptext">Total</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-md-4 border-start">
                <div className=" text-center">
                  <h6 className="fw-400 fw lh-1">Additional Data Tracking</h6>
                </div>

                <table className="w-100 mb-2 m-g-2">
                  <tbody>
                    <tr className="hd">
                      <th></th>
                      <th></th>
                      <th>
                        {/* Previous */}
                        {/* <br /> {getprevAndCurrentShift?.previousShift} */}
                      </th>
                      <th>{activedatefilter?.shift_time} Shift</th>{" "}
                      {/* getprevAndCurrentShift?.currentShift */}
                    </tr>
                    <tr>
                      <td className="t">
                        # Anticipated Admits/Transfers in this Shift
                      </td>
                      <td class="img">
                        {/* <img src={AdmitsIcon} alt="" /> */}
                        <img src={themeMode.theme === "DARK" ? AdmitsIcon1 : AdmitsIcon} alt="" />
                      </td>
                      <td>
                        {/* <input
                          className="form-control"
                          type="text"
                          value={directDataApiData?.ant_admit}
                          disabled
                          aria-label=" input example"
                          readonly
                        /> */}
                      </td>
                      <td>
                        <input
                          className={`form-control ${
                            formdata?.ant_admit === "" ? "redborder" : ""
                          }`}
                          type="number"
                          onChange={handleChange}
                          name="ant_admit"
                          value={
                            formdata?.ant_admit != null
                              ? formdata?.ant_admit
                              : ""
                          }
                          aria-label=" input example"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="t">
                        # Actual Admits/Transfers in previous Shift
                      </td>
                      <td class="img">
                        {/* <img src={DischargesIcon} alt="" /> */}
                        <img src={themeMode.theme === "DARK" ? DischargesIcon1 : DischargesIcon} alt="" />
                      </td>
                      <td>
                        {/* <input
                          className="form-control"
                          type="text"
                          value={directDataApiData?.actual_admit}
                          disabled
                          aria-label=" input example"
                          readonly
                        /> */}
                      </td>
                      <td>
                        <input
                          className={`form-control ${
                            formdata?.actual_admit === "" ? "redborder" : ""
                          }`}
                          type="number"
                          onChange={handleChange}
                          name="actual_admit"
                          value={
                            formdata?.actual_admit != null
                              ? formdata?.actual_admit
                              : ""
                          }
                          aria-label=" input example"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="t">Telemetry Patients</td>
                      <td class="img">
                        {/* <img src={TpatientsIcon} alt="" /> */}
                        <img src={themeMode.theme === "DARK" ? TpatientsIcon1 : TpatientsIcon} alt="" />
                      </td>
                      <td>
                        {/* <input
                          className="form-control"
                          type="text"
                          value={directDataApiData?.telemetry}
                          disabled
                          aria-label=" input example"
                          readonly
                        /> */}
                      </td>
                      <td>
                        <input
                          className={`form-control ${
                            formdata?.telemetry === "" ? "redborder" : ""
                          }`}
                          type="number"
                          onChange={handleChange}
                          name="telemetry"
                          value={
                            formdata?.telemetry != null
                              ? formdata?.telemetry
                              : ""
                          }
                          aria-label=" input example"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="t">Vaso-Active Drips</td>
                      <td class="img last-i">
                        {/* <img src={DripsIcon} alt="" /> */}
                        <img src={themeMode.theme === "DARK" ? DripsIcon1 : DripsIcon} alt="" />
                      </td>
                      <td>
                        {/* <input
                          className="form-control"
                          type="text"
                          value={directDataApiData?.vaso_active_drips}
                          disabled
                          aria-label=" input example"
                          readonly
                        /> */}
                      </td>
                      <td>
                        <input
                          className={`form-control ${
                            formdata?.vaso_active_drips === ""
                              ? "redborder"
                              : ""
                          }`}
                          type="number"
                          onChange={handleChange}
                          name="vaso_active_drips"
                          value={
                            formdata?.vaso_active_drips != null
                              ? formdata?.vaso_active_drips
                              : ""
                          }
                          aria-label=" input example"
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="t"></td>
                      <td className="pt-2">
                        {/* <button
                          type="button"
                          className="ms-auto btn grey-btn arrow-btn text-capitalize tooltip left-tool position-relative"
                        >
                          <div
                            onClick={() => {
                              getDirectDataById("AdditionalDataTracking");
                            }}
                            className="arrowicon"
                          >
                            <img src={RightIcon} />
                          </div>
                          <span className="tooltiptext">
                            Fill from previous shift?
                          </span>
                        </button> */}
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>

                <div className="row">
                  <div className="col-md-12">
                    <div className="m-f14">
                      Comments / Barriers / Action Plan{" "}
                    </div>
                    <div className="mt-1">
                      <textarea
                        className="form-control full"
                        onChange={handleChange}
                        name="comment"
                        value={
                          formdata?.comment != null ? formdata?.comment : ""
                        }
                        placeholder="Type Here..."
                        style={{ height: "70px" }}
                      ></textarea>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col">
                      <button
                        type="button"
                        onClick={modalsshide}
                        className="btn btn-red text-capitalize  f-12 fw-400 w-100"
                      >
                        {" "}
                        Cancel
                      </button>
                    </div>
                    <div className="col">
                      <button
                        type="button"
                        onClick={submitHandle}
                        className="btn btn-success text-capitalize  f-12 fw-400 w-100"
                      >
                        Submit{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="modal-footer border-0 pt-0 texxt-center justify-content-center mt-1" >
                        <div className="w-100 row align-items-center justify-content-end f-12">
                            <div className="col-md-4 ps-4">
                                
                            </div>
                            
                        </div>

                    </div> */}
        </div>
      </div>
      <ErrorModal isShow={showError} title="Error" content={successMsg} 
        callbackfunc={() => setShowError(false)}/>
    </Modal>
  );
}

export default AddModal;
