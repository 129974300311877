import * as TL from "../../Styled/Todoliststyle";
import { AiTwotoneDelete } from "react-icons/ai";

const TodoItems = (prop) => {
    return (
        <>
            <TL.Label>
                {/* eslint-disable-next-line react/prop-types */}
                <span>{prop.text}</span>
                {/* eslint-disable-next-line react/prop-types */}
                <AiTwotoneDelete size={"1.1rem"} style={{ color: "#0090e7" }} onClick={() => {
                    prop.onSelect(prop.id);
                }} />
                <input type="checkbox" />
                <span className="checkmark"></span>
            </TL.Label>
        </>
    );
};

export default TodoItems;
