import "./index.css";
import { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getdataService } from "../../services";
import Realtime from "./realtime";
import { useParams, useNavigate, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import PageLayout from "../../layouts/PageLayout";
import BarChart from "../../components/shared/BarChart";
import AreaChart from "../../components/shared/AreaChart";
import LineChart from "../../components/shared/LineChart";
import CurrentPrevModal from "../../components/CurrentPrevModal";
import RadialBarChart from "../../components/shared/RadialBar";
import SmallRadialChart from "../../components/shared/SmallRadialChart";
import { API_CALL_URL_PUBLIC } from "../../constants/";
import { API_CALL_URL_PRIVATE } from "../../constants/";
import axios from "../../axios";
import {
  setDepartmentDetail,
  setHeadCount,
  getPreviousAndCurrentShift,
  activedatefilter,
} from "../../store/actions/Compensate";
import ContentLoader from "react-content-loader";
// import { SocketContext } from "../../socket";
import "font-awesome/css/font-awesome.min.css";
import moment from "moment";
import Moment from "moment";
import { ChartExample } from "../../components/shared/PieChart/m-pei-chart";
import { ThemeContext } from "../../providers/ThemeProvider";

const MyLoader = ({ themeMode }) => (
  <div style={{ display: "table-caption" }}>
    <ContentLoader viewBox="0 0 100 50"
      backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
      foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
    >
      {/* Only SVG shapes */}
      <rect x="0" y="0" rx="0" ry="0" width="100" height="4.5" />
      <rect x="0" y="4.7" rx="0" ry="0" width="100" height="4.5" />
      <rect x="0" y="9.4" rx="0" ry="0" width="100" height="4.5" />
      <rect x="0" y="14.1" rx="0" ry="0" width="100" height="4.5" />
      <rect x="0" y="18.8" rx="0" ry="0" width="100" height="4.5" />
      <rect x="0" y="23.5" rx="0" ry="0" width="100" height="4.5" />
      <rect x="0" y="28.2" rx="0" ry="0" width="100" height="4.5" />
      <rect x="0" y="32.9" rx="0" ry="0" width="100" height="4.5" />
    </ContentLoader>
  </div>
);

const ChartLoader = ({ themeMode }) => (
  <div className="col-md-4 width-1">
    <ContentLoader viewBox="0 0 100 100"
      backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
      foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
    >
      {/* Only SVG shapes */}
      <rect x="1.2" y="2" rx="4" ry="4" width="87" height="90" />
    </ContentLoader>
  </div>
);

const data = {
  fte_variance: 20,
  rn_variance: 10,
  timestamp: "27-03-2023",
  cost_center_name: "1 West",
  total_fte: "34",
  average_acutiy_score_for_occupied_beds: 30,
  occupied_beds: 8,
  total_available_beds: 10,
};

function Currentperiod({ id, urlparam, urltype }) {
  //const {id} = props;
  //console.log(props);
  const [themeMode, setTheme] = useContext(ThemeContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  const detail_data = selector.compensate.departmentdetail;
  //const headcount = detail_data?.header ? detail_data.header : {};
  const [apidata, setApidata] = useState([]);
  const [period, setPeriod] = useState("currentperiod");
  const [show, setShow] = useState(false);
  const [totalvalue, setTotalvalue] = useState({});
  const [headerdata, setHeaderdata] = useState({});
  const [activedate, setActivedate] = useState("");
  const [radiaBarChart, setRadiaBarChart] = useState([]);
  const [chartName, setChartName] = useState("");
  const [paramval, setparamVal] = useState(urlparam);
  const [colorData, setColorData] = useState('');

  const [isLoader, setIsLoader] = useState(true);

  useEffect(() => {
    //setPeriod(urltype);
    fetchDetails();
    // autoFillgetDirectDataById()
  }, [id, urltype]);

  const outerringval = (e) => {
    if (e >= 100) {
      return "#48b461";
    } else if (e < 100 && e >= 98) {
      return "#ffff26";
    } else {
      return "#ff2626";
    }
  };

  async function fetchDetails() {
    if (id) {
      setIsLoader(true);
      const params = {
        cost_center_number: id,
        pay_period_type: urltype === "currentperiod" ? "current" : "previous",
      };
      const resp = await axios.post(
        API_CALL_URL_PRIVATE + "/department/getPayPeriodData",
        params
      );
      const { data } = resp;


      const sum = data.data?.contentData.reduce(function (
        previousValue,
        currentValue
      ) {
        return {
          uos_total: previousValue.uos_total + currentValue.uos_total,
          worked_hours: previousValue.worked_hours + currentValue.worked_hours,
          target_hours: previousValue.target_hours + currentValue.target_hours,
          hpuos: previousValue.hpuos + currentValue.hpuos,
          hours_variance:
            previousValue.hours_variance + currentValue.hours_variance,
        };
      });
      setTotalvalue(sum);
      setHeaderdata(data.data?.header);
      setRadiaBarChart([
        data.data?.leftSideBar[0]?.compliance,
        data.data?.leftSideBar[0]?.productivity,
      ]);
      setColorData(outerringval(data.data?.leftSideBar[0]?.compliance));
      // setColorData(outerringval('120'));
      setChartName(data.data?.leftSideBar[0]?.cost_center_name);
      setApidata(data.data?.contentData);
      setIsLoader(false);
    }
  }
  let bararrone = [];
  let bararrtwo = [];
  let bararrthree = [];

  const barchartdata = apidata.map((item) => {
    bararrone.push(item?.uos_total);
    bararrtwo.push(item?.worked_hours);
    bararrthree.push(item?.target_hours);
    //ids.push(item.id);
    //isarrEmpty = false;
    return item;
  });
  const barchartseries = [
    { name: "UOS", data: bararrone },
    // { name: "Worked Hours", data: bararrtwo },
    // { name: "Target Hours", data: bararrthree },
  ];

  const handlerclick = (key) => {
    navigate(
      "http://localhost:3000/realtime/department-details/" +
        urlparam +
        "/" +
        key
    );
  };

  const currentprevhandlerclick = async (date) => {
    const datearr = date.split("/");
    const filterdate = `${datearr[1]}-${datearr[0]}-${datearr[2]}`;
    const params = {
      cost_center_number: id,
      fromdate: {
        date: filterdate,
        shift: "03:00",
      },
      todate: {
        date: filterdate,
        shift: "23:00",
      },
    };
    const resp = getdataService(dispatch, params);
    //let { data } = await resp;
    setShow(true);
    setActivedate(date);
    //console.log(data);
  };


  return (
    <PageLayout
      data={headerdata}
      issearch={false}
      isshow={true}
      isLoader={false}
      callbackfunc={""}
      isCallback={false}
      cost_center_number={null}
      filterdate={""}
    >
      <div className="row mt-2 gx-2">
        <div className="col-md-8">
          <div className="row gx-2 h-100">
            <div className="col-md-4  ">
              <div className="white-bg shadow-box h-100 11">
                <div
                  className="box-heading text-center"
                  style={{ marginBottom: "-10px" }}
                >
                  Productivity & Completion Status 
                  <div class=" text-center pt-2 ">
                    <strong class="m-f14 primary-font color-black">
                      {chartName}
                    </strong>
                  </div>
                </div>
                {!isLoader ? (
                  <RadialBarChart newColor={colorData} series={radiaBarChart} />
                ) : (
                    <ChartLoader themeMode={themeMode} />
                )}

                <div className="d-flex text-center flex-wrap">
                  <Link
                    className={
                      "flex-grow-1 me-1 border-btn f-12 cursor-pointer green-b w-100"
                    }
                    to={`/realtime/department-details/${urlparam}/realtime`}
                  >
                    Real Time
                  </Link>
                  <Link
                    className={`flex-grow-1 me-1 border-btn f-12 cursor-pointer green-b ${
                      urltype == "currentperiod" ? "active" : ""
                    }`}
                    to={`/realtime/department-details/${urlparam}/currentperiod`}
                  >
                    Current Period
                  </Link>
                  <Link
                    className={`flex-grow-1 border-btn f-12 cursor-pointer green-b ${
                      urltype == "previousperiod" ? "active" : ""
                    }`}
                    to={`/realtime/department-details/${urlparam}/previousperiod`}
                  >
                    Previous Period 
                  </Link>
                </div>
                <div className="recordshowing text-center mt-3">
                  {apidata.length > 0
                    ? `${apidata[0].date} - ${apidata[apidata.length - 1].date}`
                    : ""}
                </div>
              </div>
            </div>
            <div className="col-md-8 ">
              <div className="white-bg shadow-box h-100">
                <div className="box-heading text-center">Biweekly Summary</div>
                <div className="overlfow-auto h-607">
                  <table className="table  f-12 mb-0 mt-2 table table-bordered tb2 table-hover">
                    <thead>
                      <tr>
                        <th className="text-center">Date</th>
                        <th className="text-center"></th>
                        <th className="text-center">UOS</th>
                        <th className="text-center">Worked Hrs</th>
                        <th className="text-center">Target Hrs</th>
                        <th className="text-center">HPUOS</th>
                        <th className="text-center">Hrs Variance</th>
                      </tr>
                    </thead>
                    {!isLoader ? (
                      <tbody>
                        {apidata.length > 0
                          ? apidata.map((item, index) => {
                              return (
                                <tr
                                  key={index}
                                  data-bs-toggle="modal"
                                  data-bs-target="#depModal"
                                  onClick={() =>
                                    currentprevhandlerclick(item?.date)
                                  }
                                >
                                  <td className="text-center pointer">
                                    {moment(item?.date).format("MM/DD")}
                                  </td>
                                  <td
                                    className="text-center pointer"
                                    style={{ width: "40px", height: "20px" }}
                                  >
                                    <div
                                      style={{
                                        marginRight: 0,
                                        position: "relative",
                                        height: "30px",
                                        top: -8,
                                      }}
                                    >
                                      <SmallRadialChart
                                        series={[
                                          item.inner_productivity,
                                          item.total_compliance,
                                        ]}
                                        newColor={colorData}
                                        isLabel={false}
                                      />
                                    </div>
                                  </td>
                                  <td className="text-center pointer">
                                    {item?.uos_total?.toFixed()}
                                  </td>
                                  <td className="text-center pointer">
                                    {item?.worked_hours?.toFixed(1)}
                                  </td>
                                  <td className="text-center pointer">
                                    {item?.target_hours?.toFixed(1)}
                                  </td>
                                  <td className="text-center pointer">
                                    {item?.hpuos?.toFixed(3)}
                                  </td>
                                  <td className="text-center pointer">
                                    {item?.hours_variance?.toFixed(1)}
                                  </td>
                                </tr>
                              );
                            })
                          : null}
                        <tr data-bs-toggle="modal" data-bs-target="#depModal">
                          <td className="text-center">Total</td>
                          <td className="text-center"></td>
                          <td className="text-center">
                            {new Intl.NumberFormat().format(
                              totalvalue?.uos_total?.toFixed()
                            )}
                          </td>
                          <td className="text-center">
                            {new Intl.NumberFormat("en-US", {
                              style: "decimal",
                              minimumFractionDigits: 1,
                              maximumFractionDigits: 1,
                            }).format(totalvalue?.worked_hours)}
                            {/* {new Intl.NumberFormat().format(
                              totalvalue?.worked_hours?.toFixed(1)
                            )} */}
                          </td>
                          <td className="text-center">
                            {new Intl.NumberFormat("en-US", {
                              style: "decimal",
                              minimumFractionDigits: 1,
                              maximumFractionDigits: 1,
                            }).format(totalvalue?.target_hours)}
                            {/* {new Intl.NumberFormat().format(
                              totalvalue?.target_hours?.toFixed(1)
                            )} */}
                          </td>
                          <td className="text-center">
                            {/* {new Intl.NumberFormat().format(
                              totalvalue?.hpuos?.toFixed(3)
                            )} */}
                            {totalvalue?.uos_total === 0 ? '0.000' : new Intl.NumberFormat("en-US", {
                              style: "decimal",
                              minimumFractionDigits: 3,
                              maximumFractionDigits: 3,
                            }).format((totalvalue?.worked_hours/ totalvalue?.uos_total))}
                          </td>
                          <td className="text-center">
                            {new Intl.NumberFormat("en-US", {
                              style: "decimal",
                              minimumFractionDigits: 1,
                              maximumFractionDigits: 1,
                            }).format(totalvalue?.hours_variance)}
                            {/* {new Intl.NumberFormat().format(
                              totalvalue?.hours_variance?.toFixed(1)
                            )} */}
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                        <MyLoader themeMode={themeMode} />
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 plr-4">
          <div class="white-bg shadow-box pb-0 h-50p">
            <div class="box-heading text-center">Workdrivers</div>
            <div class="row mt-2">
              <div class="col-md-12">
                <div class="text-center f-14 text-start white-text-dark-mode">
                  Percent Change of UOS and Worked Hours from Previous Period
                </div>
                <BarChart barchartseries={barchartseries} barchartcat={[]} />
              </div>
            </div>
          </div>
          <div class="white-bg shadow-box  pb-0 bd-2 h-50p">
            <div class="row mt-2">
              <div class="col-md-12">
                <div class="text-center f-14 text-start white-text-dark-mode">
                  Flexing Effective Score - Rolling-12 Pay Periods to Date
                </div>
                <AreaChart />
              </div>
            </div>
          </div>
        </div>
      </div>
      <CurrentPrevModal
        isShow={show}
        callbackfunc={() => setShow(false)}
        id={id}
        isLoader={isLoader}
        activedate={activedate}
        reloaddata={fetchDetails}
      />
    </PageLayout>
  );
}

export default Currentperiod;
