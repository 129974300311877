import React, { useContext, useEffect, useState } from "react";
import "./index.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ErrorMessage, Formik, Form as FormikForm } from "formik";
import { toast } from "react-toastify";
import axios from "../../axios";
import { API_CALL_URL_PRIVATE, DOMAIN_URL } from "../../constants/compensate";
import Texterror from "../Texterror";
import { departmentValidation } from "../../utils/validator";
import ReactDatePicker from "react-datepicker";
import { Switch } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setLoadingPage } from "../../store/actions";
import Select from "react-select";
import Ico1 from "../../assets/img/edit.png";
import { ThemeContext } from "../../providers/ThemeProvider";
import TextEditor from "../TextEditor/TextEditor";
import Accordion from "react-bootstrap/Accordion";
import calcIcon from "../../assets/img/calculator_hires.png";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

function EditNewCostCenter() {
  const { ID, costCenter } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [theme, setTheme] = useContext(ThemeContext);
  const [isLoading, setIsLoading] = useState(false);
  const customer_code = useSelector((state) => state.compensate.customerCode);
  const loading_page = useSelector((state) => state.compensate.LoadingPage);
  // const [FormInitialData, setFormInitialData] = useState();
  // const [selectedImage, setSelectedImage] = useState(null);
  // const [initialImageUrl, setInitialImageUrl] = useState(null);
  // const [costCenterMaster, setCostCenterMaster] = useState(null);
  // const [dropCostCenterMaster, setDropCostCenterMaster] = useState([]);
  const [facilityMaster, setFacilityMaster] = useState(null);
  const [applications, setApplications] = useState(null);
  const [hireDate, setHireDate] = useState(null);
  const [isInitialData, setISInitialData] = useState(false);
  const [terminateDate, setTerminateDate] = useState(null);
  // const [selectAllApplication, setSelectAllApplication] = useState(false);
  // const [DeselectAllApplication, setDeselectAllApplication] = useState(false);
  // const [selectAllCostCenter, setSelectAllCostCenter] = useState(false);
  // const [DeselectAllCostCenter, setDeselectAllCostCenter] = useState(false);
  const [isRealtime, setIsRealtime] = useState(false);
  const [formControl, setFormControl] = useState([]);
  const [realtimeFormula, setRealtimeFormula] = useState(null);
  const [realtimeFormulaHeaders, setRealtimeFormulaHeaders] = useState(null);
  const [categoryList, setCategoryList] = useState([]);
  const [targettypeList, setTargetTypeList] = useState([]);
  console.log(realtimeFormulaHeaders, "realtime formula");
  const checkFormFields = async () => {
    return new Promise((resolve, reject) => {
      const sections = ["patient_data", "staffing", "additional_data_tracking"];
      for (const section of sections) {
        for (const item of formControl[section]) {
          // Skip checking item.field_label if item.display is 0
          if (item.display === 0) {
            continue;
          }

          // Check item.field_label if item.display is not 0
          if (!item.field_label || item.field_label.trim() === "") {
            reject(new Error("All Label fields are mandatory"));
            return; // Exit early on failure to prevent further execution
          }
        }
      }
      resolve("All fields are valid");
    });
  };

  function generateTimeSlots() {
    const timeSlots = [];
    for (let hour = 0; hour < 24; hour++) {
      const formattedHour = hour < 10 ? `0${hour}` : `${hour}`;
      timeSlots.push(`${formattedHour}:00`);
    }
    return timeSlots;
  }

  const timeSlots = generateTimeSlots();

  console.log(formControl);
  const [initialValues, setInitialValues] = useState({
    cost_center_name: "",
    cost_center_number: "",
    cost_center_category: "",
    business_unit_description: "",
    target: "",
    target_type: "",
    tier2: "",
    tier3: "",
    tier4: "",
    tier5: "",
    uos_description: "",
    realtime: 0,
    customer_code: "",
    fixed_or_variable: "",
    standard_unit_ratio: "0",
    facility_id: "",
    facility_masters: "",
    director: "",
    manager: "",
    is10day: "0",
    adm: "0",
    validate_census: "0",
    accepted_acuity_per_nurse: "",
    first_entry: "03:00",
    interval: "4",
    warm_up: 30,
    budget1: {
      beds: "0",
      fte_max: "0",
      fte_min: "0",
      nurse_uos: "0",
      required_fte: "0",
    },
    patient_data_label: "",
    staffing_data_label: "",
    additional_data_label: "",
    number_of_days: "",
    census_grid: "",
    show_productivity_ring: "",
    show_compliance_ring: "",
    overtime_threshold: "",
    display_on_nursing_status: "",
  });

  // const getFormatDate = (val) => {
  //   const inputDate = new Date(`${val}`);
  //   const year = inputDate.getFullYear();
  //   const month = String(inputDate.getMonth() + 1).padStart(2, "0");
  //   const day = String(inputDate.getDate()).padStart(2, "0");
  //   return val ? `${day}/${month}/${year}` : "";
  // };

  const getFormatDate = (val) => {
    if (!val) return ""; // Handle empty or undefined input

    let inputDate;

    if (typeof val === "string") {
      // If the input is a string, attempt to parse it
      const dateComponents = val.split("/");
      if (dateComponents.length !== 3) return ""; // Handle invalid date format

      const [month, day, year] = dateComponents.map((component) =>
        parseInt(component, 10)
      );

      // Check if the parsed components are valid numbers
      if (isNaN(month) || isNaN(day) || isNaN(year)) {
        return ""; // Handle invalid numeric values
      }

      inputDate = new Date(year, month - 1, day); // Note: months are zero-based in JavaScript dates
    } else if (val instanceof Date) {
      // If the input is a Date object, use it directly
      inputDate = val;
    } else {
      return ""; // Handle unsupported input type
    }

    const formattedYear = inputDate.getFullYear();
    const formattedMonth = String(inputDate.getMonth() + 1).padStart(2, "0");
    const formattedDay = String(inputDate.getDate()).padStart(2, "0");

    return `${formattedDay}/${formattedMonth}/${formattedYear}`;
  };

  function removeDuplicates(data) {
    function filterUniqueByFieldName(array) {
      const seen = new Set();
      return array.filter((item) => {
        const duplicate = seen.has(item.field_name);
        seen.add(item.field_name);
        return !duplicate;
      });
    }

    return {
      patient_data: filterUniqueByFieldName(data.patient_data),
      staffing: filterUniqueByFieldName(data.staffing),
      additional_data_tracking: filterUniqueByFieldName(
        data.additional_data_tracking
      ),
    };
  }

  useEffect(() => {
    const getInitalData = async () => {
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/department/departmentDetail`,
        {
          cost_center_number: parseInt(costCenter),
          customer_code: parseInt(customer_code),
        }
      );
      // setcheckedAllFormControll(resp?.data?.data?.checkedAllFormControll);
      const Detail = resp?.data?.data?.department_details;
      // setFormControl(resp?.data?.data?.form_control);
      setRealtimeFormula(resp?.data?.data?.realtime_formula);
      const cleanedData = removeDuplicates(resp?.data?.data?.form_control);
      setFormControl(cleanedData);
      console.log(cleanedData, "cleaned form control");

      const hdateArray = Detail?.hire_date?.split("/");

      if (hdateArray && hdateArray.length === 3) {
        const [hday, hmonth, hyear] = hdateArray;

        // Check if day, month, and year are valid numbers
        if (!isNaN(hday) && !isNaN(hmonth) && !isNaN(hyear)) {
          setHireDate(new Date(`${hyear}-${hmonth}-${hday}`));
        }
      }

      const tdateArray = Detail?.termination_date?.split("/");
      if (tdateArray && tdateArray.length === 3) {
        const [day, month, year] = tdateArray;

        // Check if day, month, and year are valid numbers
        if (!isNaN(day) && !isNaN(month) && !isNaN(year)) {
          setTerminateDate(new Date(`${year}-${month}-${day}`));
        }
      }

      setIsRealtime(Detail?.realtime === 1);
      setInitialValues((prev) => {
        return {
          ...prev,
          cost_center_name: Detail?.cost_center_name,
          cost_center_number: Detail?.cost_center_number,
          cost_center_category: Detail?.cost_center_category,
          business_unit_description: Detail?.business_unit_description,
          target: Detail?.target,
          target_type:
            Detail?.target_type_masters?.find(
              (item) => item.target_type_id === Detail?.target_type
            )?.target_type_id ?? "",
          tier2: Detail?.tier2,
          tier3: Detail?.tier3,
          tier4: Detail?.tier4,
          tier5: Detail?.tier5,
          uos_description: Detail?.uos_description,
          realtime: String(Detail?.realtime),
          customer_code: Detail?.customer_code,
          facility_id: Detail?.facility_masters?.find(
            (item) => item.facility_id === Detail?.facility_id
          )
            ? Detail?.facility_id
            : "",
          // facility_id: Detail?.facility_id,
          facility_masters: Detail?.facility_masters,
          fixed_or_variable: Detail?.fixed_or_variable,
          standard_unit_ratio: String(Detail?.standard_unit_ratio || 0),
          manager: Detail?.manager,
          validate_census: String(Detail?.validate_census),
          director: Detail?.director,
          is10day: Detail?.is10day || "0",
          adm: Detail?.adm || "0",
          first_entry: Detail?.first_entry || "03:00",
          interval: Detail?.interval || "4",
          accepted_acuity_per_nurse: Detail?.accepted_acuity_per_nurse,
          patient_data_label: Detail?.patient_data_label,
          staffing_data_label: Detail?.staffing_data_label,
          additional_data_label: Detail?.additional_data_label,
          warm_up: Detail?.warm_up ? Detail.warm_up : 30,
          census_grid: Detail?.census_grid || "",
          number_of_days: Detail.number_of_days
            ? String(Detail.number_of_days)
            : "",
          budget1: {
            beds: String(Detail?.budget1?.beds || 0),
            fte_max: String(Detail?.budget1?.fte_max || 0),
            fte_min: String(Detail?.budget1?.fte_min || 0),
            nurse_uos: String(Detail?.budget1?.nurse_uos || 0),
            required_fte: String(Detail?.budget1?.required_fte || 0),
          },
          show_compliance_ring: String(Detail?.show_compliance_ring),
          show_productivity_ring: String(Detail?.show_productivity_ring),
          overtime_threshold:
            Detail.overtime_threshold && Detail.overtime_threshold !== 0
              ? Detail.overtime_threshold
              : 0.25,
          display_on_nursing_status: String(Detail?.display_on_nursing_status),
        };
      });

      setFacilityMaster(Detail?.facility_masters);
      setTargetTypeList(Detail?.target_type_masters);
      setISInitialData(true);

      // setInitialImageUrl(`${DOMAIN_URL}/${Detail?.logo}`);
    };
    const getRealtimeformula = async () => {
      try {
        const resp = await axios.post(
          `${API_CALL_URL_PRIVATE}/department/getRealTimeFormula`
        );
        setRealtimeFormulaHeaders(resp.data.formula || []);
        // setISInitialData(true);
      } catch (error) {
        console.log(error);
      }
    };
    const getCostCenterCategoryList = async () => {
      try {
        const resp = await axios.post(
          `${API_CALL_URL_PRIVATE}/costcentercategory/list`,
          {
            customer_code: parseInt(customer_code),
            searchKeyword: "",
            sort_column: "_id",
            sort_order: 1,
            page: 1,
            limit: 10000,
          }
        );
        const category_list = resp.data.data.data.docs?.map((e) => {
          return {
            cost_center_category_id: e.cost_center_category_id,
            cost_center_category: e.cost_center_category,
            note: e.note,
          };
        });
        console.log(category_list);
        setCategoryList(category_list);
        // setRealtimeFormulaHeaders(resp.data.formula);
      } catch (error) {
        console.log(error);
      }
    };
    getInitalData();
    getRealtimeformula();
    getCostCenterCategoryList();
  }, [ID]);

  //Patient Data
  const handlePatientCheck = (val) => {
    // const Vari = checkedAllFormControll ? formControlTemp : formControl;
    const person = formControl?.patient_data?.find((p) => p.field_name === val);
    if (person) {
      return person?.display === 1;
    }
    return false;
  };
  const handlePatientSwitchcheck = (val) => {
    // const Vari = checkedAllFormControll ? formControlTemp : formControl;
    const person = formControl?.patient_data?.find((p) => p.field_name === val);
    return parseInt(person?.display) > 0 && parseInt(person?.required) === 1
      ? true
      : false;
  };
  // const handlePatientOnChange = (fieldName, val) => {
  //   setFormControl((prevFormControl) => {
  //     const updatedPatientData = prevFormControl?.patient_data.map((item) => {
  //       if (item?.field_name === fieldName) {
  //         return parseInt(val)
  //           ? { ...item, display: parseInt(val) }
  //           : { ...item, display: parseInt(val), required: parseInt(val) };
  //       }
  //       return item;
  //     });

  //     return {
  //       ...prevFormControl,
  //       patient_data: updatedPatientData,
  //     };
  //   });
  // };

  const handlePatientOnChange = (fieldName, val) => {
    setFormControl((prevFormControl) => {
      const fieldExists = prevFormControl.patient_data.some(
        (item) => item.field_name === fieldName
      );

      let updatedPatientData;
      if (fieldExists) {
        updatedPatientData = prevFormControl.patient_data.map((item) => {
          if (item.field_name === fieldName) {
            return parseInt(val)
              ? { ...item, display: parseInt(val) }
              : { ...item, display: parseInt(val), required: parseInt(val) };
          }
          return item;
        });
      } else {
        updatedPatientData = [
          ...prevFormControl.patient_data,
          {
            field_name: fieldName,
            display: parseInt(val), // Assuming you want to store the parsed int or the original value
            required: parseInt(val), // This seems to imply that `required` is true when val is parseable as a non-zero integer
          },
        ];
      }

      return {
        ...prevFormControl,
        patient_data: updatedPatientData,
      };
    });
  };
  const handlePatientSort = (fieldName, val) => {
    setFormControl((prevFormControl) => {
      const fieldExists = prevFormControl.patient_data.some(
        (item) => item.field_name === fieldName
      );

      let updatedPatientData;
      if (fieldExists) {
        updatedPatientData = prevFormControl.patient_data.map((item) => {
          if (item.field_name === fieldName) {
            if (item.field_name === fieldName) {
              if (parseInt(val) === 0) {
                return {
                  ...item,
                  display: parseInt(val),
                  required: parseInt(val),
                };
              } else return { ...item, display: parseInt(val) };
            }
          }
          return item;
        });
      } else {
        updatedPatientData = [
          ...prevFormControl.patient_data,
          {
            field_name: fieldName,
            field_label: null,
            display: parseInt(val), // Assuming you want to store the parsed int or the original value
            required: 0, // This seems to imply that `required` is true when val is parseable as a non-zero integer
          },
        ];
      }

      return {
        ...prevFormControl,
        patient_data: updatedPatientData,
      };
    });
  };

  const handlePatientLabel = (fieldName, val) => {
    setFormControl((prevFormControl) => {
      const fieldExists = prevFormControl.patient_data.some(
        (item) => item.field_name === fieldName
      );

      let updatedPatientData;
      if (fieldExists) {
        updatedPatientData = prevFormControl.patient_data.map((item) => {
          if (item.field_name === fieldName) {
            return { ...item, field_label: val };
          }
          return item;
        });
      } else {
        updatedPatientData = [
          ...prevFormControl.patient_data,
          {
            field_name: fieldName,
            field_label: val,
            display: 0,
            required: 0,
          },
        ];
      }

      return {
        ...prevFormControl,
        patient_data: updatedPatientData,
      };
    });
  };

  const handlePatientSwitchOnChange = (fieldName, val) => {
    setFormControl((prevFormControl) => {
      const updatedPatientData = prevFormControl?.patient_data.map((item) => {
        if (item?.field_name === fieldName) {
          if (parseInt(item?.display) > 0) {
            return { ...item, required: parseInt(val ? "1" : "0") };
          } else {
            return { ...item, required: 0 };
          }
        }
        return item;
      });

      return {
        ...prevFormControl,
        patient_data: updatedPatientData,
      };
    });
  };

  //Staffing
  const handleStaffingCheck = (val) => {
    // const Vari = checkedAllFormControll ? formControlTemp : formControl;
    const person = formControl?.staffing?.find((p) => p?.field_name === val);
    if (person) {
      return person?.display === 1;
    }
    return false;
  };
  const handleStaffingSwitchcheck = (val) => {
    // const Vari = checkedAllFormControll ? formControlTemp : formControl;

    const person = formControl?.staffing?.find((p) => p?.field_name === val);
    return parseInt(person?.display) > 0 && parseInt(person?.required) === 1
      ? true
      : false;
  };
  // const handleStaffingOnChange = (fieldName, val) => {
  //   setFormControl((prevFormControl) => {
  //     const updatedstaffingData = prevFormControl?.staffing.map((item) => {
  //       if (item?.field_name === fieldName) {
  //         console.log(item?.field_name);
  //         return parseInt(val)
  //           ? { ...item, display: parseInt(val) }
  //           : { ...item, display: parseInt(val), required: parseInt(val) };
  //       }
  //       return item;
  //     });

  //     return {
  //       ...prevFormControl,
  //       staffing: updatedstaffingData,
  //     };
  //   });
  // };
  const handleStaffingSort = (fieldName, val) => {
    setFormControl((prevFormControl) => {
      const fieldExists = prevFormControl.staffing.some(
        (item) => item.field_name === fieldName
      );

      let updatedStaffingData;
      if (fieldExists) {
        updatedStaffingData = prevFormControl.staffing.map((item) => {
          if (item.field_name === fieldName) {
            if (parseInt(val) === 0) {
              return {
                ...item,
                display: parseInt(val),
                required: parseInt(val),
              };
            } else return { ...item, display: parseInt(val) };
          }
          return item;
        });
      } else {
        updatedStaffingData = [
          ...prevFormControl.staffing,
          {
            field_name: fieldName,
            field_label: null,
            display: parseInt(val),
            required: 0,
          },
        ];
      }

      return {
        ...prevFormControl,
        staffing: updatedStaffingData,
      };
    });
  };

  const handleStaffingLabel = (fieldName, val) => {
    setFormControl((prevFormControl) => {
      const fieldExists = prevFormControl.staffing.some(
        (item) => item.field_name === fieldName
      );

      let updatedStaffingData;
      if (fieldExists) {
        updatedStaffingData = prevFormControl.staffing.map((item) => {
          if (item.field_name === fieldName) {
            return { ...item, field_label: val };
          }
          return item;
        });
      } else {
        updatedStaffingData = [
          ...prevFormControl.staffing,
          {
            field_name: fieldName,
            field_label: val,
            display: 0, // Assuming you want to store the parsed int or the original value
            required: 0, // This seems to imply that `required` is true when val is parseable as a non-zero integer
          },
        ];
      }

      return {
        ...prevFormControl,
        staffing: updatedStaffingData,
      };
    });
  };
  const handleStaffingOnChange = (fieldName, val) => {
    setFormControl((prevFormControl) => {
      // Check if the fieldName exists in the patient_data
      const fieldExists = prevFormControl?.staffing.some(
        (item) => item?.field_name === fieldName
      );

      let updatedStaffingData;
      if (fieldExists) {
        // If the field exists, update it
        updatedStaffingData = prevFormControl?.staffing?.map((item) => {
          if (item.field_name === fieldName) {
            // Conditionally update based on whether `val` is parseable as an integer
            return parseInt(val)
              ? { ...item, display: parseInt(val) }
              : { ...item, display: parseInt(val), required: parseInt(val) }; // The requirement logic seems ambiguous; adjust as needed
          }
          return item;
        });
      } else {
        // If the field doesn't exist, add a new item
        updatedStaffingData = [
          ...prevFormControl.staffing,
          {
            field_name: fieldName,
            display: parseInt(val), // Assuming you want to store the parsed int or the original value
            required: parseInt(val), // This seems to imply that `required` is true when val is parseable as a non-zero integer
          },
        ];
      }

      return {
        ...prevFormControl,
        staffing: updatedStaffingData,
      };
    });
  };
  const handleStaffingSwitchOnChange = (fieldName, val) => {
    setFormControl((prevFormControl) => {
      const updatedstaffingData = prevFormControl?.staffing.map((item) => {
        if (item?.field_name === fieldName) {
          if (item?.display > 0) {
            return { ...item, required: parseInt(val ? "1" : "0") };
          } else {
            return { ...item, required: 0 };
          }
          // Toggle the display value (0 to 1, or 1 to 0)
        }
        return item;
      });

      return {
        ...prevFormControl,
        staffing: updatedstaffingData,
      };
    });
  };

  //Additional Data Tracking
  const handleAddDataCheck = (val) => {
    // const Vari = checkedAllFormControll ? formControlTemp : formControl;
    const person = formControl?.additional_data_tracking?.find(
      (p) => p?.field_name === val
    );
    if (person) {
      return person?.display === 1;
    }
    return false;
  };
  // const handleAddDataGraph = (fieldName, val) => {
  //   setFormControl((prevFormControl) => {
  //     const fieldExists = prevFormControl.additional_data_tracking.some(
  //       (item) => item.field_name === fieldName
  //     );

  //     let updatedAddDataData;
  //     if (fieldExists) {
  //       updatedAddDataData = prevFormControl.additional_data_tracking.map(
  //         (item) => {
  //           if (item.field_name === fieldName) {
  //             return { ...item, display_graph: parseInt(val) };
  //           }
  //           return item;
  //         }
  //       );
  //     } else {
  //       updatedAddDataData = [
  //         ...prevFormControl.additional_data_tracking,
  //         {
  //           field_name: fieldName,
  //           field_label: null,
  //           display_graph: parseInt(val),
  //           display: 0,
  //           required: 0,
  //         },
  //       ];
  //     }

  //     return {
  //       ...prevFormControl,
  //       additional_data_tracking: updatedAddDataData,
  //     };
  //   });
  // };
  function debounce(func, wait) {
    let timeout;

    return function executedFunction(...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func(...args);
      }, wait);
    };
  }
  const showErrorToast = debounce((message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
    });
  }, 500);
  // const handleAddDataGraph = (fieldName, val) => {
  //   setFormControl((prevFormControl) => {
  //     const currentCount = prevFormControl.additional_data_tracking.reduce(
  //       (acc, item) => acc + (item.display_graph === 1 ? 1 : 0),
  //       0
  //     );

  //     const isFieldCurrentlyOne =
  //       prevFormControl.additional_data_tracking.find(
  //         (item) => item.field_name === fieldName
  //       )?.display_graph === 1;

  //     if (parseInt(val) === 1 && currentCount >= 4 && !isFieldCurrentlyOne) {
  //       showErrorToast("Only 4 items can have 'Display Graph' set to Yes.");
  //       return prevFormControl;
  //     }

  //     const updatedAddDataData = prevFormControl.additional_data_tracking.map(
  //       (item) => {
  //         if (item.field_name === fieldName) {
  //           return { ...item, display_graph: parseInt(val) };
  //         }
  //         return item;
  //       }
  //     );

  //     return {
  //       ...prevFormControl,
  //       additional_data_tracking: updatedAddDataData,
  //     };
  //   });
  // };
  const handleAddDataGraph = (fieldName, val) => {
    setFormControl((prevFormControl) => {
      const currentCount = prevFormControl.additional_data_tracking.reduce(
        (acc, item) => acc + (item.display_graph === 1 ? 1 : 0),
        0
      );

      const targetItem = prevFormControl.additional_data_tracking.find(
        (item) => item.field_name === fieldName
      );

      const isFieldCurrentlyOne = targetItem?.display_graph === 1;

      if (parseInt(val) === 1 && currentCount >= 4 && !isFieldCurrentlyOne) {
        showErrorToast("Only 4 items can have 'Display Graph' set to Yes.");
        return prevFormControl;
      }

      const updatedAddDataData = prevFormControl.additional_data_tracking.map(
        (item) => {
          if (item.field_name === fieldName) {
            const displayGraphValue = item.display > 0 ? parseInt(val) : 0;
            return { ...item, display_graph: displayGraphValue };
          }
          return item;
        }
      );

      return {
        ...prevFormControl,
        additional_data_tracking: updatedAddDataData,
      };
    });
  };
  const handleAddDataSort = (fieldName, val) => {
    setFormControl((prevFormControl) => {
      const fieldExists = prevFormControl.additional_data_tracking.some(
        (item) => item.field_name === fieldName
      );

      let updatedAddDataData;
      if (fieldExists) {
        updatedAddDataData = prevFormControl.additional_data_tracking.map(
          (item) => {
            if (item.field_name === fieldName) {
              if (item.field_name === fieldName) {
                if (parseInt(val) === 0) {
                  return {
                    ...item,
                    display: parseInt(val),
                    required: parseInt(val),
                    display_graph: parseInt(val),
                  };
                } else return { ...item, display: parseInt(val) };
              }
            }
            return item;
          }
        );
      } else {
        updatedAddDataData = [
          ...prevFormControl.additional_data_tracking,
          {
            field_name: fieldName,
            field_label: null,
            display_graph: 0,
            display: parseInt(val),
            required: 0,
          },
        ];
      }

      return {
        ...prevFormControl,
        additional_data_tracking: updatedAddDataData,
      };
    });
  };

  const handleAddDataLabel = (fieldName, val) => {
    setFormControl((prevFormControl) => {
      const fieldExists = prevFormControl.additional_data_tracking.some(
        (item) => item.field_name === fieldName
      );

      let updatedAddDataData;
      if (fieldExists) {
        updatedAddDataData = prevFormControl.additional_data_tracking.map(
          (item) => {
            if (item.field_name === fieldName) {
              return { ...item, field_label: val };
            }
            return item;
          }
        );
      } else {
        updatedAddDataData = [
          ...prevFormControl.additional_data_tracking,
          {
            field_name: fieldName,
            field_label: val,
            display_graph: 0,
            display: 0, // Assuming you want to store the parsed int or the original value
            required: 0, // This seems to imply that `required` is true when val is parseable as a non-zero integer
          },
        ];
      }

      return {
        ...prevFormControl,
        additional_data_tracking: updatedAddDataData,
      };
    });
  };
  const handleAddDataSwitchcheck = (val) => {
    // const Vari = checkedAllFormControll ? formControlTemp : formControl;

    const person = formControl?.additional_data_tracking?.find(
      (p) => p?.field_name === val
    );
    return parseInt(person?.display) > 0 && parseInt(person?.required) === 1
      ? true
      : false;
  };
  const handleDisplayGraphSwitchcheck = (val) => {
    const person = formControl?.additional_data_tracking?.find(
      (p) => p?.field_name === val
    );
    return parseInt(person?.display_graph) === 1 ? true : false;
  };

  const handleAddDataOnChange = (fieldName, val) => {
    setFormControl((prevFormControl) => {
      // Check if the fieldName exists in the patient_data
      const fieldExists = prevFormControl?.additional_data_tracking.some(
        (item) => item?.field_name === fieldName
      );

      let updatedAddDataData;
      if (fieldExists) {
        // If the field exists, update it
        updatedAddDataData = prevFormControl?.additional_data_tracking?.map(
          (item) => {
            if (item.field_name === fieldName) {
              // Conditionally update based on whether `val` is parseable as an integer
              return parseInt(val)
                ? { ...item, display: parseInt(val) }
                : { ...item, display: parseInt(val), required: parseInt(val) }; // The requirement logic seems ambiguous; adjust as needed
            }
            return item;
          }
        );
      } else {
        // If the field doesn't exist, add a new item
        updatedAddDataData = [
          ...prevFormControl.additional_data_tracking,
          {
            field_name: fieldName,
            display: parseInt(val), // Assuming you want to store the parsed int or the original value
            required: parseInt(val), // This seems to imply that `required` is true when val is parseable as a non-zero integer
          },
        ];
      }

      return {
        ...prevFormControl,
        additional_data_tracking: updatedAddDataData,
      };
    });
  };
  const handleAddDataSwitchOnChange = (fieldName, val) => {
    setFormControl((prevFormControl) => {
      const updatedAddData = prevFormControl?.additional_data_tracking.map(
        (item) => {
          if (item?.field_name === fieldName) {
            if (item?.display > 0) {
              return { ...item, required: parseInt(val ? "1" : "0") };
            } else {
              return { ...item, required: 0 };
            }
            // Toggle the display value (0 to 1, or 1 to 0)
          }
          return item;
        }
      );

      return {
        ...prevFormControl,
        additional_data_tracking: updatedAddData,
      };
    });
  };

  const onSubmit = async (values, onSubmittingProps) => {
    if (parseInt(values.realtime) === 1) {
      try {
        await checkFormFields();
        formSubmit(values);
      } catch (error) {
        toast.error(error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      }
    } else {
      formSubmit(values);
    }
  };
  const formSubmit = async (values) => {
    const obj = {
      _id: ID,
      cost_center_name: values.cost_center_name,
      cost_center_number: parseInt(values.cost_center_number),
      cost_center_category:
        values.cost_center_category !== ""
          ? parseInt(values.cost_center_category)
          : null,
      business_unit_description: values.business_unit_description,
      target: parseFloat(values.target),
      target_type: Number(values.target_type),
      tier2: values.tier2,
      tier3: values.tier3,
      tier4: values.tier4,
      tier5: values.tier5,
      uos_description: values.uos_description,
      realtime: parseInt(values.realtime),
      customer_code: parseInt(values.customer_code),
      facility_id: parseInt(values.facility_id),
      fixed_or_variable: values.fixed_or_variable,
      standard_unit_ratio: parseInt(values.standard_unit_ratio),
      form_control: formControl,
      manager: values.manager,
      director: values.director,
      is10day: parseInt(values.is10day),
      adm: parseInt(values.adm),
      first_entry: values.first_entry,
      validate_census: parseInt(values.validate_census),
      interval: parseInt(values.interval),
      patient_data_label: values.patient_data_label,
      staffing_data_label: values.staffing_data_label,
      additional_data_label: values.additional_data_label,
      warm_up: parseInt(values.warm_up),
      census_grid: values.census_grid,
      number_of_days: parseInt(values.number_of_days),
      show_productivity_ring: parseInt(values.show_productivity_ring),
      show_compliance_ring: parseInt(values.show_compliance_ring),
      display_on_nursing_status: parseInt(values.display_on_nursing_status),
      budget1: {
        beds: parseInt(values.budget1.beds),
        fte_max: parseInt(values.budget1.fte_max),
        fte_min: parseInt(values.budget1.fte_min),
        nurse_uos: parseInt(values.budget1.nurse_uos),
        required_fte: parseInt(values.budget1.required_fte),
      },
      accepted_acuity_per_nurse:
        values.accepted_acuity_per_nurse !== ""
          ? parseFloat(values.accepted_acuity_per_nurse)
          : 0,
      overtime_threshold:
        values.overtime_threshold !== ""
          ? parseFloat(values.overtime_threshold)
          : 0,
    };
    try {
      setIsLoading(true);
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/department/departmentAddUpdate`,
        obj
      );
      console.log(resp);
      toast.success(resp?.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
      // navigate("/admin/customer");
    } catch (error) {
      toast.error(error.response, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });

      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isInitialData ? (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validate={departmentValidation}
          enableReinitialize
          validateOnChange
        >
          {(formik) => (
            <div className="main-panel ">
              {console.log(formik)}
              <div className="content-wrapper ">
                <div className="page-header">
                  <h3 className="page-title">
                    <a href="#" className="badge badge-dark"></a>
                  </h3>
                  <div className></div>
                </div>
                <div className="row ">
                  <div class="col-12 grid-margin stretch-card">
                    <div class="card">
                      <FormikForm className="forms-sample">
                        <div class="card-body">
                          <div className="row align-items-center">
                            <div className="col-3">
                              <Link
                                to={`/admin/department`}
                                className="f-14 white-text-dark-mode"
                              >
                                <FontAwesomeIcon icon="fa-solid fa-arrow-left " />{" "}
                                Back
                              </Link>
                            </div>
                            <div className="col-6">
                              <h4 class="card-title text-center">
                                Update details for :{" "}
                                {initialValues?.cost_center_name}
                              </h4>
                            </div>
                            <div className="col-3 text-end">
                              <Button
                                className="ms-3"
                                variant="success"
                                type="submit"
                                disabled={isLoading}
                              >
                                {isLoading ? "Saving..." : "Save"}
                              </Button>
                            </div>
                          </div>

                          <div class="row">
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_code"
                            >
                              <Form.Label>
                                Cost center number
                                <span style={{ color: "red" }}>&nbsp;*</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="cost_center_number"
                                value={formik.values.cost_center_number}
                                readonly
                              />
                              <ErrorMessage
                                name="cost_center_number"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_name"
                            >
                              <Form.Label>
                                Cost Center Name
                                <span style={{ color: "red" }}>&nbsp;*</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="cost_center_name"
                                {...formik.getFieldProps("cost_center_name")}
                              />
                              <ErrorMessage
                                name="cost_center_name"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_name"
                            >
                              <Form.Label>Director</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="director"
                                {...formik.getFieldProps("director")}
                              />
                              <ErrorMessage
                                name="director"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_name"
                            >
                              <Form.Label>Manager</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="manager"
                                {...formik.getFieldProps("manager")}
                              />
                              <ErrorMessage
                                name="manager"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3 position-relative"
                              controlId="exampleForm.c_email"
                            >
                              {/* {console.log(categoryList?.find(
                                  (e) =>
                                    e.cost_center_category_id ===
                                    formik.values.cost_center_category
                                )?.cost_center_category_id)}  */}
                              {console.log(categoryList)}
                              <Form.Label>Cost center category</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="white"
                                name="cost_center_category"
                                // value={`${formik.values.cost_center_category}`}
                                value={
                                  categoryList?.find(
                                    (e) =>
                                      e.cost_center_category_id ===
                                      parseInt(
                                        formik.values.cost_center_category
                                      )
                                  )?.cost_center_category_id
                                }
                                onChange={(e) => {
                                  formik.setFieldTouched(
                                    "cost_center_category",
                                    true,
                                    true
                                  );
                                  formik.setFieldValue(
                                    "cost_center_category",
                                    e.target.value
                                  );
                                }}
                                // onChange={formik.handleChange}
                              >
                                <option value={""}>select</option>
                                {categoryList?.map((e) => {
                                  return (
                                    <option
                                      key={e?.cost_center_category}
                                      value={e?.cost_center_category_id}
                                    >
                                      {e?.cost_center_category}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                              <ErrorMessage
                                name="cost_center_category"
                                component={Texterror}
                              />
                              <span
                                className="position-absolute"
                                style={{
                                  width: "30px",
                                  height: "30px",
                                  textAlign: "left",
                                  lineHeight: "43px",
                                  top: "26px",
                                  right: "52px",
                                }}
                              >
                                <>
                                  {
                                    <OverlayTrigger
                                      key={"top"}
                                      placement={"top"}
                                      overlay={
                                        <Tooltip id={`tooltip-${"top"}`}>
                                          <strong>{"Note:"}</strong>
                                          &nbsp;
                                          {
                                            <span
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  categoryList?.find(
                                                    (e) =>
                                                      e?.cost_center_category_id ===
                                                      parseInt(
                                                        formik.values
                                                          .cost_center_category
                                                      )
                                                  )?.note || "N/A",
                                              }}
                                            />
                                          }
                                        </Tooltip>
                                      }
                                    >
                                      <img
                                        src={calcIcon}
                                        alt=""
                                        style={{
                                          width: "30px",
                                          height: "auto",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </OverlayTrigger>
                                  }
                                </>
                              </span>
                            </Form.Group>

                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Business Unit Description</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="business_unit_description"
                                {...formik.getFieldProps(
                                  "business_unit_description"
                                )}
                              />
                              <ErrorMessage
                                name="business_unit_description"
                                component={Texterror}
                              />
                            </Form.Group>

                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>
                                Target
                                <span style={{ color: "red" }}>&nbsp;*</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="target"
                                {...formik.getFieldProps("target")}
                              />
                              <ErrorMessage
                                name="target"
                                component={Texterror}
                              />
                            </Form.Group>

                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Target type</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="white"
                                name="target_type"
                                value={`${formik.values.target_type}`}
                                onChange={(e) => {
                                  formik.setFieldTouched(
                                    "target_type",
                                    true,
                                    true
                                  );
                                  formik.setFieldValue(
                                    "target_type",
                                    e.target.value
                                  );
                                }}
                              >
                                <option value={""}>select</option>
                                {targettypeList?.map((e) => {
                                  return (
                                    <option
                                      key={e?.target_type}
                                      value={e?.target_type_id}
                                    >
                                      {e?.target_type}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                              <ErrorMessage
                                name="target_type"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Tier 2</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="tier2"
                                {...formik.getFieldProps("tier2")}
                              />
                              <ErrorMessage
                                name="tier2"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Tier 3</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="tier3"
                                {...formik.getFieldProps("tier3")}
                              />
                              <ErrorMessage
                                name="tier3"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Tier 4</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="tier4"
                                {...formik.getFieldProps("tier4")}
                              />
                              <ErrorMessage
                                name="tier4"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Tier 5</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="tier5"
                                {...formik.getFieldProps("tier5")}
                              />
                              <ErrorMessage
                                name="tier5"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Accepted Acuity per Nurse</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="accepted_acuity_per_nurse"
                                {...formik.getFieldProps(
                                  "accepted_acuity_per_nurse"
                                )}
                              />
                              <ErrorMessage
                                name="accepted_acuity_per_nurse"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>UOS Description</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="uos_description"
                                {...formik.getFieldProps("uos_description")}
                              />
                              <ErrorMessage
                                name="uos_description"
                                component={Texterror}
                              />
                            </Form.Group>

                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Realtime</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="white"
                                name="realtime"
                                value={`${formik.values.realtime}`}
                                onChange={(e) => {
                                  // formik.setFieldTouched(
                                  //   "customer_status",
                                  //   true,
                                  //   true
                                  // );
                                  formik.setFieldValue(
                                    "realtime",
                                    e.target.value
                                  );
                                  setIsRealtime(parseInt(e.target.value) === 1);
                                }}
                                // onChange={formik.handleChange}
                              >
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                              </Form.Select>
                              <ErrorMessage
                                name="realtime"
                                component={Texterror}
                              />
                            </Form.Group>

                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>ADM</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="white"
                                name="adm"
                                value={`${formik.values.adm}`}
                                onChange={(e) => {
                                  // formik.setFieldTouched(
                                  //   "customer_status",
                                  //   true,
                                  //   true
                                  // );
                                  formik.setFieldValue("adm", e.target.value);
                                  // setIsRealtime(parseInt(e.target.value) === 1);
                                }}
                                // onChange={formik.handleChange}
                              >
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                              </Form.Select>
                              <ErrorMessage name="adm" component={Texterror} />
                            </Form.Group>

                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>
                                Facility Masters
                                <span style={{ color: "red" }}>&nbsp;*</span>
                              </Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="white"
                                name="facility_id"
                                value={`${formik.values.facility_id}`}
                                onChange={(e) => {
                                  formik.setFieldTouched(
                                    "facility_id",
                                    true,
                                    true
                                  );
                                  formik.setFieldValue(
                                    "facility_id",
                                    e.target.value
                                  );
                                }}
                                // onChange={formik.handleChange}
                              >
                                <option value={""}>Select</option>
                                {facilityMaster?.map((e) => {
                                  return (
                                    <option
                                      key={e?.facility_id}
                                      value={e?.facility_id}
                                    >
                                      {e?.facility_name}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                              <ErrorMessage
                                name="facility_id"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>
                                Fixed or Variable
                                <span style={{ color: "red" }}>&nbsp;*</span>
                              </Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="white"
                                name="fixed_or_variable"
                                value={`${formik.values.fixed_or_variable}`}
                                onChange={(e) => {
                                  formik.setFieldTouched(
                                    "fixed_or_variable",
                                    true,
                                    true
                                  );
                                  formik.setFieldValue(
                                    "fixed_or_variable",
                                    e.target.value
                                  );
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched(
                                    "fixed_or_variable",
                                    true,
                                    true
                                  );
                                }}
                                // onChange={formik.handleChange}
                              >
                                <option value="">Select</option>
                                <option value="Fixed">Fixed</option>
                                <option value="Variable">Variable</option>
                              </Form.Select>
                              <ErrorMessage
                                name="fixed_or_variable"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Is10day</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="white"
                                name="is10day"
                                value={`${formik.values.is10day}`}
                                onChange={(e) => {
                                  formik.setFieldTouched("is10day", true, true);
                                  formik.setFieldValue(
                                    "is10day",
                                    e.target.value
                                  );
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched("is10day", true, true);
                                }}
                                // onChange={formik.handleChange}
                              >
                                <option value="0">No</option>
                                <option value="1">Yes</option>
                              </Form.Select>
                              <ErrorMessage
                                name="is10day"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Standard Unit Ratio</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="white"
                                name="standard_unit_ratio"
                                value={`${formik.values.standard_unit_ratio}`}
                                onChange={(e) => {
                                  formik.setFieldTouched(
                                    "standard_unit_ratio",
                                    true,
                                    true
                                  );
                                  formik.setFieldValue(
                                    "standard_unit_ratio",
                                    e.target.value
                                  );
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched(
                                    "standard_unit_ratio",
                                    true,
                                    true
                                  );
                                }}
                                // onChange={formik.handleChange}
                              >
                                <option value="0">Select</option>
                                <option value="2">1:2</option>
                                <option value="3">1:3</option>
                                <option value="4">1:4</option>
                                <option value="5">1:5</option>
                              </Form.Select>
                              <ErrorMessage
                                name="standard_unit_ratio"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Validate Census</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="white"
                                name="validate_census"
                                value={`${formik.values.validate_census}`}
                                onChange={(e) => {
                                  formik.setFieldTouched(
                                    "validate_census",
                                    true,
                                    true
                                  );
                                  formik.setFieldValue(
                                    "validate_census",
                                    e.target.value
                                  );
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched(
                                    "validate_census",
                                    true,
                                    true
                                  );
                                }}
                                // onChange={formik.handleChange}
                              >
                                <option value="0">No</option>
                                <option value="1">Yes</option>
                              </Form.Select>
                              <ErrorMessage
                                name="validate_census"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Warm Up (In Minutes)</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="warm_up"
                                {...formik.getFieldProps("warm_up")}
                              />
                              <ErrorMessage
                                name="warm_up"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>
                                No of Days
                                <span style={{ color: "red" }}>&nbsp;*</span>
                              </Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="white"
                                name="validate_census"
                                value={`${formik.values.number_of_days}`}
                                onChange={(e) => {
                                  formik.setFieldTouched(
                                    "number_of_days",
                                    true,
                                    true
                                  );
                                  formik.setFieldValue(
                                    "number_of_days",
                                    e.target.value
                                  );
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched(
                                    "number_of_days",
                                    true,
                                    true
                                  );
                                }}
                                // onChange={formik.handleChange}
                              >
                                <option value="">select</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                              </Form.Select>
                              <ErrorMessage
                                name="number_of_days"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>
                                Census Grid
                                <span style={{ color: "red" }}>&nbsp;*</span>
                              </Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="white"
                                name="census_grid"
                                value={`${formik.values.census_grid}`}
                                onChange={(e) => {
                                  formik.setFieldTouched(
                                    "census_grid",
                                    true,
                                    true
                                  );
                                  formik.setFieldValue(
                                    "census_grid",
                                    e.target.value
                                  );
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched(
                                    "census_grid",
                                    true,
                                    true
                                  );
                                }}
                                // onChange={formik.handleChange}
                              >
                                <option value="">Select</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </Form.Select>
                              <ErrorMessage
                                name="census_grid"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Show Productivity Ring</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="white"
                                name="show_productivity_ring"
                                value={`${formik.values.show_productivity_ring}`}
                                onChange={(e) => {
                                  formik.setFieldTouched(
                                    "show_productivity_ring",
                                    true,
                                    true
                                  );
                                  formik.setFieldValue(
                                    "show_productivity_ring",
                                    e.target.value
                                  );
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched(
                                    "show_productivity_ring",
                                    true,
                                    true
                                  );
                                }}
                                // onChange={formik.handleChange}
                              >
                                <option value="0">No</option>
                                <option value="1">Yes</option>
                              </Form.Select>
                              <ErrorMessage
                                name="show_productivity_ring"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Show Compliance Ring</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="white"
                                name="show_compliance_ring"
                                value={`${formik.values.show_compliance_ring}`}
                                onChange={(e) => {
                                  formik.setFieldTouched(
                                    "show_compliance_ring",
                                    true,
                                    true
                                  );
                                  formik.setFieldValue(
                                    "show_compliance_ring",
                                    e.target.value
                                  );
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched(
                                    "show_compliance_ring",
                                    true,
                                    true
                                  );
                                }}
                                // onChange={formik.handleChange}
                              >
                                <option value="0">No</option>
                                <option value="1">Yes</option>
                              </Form.Select>
                              <ErrorMessage
                                name="show_compliance_ring"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Overtime Threshold</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="overtime_threshold"
                                {...formik.getFieldProps("overtime_threshold")}
                              />
                              <ErrorMessage
                                name="overtime_threshold"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>
                                Display on Nursing Status Page
                              </Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="white"
                                name="display_on_nursing_status"
                                value={`${formik.values.display_on_nursing_status}`}
                                onChange={(e) => {
                                  formik.setFieldTouched(
                                    "display_on_nursing_status",
                                    true,
                                    true
                                  );
                                  formik.setFieldValue(
                                    "display_on_nursing_status",
                                    e.target.value
                                  );
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched(
                                    "display_on_nursing_status",
                                    true,
                                    true
                                  );
                                }}
                                // onChange={formik.handleChange}
                              >
                                <option value="0">No</option>
                                <option value="1">Yes</option>
                              </Form.Select>
                              <ErrorMessage
                                name="display_on_nursing_status"
                                component={Texterror}
                              />
                            </Form.Group>
                            <div>
                              <div className="row mt-2">
                                <div class="col-12 grid-margin stretch-card">
                                  <div class="card">
                                    <div class="card-body py-4">
                                      <div className="d-flex justify-content-between">
                                        <div className="d-flex">
                                          <h4 class="card-title fs-5 mb-0">
                                            Budget 1
                                          </h4>
                                        </div>
                                      </div>

                                      <div class="row">
                                        <div className="col-md-12 m-0">
                                          <hr />
                                        </div>
                                        <div className="col-md-12">
                                          <div className="row">
                                            <div className="col mb-3">
                                              <Form.Group controlId="exampleForm.c_code">
                                                <Form.Label>
                                                  Beds
                                                  {/* <span style={{ color: "red" }}>
                                                &nbsp;*
                                              </span> */}
                                                </Form.Label>
                                                <Form.Control
                                                  type="number"
                                                  placeholder=""
                                                  name="budget1.beds"
                                                  getFacilityMaster
                                                  // {...formik.getFieldProps(
                                                  //   "budget1.beds"
                                                  // )}
                                                  value={
                                                    formik.values.budget1.beds
                                                  }
                                                  onChange={(e) => {
                                                    formik.setFieldTouched(
                                                      "budget1.beds",
                                                      true,
                                                      true
                                                    );
                                                    formik.setFieldValue(
                                                      "budget1.beds",
                                                      String(e.target.value)
                                                    );
                                                  }}
                                                  onBlur={() => {
                                                    formik.setFieldTouched(
                                                      "budget1.beds",
                                                      true,
                                                      true
                                                    );
                                                  }}
                                                />
                                                <ErrorMessage
                                                  name="budget1.beds"
                                                  component={Texterror}
                                                />
                                              </Form.Group>
                                            </div>

                                            <Form.Group
                                              className="col mb-3"
                                              controlId="exampleForm.c_code"
                                            >
                                              <Form.Label>Fte Max</Form.Label>
                                              <Form.Control
                                                type="number"
                                                placeholder=""
                                                name="budget1.fte_max"
                                                getFacilityMaster
                                                // {...formik.getFieldProps(
                                                //   "budget1.fte_max"
                                                // )}
                                                value={
                                                  formik.values.budget1.fte_max
                                                }
                                                onChange={(e) => {
                                                  formik.setFieldTouched(
                                                    "budget1.fte_max",
                                                    true,
                                                    true
                                                  );
                                                  formik.setFieldValue(
                                                    "budget1.fte_max",
                                                    String(e.target.value)
                                                  );
                                                }}
                                                onBlur={() => {
                                                  formik.setFieldTouched(
                                                    "budget1.fte_max",
                                                    true,
                                                    true
                                                  );
                                                }}
                                              />
                                              <ErrorMessage
                                                name="budget1.fte_max"
                                                component={Texterror}
                                              />
                                            </Form.Group>
                                            <Form.Group
                                              className="col mb-3"
                                              controlId="exampleForm.c_code"
                                            >
                                              <Form.Label>Fte Min</Form.Label>
                                              <Form.Control
                                                type="number"
                                                placeholder=""
                                                name="budget1.fte_min"
                                                getFacilityMaster
                                                // {...formik.getFieldProps(
                                                //   "budget1.fte_min"
                                                // )}
                                                value={
                                                  formik.values.budget1.fte_min
                                                }
                                                onChange={(e) => {
                                                  formik.setFieldTouched(
                                                    "budget1.fte_min",
                                                    true,
                                                    true
                                                  );
                                                  formik.setFieldValue(
                                                    "budget1.fte_min",
                                                    String(e.target.value)
                                                  );
                                                }}
                                                onBlur={() => {
                                                  formik.setFieldTouched(
                                                    "budget1.fte_min",
                                                    true,
                                                    true
                                                  );
                                                }}
                                              />
                                              <ErrorMessage
                                                name="budget1.fte_min"
                                                component={Texterror}
                                              />
                                            </Form.Group>
                                            <Form.Group
                                              className="col mb-3"
                                              controlId="exampleForm.c_code"
                                            >
                                              <Form.Label>Nurse Uos</Form.Label>
                                              <Form.Control
                                                type="number"
                                                placeholder=""
                                                name="budget1.nurse_uos"
                                                getFacilityMaster
                                                // {...formik.getFieldProps(
                                                //   "budget1.nurse_uos"
                                                // )}
                                                value={
                                                  formik.values.budget1
                                                    .nurse_uos
                                                }
                                                onChange={(e) => {
                                                  formik.setFieldTouched(
                                                    "budget1.nurse_uos",
                                                    true,
                                                    true
                                                  );
                                                  formik.setFieldValue(
                                                    "budget1.nurse_uos",
                                                    String(e.target.value)
                                                  );
                                                }}
                                                onBlur={() => {
                                                  formik.setFieldTouched(
                                                    "budget1.nurse_uos",
                                                    true,
                                                    true
                                                  );
                                                }}
                                              />
                                              <ErrorMessage
                                                name="budget1.nurse_uos"
                                                component={Texterror}
                                              />
                                            </Form.Group>
                                            <Form.Group
                                              className="col mb-3"
                                              controlId="exampleForm.c_code"
                                            >
                                              <Form.Label>
                                                Required Fte
                                              </Form.Label>
                                              <Form.Control
                                                type="number"
                                                placeholder=""
                                                name="budget1.required_fte"
                                                getFacilityMaster
                                                // {...formik.getFieldProps(
                                                //   "budget1.required_fte"
                                                // )}
                                                value={
                                                  formik.values.budget1
                                                    .required_fte
                                                }
                                                onChange={(e) => {
                                                  formik.setFieldTouched(
                                                    "budget1.required_fte",
                                                    true,
                                                    true
                                                  );
                                                  formik.setFieldValue(
                                                    "budget1.required_fte",
                                                    String(e.target.value)
                                                  );
                                                }}
                                                onBlur={() => {
                                                  formik.setFieldTouched(
                                                    "budget1.required_fte",
                                                    true,
                                                    true
                                                  );
                                                }}
                                              />
                                              <ErrorMessage
                                                name="budget1.required_fte"
                                                component={Texterror}
                                              />
                                            </Form.Group>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {isRealtime && (
                              <>
                                <div>
                                  <div>
                                    <div className="row mt-2">
                                      <div class="col-12 grid-margin stretch-card">
                                        <div class="card">
                                          <div class="card-body py-4">
                                            <div className="d-flex justify-content-between">
                                              <div className="d-flex">
                                                <h4 class="card-title fs-5 mb-0">
                                                  Shift Interval Configuration
                                                </h4>
                                              </div>
                                            </div>

                                            <div class="row">
                                              <div className="col-md-12 m-0">
                                                <hr />
                                              </div>
                                              <div className="col-md-12">
                                                <div className="row">
                                                  <Form.Group
                                                    className="col mb-3"
                                                    controlId="exampleForm.c_email"
                                                  >
                                                    <Form.Label>
                                                      First Entry
                                                    </Form.Label>
                                                    <Form.Select
                                                      aria-label="Default select example"
                                                      className="white"
                                                      name="first_entry"
                                                      value={`${formik.values.first_entry}`}
                                                      onChange={(e) => {
                                                        formik.setFieldTouched(
                                                          "first_entry",
                                                          true,
                                                          true
                                                        );
                                                        formik.setFieldValue(
                                                          "first_entry",
                                                          e.target.value
                                                        );
                                                      }}
                                                      onBlur={() => {
                                                        formik.setFieldTouched(
                                                          "first_entry",
                                                          true,
                                                          true
                                                        );
                                                      }}
                                                      // onChange={formik.handleChange}
                                                    >
                                                      {timeSlots?.map((e) => {
                                                        return (
                                                          <option
                                                            key={e}
                                                            value={e}
                                                          >
                                                            {e}
                                                          </option>
                                                        );
                                                      })}
                                                    </Form.Select>
                                                    <ErrorMessage
                                                      name="first_entry"
                                                      component={Texterror}
                                                    />
                                                  </Form.Group>
                                                  <Form.Group
                                                    className="col mb-3"
                                                    controlId="exampleForm.c_email"
                                                  >
                                                    <Form.Label>
                                                      Duration (in hours)
                                                    </Form.Label>
                                                    <Form.Select
                                                      aria-label="Default select example"
                                                      className="white"
                                                      name="interval"
                                                      value={`${formik.values.interval}`}
                                                      onChange={(e) => {
                                                        formik.setFieldTouched(
                                                          "interval",
                                                          true,
                                                          true
                                                        );
                                                        formik.setFieldValue(
                                                          "interval",
                                                          e.target.value
                                                        );
                                                      }}
                                                      onBlur={() => {
                                                        formik.setFieldTouched(
                                                          "interval",
                                                          true,
                                                          true
                                                        );
                                                      }}
                                                      // onChange={formik.handleChange}
                                                    >
                                                      {Array.from(
                                                        { length: 24 },
                                                        (x, i) => i + 1
                                                      )?.map((e) => {
                                                        return (
                                                          <option
                                                            key={e}
                                                            value={e}
                                                          >
                                                            {e}
                                                          </option>
                                                        );
                                                      })}
                                                    </Form.Select>
                                                    <ErrorMessage
                                                      name="interval"
                                                      component={Texterror}
                                                    />
                                                  </Form.Group>
                                                  <Form.Group
                                                    className="col mb-3"
                                                    controlId="exampleForm.c_code"
                                                  >
                                                    <Form.Label>
                                                      Patient Data Label
                                                    </Form.Label>
                                                    <Form.Control
                                                      type="text"
                                                      placeholder=""
                                                      name="patient_data_label"
                                                      // {...formik.getFieldProps(
                                                      //   "budget1.required_fte"
                                                      // )}
                                                      value={
                                                        formik.values
                                                          .patient_data_label
                                                      }
                                                      onChange={(e) => {
                                                        formik.setFieldTouched(
                                                          "patient_data_label",
                                                          true,
                                                          true
                                                        );
                                                        formik.setFieldValue(
                                                          "patient_data_label",
                                                          String(e.target.value)
                                                        );
                                                      }}
                                                      onBlur={() => {
                                                        formik.setFieldTouched(
                                                          "patient_data_label",
                                                          true,
                                                          true
                                                        );
                                                      }}
                                                    />
                                                    <ErrorMessage
                                                      name="patient_data_label"
                                                      component={Texterror}
                                                    />
                                                  </Form.Group>
                                                  <Form.Group
                                                    className="col mb-3"
                                                    controlId="exampleForm.c_code"
                                                  >
                                                    <Form.Label>
                                                      Staffing Data Label
                                                    </Form.Label>
                                                    <Form.Control
                                                      type="text"
                                                      placeholder=""
                                                      name="staffing_data_label"
                                                      // {...formik.getFieldProps(
                                                      //   "budget1.required_fte"
                                                      // )}
                                                      value={
                                                        formik.values
                                                          .staffing_data_label
                                                      }
                                                      onChange={(e) => {
                                                        formik.setFieldTouched(
                                                          "staffing_data_label",
                                                          true,
                                                          true
                                                        );
                                                        formik.setFieldValue(
                                                          "staffing_data_label",
                                                          String(e.target.value)
                                                        );
                                                      }}
                                                      onBlur={() => {
                                                        formik.setFieldTouched(
                                                          "staffing_data_label",
                                                          true,
                                                          true
                                                        );
                                                      }}
                                                    />
                                                    <ErrorMessage
                                                      name="staffing_data_label"
                                                      component={Texterror}
                                                    />
                                                  </Form.Group>
                                                  <Form.Group
                                                    className="col mb-3"
                                                    controlId="exampleForm.c_code"
                                                  >
                                                    <Form.Label>
                                                      Additional Data Label
                                                    </Form.Label>
                                                    <Form.Control
                                                      type="text"
                                                      placeholder=""
                                                      name="additional_data_label"
                                                      // {...formik.getFieldProps(
                                                      //   "budget1.required_fte"
                                                      // )}
                                                      value={
                                                        formik.values
                                                          .additional_data_label
                                                      }
                                                      onChange={(e) => {
                                                        formik.setFieldTouched(
                                                          "additional_data_label",
                                                          true,
                                                          true
                                                        );
                                                        formik.setFieldValue(
                                                          "additional_data_label",
                                                          String(e.target.value)
                                                        );
                                                      }}
                                                      onBlur={() => {
                                                        formik.setFieldTouched(
                                                          "additional_data_label",
                                                          true,
                                                          true
                                                        );
                                                      }}
                                                    />
                                                    <ErrorMessage
                                                      name="additional_data_label"
                                                      component={Texterror}
                                                    />
                                                  </Form.Group>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div>
                                  <div className="row mt-2">
                                    <div class="col-12 grid-margin stretch-card">
                                      <Accordion
                                        className="mb-2"
                                        // defaultActiveKey="0"
                                      >
                                        <Accordion.Item eventKey="0">
                                          <Accordion.Header>
                                            Patient Data
                                          </Accordion.Header>
                                          <Accordion.Body>
                                            <div class="row">
                                              <div className="col-md-12 m-0">
                                                <hr />
                                              </div>
                                              <div className="col-md-12">
                                                <div className="row">
                                                  <div className="col-md-12">
                                                    <div class="table-responsive">
                                                      <table class="table">
                                                        <thead>
                                                          <tr>
                                                            <th>
                                                              Show/Hide/Sort
                                                            </th>
                                                            <th></th>
                                                            <th>Key</th>
                                                            <th>Label</th>
                                                            <th>Mandatory</th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl.patient_data?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "acuity"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "acuity",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders?.[
                                                              "acuity"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders?.[
                                                                              "acuity"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "acuity"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "acuity"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "acuity",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "acuity"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "acuity",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "total_patients"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "total_patients",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders?.[
                                                              "total_patients"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "35px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders?.[
                                                                              "total_patients"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "36px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "total_patients"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "total_patients"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "total_patients",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "total_patients"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "total_patients",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "total_patients_two"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "total_patients_two",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders?.[
                                                              "total_patients_two"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders?.[
                                                                              "total_patients_two"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "total_patients_two"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "total_patients_two"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "total_patients_two",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "total_patients_two"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "total_patients_two",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "total_patients_three"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "total_patients_three",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders?.[
                                                              "total_patients_three"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders?.[
                                                                              "total_patients_three"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "total_patients_three"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "total_patients_three"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "total_patients_three",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                                {/* <span className="d-inline-block ms-3">
                                                              {" "}
                                                              <img
                                                                src={Ico1}
                                                                className="w-a cursor-pointer"
                                                                alt="Icon"
                                                              />
                                                            </span> */}
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "total_patients_three"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "total_patients_three",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "two_one"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "two_one",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders?.[
                                                              "two_one"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders?.[
                                                                              "two_one"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "two_one"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>{" "}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "two_one"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "two_one",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "two_one"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "two_one",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "one_one"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "one_one",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders?.[
                                                              "one_one"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders?.[
                                                                              "one_one"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "one_one"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "one_one"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "one_one",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "one_one"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "one_one",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "one_two"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "one_two",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders?.[
                                                              "one_two"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders?.[
                                                                              "one_two"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "one_two"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "one_two"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "one_two",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "one_two"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "one_two",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "one_three"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "one_three",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders?.[
                                                              "one_three"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders?.[
                                                                              "one_three"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "one_three"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "one_three"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "one_three",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "one_three"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "one_three",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "one_four"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "one_four",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders?.[
                                                              "one_four"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders?.[
                                                                              "one_four"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "one_four"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "one_four"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "one_four",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "one_four"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "one_four",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "one_five"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "one_five",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders?.[
                                                              "one_five"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders?.[
                                                                              "one_five"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "one_five"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "one_five"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "one_five",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "one_five"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "one_five",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "one_six"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "one_six",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders?.[
                                                              "one_six"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders?.[
                                                                              "fixed_position_1"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "one_six"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "one_six"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "one_six",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "one_six"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "one_six",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "wit"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "wit",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders?.[
                                                              "wit"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders?.[
                                                                              "wit"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={"wit"}
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "wit"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "wit",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "wit"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "wit",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "wit_rn"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "wit_rn",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders?.[
                                                              "wit_rn"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "35px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders?.[
                                                                              "wit_rn"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "36px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "wit_rn"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "wit_rn"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "wit_rn",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "wit_rn"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "wit_rn",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "rn_available_hours"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "rn_available_hours",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders?.[
                                                              "rn_available_hours"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "35px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders?.[
                                                                              "rn_available_hours"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "36px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "rn_available_hours"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "rn_available_hours"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "rn_available_hours",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "rn_available_hours"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "rn_available_hours",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "rn_variance"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "rn_variance",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders?.[
                                                              "rn_variance"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "35px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders?.[
                                                                              "rn_variance"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "36px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "rn_variance"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "rn_variance"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "rn_variance",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "rn_variance"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "rn_variance",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>

                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "observation_patients"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "observation_patients",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "observation_patients"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "observation_patients"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "observation_patients"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "observation_patients"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "observation_patients",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                                {/* <span className="d-inline-block ms-3">
                                                              {" "}
                                                              <img
                                                                src={Ico1}
                                                                className="w-a cursor-pointer"
                                                                alt="Icon"
                                                              />
                                                            </span> */}
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "observation_patients"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "observation_patients",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "inpatient_boarders"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "inpatient_boarders",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "inpatient_boarders"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "inpatient_boarders"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "inpatient_boarders"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "inpatient_boarders"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "inpatient_boarders",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                                {/* <span className="d-inline-block ms-3">
                                                              {" "}
                                                              <img
                                                                src={Ico1}
                                                                className="w-a cursor-pointer"
                                                                alt="Icon"
                                                              />
                                                            </span> */}
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "inpatient_boarders"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "inpatient_boarders",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "pt_type1_0_20"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "pt_type1_0_20",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "pt_type1_0_20"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "pt_type1_0_20"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "pt_type1_0_20"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "pt_type1_0_20"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "pt_type1_0_20",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                                {/* <span className="d-inline-block ms-3">
                                                              {" "}
                                                              <img
                                                                src={Ico1}
                                                                className="w-a cursor-pointer"
                                                                alt="Icon"
                                                              />
                                                            </span> */}
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "pt_type1_0_20"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "pt_type1_0_20",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "pt_type2_21_28"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "pt_type2_21_28",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "pt_type2_21_28"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "pt_type2_21_28"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "pt_type2_21_28"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "pt_type2_21_28"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "pt_type2_21_28",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                                {/* <span className="d-inline-block ms-3">
                                                              {" "}
                                                              <img
                                                                src={Ico1}
                                                                className="w-a cursor-pointer"
                                                                alt="Icon"
                                                              />
                                                            </span> */}
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "pt_type2_21_28"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "pt_type2_21_28",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "pt_type3_29_68"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "pt_type3_29_68",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "pt_type3_29_68"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "pt_type3_29_68"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "pt_type3_29_68"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "pt_type3_29_68"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "pt_type3_29_68",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                                {/* <span className="d-inline-block ms-3">
                                                              {" "}
                                                              <img
                                                                src={Ico1}
                                                                className="w-a cursor-pointer"
                                                                alt="Icon"
                                                              />
                                                            </span> */}
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "pt_type3_29_68"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "pt_type3_29_68",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "labor_patients_recovery"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "labor_patients_recovery",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "labor_patients_recovery"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "labor_patients_recovery"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "labor_patients_recovery"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl.patient_data?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "labor_patients_recovery"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "labor_patients_recovery",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                                {/* <span className="d-inline-block ms-3">
                                                              {" "}
                                                              <img
                                                                src={Ico1}
                                                                className="w-a cursor-pointer"
                                                                alt="Icon"
                                                              />
                                                            </span> */}
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "labor_patients_recovery"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "labor_patients_recovery",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl.patient_data?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "nicu_cpap"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "nicu_cpap",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "nicu_cpap"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "nicu_cpap"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "nicu_cpap"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl.patient_data?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "nicu_cpap"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "nicu_cpap",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                                {/* <span className="d-inline-block ms-3">
                                                              {" "}
                                                              <img
                                                                src={Ico1}
                                                                className="w-a cursor-pointer"
                                                                alt="Icon"
                                                              />
                                                            </span> */}
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "nicu_cpap"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "nicu_cpap",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl.patient_data?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "nicu_feeder_growers"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "nicu_feeder_growers",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "nicu_feeder_growers"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "nicu_feeder_growers"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "nicu_feeder_growers"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "nicu_feeder_growers"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "nicu_feeder_growers",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                                {/* <span className="d-inline-block ms-3">
                                                              {" "}
                                                              <img
                                                                src={Ico1}
                                                                className="w-a cursor-pointer"
                                                                alt="Icon"
                                                              />
                                                            </span> */}
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "nicu_feeder_growers"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "nicu_feeder_growers",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl.patient_data?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "post_op_pp_one_two"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "post_op_pp_one_two",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "post_op_pp_one_two"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "post_op_pp_one_two"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "post_op_pp_one_two"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "post_op_pp_one_two"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "post_op_pp_one_two",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                                {/* <span className="d-inline-block ms-3">
                                                              {" "}
                                                              <img
                                                                src={Ico1}
                                                                className="w-a cursor-pointer"
                                                                alt="Icon"
                                                              />
                                                            </span> */}
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "post_op_pp_one_two"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "post_op_pp_one_two",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl.patient_data?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "stable_pp_one_three"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "stable_pp_one_three",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "stable_pp_one_three"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "stable_pp_one_three"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "stable_pp_one_three"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "stable_pp_one_three"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "stable_pp_one_three",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                                {/* <span className="d-inline-block ms-3">
                                                              {" "}
                                                              <img
                                                                src={Ico1}
                                                                className="w-a cursor-pointer"
                                                                alt="Icon"
                                                              />
                                                            </span> */}
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "stable_pp_one_three"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "stable_pp_one_three",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "pp_rn_variance"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "pp_rn_variance",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "pp_rn_variance"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "35px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "pp_rn_variance"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "36px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "pp_rn_variance"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "pp_rn_variance"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "pp_rn_variance",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "pp_rn_variance"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "pp_rn_variance",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "l_and_d_rn_variance"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "l_and_d_rn_variance",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "l_and_d_rn_variance"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "35px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "l_and_d_rn_variance"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "36px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "l_and_d_rn_variance"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "l_and_d_rn_variance"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "l_and_d_rn_variance",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "l_and_d_rn_variance"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "l_and_d_rn_variance",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "nicu_variance"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "nicu_variance",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "nicu_variance"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "35px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "nicu_variance"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "36px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "nicu_variance"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "nicu_variance"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "nicu_variance",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "nicu_variance"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "nicu_variance",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "custom_rn_variance"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "custom_rn_variance",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "custom_rn_variance"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "35px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "custom_rn_variance"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "36px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "custom_rn_variance"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "custom_rn_variance"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "custom_rn_variance",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "custom_rn_variance"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "custom_rn_variance",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "total_uos"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "total_uos",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "total_uos"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "35px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "total_uos"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "36px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "total_uos"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "total_uos"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "total_uos",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "total_uos"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "total_uos",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "of_cardiac_procedures"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "of_cardiac_procedures",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "of_cardiac_procedures"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "of_cardiac_procedures"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "of_cardiac_procedures"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "of_cardiac_procedures"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "of_cardiac_procedures",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "of_cardiac_procedures"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "of_cardiac_procedures",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "front_desk_reg"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "front_desk_reg",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "front_desk_reg"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "front_desk_reg"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "front_desk_reg"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "front_desk_reg"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "front_desk_reg",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "front_desk_reg"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "front_desk_reg",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "ivs_encounters_processed"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "ivs_encounters_processed",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "ivs_encounters_processed"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "ivs_encounters_processed"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "ivs_encounters_processed"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "ivs_encounters_processed"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "ivs_encounters_processed",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "ivs_encounters_processed"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "ivs_encounters_processed",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "pts_scheduled"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "pts_scheduled",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "pts_scheduled"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "pts_scheduled"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "pts_scheduled"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "pts_scheduled"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "pts_scheduled",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "pts_scheduled"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "pts_scheduled",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "new_referral"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "new_referral",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "new_referral"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "new_referral"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "new_referral"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "new_referral"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "new_referral",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "new_referral"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "new_referral",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "work_queue_calls"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "work_queue_calls",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "work_queue_calls"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "work_queue_calls"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "work_queue_calls"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "work_queue_calls"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "work_queue_calls",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "work_queue_calls"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "work_queue_calls",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "procedures"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "procedures",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "procedures"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "procedures"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "procedures"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "procedures"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "procedures",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "procedures"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "procedures",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "echo"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "echo",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "echo"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "echo"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={"echo"}
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "echo"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "echo",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "echo"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "echo",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "dobutamine_stress_echo"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "dobutamine_stress_echo",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "dobutamine_stress_echo"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "dobutamine_stress_echo"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "dobutamine_stress_echo"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "dobutamine_stress_echo"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "dobutamine_stress_echo",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "dobutamine_stress_echo"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "dobutamine_stress_echo",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "stress_echo"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "stress_echo",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "stress_echo"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "stress_echo"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "stress_echo"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "stress_echo"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "stress_echo",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "stress_echo"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "stress_echo",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "transesopageal_echo"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "transesopageal_echo",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "transesopageal_echo"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "transesopageal_echo"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "transesopageal_echo"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "transesopageal_echo"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "transesopageal_echo",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "transesopageal_echo"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "transesopageal_echo",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "gi"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "gi",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "gi"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "gi"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={"gi"}
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "gi"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "gi",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "gi"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "gi",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "ercp"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "ercp",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "ercp"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "ercp"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={"ercp"}
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "ercp"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "ercp",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "ercp"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "ercp",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "bronchoscopy"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "bronchoscopy",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "bronchoscopy"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "bronchoscopy"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "bronchoscopy"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "bronchoscopy"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "bronchoscopy",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "bronchoscopy"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "bronchoscopy",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "planned_procedures"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "planned_procedures",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "planned_procedures"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "planned_procedures"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "planned_procedures"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "planned_procedures"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "planned_procedures",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "planned_procedures"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "planned_procedures",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "of_minutes"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "of_minutes",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "of_minutes"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "35px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "of_minutes"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "36px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "of_minutes"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "of_minutes"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "of_minutes",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "of_minutes"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "of_minutes",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "of_cases"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "of_cases",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "of_cases"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "of_cases"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "of_cases"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "of_cases"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "of_cases",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "of_cases"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "of_cases",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "items"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "items",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "items"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "items"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "items"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "items"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "items",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "items"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "items",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "unprocessed_trays"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "unprocessed_trays",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "unprocessed_trays"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "unprocessed_trays"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "unprocessed_trays"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "unprocessed_trays"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "unprocessed_trays",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "unprocessed_trays"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "unprocessed_trays",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "cases_scheduled_today"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "cases_scheduled_today",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "cases_scheduled_today"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "cases_scheduled_today"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "cases_scheduled_today"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "cases_scheduled_today"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "cases_scheduled_today",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "cases_scheduled_today"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "cases_scheduled_today",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "cases_picked"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "cases_picked",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "cases_picked"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "cases_picked"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "cases_picked"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "cases_picked"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "cases_picked",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "cases_picked"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "cases_picked",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "actual_minutes"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "actual_minutes",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "actual_minutes"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "actual_minutes"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "actual_minutes"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "actual_minutes"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "actual_minutes",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "actual_minutes"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "actual_minutes",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "planned_minutes"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "planned_minutes",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "planned_minutes"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "planned_minutes"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "planned_minutes"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "planned_minutes"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "planned_minutes",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "planned_minutes"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "planned_minutes",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "iol_pain_local_cases"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "iol_pain_local_cases",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "iol_pain_local_cases"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "iol_pain_local_cases"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "iol_pain_local_cases"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "iol_pain_local_cases"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "iol_pain_local_cases",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "iol_pain_local_cases"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "iol_pain_local_cases",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "mac_cases"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "mac_cases",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "mac_cases"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "mac_cases"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "mac_cases"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "mac_cases"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "mac_cases",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "mac_cases"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "mac_cases",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "pacu_cases"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "pacu_cases",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "pacu_cases"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "pacu_cases"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "pacu_cases"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "pacu_cases"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "pacu_cases",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "pacu_cases"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "pacu_cases",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "cath_lab_cases"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "cath_lab_cases",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "cath_lab_cases"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "cath_lab_cases"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "cath_lab_cases"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "cath_lab_cases"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "cath_lab_cases",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "cath_lab_cases"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "cath_lab_cases",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "in_clinic_visits"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "in_clinic_visits",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "in_clinic_visits"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "35px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "in_clinic_visits"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "36px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "in_clinic_visits"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "in_clinic_visits"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "in_clinic_visits",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "in_clinic_visits"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "in_clinic_visits",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "level_1_visits"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "level_1_visits",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "level_1_visits"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "level_1_visits"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "level_1_visits"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "level_1_visits"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "level_1_visits",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "level_1_visits"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "level_1_visits",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "level_2_visits"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "level_2_visits",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "level_2_visits"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "level_2_visits"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "level_2_visits"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "level_2_visits"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "level_2_visits",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "level_2_visits"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "level_2_visits",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "level_3_visits"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "level_3_visits",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "level_3_visits"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "level_3_visits"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "level_3_visits"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "level_3_visits"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "level_3_visits",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "level_3_visits"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "level_3_visits",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "level_4_visits"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "level_4_visits",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td></td>
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "level_4_visits"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "level_4_visits"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "level_4_visits",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "level_4_visits"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "level_4_visits",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "level_5_visits"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "level_5_visits",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "level_5_visits"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "level_5_visits"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "level_5_visits"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "level_5_visits"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "level_5_visits",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "level_5_visits"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "level_5_visits",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "of_roomed_er_patients"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "of_roomed_er_patients",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "of_roomed_er_patients"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "of_roomed_er_patients"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "of_roomed_er_patients"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "of_roomed_er_patients"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "of_roomed_er_patients",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "of_roomed_er_patients"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "of_roomed_er_patients",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "psych_pts_awaiting_placement"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "psych_pts_awaiting_placement",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "psych_pts_awaiting_placement"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "psych_pts_awaiting_placement"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "psych_pts_awaiting_placement"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "psych_pts_awaiting_placement"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "psych_pts_awaiting_placement",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "psych_pts_awaiting_placement"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "psych_pts_awaiting_placement",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "capped_pts_awaiting_hospitalist_assignment"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "capped_pts_awaiting_hospitalist_assignment",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "capped_pts_awaiting_hospitalist_assignment"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "capped_pts_awaiting_hospitalist_assignment"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "capped_pts_awaiting_hospitalist_assignment"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "capped_pts_awaiting_hospitalist_assignment"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "capped_pts_awaiting_hospitalist_assignment",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "capped_pts_awaiting_hospitalist_assignment"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "capped_pts_awaiting_hospitalist_assignment",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "admits_awaiting_bed_assignment_boarders"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "admits_awaiting_bed_assignment_boarders",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "admits_awaiting_bed_assignment_boarders"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "admits_awaiting_bed_assignment_boarders"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "admits_awaiting_bed_assignment_boarders"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "admits_awaiting_bed_assignment_boarders"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "admits_awaiting_bed_assignment_boarders",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "admits_awaiting_bed_assignment_boarders"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "admits_awaiting_bed_assignment_boarders",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "pts_in_waiting_room"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "pts_in_waiting_room",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "pts_in_waiting_room"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "pts_in_waiting_room"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "pts_in_waiting_room"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "pts_in_waiting_room"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "pts_in_waiting_room",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "pts_in_waiting_room"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "pts_in_waiting_room",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "calculated_total_registrations"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "calculated_total_registrations",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "calculated_total_registrations"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "35px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "calculated_total_registrations"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "36px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "calculated_total_registrations"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "calculated_total_registrations"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "calculated_total_registrations",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "calculated_total_registrations"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "calculated_total_registrations",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "calculated_units_of_service"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "calculated_units_of_service",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "calculated_units_of_service"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "35px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "calculated_units_of_service"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "36px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "calculated_units_of_service"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "calculated_units_of_service"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "calculated_units_of_service",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "calculated_units_of_service"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "calculated_units_of_service",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "calculated_procedures_1"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "calculated_procedures_1",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "calculated_procedures_1"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "35px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "calculated_procedures_1"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "36px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "calculated_procedures_1"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "calculated_procedures_1"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "calculated_procedures_1",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "calculated_procedures_1"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "calculated_procedures_1",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "calculated_procedures_2"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "calculated_procedures_2",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "calculated_procedures_2"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "35px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "calculated_procedures_2"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "36px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "calculated_procedures_2"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "calculated_procedures_2"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "calculated_procedures_2",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "calculated_procedures_2"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "calculated_procedures_2",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "calculated_items"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "calculated_items",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "calculated_items"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "35px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "calculated_items"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "36px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "calculated_items"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "calculated_items"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "calculated_items",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "calculated_items"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "calculated_items",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "calculated_minutes"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "calculated_minutes",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "calculated_minutes"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "35px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "calculated_minutes"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "36px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "calculated_minutes"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "calculated_minutes"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "calculated_minutes",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "calculated_minutes"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "calculated_minutes",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "seven_ave_reg_patient"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "seven_ave_reg_patient",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "seven_ave_reg_patient"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "35px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "seven_ave_reg_patient"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "36px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "seven_ave_reg_patient"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "seven_ave_reg_patient"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "seven_ave_reg_patient",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "seven_ave_reg_patient"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "seven_ave_reg_patient",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "ed_reg_patient"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "ed_reg_patient",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "ed_reg_patient"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "ed_reg_patient"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "ed_reg_patient"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "ed_reg_patient"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "ed_reg_patient",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "ed_reg_patient"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "ed_reg_patient",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>

                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "iol_mac_pacu_minutes"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "iol_mac_pacu_minutes",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "iol_mac_pacu_minutes"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "35px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "iol_mac_pacu_minutes"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "36px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "iol_mac_pacu_minutes"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "iol_mac_pacu_minutes"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "iol_mac_pacu_minutes",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "iol_mac_pacu_minutes"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "iol_mac_pacu_minutes",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "in_chair_minutes"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "in_chair_minutes",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "in_chair_minutes"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "in_chair_minutes"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "in_chair_minutes"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "in_chair_minutes"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "in_chair_minutes",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "in_chair_minutes"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "in_chair_minutes",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "pain_management"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSort(
                                                                    "pain_management",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "pain_management"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "pain_management"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "pain_management"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "pain_management"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "pain_management",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "pain_management"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "pain_management",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.patient_data?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "uos_24"
                                                                  )?.display
                                                                }
                                                                readOnly
                                                                // onChange={(
                                                                //   e
                                                                // ) => {
                                                                //   handlePatientSort(
                                                                //     "uos_24",
                                                                //     e.target
                                                                //       .value !==
                                                                //       ""
                                                                //       ? e.target
                                                                //           .value
                                                                //       : "0"
                                                                //   );
                                                                // }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "uos_24"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "35px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "uos_24"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "36px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "uos_24"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.patient_data?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "uos_24"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handlePatientLabel(
                                                                      "uos_24",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handlePatientSwitchcheck(
                                                                  "uos_24"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handlePatientSwitchOnChange(
                                                                    "uos_24",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1">
                                          <Accordion.Header>
                                            Staffing
                                          </Accordion.Header>
                                          <Accordion.Body>
                                            <div class="row">
                                              {/* New Design */}
                                              <div className="col-md-12 m-0">
                                                <hr />
                                              </div>
                                              <div className="col-md-12">
                                                <div className="row">
                                                  <div className="col-md-12">
                                                    <div class="table-responsive">
                                                      <table class="table">
                                                        <thead>
                                                          <tr>
                                                            <th>
                                                              Show/Hide/Sort
                                                            </th>
                                                            <th></th>
                                                            <th>Key</th>
                                                            <th>Label</th>
                                                            <th>Mandatory</th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl.staffing?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "charge"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSort(
                                                                    "charge",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "charge"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "charge"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "charge"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.staffing?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "charge"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleStaffingLabel(
                                                                      "charge",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleStaffingSwitchcheck(
                                                                  "charge"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSwitchOnChange(
                                                                    "charge",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.staffing?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "cna"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSort(
                                                                    "cna",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "cna"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "cna"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={"cna"}
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.staffing?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "cna"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleStaffingLabel(
                                                                      "cna",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleStaffingSwitchcheck(
                                                                  "cna"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSwitchOnChange(
                                                                    "cna",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.staffing?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "orientation"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSort(
                                                                    "orientation",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "orientation"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "orientation"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "orientation"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.staffing?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "orientation"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleStaffingLabel(
                                                                      "orientation",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleStaffingSwitchcheck(
                                                                  "orientation"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSwitchOnChange(
                                                                    "orientation",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.staffing?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "nurses"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSort(
                                                                    "nurses",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "nurses"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "nurses"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "nurses"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.staffing?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "nurses"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleStaffingLabel(
                                                                      "nurses",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleStaffingSwitchcheck(
                                                                  "nurses"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSwitchOnChange(
                                                                    "nurses",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.staffing?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "res_brk_rn"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSort(
                                                                    "res_brk_rn",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "res_brk_rn"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "res_brk_rn"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "res_brk_rn"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.staffing?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "res_brk_rn"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleStaffingLabel(
                                                                      "res_brk_rn",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleStaffingSwitchcheck(
                                                                  "res_brk_rn"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSwitchOnChange(
                                                                    "res_brk_rn",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.staffing?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "unit_sec"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSort(
                                                                    "unit_sec",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "unit_sec"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "unit_sec"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "unit_sec"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.staffing?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "unit_sec"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleStaffingLabel(
                                                                      "unit_sec",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleStaffingSwitchcheck(
                                                                  "unit_sec"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSwitchOnChange(
                                                                    "unit_sec",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.staffing?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "sitter"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSort(
                                                                    "sitter",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "sitter"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "sitter"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "sitter"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.staffing?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "sitter"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleStaffingLabel(
                                                                      "sitter",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleStaffingSwitchcheck(
                                                                  "sitter"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSwitchOnChange(
                                                                    "sitter",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.staffing?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "techs_cna"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSort(
                                                                    "techs_cna",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "techs_cna"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "techs_cna"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "techs_cna"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.staffing?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "techs_cna"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleStaffingLabel(
                                                                      "techs_cna",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleStaffingSwitchcheck(
                                                                  "techs_cna"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSwitchOnChange(
                                                                    "techs_cna",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.staffing?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "psa_si"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSort(
                                                                    "psa_si",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "psa_si"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "psa_si"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "psa_si"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.staffing?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "psa_si"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleStaffingLabel(
                                                                      "psa_si",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleStaffingSwitchcheck(
                                                                  "psa_si"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSwitchOnChange(
                                                                    "psa_si",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.staffing?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "psa_medical"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSort(
                                                                    "psa_medical",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "psa_medical"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "psa_medical"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "psa_medical"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.staffing?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "psa_medical"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleStaffingLabel(
                                                                      "psa_medical",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleStaffingSwitchcheck(
                                                                  "psa_medical"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSwitchOnChange(
                                                                    "psa_medical",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.staffing?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "blt"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSort(
                                                                    "blt",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "blt"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "blt"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={"blt"}
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.staffing?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "blt"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleStaffingLabel(
                                                                      "blt",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleStaffingSwitchcheck(
                                                                  "blt"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSwitchOnChange(
                                                                    "blt",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.staffing?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "other"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSort(
                                                                    "other",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "other"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "other"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "other"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.staffing?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "other"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleStaffingLabel(
                                                                      "other",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleStaffingSwitchcheck(
                                                                  "other"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSwitchOnChange(
                                                                    "other",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.staffing?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "soc_worker_ft"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSort(
                                                                    "soc_worker_ft",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "soc_worker_ft"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "soc_worker_ft"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "soc_worker_ft"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.staffing?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "soc_worker_ft"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleStaffingLabel(
                                                                      "soc_worker_ft",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleStaffingSwitchcheck(
                                                                  "soc_worker_ft"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSwitchOnChange(
                                                                    "soc_worker_ft",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.staffing?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "soc_worker_pt"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSort(
                                                                    "soc_worker_pt",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "soc_worker_pt"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "soc_worker_pt"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "soc_worker_pt"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.staffing?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "soc_worker_pt"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleStaffingLabel(
                                                                      "soc_worker_pt",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleStaffingSwitchcheck(
                                                                  "soc_worker_pt"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSwitchOnChange(
                                                                    "soc_worker_pt",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.staffing?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "occ_therapist"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSort(
                                                                    "occ_therapist",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "occ_therapist"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "occ_therapist"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "occ_therapist"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.staffing?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "occ_therapist"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleStaffingLabel(
                                                                      "occ_therapist",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleStaffingSwitchcheck(
                                                                  "occ_therapist"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSwitchOnChange(
                                                                    "occ_therapist",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.staffing?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "pp_rn"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSort(
                                                                    "pp_rn",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "pp_rn"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "pp_rn"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "pp_rn"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.staffing?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "pp_rn"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleStaffingLabel(
                                                                      "pp_rn",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleStaffingSwitchcheck(
                                                                  "pp_rn"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSwitchOnChange(
                                                                    "pp_rn",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.staffing?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "l_and_d_rn"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSort(
                                                                    "l_and_d_rn",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "l_and_d_rn"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "l_and_d_rn"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "l_and_d_rn"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.staffing?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "l_and_d_rn"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleStaffingLabel(
                                                                      "l_and_d_rn",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleStaffingSwitchcheck(
                                                                  "l_and_d_rn"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSwitchOnChange(
                                                                    "l_and_d_rn",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.staffing?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "nicu_peds_rn"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSort(
                                                                    "nicu_peds_rn",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "nicu_peds_rn"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "nicu_peds_rn"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "nicu_peds_rn"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.staffing?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "nicu_peds_rn"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleStaffingLabel(
                                                                      "nicu_peds_rn",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleStaffingSwitchcheck(
                                                                  "nicu_peds_rn"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSwitchOnChange(
                                                                    "nicu_peds_rn",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.staffing?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "triage_rn"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSort(
                                                                    "triage_rn",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "triage_rn"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "triage_rn"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "triage_rn"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.staffing?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "triage_rn"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleStaffingLabel(
                                                                      "triage_rn",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleStaffingSwitchcheck(
                                                                  "triage_rn"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSwitchOnChange(
                                                                    "triage_rn",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.staffing?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "lead_tech"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSort(
                                                                    "lead_tech",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "lead_tech"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "lead_tech"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "lead_tech"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.staffing?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "lead_tech"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleStaffingLabel(
                                                                      "lead_tech",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleStaffingSwitchcheck(
                                                                  "lead_tech"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSwitchOnChange(
                                                                    "lead_tech",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.staffing?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "clinical_coordinator"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSort(
                                                                    "clinical_coordinator",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "clinical_coordinator"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "clinical_coordinator"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "clinical_coordinator"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.staffing?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "clinical_coordinator"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleStaffingLabel(
                                                                      "clinical_coordinator",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleStaffingSwitchcheck(
                                                                  "clinical_coordinator"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSwitchOnChange(
                                                                    "clinical_coordinator",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.staffing?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "front_desk"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSort(
                                                                    "front_desk",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "front_desk"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "front_desk"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "front_desk"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.staffing?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "front_desk"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleStaffingLabel(
                                                                      "front_desk",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleStaffingSwitchcheck(
                                                                  "front_desk"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSwitchOnChange(
                                                                    "front_desk",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.staffing?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "ivs"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSort(
                                                                    "ivs",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "ivs"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "ivs"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={"ivs"}
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.staffing?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "ivs"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleStaffingLabel(
                                                                      "ivs",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleStaffingSwitchcheck(
                                                                  "ivs"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSwitchOnChange(
                                                                    "ivs",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.staffing?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "ed_reg"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSort(
                                                                    "ed_reg",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "ed_reg"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "ed_reg"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "ed_reg"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.staffing?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "ed_reg"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleStaffingLabel(
                                                                      "ed_reg",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleStaffingSwitchcheck(
                                                                  "ed_reg"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSwitchOnChange(
                                                                    "ed_reg",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.staffing?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "seven_ave_reg"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSort(
                                                                    "seven_ave_reg",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "seven_ave_reg"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "seven_ave_reg"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "seven_ave_reg"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.staffing?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "seven_ave_reg"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleStaffingLabel(
                                                                      "seven_ave_reg",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleStaffingSwitchcheck(
                                                                  "seven_ave_reg"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSwitchOnChange(
                                                                    "seven_ave_reg",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.staffing?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "rt"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSort(
                                                                    "rt",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "rt"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "rt"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={"rt"}
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.staffing?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "rt"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleStaffingLabel(
                                                                      "rt",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleStaffingSwitchcheck(
                                                                  "rt"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSwitchOnChange(
                                                                    "rt",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.staffing?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "support"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSort(
                                                                    "support",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "support"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "support"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "support"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.staffing?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "support"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleStaffingLabel(
                                                                      "support",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleStaffingSwitchcheck(
                                                                  "support"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSwitchOnChange(
                                                                    "support",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.staffing?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "scrub_tech"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSort(
                                                                    "scrub_tech",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "scrub_tech"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "scrub_tech"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "scrub_tech"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.staffing?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "scrub_tech"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleStaffingLabel(
                                                                      "scrub_tech",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleStaffingSwitchcheck(
                                                                  "scrub_tech"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSwitchOnChange(
                                                                    "scrub_tech",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.staffing?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "rn_preceptor"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSort(
                                                                    "rn_preceptor",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "rn_preceptor"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "rn_preceptor"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "rn_preceptor"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.staffing?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "rn_preceptor"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleStaffingLabel(
                                                                      "rn_preceptor",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleStaffingSwitchcheck(
                                                                  "rn_preceptor"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSwitchOnChange(
                                                                    "rn_preceptor",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.staffing?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "rn_board_runner"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSort(
                                                                    "rn_board_runner",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "rn_board_runner"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "rn_board_runner"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "rn_board_runner"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.staffing?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "rn_board_runner"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleStaffingLabel(
                                                                      "rn_board_runner",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleStaffingSwitchcheck(
                                                                  "rn_board_runner"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSwitchOnChange(
                                                                    "rn_board_runner",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.staffing?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "scrub_tech_preceptor"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSort(
                                                                    "scrub_tech_preceptor",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "scrub_tech_preceptor"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "scrub_tech_preceptor"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "scrub_tech_preceptor"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.staffing?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "scrub_tech_preceptor"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleStaffingLabel(
                                                                      "scrub_tech_preceptor",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleStaffingSwitchcheck(
                                                                  "scrub_tech_preceptor"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSwitchOnChange(
                                                                    "scrub_tech_preceptor",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.staffing?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "scrub_tech_board_runner"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSort(
                                                                    "scrub_tech_board_runner",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "scrub_tech_board_runner"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "scrub_tech_board_runner"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "scrub_tech_board_runner"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.staffing?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "scrub_tech_board_runner"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleStaffingLabel(
                                                                      "scrub_tech_board_runner",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleStaffingSwitchcheck(
                                                                  "scrub_tech_board_runner"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSwitchOnChange(
                                                                    "scrub_tech_board_runner",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.staffing?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "rnfa_stfa"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSort(
                                                                    "rnfa_stfa",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "rnfa_stfa"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "rnfa_stfa"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "rnfa_stfa"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.staffing?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "rnfa_stfa"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleStaffingLabel(
                                                                      "rnfa_stfa",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleStaffingSwitchcheck(
                                                                  "rnfa_stfa"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSwitchOnChange(
                                                                    "rnfa_stfa",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.staffing?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "rcu_rn"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSort(
                                                                    "rcu_rn",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "rcu_rn"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "43px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "rcu_rn"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "40px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "rcu_rn"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.staffing?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "rcu_rn"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleStaffingLabel(
                                                                      "rcu_rn",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleStaffingSwitchcheck(
                                                                  "rcu_rn"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSwitchOnChange(
                                                                    "rcu_rn",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>

                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.staffing?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "fixed_position_5_1"
                                                                  )?.display
                                                                }
                                                                readOnly
                                                                // onChange={(
                                                                //   e
                                                                // ) => {
                                                                //   handleStaffingSort(
                                                                //     "fixed_position_5_1",
                                                                //     e.target
                                                                //       .value !==
                                                                //       ""
                                                                //       ? e
                                                                //           .target
                                                                //           .value
                                                                //       : "0"
                                                                //   );
                                                                // }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "fixed_position_5_1"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "35px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "fixed_position_5_1"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "36px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "fixed_position_5_1"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.staffing?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "fixed_position_5_1"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleStaffingLabel(
                                                                      "fixed_position_5_1",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleStaffingSwitchcheck(
                                                                  "fixed_position_5_1"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSwitchOnChange(
                                                                    "fixed_position_5_1",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.staffing?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "fixed_position_5_2"
                                                                  )?.display
                                                                }
                                                                readOnly
                                                                // onChange={(
                                                                //   e
                                                                // ) => {
                                                                //   handleStaffingSort(
                                                                //     "fixed_position_5_2",
                                                                //     e.target
                                                                //       .value !==
                                                                //       ""
                                                                //       ? e
                                                                //           .target
                                                                //           .value
                                                                //       : "0"
                                                                //   );
                                                                // }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "fixed_position_5_2"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "35px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "fixed_position_5_2"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "36px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "fixed_position_5_2"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.staffing?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "fixed_position_5_2"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleStaffingLabel(
                                                                      "fixed_position_5_2",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleStaffingSwitchcheck(
                                                                  "fixed_position_5_2"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSwitchOnChange(
                                                                    "fixed_position_5_2",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.staffing?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "fixed_position_5_3"
                                                                  )?.display
                                                                }
                                                                readOnly
                                                                // onChange={(
                                                                //   e
                                                                // ) => {
                                                                //   handleStaffingSort(
                                                                //     "fixed_position_5_3",
                                                                //     e.target
                                                                //       .value !==
                                                                //       ""
                                                                //       ? e
                                                                //           .target
                                                                //           .value
                                                                //       : "0"
                                                                //   );
                                                                //}}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "fixed_position_5_3"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "35px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "fixed_position_5_3"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "36px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "fixed_position_5_3"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.staffing?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "fixed_position_5_3"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleStaffingLabel(
                                                                      "fixed_position_5_3",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleStaffingSwitchcheck(
                                                                  "fixed_position_5_3"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSwitchOnChange(
                                                                    "fixed_position_5_3",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>

                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.staffing?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "fixed_position_5_4"
                                                                  )?.display
                                                                }
                                                                readOnly
                                                                // onChange={(
                                                                //   e
                                                                // ) => {
                                                                //   handleStaffingSort(
                                                                //     "fixed_position_5_4",
                                                                //     e.target
                                                                //       .value !==
                                                                //       ""
                                                                //       ? e
                                                                //           .target
                                                                //           .value
                                                                //       : "0"
                                                                //   );
                                                                // }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "fixed_position_5_4"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "35px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "fixed_position_5_4"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "36px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "fixed_position_5_4"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.staffing?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "fixed_position_5_4"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleStaffingLabel(
                                                                      "fixed_position_5_4",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleStaffingSwitchcheck(
                                                                  "fixed_position_5_4"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSwitchOnChange(
                                                                    "fixed_position_5_4",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.staffing?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "fixed_position_5_5"
                                                                  )?.display
                                                                }
                                                                readOnly
                                                                // onChange={(
                                                                //   e
                                                                // ) => {
                                                                //   handleStaffingSort(
                                                                //     "fixed_position_5_5",
                                                                //     e.target
                                                                //       .value !==
                                                                //       ""
                                                                //       ? e
                                                                //           .target
                                                                //           .value
                                                                //       : "0"
                                                                //   );
                                                                // }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "fixed_position_5_5"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "35px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "fixed_position_5_5"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "36px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "fixed_position_5_5"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.staffing?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "fixed_position_5_5"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleStaffingLabel(
                                                                      "fixed_position_5_5",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleStaffingSwitchcheck(
                                                                  "fixed_position_5_5"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSwitchOnChange(
                                                                    "fixed_position_5_5",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.staffing?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "fixed_position_5_8"
                                                                  )?.display
                                                                }
                                                                readOnly
                                                                // onChange={(
                                                                //   e
                                                                // ) => {
                                                                //   handleStaffingSort(
                                                                //     "fixed_position_5_8",
                                                                //     e.target
                                                                //       .value !==
                                                                //       ""
                                                                //       ? e
                                                                //           .target
                                                                //           .value
                                                                //       : "0"
                                                                //   );
                                                                // }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "fixed_position_5_8"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "35px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "fixed_position_5_8"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "36px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "fixed_position_5_8"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.staffing?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "fixed_position_5_8"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleStaffingLabel(
                                                                      "fixed_position_5_8",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleStaffingSwitchcheck(
                                                                  "fixed_position_5_8"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSwitchOnChange(
                                                                    "fixed_position_5_8",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.staffing?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "fixed_position_5_5_2"
                                                                  )?.display
                                                                }
                                                                readOnly
                                                                // onChange={(
                                                                //   e
                                                                // ) => {
                                                                //   handleStaffingSort(
                                                                //     "fixed_position_5_5_2",
                                                                //     e.target
                                                                //       .value !==
                                                                //       ""
                                                                //       ? e.target
                                                                //           .value
                                                                //       : "0"
                                                                //   );
                                                                // }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "fixed_position_5_5_2"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "35px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "fixed_position_5_5_2"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "36px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "fixed_position_5_5_2"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.staffing?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "fixed_position_5_5_2"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleStaffingLabel(
                                                                      "fixed_position_5_5_2",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleStaffingSwitchcheck(
                                                                  "fixed_position_5_5_2"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSwitchOnChange(
                                                                    "fixed_position_5_5_2",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.staffing?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "fixed_position_7_1"
                                                                  )?.display
                                                                }
                                                                readOnly
                                                                // onChange={(
                                                                //   e
                                                                // ) => {
                                                                //   handleStaffingSort(
                                                                //     "fixed_position_7_1",
                                                                //     e.target
                                                                //       .value !==
                                                                //       ""
                                                                //       ? e.target
                                                                //           .value
                                                                //       : "0"
                                                                //   );
                                                                // }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "fixed_position_7_1"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "35px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "fixed_position_7_1"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "36px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "fixed_position_7_1"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.staffing?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "fixed_position_7_1"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleStaffingLabel(
                                                                      "fixed_position_7_1",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleStaffingSwitchcheck(
                                                                  "fixed_position_7_1"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSwitchOnChange(
                                                                    "fixed_position_7_1",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.staffing?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "fixed_position_7_2"
                                                                  )?.display
                                                                }
                                                                readOnly
                                                                // onChange={(
                                                                //   e
                                                                // ) => {
                                                                //   handleStaffingSort(
                                                                //     "fixed_position_7_2",
                                                                //     e.target
                                                                //       .value !==
                                                                //       ""
                                                                //       ? e.target
                                                                //           .value
                                                                //       : "0"
                                                                //   );
                                                                // }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "fixed_position_7_2"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "35px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "fixed_position_7_2"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "36px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "fixed_position_7_2"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.staffing?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "fixed_position_7_2"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleStaffingLabel(
                                                                      "fixed_position_7_2",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleStaffingSwitchcheck(
                                                                  "fixed_position_7_2"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSwitchOnChange(
                                                                    "fixed_position_7_2",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.staffing?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "fixed_position_7_3"
                                                                  )?.display
                                                                }
                                                                readOnly
                                                                // onChange={(
                                                                //   e
                                                                // ) => {
                                                                //   handleStaffingSort(
                                                                //     "fixed_position_7_3",
                                                                //     e.target
                                                                //       .value !==
                                                                //       ""
                                                                //       ? e.target
                                                                //           .value
                                                                //       : "0"
                                                                //   );
                                                                // }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "fixed_position_7_3"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "35px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "fixed_position_7_3"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "36px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "fixed_position_7_3"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.staffing?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "fixed_position_7_3"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleStaffingLabel(
                                                                      "fixed_position_7_3",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleStaffingSwitchcheck(
                                                                  "fixed_position_7_3"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSwitchOnChange(
                                                                    "fixed_position_7_3",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.staffing?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "fixed_position_7_4"
                                                                  )?.display
                                                                }
                                                                readOnly
                                                                // onChange={(
                                                                //   e
                                                                // ) => {
                                                                //   handleStaffingSort(
                                                                //     "fixed_position_7_4",
                                                                //     e.target
                                                                //       .value !==
                                                                //       ""
                                                                //       ? e.target
                                                                //           .value
                                                                //       : "0"
                                                                //   );
                                                                // }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "fixed_position_7_4"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "35px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "fixed_position_7_4"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "36px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "fixed_position_7_4"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.staffing?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "fixed_position_7_4"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleStaffingLabel(
                                                                      "fixed_position_7_4",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleStaffingSwitchcheck(
                                                                  "fixed_position_7_4"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSwitchOnChange(
                                                                    "fixed_position_7_4",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.staffing?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "fixed_position_7_5"
                                                                  )?.display
                                                                }
                                                                readOnly
                                                                // onChange={(
                                                                //   e
                                                                // ) => {
                                                                //   handleStaffingSort(
                                                                //     "fixed_position_7_5",
                                                                //     e.target
                                                                //       .value !==
                                                                //       ""
                                                                //       ? e.target
                                                                //           .value
                                                                //       : "0"
                                                                //   );
                                                                // }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "fixed_position_7_5"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "35px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "fixed_position_7_5"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "36px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "fixed_position_7_5"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.staffing?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "fixed_position_7_5"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleStaffingLabel(
                                                                      "fixed_position_7_5",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleStaffingSwitchcheck(
                                                                  "fixed_position_7_5"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSwitchOnChange(
                                                                    "fixed_position_7_5",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.staffing?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "fixed_position_7_5_2"
                                                                  )?.display
                                                                }
                                                                readOnly
                                                                // onChange={(
                                                                //   e
                                                                // ) => {
                                                                //   handleStaffingSort(
                                                                //     "fixed_position_7_5_2",
                                                                //     e.target
                                                                //       .value !==
                                                                //       ""
                                                                //       ? e.target
                                                                //           .value
                                                                //       : "0"
                                                                //   );
                                                                // }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "fixed_position_7_5_2"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "35px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "fixed_position_7_5_2"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "36px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "fixed_position_7_5_2"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.staffing?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "fixed_position_7_5_2"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleStaffingLabel(
                                                                      "fixed_position_7_5_2",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleStaffingSwitchcheck(
                                                                  "fixed_position_7_5_2"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSwitchOnChange(
                                                                    "fixed_position_7_5_2",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.staffing?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "fixed_position_7_8"
                                                                  )?.display
                                                                }
                                                                readOnly
                                                                // onChange={(
                                                                //   e
                                                                // ) => {
                                                                //   handleStaffingSort(
                                                                //     "fixed_position_7_8",
                                                                //     e.target
                                                                //       .value !==
                                                                //       ""
                                                                //       ? e.target
                                                                //           .value
                                                                //       : "0"
                                                                //   );
                                                                // }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "fixed_position_7_8"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "35px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "fixed_position_7_8"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "36px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "fixed_position_7_8"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.staffing?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "fixed_position_7_8"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleStaffingLabel(
                                                                      "fixed_position_7_8",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleStaffingSwitchcheck(
                                                                  "fixed_position_7_8"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSwitchOnChange(
                                                                    "fixed_position_7_8",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.staffing?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "lab_technologist"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSort(
                                                                    "lab_technologist",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "lab_technologist"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "35px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "lab_technologist"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "36px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "lab_technologist"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.staffing?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "lab_technologist"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleStaffingLabel(
                                                                      "lab_technologist",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleStaffingSwitchcheck(
                                                                  "lab_technologist"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSwitchOnChange(
                                                                    "lab_technologist",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.staffing?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "phlebotomist_clinical_services"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSort(
                                                                    "phlebotomist_clinical_services",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "phlebotomist_clinical_services"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "35px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "phlebotomist_clinical_services"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "36px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "phlebotomist_clinical_services"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.staffing?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "phlebotomist_clinical_services"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleStaffingLabel(
                                                                      "phlebotomist_clinical_services",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleStaffingSwitchcheck(
                                                                  "phlebotomist_clinical_services"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSwitchOnChange(
                                                                    "phlebotomist_clinical_services",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.staffing?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "education_orientation"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSort(
                                                                    "education_orientation",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "education_orientation"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "35px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "education_orientation"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "36px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "education_orientation"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.staffing?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "education_orientation"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleStaffingLabel(
                                                                      "education_orientation",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleStaffingSwitchcheck(
                                                                  "education_orientation"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSwitchOnChange(
                                                                    "education_orientation",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.staffing?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "contract_labor_tech"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSort(
                                                                    "contract_labor_tech",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "contract_labor_tech"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "35px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "contract_labor_tech"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "36px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "contract_labor_tech"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.staffing?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "contract_labor_tech"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleStaffingLabel(
                                                                      "contract_labor_tech",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleStaffingSwitchcheck(
                                                                  "contract_labor_tech"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSwitchOnChange(
                                                                    "contract_labor_tech",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.staffing?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "contract_labor_phlebotomy"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSort(
                                                                    "contract_labor_phlebotomy",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "contract_labor_phlebotomy"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "35px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "contract_labor_phlebotomy"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "36px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "contract_labor_phlebotomy"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.staffing?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "contract_labor_phlebotomy"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleStaffingLabel(
                                                                      "contract_labor_phlebotomy",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleStaffingSwitchcheck(
                                                                  "contract_labor_phlebotomy"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSwitchOnChange(
                                                                    "contract_labor_phlebotomy",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.staffing?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "histology"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSort(
                                                                    "histology",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "histology"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "35px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "histology"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "36px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "histology"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.staffing?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "histology"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleStaffingLabel(
                                                                      "histology",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleStaffingSwitchcheck(
                                                                  "histology"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSwitchOnChange(
                                                                    "histology",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.staffing?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "client_services"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSort(
                                                                    "client_services",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "client_services"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "35px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "client_services"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "36px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "client_services"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.staffing?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "client_services"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleStaffingLabel(
                                                                      "client_services",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleStaffingSwitchcheck(
                                                                  "client_services"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSwitchOnChange(
                                                                    "client_services",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>

                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.staffing?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "receptionist"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSort(
                                                                    "receptionist",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "receptionist"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "35px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "receptionist"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "36px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "receptionist"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.staffing?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "receptionist"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleStaffingLabel(
                                                                      "receptionist",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleStaffingSwitchcheck(
                                                                  "receptionist"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSwitchOnChange(
                                                                    "receptionist",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.staffing?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "health_plan_coordinator"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSort(
                                                                    "health_plan_coordinator",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "health_plan_coordinator"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "35px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "health_plan_coordinator"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "36px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "health_plan_coordinator"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.staffing?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "health_plan_coordinator"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleStaffingLabel(
                                                                      "health_plan_coordinator",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleStaffingSwitchcheck(
                                                                  "health_plan_coordinator"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSwitchOnChange(
                                                                    "health_plan_coordinator",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.staffing?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "medical_assistant"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSort(
                                                                    "medical_assistant",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "medical_assistant"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "35px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "medical_assistant"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "36px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "medical_assistant"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.staffing?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "medical_assistant"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleStaffingLabel(
                                                                      "medical_assistant",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleStaffingSwitchcheck(
                                                                  "medical_assistant"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSwitchOnChange(
                                                                    "medical_assistant",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.staffing?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "lead_medical_assistant"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSort(
                                                                    "lead_medical_assistant",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "lead_medical_assistant"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "35px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "lead_medical_assistant"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "36px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "lead_medical_assistant"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.staffing?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "lead_medical_assistant"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleStaffingLabel(
                                                                      "lead_medical_assistant",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleStaffingSwitchcheck(
                                                                  "lead_medical_assistant"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSwitchOnChange(
                                                                    "lead_medical_assistant",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.staffing?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "radiologist_technologist"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSort(
                                                                    "radiologist_technologist",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "radiologist_technologist"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "35px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "radiologist_technologist"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "36px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "radiologist_technologist"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.staffing?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "radiologist_technologist"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleStaffingLabel(
                                                                      "radiologist_technologist",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleStaffingSwitchcheck(
                                                                  "radiologist_technologist"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSwitchOnChange(
                                                                    "radiologist_technologist",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.staffing?.find(
                                                                    (e) =>
                                                                      e.field_name ===
                                                                      "athletic_trainer"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSort(
                                                                    "athletic_trainer",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            {realtimeFormulaHeaders[
                                                              "athletic_trainer"
                                                            ] ? (
                                                              <td
                                                                style={{
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  textAlign:
                                                                    "left",
                                                                  lineHeight:
                                                                    "35px",
                                                                }}
                                                              >
                                                                <>
                                                                  {
                                                                    <OverlayTrigger
                                                                      key={
                                                                        "top"
                                                                      }
                                                                      placement={
                                                                        "top"
                                                                      }
                                                                      overlay={
                                                                        <Tooltip
                                                                          id={`tooltip-${"top"}`}
                                                                        >
                                                                          <strong>
                                                                            {
                                                                              "Formula:"
                                                                            }
                                                                          </strong>
                                                                          &nbsp;
                                                                          {
                                                                            realtimeFormulaHeaders[
                                                                              "athletic_trainer"
                                                                            ]
                                                                          }
                                                                        </Tooltip>
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={
                                                                          calcIcon
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "36px",
                                                                          height:
                                                                            "auto",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      />
                                                                    </OverlayTrigger>
                                                                  }
                                                                </>
                                                              </td>
                                                            ) : (
                                                              <td></td>
                                                            )}
                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "athletic_trainer"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.staffing?.find(
                                                                      (e) =>
                                                                        e.field_name ===
                                                                        "athletic_trainer"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleStaffingLabel(
                                                                      "athletic_trainer",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleStaffingSwitchcheck(
                                                                  "athletic_trainer"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleStaffingSwitchOnChange(
                                                                    "athletic_trainer",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2">
                                          <Accordion.Header className="white-bg">
                                            Additional Data Tracking
                                          </Accordion.Header>
                                          <Accordion.Body>
                                            <div class="row">
                                              {/*New Design */}
                                              <div className="col-md-12 m-0">
                                                <hr />
                                              </div>
                                              <div className="col-md-12">
                                                <div className="row">
                                                  <div className="col-md-12">
                                                    <div class="table-responsive">
                                                      <table class="table">
                                                        <thead>
                                                          <tr>
                                                            <th>
                                                              Show/Hide/Sort
                                                            </th>

                                                            <th>Key</th>
                                                            <th>Label</th>
                                                            <th>
                                                              Display Graph
                                                            </th>
                                                            <th>Mandatory</th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "ant_admit"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "ant_admit",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "ant_admit"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "ant_admit"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "ant_admit",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "ant_admit"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "ant_admit",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "ant_admit"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "ant_admit",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "actual_admit"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "actual_admit",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "actual_admit"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "actual_admit"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "actual_admit",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "actual_admit"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "actual_admit",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "actual_admit"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "actual_admit",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "telemetry"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "telemetry",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "telemetry"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "telemetry"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "telemetry",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "telemetry"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "telemetry",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "telemetry"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "telemetry",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "vaso_active_drips"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "vaso_active_drips",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "vaso_active_drips"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "vaso_active_drips"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "vaso_active_drips",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "vaso_active_drips"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "vaso_active_drips",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "vaso_active_drips"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "vaso_active_drips",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "isolation_patients"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "isolation_patients",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "isolation_patients"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "isolation_patients"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "isolation_patients",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "isolation_patients"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "isolation_patients",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "isolation_patients"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "isolation_patients",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>

                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "of_isolation"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "of_isolation",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "of_isolation"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "of_isolation"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "of_isolation",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "of_isolation"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "of_isolation",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "of_isolation"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "of_isolation",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "of_rrs_codes"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "of_rrs_codes",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "of_rrs_codes"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "of_rrs_codes"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "of_rrs_codes",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "of_rrs_codes"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "of_rrs_codes",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "of_rrs_codes"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "of_rrs_codes",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "of_video_monitors"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "of_video_monitors",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "of_video_monitors"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "of_video_monitors"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "of_video_monitors",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "of_video_monitors"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "of_video_monitors",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "of_video_monitors"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "of_video_monitors",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "did_blt_cover_obs"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "did_blt_cover_obs",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "did_blt_cover_obs"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "did_blt_cover_obs"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "did_blt_cover_obs",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "did_blt_cover_obs"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "did_blt_cover_obs",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "did_blt_cover_obs"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "did_blt_cover_obs",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>

                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "of_patients_w_video_monitor_last_six_hours"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "of_patients_w_video_monitor_last_six_hours",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "of_patients_w_video_monitor_last_six_hours"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "of_patients_w_video_monitor_last_six_hours"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "of_patients_w_video_monitor_last_six_hours",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "of_patients_w_video_monitor_last_six_hours"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "of_patients_w_video_monitor_last_six_hours",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "of_patients_w_video_monitor_last_six_hours"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "of_patients_w_video_monitor_last_six_hours",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "of_patients_in_seclusion_last_six_hours"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "of_patients_in_seclusion_last_six_hours",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "of_patients_in_seclusion_last_six_hours"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "of_patients_in_seclusion_last_six_hours"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "of_patients_in_seclusion_last_six_hours",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "of_patients_in_seclusion_last_six_hours"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "of_patients_in_seclusion_last_six_hours",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "of_patients_in_seclusion_last_six_hours"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "of_patients_in_seclusion_last_six_hours",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "of_patients_in_restraints_last_six_hours"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "of_patients_in_restraints_last_six_hours",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "of_patients_in_restraints_last_six_hours"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "of_patients_in_restraints_last_six_hours"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "of_patients_in_restraints_last_six_hours",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "of_patients_in_restraints_last_six_hours"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "of_patients_in_restraints_last_six_hours",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "of_patients_in_restraints_last_six_hours"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "of_patients_in_restraints_last_six_hours",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "of_icu_patients_waiting_in_er"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "of_icu_patients_waiting_in_er",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "of_icu_patients_waiting_in_er"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "of_icu_patients_waiting_in_er"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "of_icu_patients_waiting_in_er",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "of_icu_patients_waiting_in_er"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "of_icu_patients_waiting_in_er",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "of_icu_patients_waiting_in_er"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "of_icu_patients_waiting_in_er",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>

                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "of_anticipated_transfers_in_next_four_hours"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "of_anticipated_transfers_in_next_four_hours",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "of_anticipated_transfers_in_next_four_hours"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "of_anticipated_transfers_in_next_four_hours"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "of_anticipated_transfers_in_next_four_hours",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "of_anticipated_transfers_in_next_four_hours"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "of_anticipated_transfers_in_next_four_hours",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "of_anticipated_transfers_in_next_four_hours"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "of_anticipated_transfers_in_next_four_hours",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "of_anticipated_transfers_out_next_four_hours"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "of_anticipated_transfers_out_next_four_hours",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "of_anticipated_transfers_out_next_four_hours"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "of_anticipated_transfers_out_next_four_hours"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "of_anticipated_transfers_out_next_four_hours",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "of_anticipated_transfers_out_next_four_hours"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "of_anticipated_transfers_out_next_four_hours",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "of_anticipated_transfers_out_next_four_hours"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "of_anticipated_transfers_out_next_four_hours",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "of_rns_wa_wit_higher_than_shift_standard"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "of_rns_wa_wit_higher_than_shift_standard",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "of_rns_wa_wit_higher_than_shift_standard"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "of_rns_wa_wit_higher_than_shift_standard"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "of_rns_wa_wit_higher_than_shift_standard",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "of_rns_wa_wit_higher_than_shift_standard"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "of_rns_wa_wit_higher_than_shift_standard",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "of_rns_wa_wit_higher_than_shift_standard"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "of_rns_wa_wit_higher_than_shift_standard",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "of_pcu_pts_waiting_in_er"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "of_pcu_pts_waiting_in_er",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "of_pcu_pts_waiting_in_er"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "of_pcu_pts_waiting_in_er"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "of_pcu_pts_waiting_in_er",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "of_pcu_pts_waiting_in_er"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "of_pcu_pts_waiting_in_er",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "of_pcu_pts_waiting_in_er"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "of_pcu_pts_waiting_in_er",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "of_c_sections_since_last_time_stamp"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "of_c_sections_since_last_time_stamp",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "of_c_sections_since_last_time_stamp"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "of_c_sections_since_last_time_stamp"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "of_c_sections_since_last_time_stamp",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "of_c_sections_since_last_time_stamp"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "of_c_sections_since_last_time_stamp",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "of_c_sections_since_last_time_stamp"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "of_c_sections_since_last_time_stamp",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "of_inductions_since_last_time_stamp"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "of_inductions_since_last_time_stamp",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "of_inductions_since_last_time_stamp"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "of_inductions_since_last_time_stamp"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "of_inductions_since_last_time_stamp",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "of_inductions_since_last_time_stamp"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "of_inductions_since_last_time_stamp",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "of_inductions_since_last_time_stamp"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "of_inductions_since_last_time_stamp",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "of_births_since_last_time_stamp"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "of_births_since_last_time_stamp",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "of_births_since_last_time_stamp"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "of_births_since_last_time_stamp"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "of_births_since_last_time_stamp",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "of_births_since_last_time_stamp"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "of_births_since_last_time_stamp",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "of_births_since_last_time_stamp"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "of_births_since_last_time_stamp",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "of_triage_patients_since_last_time_stamp"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "of_triage_patients_since_last_time_stamp",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "of_triage_patients_since_last_time_stamp"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "of_triage_patients_since_last_time_stamp"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "of_triage_patients_since_last_time_stamp",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "of_triage_patients_since_last_time_stamp"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "of_triage_patients_since_last_time_stamp",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "of_triage_patients_since_last_time_stamp"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "of_triage_patients_since_last_time_stamp",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "of_pp_hemorrhage_ob_emergency"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "of_pp_hemorrhage_ob_emergency",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "of_pp_hemorrhage_ob_emergency"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "of_pp_hemorrhage_ob_emergency"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "of_pp_hemorrhage_ob_emergency",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "of_pp_hemorrhage_ob_emergency"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "of_pp_hemorrhage_ob_emergency",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "of_pp_hemorrhage_ob_emergency"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "of_pp_hemorrhage_ob_emergency",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "of_resuscitations"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "of_resuscitations",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "of_resuscitations"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "of_resuscitations"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "of_resuscitations",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "of_resuscitations"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "of_resuscitations",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "of_resuscitations"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "of_resuscitations",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "of_op_dialysis_patients"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "of_op_dialysis_patients",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "of_op_dialysis_patients"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "of_op_dialysis_patients"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "of_op_dialysis_patients",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "of_op_dialysis_patients"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "of_op_dialysis_patients",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "of_op_dialysis_patients"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "of_op_dialysis_patients",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "cx_no_shows_w_i_24_hrs"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "cx_no_shows_w_i_24_hrs",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "cx_no_shows_w_i_24_hrs"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "cx_no_shows_w_i_24_hrs"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "cx_no_shows_w_i_24_hrs",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "cx_no_shows_w_i_24_hrs"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "cx_no_shows_w_i_24_hrs",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "cx_no_shows_w_i_24_hrs"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "cx_no_shows_w_i_24_hrs",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "downtime_hrs"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "downtime_hrs",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "downtime_hrs"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "downtime_hrs"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "downtime_hrs",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "downtime_hrs"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "downtime_hrs",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "downtime_hrs"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "downtime_hrs",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "hrs_non_cath_proc"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "hrs_non_cath_proc",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "hrs_non_cath_proc"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "hrs_non_cath_proc"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "hrs_non_cath_proc",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "hrs_non_cath_proc"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "hrs_non_cath_proc",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "hrs_non_cath_proc"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "hrs_non_cath_proc",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "of_proc_cx_no_shows_w_i_24_hrs"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "of_proc_cx_no_shows_w_i_24_hrs",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "of_proc_cx_no_shows_w_i_24_hrs"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "of_proc_cx_no_shows_w_i_24_hrs"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "of_proc_cx_no_shows_w_i_24_hrs",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "of_proc_cx_no_shows_w_i_24_hrs"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "of_proc_cx_no_shows_w_i_24_hrs",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "of_proc_cx_no_shows_w_i_24_hrs"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "of_proc_cx_no_shows_w_i_24_hrs",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "hrs_equip_down"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "hrs_equip_down",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "hrs_equip_down"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "hrs_equip_down"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "hrs_equip_down",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "hrs_equip_down"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "hrs_equip_down",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "hrs_equip_down"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "hrs_equip_down",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "on_call_hrs_paid"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "on_call_hrs_paid",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "on_call_hrs_paid"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "on_call_hrs_paid"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "on_call_hrs_paid",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "on_call_hrs_paid"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "on_call_hrs_paid",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "on_call_hrs_paid"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "on_call_hrs_paid",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "times_called_in"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "times_called_in",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "times_called_in"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "times_called_in"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "times_called_in",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "times_called_in"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "times_called_in",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "times_called_in"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "times_called_in",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "of_hrs_in_surgery"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "of_hrs_in_surgery",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "of_hrs_in_surgery"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "of_hrs_in_surgery"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "of_hrs_in_surgery",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "of_hrs_in_surgery"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "of_hrs_in_surgery",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "of_hrs_in_surgery"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "of_hrs_in_surgery",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "active_work_queue"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "active_work_queue",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "active_work_queue"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "active_work_queue"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "active_work_queue",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "active_work_queue"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "active_work_queue",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "active_work_queue"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "active_work_queue",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "total_work_queue"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "total_work_queue",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "total_work_queue"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "total_work_queue"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "total_work_queue",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "total_work_queue"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "total_work_queue",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "total_work_queue"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "total_work_queue",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "uncontacted_referral_grater_7_days"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "uncontacted_referral_grater_7_days",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "uncontacted_referral_grater_7_days"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "uncontacted_referral_grater_7_days"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "uncontacted_referral_grater_7_days",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "uncontacted_referral_grater_7_days"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "uncontacted_referral_grater_7_days",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "uncontacted_referral_grater_7_days"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "uncontacted_referral_grater_7_days",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "surgery_hrs"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "surgery_hrs",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "surgery_hrs"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "surgery_hrs"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "surgery_hrs",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "surgery_hrs"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "surgery_hrs",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "surgery_hrs"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "surgery_hrs",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "fluoro_hrs"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "fluoro_hrs",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "fluoro_hrs"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "fluoro_hrs"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "fluoro_hrs",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "fluoro_hrs"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "fluoro_hrs",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "fluoro_hrs"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "fluoro_hrs",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "ip_same_day_add_ons"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "ip_same_day_add_ons",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "ip_same_day_add_ons"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "ip_same_day_add_ons"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "ip_same_day_add_ons",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "ip_same_day_add_ons"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "ip_same_day_add_ons",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "ip_same_day_add_ons"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "ip_same_day_add_ons",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "actual_rn_worked_hrs"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "actual_rn_worked_hrs",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "actual_rn_worked_hrs"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "actual_rn_worked_hrs"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "actual_rn_worked_hrs",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "actual_rn_worked_hrs"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "actual_rn_worked_hrs",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "actual_rn_worked_hrs"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "actual_rn_worked_hrs",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "uae_echos"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "uae_echos",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "uae_echos"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "uae_echos"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "uae_echos",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "uae_echos"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "uae_echos",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "uae_echos"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "uae_echos",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "providers_out"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "providers_out",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "providers_out"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "providers_out"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "providers_out",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "providers_out"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "providers_out",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "providers_out"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "providers_out",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "cancellations_within_24_hrs"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "cancellations_within_24_hrs",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "cancellations_within_24_hrs"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "cancellations_within_24_hrs"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "cancellations_within_24_hrs",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "cancellations_within_24_hrs"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "cancellations_within_24_hrs",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "cancellations_within_24_hrs"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "cancellations_within_24_hrs",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "ip_add_ons"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "ip_add_ons",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "ip_add_ons"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "ip_add_ons"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "ip_add_ons",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "ip_add_ons"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "ip_add_ons",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "ip_add_ons"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "ip_add_ons",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "dac_hrs_spent"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "dac_hrs_spent",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "dac_hrs_spent"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "dac_hrs_spent"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "dac_hrs_spent",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "dac_hrs_spent"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "dac_hrs_spent",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "dac_hrs_spent"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "dac_hrs_spent",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "no_shows"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "no_shows",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "no_shows"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "no_shows"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "no_shows",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "no_shows"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "no_shows",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "no_shows"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "no_shows",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "cx_w_i_24_hrs"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "cx_w_i_24_hrs",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "cx_w_i_24_hrs"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "cx_w_i_24_hrs"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "cx_w_i_24_hrs",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "cx_w_i_24_hrs"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "cx_w_i_24_hrs",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "cx_w_i_24_hrs"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "cx_w_i_24_hrs",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "patients_w_stay_grater_70_min"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "patients_w_stay_grater_70_min",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "patients_w_stay_grater_70_min"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "patients_w_stay_grater_70_min"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "patients_w_stay_grater_70_min",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "patients_w_stay_grater_70_min"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "patients_w_stay_grater_70_min",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "patients_w_stay_grater_70_min"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "patients_w_stay_grater_70_min",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "crash_carts_set_up_restocked"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "crash_carts_set_up_restocked",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "crash_carts_set_up_restocked"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "crash_carts_set_up_restocked"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "crash_carts_set_up_restocked",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "crash_carts_set_up_restocked"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "crash_carts_set_up_restocked",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "crash_carts_set_up_restocked"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "crash_carts_set_up_restocked",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "instruments_returned_unused"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "instruments_returned_unused",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "instruments_returned_unused"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "instruments_returned_unused"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "instruments_returned_unused",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "instruments_returned_unused"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "instruments_returned_unused",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "instruments_returned_unused"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "instruments_returned_unused",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "flashed_items"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "flashed_items",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "flashed_items"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "flashed_items"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "flashed_items",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "flashed_items"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "flashed_items",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "flashed_items"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "flashed_items",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "procedures_requiring_staff_grater_normal_ortho"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "procedures_requiring_staff_grater_normal_ortho",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "procedures_requiring_staff_grater_normal_ortho"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "procedures_requiring_staff_grater_normal_ortho"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "procedures_requiring_staff_grater_normal_ortho",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "procedures_requiring_staff_grater_normal_ortho"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "procedures_requiring_staff_grater_normal_ortho",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "procedures_requiring_staff_grater_normal_ortho"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "procedures_requiring_staff_grater_normal_ortho",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "procedures_requiring_grater_normal_plastics"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "procedures_requiring_grater_normal_plastics",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "procedures_requiring_grater_normal_plastics"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "procedures_requiring_grater_normal_plastics"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "procedures_requiring_grater_normal_plastics",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "procedures_requiring_grater_normal_plastics"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "procedures_requiring_grater_normal_plastics",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "procedures_requiring_grater_normal_plastics"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "procedures_requiring_grater_normal_plastics",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "procedures_requiring_grater_normal_for_ophthamolgy"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "procedures_requiring_grater_normal_for_ophthamolgy",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "procedures_requiring_grater_normal_for_ophthamolgy"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "procedures_requiring_grater_normal_for_ophthamolgy"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "procedures_requiring_grater_normal_for_ophthamolgy",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "procedures_requiring_grater_normal_for_ophthamolgy"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "procedures_requiring_grater_normal_for_ophthamolgy",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "procedures_requiring_grater_normal_for_ophthamolgy"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "procedures_requiring_grater_normal_for_ophthamolgy",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "procedures_requiring_grater_normal_general_baritics_other"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "procedures_requiring_grater_normal_general_baritics_other",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "procedures_requiring_grater_normal_general_baritics_other"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "procedures_requiring_grater_normal_general_baritics_other"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "procedures_requiring_grater_normal_general_baritics_other",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "procedures_requiring_grater_normal_general_baritics_other"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "procedures_requiring_grater_normal_general_baritics_other",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "procedures_requiring_grater_normal_general_baritics_other"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "procedures_requiring_grater_normal_general_baritics_other",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "ip_status_patients_held_over"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "ip_status_patients_held_over",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "ip_status_patients_held_over"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "ip_status_patients_held_over"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "ip_status_patients_held_over",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "ip_status_patients_held_over"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "ip_status_patients_held_over",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "ip_status_patients_held_over"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "ip_status_patients_held_over",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "of_hours_the_rcu_was_open_last_4_hrs"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "of_hours_the_rcu_was_open_last_4_hrs",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "of_hours_the_rcu_was_open_last_4_hrs"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "of_hours_the_rcu_was_open_last_4_hrs"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "of_hours_the_rcu_was_open_last_4_hrs",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "of_hours_the_rcu_was_open_last_4_hrs"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "of_hours_the_rcu_was_open_last_4_hrs",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "of_hours_the_rcu_was_open_last_4_hrs"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "of_hours_the_rcu_was_open_last_4_hrs",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "one_1_and_1_2_pts_currently_awaiting_placement"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "one_1_and_1_2_pts_currently_awaiting_placement",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "one_1_and_1_2_pts_currently_awaiting_placement"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "one_1_and_1_2_pts_currently_awaiting_placement"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "one_1_and_1_2_pts_currently_awaiting_placement",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "one_1_and_1_2_pts_currently_awaiting_placement"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "one_1_and_1_2_pts_currently_awaiting_placement",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "one_1_and_1_2_pts_currently_awaiting_placement"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "one_1_and_1_2_pts_currently_awaiting_placement",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "code_3s_stemi_or_strokes_last_4_hours"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "code_3s_stemi_or_strokes_last_4_hours",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "code_3s_stemi_or_strokes_last_4_hours"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "code_3s_stemi_or_strokes_last_4_hours"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "code_3s_stemi_or_strokes_last_4_hours",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "code_3s_stemi_or_strokes_last_4_hours"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "code_3s_stemi_or_strokes_last_4_hours",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "code_3s_stemi_or_strokes_last_4_hours"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "code_3s_stemi_or_strokes_last_4_hours",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "of_psa_si_pts"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "of_psa_si_pts",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "of_psa_si_pts"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "of_psa_si_pts"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "of_psa_si_pts",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "of_psa_si_pts"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "of_psa_si_pts",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "of_psa_si_pts"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "of_psa_si_pts",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "of_lost_doses"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "of_lost_doses",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "of_lost_doses"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "of_lost_doses"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "of_lost_doses",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "of_lost_doses"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "of_lost_doses",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "of_lost_doses"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "of_lost_doses",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "active_cases_in_the_work_queue"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "active_cases_in_the_work_queue",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "active_cases_in_the_work_queue"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "active_cases_in_the_work_queue"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "active_cases_in_the_work_queue",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "active_cases_in_the_work_queue"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "active_cases_in_the_work_queue",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "active_cases_in_the_work_queue"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "active_cases_in_the_work_queue",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "of_ir_vas_procedures"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "of_ir_vas_procedures",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "of_ir_vas_procedures"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "of_ir_vas_procedures"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "of_ir_vas_procedures",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "of_ir_vas_procedures"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "of_ir_vas_procedures",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "of_ir_vas_procedures"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "of_ir_vas_procedures",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>

                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "hi_flow_in_us"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "hi_flow_in_us",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "hi_flow_in_us"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "hi_flow_in_us"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "hi_flow_in_us",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "hi_flow_in_us"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "hi_flow_in_us",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "hi_flow_in_us"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "hi_flow_in_us",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "of_vents_in_use"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "of_vents_in_use",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "of_vents_in_use"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "of_vents_in_use"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "of_vents_in_use",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "of_vents_in_use"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "of_vents_in_use",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "of_vents_in_use"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "of_vents_in_use",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "of_bi_paps_in_use"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "of_bi_paps_in_use",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "of_bi_paps_in_use"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "of_bi_paps_in_use"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "of_bi_paps_in_use",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "of_bi_paps_in_use"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "of_bi_paps_in_use",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "of_bi_paps_in_use"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "of_bi_paps_in_use",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "of_c_paps_in_use_noc_only"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "of_c_paps_in_use_noc_only",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "of_c_paps_in_use_noc_only"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "of_c_paps_in_use_noc_only"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "of_c_paps_in_use_noc_only",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "of_c_paps_in_use_noc_only"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "of_c_paps_in_use_noc_only",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "of_c_paps_in_use_noc_only"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "of_c_paps_in_use_noc_only",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "of_loaner_trays_processed"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "of_loaner_trays_processed",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "of_loaner_trays_processed"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "of_loaner_trays_processed"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "of_loaner_trays_processed",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "of_loaner_trays_processed"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "of_loaner_trays_processed",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "of_loaner_trays_processed"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "of_loaner_trays_processed",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>

                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "urgent_cases_added_to_schedule"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "urgent_cases_added_to_schedule",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "urgent_cases_added_to_schedule"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "urgent_cases_added_to_schedule"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "urgent_cases_added_to_schedule",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "urgent_cases_added_to_schedule"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "urgent_cases_added_to_schedule",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "urgent_cases_added_to_schedule"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "urgent_cases_added_to_schedule",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "unplanned_providers_absent"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "unplanned_providers_absent",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "unplanned_providers_absent"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "unplanned_providers_absent"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "unplanned_providers_absent",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "unplanned_providers_absent"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "unplanned_providers_absent",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "unplanned_providers_absent"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "unplanned_providers_absent",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "no_show_patients_same_day_cancelation"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "no_show_patients_same_day_cancelation",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "no_show_patients_same_day_cancelation"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "no_show_patients_same_day_cancelation"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "no_show_patients_same_day_cancelation",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleDisplayGraphSwitchcheck(
                                                                  "no_show_patients_same_day_cancelation"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataGraph(
                                                                    "no_show_patients_same_day_cancelation",
                                                                    e
                                                                      ? "1"
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "no_show_patients_same_day_cancelation"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "no_show_patients_same_day_cancelation",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>

                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "comment"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "comment",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "comment"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "comment"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "comment",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle"></td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "comment"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "comment",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="sm-transparent-input">
                                                              <input
                                                                type="text"
                                                                class="form-control"
                                                                value={
                                                                  formControl?.additional_data_tracking?.find(
                                                                    (e) =>
                                                                      e?.field_name ===
                                                                      "submitted_by_text"
                                                                  )?.display
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSort(
                                                                    "submitted_by_text",
                                                                    e.target
                                                                      .value !==
                                                                      ""
                                                                      ? e.target
                                                                          .value
                                                                      : "0"
                                                                  );
                                                                }}
                                                              />
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    "submitted_by_text"
                                                                  }
                                                                  readOnly
                                                                />
                                                              </div>
                                                            </td>

                                                            <td className="pe-5">
                                                              <div className="d-flex align-items-center">
                                                                <input
                                                                  type="text"
                                                                  class="form-control mx-w-340"
                                                                  value={
                                                                    formControl?.additional_data_tracking?.find(
                                                                      (e) =>
                                                                        e?.field_name ===
                                                                        "submitted_by_text"
                                                                    )
                                                                      ?.field_label
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleAddDataLabel(
                                                                      "submitted_by_text",
                                                                      e.target
                                                                        .value
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="vertical-aling-middle"></td>
                                                            <td className="vertical-aling-middle">
                                                              <Switch
                                                                checkedChildren="Yes"
                                                                unCheckedChildren="No"
                                                                checked={handleAddDataSwitchcheck(
                                                                  "submitted_by_text"
                                                                )}
                                                                className="blue-b"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleAddDataSwitchOnChange(
                                                                    "submitted_by_text",
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="3">
                                          <Accordion.Header>
                                            Realtime Formula
                                          </Accordion.Header>
                                          <Accordion.Body>
                                            <div class="row">
                                              <div className="col-md-12 m-0">
                                                <hr />
                                              </div>
                                              <div className="col-md-12">
                                                <div className="row">
                                                  <div className="col-md-12 editor-styles">
                                                    {Object.keys(
                                                      realtimeFormulaHeaders
                                                    )?.map((e) => (
                                                      <div
                                                        key={e}
                                                        style={{
                                                          lineHeight: "40px",
                                                          fontSize: "1rem",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontWeight: "500",
                                                            fontSize: "1.1rem",
                                                          }}
                                                        >
                                                          {e}&nbsp;:&nbsp;
                                                        </span>

                                                        {
                                                          realtimeFormulaHeaders[
                                                            e
                                                          ]
                                                        }
                                                      </div>
                                                    ))}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </Accordion.Body>
                                        </Accordion.Item>
                                      </Accordion>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                            <Form.Group className="col-md-12 mb-3 d-flex justify-content-end">
                              {/* <Button variant="primary" type="submit">
                              Reset
                            </Button> */}
                              <Button
                                className="ms-3"
                                variant="success"
                                type="submit"
                                disabled={isLoading}
                              >
                                {isLoading ? "Saving..." : "Save"}
                              </Button>
                            </Form.Group>
                          </div>
                        </div>
                      </FormikForm>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Formik>
      ) : (
        <div className="d-flex align-items-center justify-content-center w-100vw h-100vh">
          <span class="loader" style={{ width: "50px", height: "50px" }}></span>
        </div>
      )}
    </>
  );
}

export default EditNewCostCenter;
