
import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './index.css';
import Ico2 from "../../../../assets/img/info.png";
import Ico3 from "../../../../assets/img/warning.png";
import Ico4 from "../../../../assets/img/critical.png";

function InfoModal(props) {
  return (
    <Modal
    {...props}
    size="md"
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <Modal.Header closeButton className='custom-font-size'>
      <Modal.Title id="contained-modal-title-vcenter" className='fw-normal color-black f-18'>
      Report accounts for the following exceptions:
      </Modal.Title>
    </Modal.Header>
    <Modal.Body className='custom-font-size text-indent-15'>
      <h4 className=' fw-normal color-black f-18  mb-0'><img src={Ico4}/> Critical</h4>
      <p>- An odd clocking detected </p>
      <p className='text-indent-30'>- Employee will not be paid for the pay period if not corrected</p>
      <h4 className=' fw-normal color-black f-18 f-16 mb-0'><img src={Ico3} /> Warning</h4>
      <p>- Absent Attendance Code of Unexcused Absence applied to schedule</p>
      <p>- Early Departure Attendance Code of Left Early applied to schedule</p>
      <p>- No meal break taken, one Meal Break Penalty recorded</p>
      <p>- Regular Hours reported in excess of 80 in the pay period</p>
      <h4 className=' fw-normal color-black f-18 f-16 mb-0'> <img src={Ico2}/> Informational</h4>
      <p>- Hours reported in excess of approved hours</p>
      <p>- Hours reported in excess of Daily Hours on the posted day</p>
    </Modal.Body>
    
  </Modal>
  )
}

export default InfoModal

