import "./index.css";
import { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import {getdata} from '../../services';
import PageLayout from "../../layouts/PageLayout";
import LineChart from "../../components/shared/LineChart";
import AddModal from "../../components/AddModal";
import UpdateModal from "../../components/UpdateModal";
import SuccessModal from "../../components/shared/SuccessModal";
import ErrorModal from "../../components/shared/SuccessModal/ErrorModal";
import DateFilter from "../../components/DateFilter";
import DataTable from "../../components/DataTable";
import { API_CALL_URL_PUBLIC } from "../../constants/";
import { API_CALL_URL_PRIVATE } from "../../constants/";
import axios from "../../axios";
import {
  setDepartmentDetail,
  setHeadCount,
  getPreviousAndCurrentShift,
  activedatefilter,
} from "../../store/actions/Compensate";
import ContentLoader from "react-content-loader";
// import { SocketContext } from "../../socket";
import "font-awesome/css/font-awesome.min.css";
import moment from "moment";
import { ChartExample } from "../../components/shared/PieChart/m-pei-chart";
import { ThemeContext } from "../../providers/ThemeProvider";


const MyLoader = ({ themeMode }) => (
  <div className="col-md-4 width-1">
    <ContentLoader viewBox="0 0 100 100"
      backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
      foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
    >
      {/* Only SVG shapes */}
      <rect x="1.2" y="2" rx="4" ry="4" width="87" height="90" />
    </ContentLoader>
  </div>
);

function Realtime(props) {

  const {urlparam} = props;
  const {id} = props;
  const [themeMode, setTheme] = useContext(ThemeContext);
  // const socket = useContext(SocketContext);
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  const detail_data = selector.compensate.departmentdetail;
  const multichart = detail_data?.left_sidebar ? detail_data.left_sidebar.sidebar_1 : {};
  //const pieichart = detail_data?.left_sidebar ? detail_data.left_sidebar.sidebar_2 : [];
  const contentone = detail_data?.content ? detail_data.content.content_1 : {};
  const headcount = detail_data?.header ? detail_data.header : {};
  const barchart = detail_data?.footer ? detail_data.footer : [];
  //const commentlist = detail_data?.content?.comment ? detail_data.content.content_2.comment : [];
  const dataTable = detail_data?.right_sidebar?.tableData ? detail_data?.right_sidebar.tableData : [];
  const minMaxData = detail_data?.right_sidebar?.minMaxData ? detail_data?.right_sidebar?.minMaxData : []
  const getprevAndCurrentShift = selector.compensate.previousAndCurrentShift;

  const [show, setShow] = useState(false);
  const [showupdate, setShowupdate] = useState(false);
  const [showsucess, setShowsuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [sucessmsg, setSucessmsg] = useState("");
  const [contentId, setContent] = useState({});
  const [sliderFilterData, setSliderFilterData] = useState({});
  const [realtimeshift, setRealtimeshift] = useState("");
  const [boxColor, setBoxColor] = useState({ color: "" });
  const [gridcolor, setGridcolor] = useState("");
  const [filterdate, setFilterdate] = useState({});
  const [activefiltertab, setActivefiltertab] = useState("realtime");
  // const [autoFillformdata, setautoFillformdata] = useState({});
  const [previousShiftData, setPreviousShiftData] = useState({});
  const [lastShiftData, setLastShiftData] = useState({});

  const [gridData, setGridData] = useState({});
  //const [addgridData, setAddGridData] = useState({});
  //const [directId, setDirectId] = useState("");
  const [isLoader, setisLoader] = useState(true);

  const [getPreAndCurr, setPreAndCurrShift] = useState({});


  window?.localStorage?.setItem("activefiltertab", "realtime");
  const handleShow = (event) => {
    setShow(true);
    // autoFillgetDirectDataById()
  };
  const callBackhide = (value, issuceess, msg) => {
    //
    // alert(value)
    setShow(value);
    if (issuceess) {
      setShowsuccess(true);
      setSucessmsg(msg);
      setTimeout(() => {
        setShowsuccess(false);
      }, 2000);
      //setShowsuccess(false);
    }
  };
  const handleShowUpdate = () => setShowupdate(true);
  const callBackhideupdate = (value, issuceess, msg) => {
    setShowupdate(value);
    if (issuceess) {
      setShowsuccess(true);
      setSucessmsg(msg);
      setTimeout(() => {
        setShowsuccess(false);
      }, 2000);
    }
  };
  const callBacksuccess = () => {};

  useEffect(() => {
    fetchDetails();
    // autoFillgetDirectDataById()
  }, [id, sliderFilterData]);

  /* useEffect(()=>{
        socket.on("csvUpdate", (data)=>{
            //console.log("csvUpdate "+{data})
            fetchDetails();
        });
    },[socket]); */

  // useEffect(() => {
  //   socket.on("csvUpdate", (socketData) => {
  //     if (window?.localStorage?.getItem("activefiltertab") === "realtime") {
  //       // alert("ok")
  //       fetchDetails();
  //       //console.log("00000000")
  //     }
  //   });
  // }, [socket]);

  let param = {
    fromdate: { date: moment(new Date()).format("DD-MM-YYYY"), shift_time: "03:00" },
    todate: { date: moment(new Date()).format("DD-MM-YYYY"), shift_time: "07:00" },
  };
  async function fetchDetails(contend_id, shifttime) {
    const getPreAndCurr = await getPreAndCurrShift();
    setPreAndCurrShift(getPreAndCurr);
    setisLoader(true);
    try {
      if (id != null) {
        //dispatch(setAirlines([]));
        //console.log("sliderFilterData", sliderFilterData);
        if (activefiltertab === "realtime") {
          const getCurrentShift = await getPreAndCurrShift();
          param = getCurrentShift?.filterDates;
          param["cost_center_number"] = id;
        } else {
          param["fromdate"] = {
            date: moment(sliderFilterData.date).format("DD-MM-YYYY"),
            shift_time: "03:00",
          };
          param["todate"] = {
            date: moment(sliderFilterData.date).format("DD-MM-YYYY"),
            shift_time: "23:00",
          };
          param["cost_center_number"] = id;
        }
        //console.log(param, 'sliderFilterData filter date');
        // const resp = await axios.post(
        //   API_CALL_URL + "/department/getData",
        //   param
        // );
        const resp = getdata(param);
        let { data } = await resp;

        setisLoader(false);
        dispatch(setHeadCount(data.data.header));
        dispatch(setDepartmentDetail(data.data));
        //setDirectId(data.data.right_sidebar?.comments[0]);
        let defaultshift = shifttime
          ? shifttime
          : data.data?.content?.content_1?.selectedShift;
        let contentid = data.data?.content?.content_1?.id;
        setRealtimeshift(defaultshift);
        autoFillgetDirectDataById(contentid, true);
        const lastShiftEntry = data.data?.content?.lastShiftEntry;
        const lastShiftGridData = data.data?.content?.lastShiftGridData;
        setLastShiftData({ lastShiftEntry, lastShiftGridData });
        //setPreviousShiftData(data.data?.content?.lastShiftEntry);
        //setGridData(data.data?.content?.lastShiftGridData);
        //const paramid = contend_id ? contend_id : contentid;
      }
    } catch (error) {
      console.log(error);
    }
  }


  const getPreAndCurrShift = async () => {
    try {
      if (id != null) {
        const resp = await axios.post(
          API_CALL_URL_PRIVATE + "/department/getPreviousAndCurrentShift",
          { cost_center_number: id }
        );
        const { data } = await resp;
        const result = data.data;
        //console.log("getPreviousAndCurrentShift", result);
        dispatch(getPreviousAndCurrentShift(result));
        return result;
      }
      //dispatch(setEditDepartmentDetail(data.data.directData));
    } catch (error) {
      console.log(error);
    }
  };

  const autoFillgetDirectDataById = async (contentId, isdate) => {
    try {
      //console.log(contentId,"contentId")
      const fromdatearr = param.fromdate.date.split("-");
      const todatearr = param.todate.date.split("-");
      let fromdate = `${fromdatearr[1]}/${fromdatearr[0]}/${fromdatearr[2]}`;
      let todate = `${todatearr[1]}/${todatearr[0]}/${todatearr[2]}`;
      if (contentId) {
        const resp = await axios.post(
          API_CALL_URL_PRIVATE + "/department/getDirectDataById",
          { id: contentId }
        );
        const { data } = resp;
        setPreviousShiftData(data.data.directData);
        setGridData(data.data.gridData);
        setContent({ id: contentId });
        if (isdate) {
          fromdate = `${fromdate} ${data.data.directData.shift_time}`;
          todate = `${todate} ${data.data.directData.shift_time}`;
          setFilterdate({ fromdate, todate });
        }
      } else {
        setPreviousShiftData([]);
        setGridData([]);
        setContent({ id: false });
        if (isdate) {
          fromdate = fromdate + ` ${realtimeshift}`; //param.fromdate.shift
          todate = todate + ` ${realtimeshift}`; //param.todate.shift
          setFilterdate({ fromdate, todate });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  function isactivefilter(addclass) {
    setGridcolor(addclass);
  }

  const reportnotecallback = (obj, type) => {
    //console.log(obj, 'objobjobjobj');
    dispatch(activedatefilter(obj));
    if (type === 1) {
      autoFillgetDirectDataById(obj.id, false);
      handleShowUpdate();
    } else {
      autoFillgetDirectDataById(obj._id, false);
      handleShow();
    }

    //fetchDetails(id, shift);
  };

  const addupdatereportnotecallback = (id) => {
    fetchDetails(id, null);
  };
  //console.log("head", headcount);

  let bararrone = [];
  let bararrtwo = [];
  let bararrthree = [];
  let ids = [];
  let isarrEmpty = true;
  const barchartdata = barchart.map((item) => {
    bararrone.push(item.actual_hours);
    bararrtwo.push(item.target_hours);
    ids.push(item.id);
    isarrEmpty = false;
    return item;
  });
  const barchartseries = [
    { name: "Actual Hours", data: bararrone },
    { name: "Target Hours", data: bararrtwo },
  ];

  const barchartcat = barchart.map((item) => {
    const arr = item.date_time.split(" ");
    const arr2 = arr[0].split("-");
    const getdate = `${arr2[1]}/${arr2[0]}/${arr2[2]} ${arr[1]}`;
    return getdate + " GMT";
  });

  const antadmitlinechartat = dataTable
    // .filter(
    //   (item) => item.status !== "entry_due" && item.status !== "entry_upcoming"
    // )
    .map((item) => {
        return item.shift_time
    });
  const antadmitlinechartseries = dataTable
    // .filter(
    //   (item) => item.status !== "entry_due" && item.status !== "entry_upcoming"
    // )
    .map((item) => (item?.ant_admit >= 0 ? item.ant_admit : null));
  const admitlinechartseries = dataTable
    // .filter(
    //   (item) => item.status !== "entry_due" && item.status !== "entry_upcoming"
    // )
    .map((item) => (item?.actual_admit >= 0 ? item.actual_admit : null));
  const telemetrylinechartseries = dataTable
    // .filter(
    //   (item) => item.status !== "entry_due" && item.status !== "entry_upcoming"
    // )
    .map((item) => (item?.telemetry >= 0 ? item.telemetry : null));
  const vasolinechartseries = dataTable
    // .filter(
    //   (item) => item.status !== "entry_due" && item.status !== "entry_upcoming"
    // )
    .map((item) => (item?.vaso_active_drips >= 0 ? item.vaso_active_drips : null));

  const searchcallback = async (searchkey) => {
    const params = {
      cost_center_number: id,
      searchDate: "15-04-2023",
      searchKeyword: searchkey,
    };
    const resp = await axios.post(API_CALL_URL_PRIVATE + "/department/searchShifts", {
      id: contentId,
    });
    const { data } = resp;
  };

  return (
    <PageLayout
      data={headcount}
      issearch={false}
      isshow={true}
      isLoader={isLoader}
      callbackfunc={reportnotecallback}
      isCallback={false}
      cost_center_number={id}
      filterdate={filterdate.fromdate}
    >
      <div className="row mt-2 gx-2">
        <div className="col-md-8">
          <div className="row gx-2 h-100">
            <div className="col-md-4  ">
              <div className="white-bg shadow-box h-100 1111 details overflow-hidden">
                <div class="box-heading text-center">
                Productivity & Completion Status 
                </div>
                {Object.keys(multichart).length > 0 && !isLoader ? (
                  //<Ampiechart data={detail_data.left_sidebar?.sidebar_1} callbackfunc={reportnotecallback} data1={multichart} isdetail={true} />
                  <ChartExample
                    data={detail_data.left_sidebar?.sidebar_1}
                    callbackfunc={reportnotecallback}
                    data1={multichart}
                    isdetail={true}
                    directId={id}
                  />
                ) : (
                  // <ProgressChart isPieChart="true" data={multichart} piechart={pieichart} />
                    <MyLoader themeMode={themeMode} />
                )}
                <div className="d-flex text-center flex-wrap">
                <Link className={"flex-grow-1 me-1 border-btn f-12 cursor-pointer green-b active w-100"} to={`/realtime/department-details/${urlparam}/realtime`}>Real Time</Link>
                <Link className={"flex-grow-1 me-1 border-btn f-12 cursor-pointer green-b"} to={`/realtime/department-details/${urlparam}/currentperiod`}>Current Period</Link>
                <Link className={"flex-grow-1 border-btn f-12 cursor-pointer green-b"} to={`/realtime/department-details/${urlparam}/previousperiod`}>Previous Period</Link>
                                
                  {/* <button
                    key={"week"}
                    id={"week"}
                    className={
                      "flex-grow-1 me-1 border-btn f-12 cursor-pointer green-b active w-100"
                    }
                  >
                    Real Time
                  </button>
                  <button
                    key={"week"}
                    id={"week"}
                    className={
                      "flex-grow-1 me-1 border-btn f-12 cursor-pointer green-b"
                    }
                  >
                    Current Period
                  </button>
                  <button
                    key={"week"}
                    id={"week"}
                    className={
                      "flex-grow-1 border-btn f-12 cursor-pointer green-b"
                    }
                  >
                    Previous Period
                  </button> */}
                </div>
                <div>
                  {Object.keys(filterdate).length > 0 ? (
                    <div className="col-md-12 mt-3">
                      <div className="recordshowing text-center">
                        {filterdate.fromdate}
                      </div>
                      {/* - {filterdate.todate}*/}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="col-md-8 ">
              {/* <DataTool data={contentone} gridData={gridData} previousShiftData={previousShiftData} getprevAndCurrentShift={getprevAndCurrentShift} numbers={headcount?.box3?.total_rn_variance} isLoader={isLoader} /> */}
              <div className="h-100">
                <DataTable
                  cost_center_name={multichart.cost_center_name}
                  callbackfunc={reportnotecallback}
                  directId={contentId}
                  realtimeshift={realtimeshift}
                  isLoader={isLoader}
                  data={dataTable}
                  cost_center_number={id}
                />
              </div>
              {/* <CommentMessage data={commentlist} cost_center_number={id} /> */}
              {/*<LineChart barchartseries={barchartseries} barchartcat={barchartcat} ids={ids} callbackfunc={reportnotecallback} isLoader={isLoader} />*/}
              {/* <BarChart barchartseries={barchartseries} barchartcat={barchartcat} ids={ids} callbackfunc={reportnotecallback} /> */}
              {
                // Object.keys(filterdate).length > 0 ?
                //     <div className="col-md-12">
                //         <div className='recordshowing'>Record showing : {filterdate.fromdate}</div>{/* - {filterdate.todate}*/}
                //     </div> : null
              }
              {/* <div className="col-md-12">
                                <DateFilter fromdate={filterdate.fromdate} setSliderFilterData={setSliderFilterData} setBoxColor={setBoxColor} isactivefilter={isactivefilter} active={activefiltertab} activetab={(tab) => setActivefiltertab(tab)} cost_center_number={id} />
                                
                            </div> */}
            </div>
          </div>
        </div>
        <div className="col-md-4 plr-4">
          <div class="white-bg shadow-box h-100">
            <div class="box-heading text-center mb-2">Workdrivers</div>

            <LineChart
              admitlinechartat={antadmitlinechartat}
              title={"Anticipated Admits"}
              minData={minMaxData[0]?.ant_admit_min}
              maxMaxData={minMaxData[0]?.ant_admit_max}
              admitlinechartseries={antadmitlinechartseries}
              color={"#458FFF"}
              isLoader={isLoader}
            />
            <LineChart
              admitlinechartat={antadmitlinechartat}
              title={"Admits"}
              minData={minMaxData[0]?.admit_min}
              maxMaxData={minMaxData[0]?.admit_max}
              admitlinechartseries={admitlinechartseries}
              color={"#28A745"}
              isLoader={isLoader}
            />
            <LineChart
              admitlinechartat={antadmitlinechartat}
              title={"Telemetry Patients"}
              admitlinechartseries={telemetrylinechartseries}
              color={"#FFA800"}
              minData={minMaxData[0]?.telemetry_min}
              maxMaxData={minMaxData[0]?.telemetry_max}
              isLoader={isLoader}
            />
            <LineChart
              admitlinechartat={antadmitlinechartat}
              title={"Vaso-Active Drips"}
              admitlinechartseries={vasolinechartseries}
              color={"#dc0b0b"}
              minData={minMaxData[0]?.vaso_active_drips_min}
              maxMaxData={minMaxData[0]?.vaso_active_drips_max}
              isLoader={isLoader}
            />
          </div>
          {/* 
                    <div className="row mb-2 gx-2">
                        <div className="col-4  h-100">
                            <div id="add" data-value="add" onClick={handleShow} className="av-btn green-btn d-flex align-items-center justify-content-center h-100">
                                <div className="f-14 "><i className="fa fa-plus-circle" aria-hidden="true"></i><br />Add Details</div>
                            </div>
                        </div>
                        <div className="col-4  h-100" id="update">
                            <div data-value="update" onClick={handleShowUpdate} className="av-btn blue-btn d-flex align-items-center justify-content-center h-100">
                                <div className="f-14" ><i className="fa fa-pencil-square-o" aria-hidden="true"></i><br />Update Details</div>
                            </div>
                        </div>
                        <div className="col-4  h-100" id="edit">
                            <div data-value="update" onClick={handleShowUpdate} className="av-btn blue-btn d-flex align-items-center justify-content-center h-100">
                                <div className="f-14"><i className="fa fa-pencil" aria-hidden="true"></i><br />Edit Details</div>
                            </div>
                        </div>
                        <div className="col-4  h-100" id="edit">
                            <a href="/" className="av-btn purple-btn d-flex align-items-center justify-content-center h-100">
                                <div className="f-14"><i className="fa fa-home" aria-hidden="true"></i><br />Home</div>
                            </a>
                        </div>
                    </div>
                    */}
          {/*<Navigation cost_center_name={multichart.cost_center_name} navigations={notificationsData}  callbackfunc={reportnotecallback} directId={contentId} isLoader={isLoader}  /> */}
        </div>
        {/* <div className="col-md-8 offset-md-3">
          <DateFilter
            fromdate={filterdate.fromdate}
            setSliderFilterData={setSliderFilterData}
            setBoxColor={setBoxColor}
            isactivefilter={isactivefilter}
            active={activefiltertab}
            activetab={(tab) => setActivefiltertab(tab)}
            cost_center_number={id}
          />
        </div> */}
      </div>

      <AddModal
        isShow={show}
        cost_center_name={multichart.cost_center_name}
        numbers={headcount?.box3?.total_rn_variance}
        callbackfunc={callBackhide}
        contentId={contentId}
        previousShiftData={lastShiftData.lastShiftEntry}
        getprevAndCurrentShift={getprevAndCurrentShift}
        addgridData={lastShiftData.lastShiftGridData}
        reloaddata={addupdatereportnotecallback}
        cost_center_number={id}
        getPreAndCurr={getPreAndCurr}
      />
      {contentone.id !== undefined ? (
        <UpdateModal
          isShow={showupdate}
          cost_center_name={multichart.cost_center_name}
          numbers={headcount?.box3?.total_rn_variance}
          previousShiftData={previousShiftData}
          getprevAndCurrentShift={getprevAndCurrentShift}
          gridData={gridData}
          callbackfunc={callBackhideupdate}
          reloaddata={addupdatereportnotecallback}
          data_id={contentId}
          cost_center_number={id}
          getPreAndCurr={getPreAndCurr}
        />
      ) : null}
      <SuccessModal
        isShow={showsucess}
        callbackfunc={callBacksuccess}
        title="Success"
        content={sucessmsg}
      />
      <ErrorModal
        isShow={showError}
        callbackfunc={callBacksuccess}
        title="Error"
        content={sucessmsg}
      />
    </PageLayout>
  );
}

export default Realtime;
