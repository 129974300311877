import React, { useEffect, useState } from "react";
import "./index.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import { ErrorMessage, Formik, Form as FormikForm } from "formik";
import { toast } from "react-toastify";
import axios from "../../axios";
import { API_CALL_URL_PRIVATE } from "../../constants/compensate";
import { FacilityListValidation } from "../../utils/validator";
import Texterror from "../Texterror";
import { useSelector } from "react-redux";

function AddFacilityForm() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [TimezoneOptions, setTimezoneOptions] = useState([]);
  const [isInitialData, setISInitialData] = useState(false);
  const [facilityDetail, setFacilityDetail] = useState({});
  const customer_code = useSelector((state) => state.compensate.customerCode);

  const [initialValues, setInitialValues] = useState({
    facility_id: "",
    facility_name: "",
    timezone: "",
  });
  const [selectedImage, setSelectedImage] = useState(null);
  useEffect(() => {
    const getTimezoneOptions = async () => {
      setISInitialData(false);
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/general/timezoneMaster`
      );

      setTimezoneOptions(resp.data.data);
      setISInitialData(true);
    };

    const getFacilityDetail = async () => {
      setISInitialData(false);
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/facility/customerDetail`,
        {
          customer_code: parseInt(customer_code),
        }
      );

      setFacilityDetail(resp.data.data);
       setInitialValues((prev) => {
         return {
           ...prev,
           timezone: resp.data.data?.timezone,
         };
       });
      setISInitialData(true);
    };
    getTimezoneOptions();
    getFacilityDetail();
  }, []);
  const onSubmit = (values, onSubmittingProps) => {
    console.log("form Values", values);
    formSubmit(values);
    // setTimeout(() => {
    //   onSubmittingProps.setSubmitting(false);
    // }, [2000]);
  };
  const formSubmit = async (values) => {
    const obj = {
      facility_id: parseInt(values.facility_id),
      customer_code: customer_code,
      facility_name: values.facility_name,
      timezone: values.timezone,
    };
    try {
      setIsLoading(true);
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/facility/facilityAddUpdate`,
        obj
      );
      console.log(resp);
      toast.success(resp?.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
      navigate("/admin/facility");
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });

      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  
  return (
    <>
      {isInitialData ? (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validate={FacilityListValidation}
          enableReinitialize
          validateOnChange
        >
          {(formik) => (
            <div className="main-panel">
              {console.log(formik)}
              <div className="content-wrapper">
                <div className="page-header">
                  <h3 className="page-title">
                    <a href="#" className="badge badge-dark"></a>
                  </h3>
                  <div className></div>
                </div>
                <div className="row">
                  <div class="col-12 grid-margin stretch-card">
                    <div class="card">
                      <div class="card-body">
                        <div className="d-flex justify-content-between">
                          <h4 class="card-title">Add Facility</h4>
                          <Link
                            to={`/admin/customer/`}
                            className="f-14 white-text-dark-mode"
                          >
                            <FontAwesomeIcon icon="fa-solid fa-arrow-left " />{" "}
                            Back
                          </Link>
                        </div>

                        <FormikForm className="forms-sample">
                          <div class="row">
                            <Form.Group
                              className="col-md-4 mb-3"
                              controlId="exampleForm.c_code"
                            >
                              <Form.Label>Facility Id</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="facility_id"
                                {...formik.getFieldProps("facility_id")}
                              />
                              <ErrorMessage
                                name="facility_id"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-4 mb-3"
                              controlId="exampleForm.c_name"
                            >
                              <Form.Label>Facility Name</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="facility_name"
                                {...formik.getFieldProps("facility_name")}
                              />
                              <ErrorMessage
                                name="facility_name"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-4 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Timezone</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="white"
                                name="timezone"
                                value={`${formik.values.timezone}`}
                                onChange={(e) => {
                                  formik.setFieldTouched(
                                    "timezone",
                                    true,
                                    true
                                  );
                                  formik.setFieldValue(
                                    "timezone",
                                    e.target.value
                                  );
                                  console.log(e.target.value);
                                }}
                                // onChange={formik.handleChange}
                              >
                                <option value={""}>Select</option>
                                {TimezoneOptions?.map((e) => {
                                  return (
                                    <option key={e?._id} value={e?.offset}>
                                      {`${e?.timezone} ${e?.type}${e?.offset}`}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                              <ErrorMessage
                                name="timezone"
                                component={Texterror}
                              />
                            </Form.Group>

                            <Form.Group className="col-md-12 mb-3 d-flex justify-content-end">
                              {/* <Button
                              variant="primary"
                              type="button"
                              onClick={() => {
                                formik.resetForm();
                                setSelectedImage(null);
                                // const fileInput =
                                //   document.getElementById("logo");
                                // if (fileInput) {
                                //   fileInput.value = "";
                                // }
                              }}
                            >
                              Reset
                            </Button> */}
                              <Button
                                className="ms-3"
                                variant="success"
                                type="submit"
                                disabled={isLoading}
                              >
                                {isLoading ? "Saving..." : "Save"}
                              </Button>
                            </Form.Group>
                          </div>
                        </FormikForm>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Formik>
      ) : (
        <div className="d-flex align-items-center justify-content-center w-100vw h-100vh">
          <span class="loader" style={{ width: "50px", height: "50px" }}></span>
        </div>
      )}
    </>
  );
}

export default AddFacilityForm;
