import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import { ErrorMessage, Formik, Form as FormikForm } from "formik";
import { toast } from "react-toastify";
import axios from "../../axios";
import { API_CALL_URL_PRIVATE, DOMAIN_URL } from "../../constants/compensate";
// import { helpCenterValidation } from "../../utils/validator";
import Texterror from "../Texterror";
import { useDispatch, useSelector } from "react-redux";
import { FaRegFilePdf } from "react-icons/fa";

function AddNursingGrid() {
  const attachmentRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const customer_code = useSelector((state) => state.compensate.customerCode);
  const [isLoading, setIsLoading] = useState(false);
  const [applicationMaster, setApplicationMaster] = useState(null);
  const [imageLoader, setImageLoader] = useState(false);
  const [initialValues, setInitialValues] = useState({
    attachment: "",
  });
  const [selectedImage, setSelectedImage] = useState(null);
  const [errors, setErrors] = useState([]);
  const [respMessage, setRespMessage] = useState(null);
  const onSubmit = (values, onSubmittingProps) => {
    console.log("form Values", values);
    formSubmit(values);
    // setTimeout(() => {
    //   onSubmittingProps.setSubmitting(false);
    // }, [2000]);
  };
  const formSubmit = async (values) => {
    if (values.attachment) {
      const formData = new FormData();
      formData.append("csv_file", values.attachment);

      try {
        setIsLoading(true);
        const response = await axios.post(
          `${API_CALL_URL_PRIVATE}/nursing-grid/uploadCSV`,
          formData
        );
        setRespMessage(response?.data?.message);

        if (response.status === 200 && response?.data?.errorRow.length > 0) {
          //   setSelectedImage(response.data.data.displayPath);
          //   formik.setFieldValue("attachment", response.data.data.attachmentPath);
          setErrors(response?.data?.errorRow);
        } else if (
          response.status === 200 &&
          !response?.data?.errorRow.length > 0
        ) {
          //   toast.success(response.data.message, {
          //     position: "top-right",
          //     autoClose: 5000,
          //     hideProgressBar: false,
          //     closeOnClick: true,
          //   });
        }
      } catch (error) {
        console.log(error);
        const errorMessage =
          error.response?.data?.message || error.message || "An error occurred";
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      } finally {
        setIsLoading(false);
      }
    } else {
      toast.error("Select file first", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
    }
    // try {
    //   setIsLoading(true);
    //   const resp = await axios.post(
    //     `${API_CALL_URL_PRIVATE}/helpCenter/create`,
    //     formData
    //   );
    //   console.log(resp);
    //   toast.success(resp?.data.message, {
    //     position: "top-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //   });
    //   //   navigate("/admin/help-center");
    // } catch (error) {
    //   toast.error(error.response.data.message, {
    //     position: "top-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //   });

    //   console.log(error);
    // } finally {
    //   setIsLoading(false);
    // }
  };

  function getFileType(link) {
    // Check if the link is not provided or is an empty string
    if (!link) {
      return false;
    }

    try {
      const pathWithoutQuery = new URL(link).pathname;
      const fileName = pathWithoutQuery.substring(
        pathWithoutQuery.lastIndexOf("/") + 1
      );

      // Extract the file extension from the file name
      const extension = fileName
        .substring(fileName.lastIndexOf(".") + 1)
        .toLowerCase();

      console.log(extension); // You can remove this line if you don't need to log the extension
      return extension === "pdf";
    } catch (error) {
      console.error("Error processing the link:", error);
      return false;
    }
  }

  const handleAttachmentChange = async (e, formik) => {
    formik.setFieldTouched("attachment", true, true);
    formik.setFieldValue("attachment", e.target.files[0]);
    // if (attachmentRef.current) {
    //   attachmentRef.current.value = "";
    // }
    // const file = e.target.files[0];
    // console.log("file", file);
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        enableReinitialize
        validateOnChange
      >
        {(formik) => (
          <div className="main-panel">
            {console.log(formik)}
            <div className="content-wrapper">
              <div className="page-header">
                <h3 className="page-title">
                  <a href="#" className="badge badge-dark"></a>
                </h3>
                <div className></div>
              </div>
              <div className="row">
                <div class="col-12 grid-margin stretch-card">
                  <div class="card">
                    <FormikForm className="forms-sample">
                      <div class="card-body">
                        <div className="row align-items-center">
                          <div className="col-3">
                            <Link
                              to={`/admin/staffing-grid`}
                              className="f-14 white-text-dark-mode"
                            >
                              <FontAwesomeIcon icon="fa-solid fa-arrow-left " />{" "}
                              Back
                            </Link>
                          </div>
                          <div className="col-6">
                            <h4 class="card-title text-center">Import File</h4>
                          </div>
                          <div className="col-3 text-end">
                            <Button
                              className="ms-3"
                              variant="success"
                              onClick={() => {
                                window.open(
                                  `${DOMAIN_URL}/uploads/samples/nursing_grid_sample.csv`
                                );
                              }}
                            >
                              Download Sample CSV
                            </Button>
                          </div>
                        </div>

                        <div class="row py-4 justify-content-center">
                          <div className="col-md-6 d-flex flex-column gap-3 mx-0 mb-4">
                            <div>
                              <span style={{ color: "red" }}>
                                Mandatory fields*
                              </span>
                              &nbsp;:&nbsp; customer_code (Only Number),
                              cost_center_number (Only Number), year(Valid year
                              in YYYY format), active_date(MM/DD/YYYY HH:MM:SS
                              format), shift_time (Valid time in HH:MM:SS
                              format), census(Only Number)
                            </div>
                            <div className="d-flex flex-column gap-1">
                              <div>Note :- </div>
                              <div>
                                1.&nbsp;If non-mandatory fields are missing or
                                have no value then it will set to 0 or null.
                              </div>
                              <div>
                                2.&nbsp;If matching records for fields
                                (customer_code, cost_center_number, year,
                                shift_time and census ) will found then it will
                                update existing record otherwise insert as new
                                record.
                              </div>
                              <div>
                                3.&nbsp;Please Set the type of Active date field to text in excel before upload.
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12 d-flex justify-content-center">
                            <Form.Group
                              controlId="formFile"
                              className="mb-0 col-md-6 position-relative"
                            >
                              <Form.Label className="w-100">
                                <div className="d-flex align-items-center w-100">
                                  Import CSV File
                                  <span style={{ color: "red" }}>*&nbsp;</span>
                                  <span>(Comma Delimited)</span>
                                </div>
                              </Form.Label>
                              <Form.Control
                                type="file"
                                name="attachment"
                                ref={attachmentRef}
                                accept=".csv"
                                onChange={async (e) => {
                                  handleAttachmentChange(e, formik);
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched(
                                    "attachment",
                                    true,
                                    true
                                  );
                                }}
                              />
                              {respMessage ? (
                                <div
                                  style={{
                                    color: "rgb(41,167,69)",
                                    fontSize: "1.2rem",
                                  }}
                                  className="mt-1"
                                >
                                  {` ${respMessage} `}
                                </div>
                              ) : (
                                ""
                              )}
                              <ErrorMessage
                                name="attachment"
                                component={Texterror}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-md-6 mt-4 d-flex flex-column gap-2">
                            {errors?.length > 0 ? (
                              <>
                                <div>Errors&nbsp;:-</div>
                                {errors?.map((e, i) => {
                                  return (
                                    <div>
                                      {i + 1}.&nbsp;
                                      <span className="text-danger">
                                        {e.error}
                                      </span>
                                    </div>
                                  );
                                })}
                              </>
                            ) : (
                              ""
                            )}
                          </div>

                          <Form.Group className="col-md-12 mb-3 d-flex justify-content-end">
                            {/* <Button
                              variant="primary"
                              type="button"
                              onClick={() => {
                                formik.resetForm();
                                setSelectedImage(null);
                                // const fileInput =
                                //   document.getElementById("logo");
                                // if (fileInput) {
                                //   fileInput.value = "";
                                // }
                              }}
                            >
                              Reset
                            </Button> */}
                            <Button
                              className="ms-3"
                              variant="success"
                              type="submit"
                              disabled={isLoading}
                            >
                              {isLoading ? "Submitting..." : "Submit"}
                            </Button>
                          </Form.Group>
                        </div>
                      </div>
                    </FormikForm>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Formik>
    </>
  );
}

export default AddNursingGrid;
