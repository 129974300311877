import React, { useContext, useEffect, useRef, useState } from "react";
import "./index.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ErrorMessage, Formik, Form as FormikForm } from "formik";
import { toast } from "react-toastify";
import axios from "../../axios";
import { API_CALL_URL_PRIVATE, DOMAIN_URL } from "../../constants/compensate";
import Texterror from "../Texterror";
import { NursingGridValidation } from "../../utils/validator";
import { setNavigate } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { decrypt } from "../../utils/CryptoUtils";
import { ThemeContext } from "../../providers/ThemeProvider";

function EditNursingGridForm() {
  const dispatch = useDispatch();
  const { ID } = useParams();
  const [theme, setTheme] = useContext(ThemeContext);
  const customer_code = useSelector((state) => state.compensate.customerCode);
  const IsNavigate = useSelector((state) => state.compensate.IsNavigate);
  console.log(ID);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialData, setISInitialData] = useState(false);
  const [initialValues, setInitialValues] = useState({
    customer_code: "",
    cost_center_number: "",
    year: "",
    shift_time: "",
    census: "",
    dc_nurse: "",
    orientation: "",
    required_charge: "",
    required_cna: "",
    required_nurses: "",
    required_res_brk_rn: "",
    sitter: "",
    unit_sec: "",
    blt: "",
    other: "",
    occ_therapist: "",
    psa_behavior: "",
    soc_worker_ft: "",
    soc_worker_pt: "",
    l_and_d_rn: "",
    nicu_peds_rn: "",
    pp_rn: "",
    psa_medical: "",
    psa_si: "",
    triage_rn: "",
    techs_cna: "",
    lead_tech: "",
    clinical_coordinator: "",
    front_desk: "",
    ivs: "",
    ed_reg: "",
    seven_ave_reg: "",
    rt: "",
    support: "",
    scrub_tech: "",
    rn_preceptor: "",
    rn_board_runner: "",
    scrub_tech_preceptor: "",
    scrub_tech_board_runner: "",
    rnfa_stfa: "",
    rcu_rn: "",
  });
  const [selectedIcon, setSelectedIcon] = useState(null);

  function generateTimeSlots() {
    const timeSlots = [];
    for (let hour = 0; hour < 24; hour++) {
      const formattedHour = hour < 10 ? `0${hour}` : `${hour}`;
      const formattedMinutes = "00";
      const formattedSeconds = "00";
      timeSlots.push(
        `${formattedHour}:${formattedMinutes}:${formattedSeconds}`
      );
    }
    return timeSlots;
  }

  const timeSlots = generateTimeSlots();

  function generateYearDropdown(startYear = 2020) {
    const currentYear = new Date().getFullYear();
    const years = [];

    for (let year = startYear; year <= currentYear; year++) {
      years.push(year);
    }

    return years;
  }

  // Example usage
  const yearDropdown = generateYearDropdown();

  useEffect(() => {
    const getInitalData = async () => {
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/nursing-grid/detail`,
        { _id: ID }
      );
      console.log(resp);
      const Detail = resp?.data?.data?.details;
      setInitialValues((prev) => {
        return {
          ...prev,
          customer_code: Detail?.customer_code,
          cost_center_number: Detail?.cost_center_number,
          year: Detail?.year,
          shift_time: Detail?.shift_time,
          census: Detail?.census,
          dc_nurse: Detail?.dc_nurse,
          orientation: Detail?.orientation,
          required_charge: Detail?.required_charge,
          required_cna: Detail?.required_cna,
          required_nurses: Detail?.required_nurses,
          required_res_brk_rn: Detail?.required_res_brk_rn,
          sitter: Detail?.sitter,
          unit_sec: Detail?.unit_sec,
          blt: Detail?.blt,
          other: Detail?.other,
          occ_therapist: Detail?.occ_therapist,
          psa_behavior: Detail?.psa_behavior,
          soc_worker_ft: Detail?.soc_worker_ft,
          soc_worker_pt: Detail?.soc_worker_pt,
          l_and_d_rn: Detail?.l_and_d_rn,
          nicu_peds_rn: Detail?.nicu_peds_rn,
          pp_rn: Detail?.pp_rn,
          psa_medical: Detail?.psa_medical,
          psa_si: Detail?.psa_si,
          triage_rn: Detail?.triage_rn,
          techs_cna: Detail?.techs_cna,
          lead_tech: Detail?.lead_tech,
          clinical_coordinator: Detail?.clinical_coordinator,
          front_desk: Detail?.front_desk,
          ivs: Detail?.ivs,
          ed_reg: Detail?.ed_reg,
          seven_ave_reg: Detail?.seven_ave_reg,
          rt: Detail?.rt,
          support: Detail?.support,
          scrub_tech: Detail?.scrub_tech,
          rn_preceptor: Detail?.rn_preceptor,
          rn_board_runner: Detail?.rn_board_runner,
          scrub_tech_preceptor: Detail?.scrub_tech_preceptor,
          scrub_tech_board_runner: Detail?.scrub_tech_board_runner,
          rnfa_stfa: Detail?.rnfa_stfa,
          rcu_rn: Detail?.rcu_rn,
        };
      });

      setISInitialData(true);
    };
    getInitalData();
  }, [ID]);

  useEffect(() => {
    if (IsNavigate) {
      navigate("/admin/nursing-grid");
    }
  }, [customer_code]);

  const onSubmit = (values, onSubmittingProps) => {
    console.log("form Values", values);
    formSubmit(values);
  };
  const formSubmit = async (values) => {
    const obj = {
      _id: ID,
      customer_code: parseInt(values.customer_code),
      cost_center_number: parseInt(values.cost_center_number),
      year: values.year,
      shift_time: values.shift_time,
      census: parseInt(values.census),
      dc_nurse: parseInt(values.dc_nurse),
      orientation: parseInt(values.orientation),
      required_charge: parseInt(values.required_charge),
      required_cna: parseInt(values.required_cna),
      required_nurses: parseInt(values.required_nurses),
      required_res_brk_rn: parseInt(values.required_res_brk_rn),
      sitter: parseInt(values.sitter),
      unit_sec: parseInt(values.unit_sec),
      blt: parseInt(values.blt),
      other: parseInt(values.other),
      occ_therapist: parseInt(values.occ_therapist),
      psa_behavior: parseInt(values.psa_behavior),
      soc_worker_ft: parseInt(values.soc_worker_ft),
      soc_worker_pt: parseInt(values.soc_worker_pt),
      l_and_d_rn: parseInt(values.l_and_d_rn),
      nicu_peds_rn: parseInt(values.nicu_peds_rn),
      pp_rn: parseInt(values.pp_rn),
      psa_medical: parseInt(values.psa_medical),
      psa_si: parseInt(values.psa_si),
      triage_rn: parseInt(values.triage_rn),
      techs_cna: parseInt(values.techs_cna),
      lead_tech: parseInt(values.lead_tech),
      clinical_coordinator: parseInt(values.clinical_coordinator),
      front_desk: parseInt(values.front_desk),
      ivs: parseInt(values.ivs),
      ed_reg: parseInt(values.ed_reg),
      seven_ave_reg: parseInt(values.seven_ave_reg),
      rt: parseInt(values.rt),
      support: parseInt(values.support),
      scrub_tech: parseInt(values.scrub_techr),
      rn_preceptor: parseInt(values.rn_preceptor),
      rn_board_runner: parseInt(values.rn_board_runner),
      scrub_tech_preceptor: parseInt(values.scrub_tech_preceptor),
      scrub_tech_board_runner: parseInt(values.scrub_tech_board_runner),
      rnfa_stfa: parseInt(values.rnfa_stfa),
      rcu_rn: parseInt(values.rcu_rn),
    };

    try {
      setIsLoading(true);
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/nursing-grid/addOrUpdate`,
        obj
      );
      console.log(resp);
      toast.success(resp?.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
      // navigate("/admin/customer");
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });

      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      {isInitialData ? (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validate={NursingGridValidation}
          enableReinitialize
          validateOnChange
        >
          {(formik) => (
            <div className="main-panel">
              {console.log(formik)}
              <div className="content-wrapper">
                <div className="page-header">
                  <h3 className="page-title">
                    <a href="#" className="badge badge-dark"></a>
                  </h3>
                  <div className></div>
                </div>
                <div className="row">
                  <div class="col-12 grid-margin stretch-card">
                    <div class="card">
                      <FormikForm className="forms-sample">
                        <div class="card-body">
                          <div className="row align-items-center">
                            <div className="col-3">
                              <Link
                                to={`/admin/nursing-grid`}
                                className="f-14 white-text-dark-mode"
                              >
                                <FontAwesomeIcon icon="fa-solid fa-arrow-left " />{" "}
                                Back
                              </Link>
                            </div>
                            <div className="col-6">
                              <h4 class="card-title text-center">
                                Update details for :{" "}
                                {initialValues?.cost_center_number}
                              </h4>
                            </div>
                            <div className="col-3 text-end">
                              <Button
                                className="ms-3"
                                variant="success"
                                type="submit"
                                disabled={isLoading}
                              >
                                {isLoading ? "Saving..." : "Save"}
                              </Button>
                            </div>
                          </div>

                          <div class="row">
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_code"
                            >
                              <Form.Label>Cost Center Number</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="cost_center_number"
                                {...formik.getFieldProps("cost_center_number")}
                              />
                              <ErrorMessage
                                name="cost_center_number"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Shift Time</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="white"
                                name="shift_time"
                                value={`${formik.values.shift_time}`}
                                onChange={(e) => {
                                  formik.setFieldTouched(
                                    "shift_time",
                                    true,
                                    true
                                  );
                                  formik.setFieldValue(
                                    "shift_time",
                                    e.target.value
                                  );
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched(
                                    "shift_time",
                                    true,
                                    true
                                  );
                                }}
                              >
                                <option value={""}>Select</option>
                                {timeSlots?.map((e) => {
                                  return (
                                    <option key={e} value={e}>
                                      {e}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                              <ErrorMessage
                                name="shift_time"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Year</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="white"
                                name="year"
                                value={`${formik.values.year}`}
                                onChange={(e) => {
                                  formik.setFieldTouched("year", true, true);
                                  formik.setFieldValue("year", e.target.value);
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched("year", true, true);
                                }}
                              >
                                <option value={""}>Select</option>
                                {yearDropdown?.map((e) => {
                                  return (
                                    <option key={e} value={e}>
                                      {e}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                              <ErrorMessage name="year" component={Texterror} />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_code"
                            >
                              <Form.Label>Census</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder=""
                                name="census"
                                value={formik.values.census}
                                onChange={(e) => {
                                  formik.setFieldTouched("census", true, true);
                                  formik.setFieldValue(
                                    "census",
                                    String(e.target.value)
                                  );
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched("census", true, true);
                                }}
                              />
                              <ErrorMessage
                                name="census"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_code"
                            >
                              <Form.Label>Dc Nurse</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder=""
                                name="dc_nurse"
                                value={formik.values.dc_nurse}
                                onChange={(e) => {
                                  formik.setFieldTouched(
                                    "dc_nurse",
                                    true,
                                    true
                                  );
                                  formik.setFieldValue(
                                    "dc_nurse",
                                    String(e.target.value)
                                  );
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched(
                                    "dc_nurse",
                                    true,
                                    true
                                  );
                                }}
                              />
                              <ErrorMessage
                                name="dc_nurse"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_code"
                            >
                              <Form.Label>Orientation</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder=""
                                name="orientation"
                                value={formik.values.orientation}
                                onChange={(e) => {
                                  formik.setFieldTouched(
                                    "orientation",
                                    true,
                                    true
                                  );
                                  formik.setFieldValue(
                                    "orientation",
                                    String(e.target.value)
                                  );
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched(
                                    "orientation",
                                    true,
                                    true
                                  );
                                }}
                              />
                              <ErrorMessage
                                name="orientation"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_code"
                            >
                              <Form.Label>Required Charge</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder=""
                                name="required_charge"
                                value={formik.values.required_charge}
                                onChange={(e) => {
                                  formik.setFieldTouched(
                                    "required_charge",
                                    true,
                                    true
                                  );
                                  formik.setFieldValue(
                                    "required_charge",
                                    String(e.target.value)
                                  );
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched(
                                    "required_charge",
                                    true,
                                    true
                                  );
                                }}
                              />
                              <ErrorMessage
                                name="required_charge"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_code"
                            >
                              <Form.Label>Required Cna</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder=""
                                name="required_cna"
                                value={formik.values.required_cna}
                                onChange={(e) => {
                                  formik.setFieldTouched(
                                    "required_cna",
                                    true,
                                    true
                                  );
                                  formik.setFieldValue(
                                    "required_cna",
                                    String(e.target.value)
                                  );
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched(
                                    "required_cna",
                                    true,
                                    true
                                  );
                                }}
                              />
                              <ErrorMessage
                                name="required_cna"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_code"
                            >
                              <Form.Label>Required Nurses</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder=""
                                name="required_nurses"
                                value={formik.values.required_nurses}
                                onChange={(e) => {
                                  formik.setFieldTouched(
                                    "required_nurses",
                                    true,
                                    true
                                  );
                                  formik.setFieldValue(
                                    "required_nurses",
                                    String(e.target.value)
                                  );
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched(
                                    "required_nurses",
                                    true,
                                    true
                                  );
                                }}
                              />
                              <ErrorMessage
                                name="required_nurses"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_code"
                            >
                              <Form.Label>Required Res Brk Rn</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder=""
                                name="required_res_brk_rn"
                                value={formik.values.required_res_brk_rn}
                                onChange={(e) => {
                                  formik.setFieldTouched(
                                    "required_res_brk_rn",
                                    true,
                                    true
                                  );
                                  formik.setFieldValue(
                                    "required_res_brk_rn",
                                    String(e.target.value)
                                  );
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched(
                                    "required_res_brk_rn",
                                    true,
                                    true
                                  );
                                }}
                              />
                              <ErrorMessage
                                name="required_res_brk_rn"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_code"
                            >
                              <Form.Label>Sitter</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder=""
                                name="sitter"
                                value={formik.values.sitter}
                                onChange={(e) => {
                                  formik.setFieldTouched("sitter", true, true);
                                  formik.setFieldValue(
                                    "sitter",
                                    String(e.target.value)
                                  );
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched("sitter", true, true);
                                }}
                              />
                              <ErrorMessage
                                name="sitter"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_code"
                            >
                              <Form.Label>Unit Sec</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder=""
                                name="unit_sec"
                                value={formik.values.unit_sec}
                                onChange={(e) => {
                                  formik.setFieldTouched(
                                    "unit_sec",
                                    true,
                                    true
                                  );
                                  formik.setFieldValue(
                                    "unit_sec",
                                    String(e.target.value)
                                  );
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched(
                                    "unit_sec",
                                    true,
                                    true
                                  );
                                }}
                              />
                              <ErrorMessage
                                name="unit_sec"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_code"
                            >
                              <Form.Label>Other</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder=""
                                name="other"
                                value={formik.values.other}
                                onChange={(e) => {
                                  formik.setFieldTouched("other", true, true);
                                  formik.setFieldValue(
                                    "other",
                                    String(e.target.value)
                                  );
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched("other", true, true);
                                }}
                              />
                              <ErrorMessage
                                name="other"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_code"
                            >
                              <Form.Label>Blt</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder=""
                                name="blt"
                                value={formik.values.blt}
                                onChange={(e) => {
                                  formik.setFieldTouched("blt", true, true);
                                  formik.setFieldValue(
                                    "blt",
                                    String(e.target.value)
                                  );
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched("blt", true, true);
                                }}
                              />
                              <ErrorMessage name="blt" component={Texterror} />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_code"
                            >
                              <Form.Label>Occ Therapist</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder=""
                                name="occ_therapist"
                                value={formik.values.occ_therapist}
                                onChange={(e) => {
                                  formik.setFieldTouched(
                                    "occ_therapist",
                                    true,
                                    true
                                  );
                                  formik.setFieldValue(
                                    "occ_therapist",
                                    String(e.target.value)
                                  );
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched(
                                    "occ_therapist",
                                    true,
                                    true
                                  );
                                }}
                              />
                              <ErrorMessage
                                name="occ_therapist"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_code"
                            >
                              <Form.Label>Psa Behavior</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder=""
                                name="psa_behavior"
                                value={formik.values.psa_behavior}
                                onChange={(e) => {
                                  formik.setFieldTouched(
                                    "psa_behavior",
                                    true,
                                    true
                                  );
                                  formik.setFieldValue(
                                    "psa_behavior",
                                    String(e.target.value)
                                  );
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched(
                                    "psa_behavior",
                                    true,
                                    true
                                  );
                                }}
                              />
                              <ErrorMessage
                                name="psa_behavior"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_code"
                            >
                              <Form.Label>Soc Worker Ft</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder=""
                                name="soc_worker_ft"
                                value={formik.values.soc_worker_ft}
                                onChange={(e) => {
                                  formik.setFieldTouched(
                                    "soc_worker_ft",
                                    true,
                                    true
                                  );
                                  formik.setFieldValue(
                                    "soc_worker_ft",
                                    String(e.target.value)
                                  );
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched(
                                    "soc_worker_ft",
                                    true,
                                    true
                                  );
                                }}
                              />
                              <ErrorMessage
                                name="soc_worker_ft"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_code"
                            >
                              <Form.Label>Soc Worker Pt</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder=""
                                name="soc_worker_pt"
                                value={formik.values.soc_worker_pt}
                                onChange={(e) => {
                                  formik.setFieldTouched(
                                    "soc_worker_pt",
                                    true,
                                    true
                                  );
                                  formik.setFieldValue(
                                    "soc_worker_pt",
                                    String(e.target.value)
                                  );
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched(
                                    "soc_worker_pt",
                                    true,
                                    true
                                  );
                                }}
                              />
                              <ErrorMessage
                                name="soc_worker_pt"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_code"
                            >
                              <Form.Label>L And D Rn</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder=""
                                name="l_and_d_rn"
                                value={formik.values.l_and_d_rn}
                                onChange={(e) => {
                                  formik.setFieldTouched(
                                    "l_and_d_rn",
                                    true,
                                    true
                                  );
                                  formik.setFieldValue(
                                    "l_and_d_rn",
                                    String(e.target.value)
                                  );
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched(
                                    "l_and_d_rn",
                                    true,
                                    true
                                  );
                                }}
                              />
                              <ErrorMessage
                                name="l_and_d_rn"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_code"
                            >
                              <Form.Label>Nicu Peds Rn</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder=""
                                name="nicu_peds_rn"
                                value={formik.values.nicu_peds_rn}
                                onChange={(e) => {
                                  formik.setFieldTouched(
                                    "nicu_peds_rn",
                                    true,
                                    true
                                  );
                                  formik.setFieldValue(
                                    "nicu_peds_rn",
                                    String(e.target.value)
                                  );
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched(
                                    "nicu_peds_rn",
                                    true,
                                    true
                                  );
                                }}
                              />
                              <ErrorMessage
                                name="nicu_peds_rn"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_code"
                            >
                              <Form.Label>Pp Rn</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder=""
                                name="pp_rn"
                                value={formik.values.pp_rn}
                                onChange={(e) => {
                                  formik.setFieldTouched("pp_rn", true, true);
                                  formik.setFieldValue(
                                    "pp_rn",
                                    String(e.target.value)
                                  );
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched("pp_rn", true, true);
                                }}
                              />
                              <ErrorMessage
                                name="pp_rn"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_code"
                            >
                              <Form.Label>Psa Medical</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder=""
                                name="psa_medical"
                                value={formik.values.psa_medical}
                                onChange={(e) => {
                                  formik.setFieldTouched(
                                    "psa_medical",
                                    true,
                                    true
                                  );
                                  formik.setFieldValue(
                                    "psa_medical",
                                    String(e.target.value)
                                  );
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched(
                                    "psa_medical",
                                    true,
                                    true
                                  );
                                }}
                              />
                              <ErrorMessage
                                name="psa_medical"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_code"
                            >
                              <Form.Label>Psa Si</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder=""
                                name="psa_si"
                                value={formik.values.psa_si}
                                onChange={(e) => {
                                  formik.setFieldTouched("psa_si", true, true);
                                  formik.setFieldValue(
                                    "psa_si",
                                    String(e.target.value)
                                  );
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched("psa_si", true, true);
                                }}
                              />
                              <ErrorMessage
                                name="psa_si"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_code"
                            >
                              <Form.Label>Triage Rn</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder=""
                                name="triage_rn"
                                value={formik.values.triage_rn}
                                onChange={(e) => {
                                  formik.setFieldTouched(
                                    "triage_rn",
                                    true,
                                    true
                                  );
                                  formik.setFieldValue(
                                    "triage_rn",
                                    String(e.target.value)
                                  );
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched(
                                    "triage_rn",
                                    true,
                                    true
                                  );
                                }}
                              />
                              <ErrorMessage
                                name="triage_rn"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_code"
                            >
                              <Form.Label>Tech Cna</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder=""
                                name="techs_cna"
                                value={formik.values.techs_cna}
                                onChange={(e) => {
                                  formik.setFieldTouched(
                                    "techs_cna",
                                    true,
                                    true
                                  );
                                  formik.setFieldValue(
                                    "techs_cna",
                                    String(e.target.value)
                                  );
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched(
                                    "techs_cna",
                                    true,
                                    true
                                  );
                                }}
                              />
                              <ErrorMessage
                                name="techs_cna"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_code"
                            >
                              <Form.Label>Lead Tech</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder=""
                                name="lead_tech"
                                value={formik.values.lead_tech}
                                onChange={(e) => {
                                  formik.setFieldTouched(
                                    "lead_tech",
                                    true,
                                    true
                                  );
                                  formik.setFieldValue(
                                    "lead_tech",
                                    String(e.target.value)
                                  );
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched(
                                    "lead_tech",
                                    true,
                                    true
                                  );
                                }}
                              />
                              <ErrorMessage
                                name="lead_tech"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_code"
                            >
                              <Form.Label>Clinical Coordinator</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder=""
                                name="clinical_coordinator"
                                value={formik.values.clinical_coordinator}
                                onChange={(e) => {
                                  formik.setFieldTouched(
                                    "clinical_coordinator",
                                    true,
                                    true
                                  );
                                  formik.setFieldValue(
                                    "clinical_coordinator",
                                    String(e.target.value)
                                  );
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched(
                                    "clinical_coordinator",
                                    true,
                                    true
                                  );
                                }}
                              />
                              <ErrorMessage
                                name="clinical_coordinator"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_code"
                            >
                              <Form.Label>Front Desk</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder=""
                                name="front_desk"
                                value={formik.values.front_desk}
                                onChange={(e) => {
                                  formik.setFieldTouched(
                                    "front_desk",
                                    true,
                                    true
                                  );
                                  formik.setFieldValue(
                                    "front_desk",
                                    String(e.target.value)
                                  );
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched(
                                    "front_desk",
                                    true,
                                    true
                                  );
                                }}
                              />
                              <ErrorMessage
                                name="front_desk"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_code"
                            >
                              <Form.Label>Ivs</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder=""
                                name="ivs"
                                value={formik.values.ivs}
                                onChange={(e) => {
                                  formik.setFieldTouched("ivs", true, true);
                                  formik.setFieldValue(
                                    "ivs",
                                    String(e.target.value)
                                  );
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched("ivs", true, true);
                                }}
                              />
                              <ErrorMessage name="ivs" component={Texterror} />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_code"
                            >
                              <Form.Label>Ed Reg</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder=""
                                name="ed_reg"
                                value={formik.values.ed_reg}
                                onChange={(e) => {
                                  formik.setFieldTouched("ed_reg", true, true);
                                  formik.setFieldValue(
                                    "ed_reg",
                                    String(e.target.value)
                                  );
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched("ed_reg", true, true);
                                }}
                              />
                              <ErrorMessage
                                name="ed_reg"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_code"
                            >
                              <Form.Label>Seven Ave Reg</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder=""
                                name="seven_ave_reg"
                                value={formik.values.seven_ave_reg}
                                onChange={(e) => {
                                  formik.setFieldTouched(
                                    "seven_ave_reg",
                                    true,
                                    true
                                  );
                                  formik.setFieldValue(
                                    "seven_ave_reg",
                                    String(e.target.value)
                                  );
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched(
                                    "seven_ave_reg",
                                    true,
                                    true
                                  );
                                }}
                              />
                              <ErrorMessage
                                name="seven_ave_reg"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_code"
                            >
                              <Form.Label>Rt</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder=""
                                name="rt"
                                value={formik.values.rt}
                                onChange={(e) => {
                                  formik.setFieldTouched("rt", true, true);
                                  formik.setFieldValue(
                                    "rt",
                                    String(e.target.value)
                                  );
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched("rt", true, true);
                                }}
                              />
                              <ErrorMessage name="rt" component={Texterror} />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_code"
                            >
                              <Form.Label>Support</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder=""
                                name="support"
                                value={formik.values.support}
                                onChange={(e) => {
                                  formik.setFieldTouched("support", true, true);
                                  formik.setFieldValue(
                                    "support",
                                    String(e.target.value)
                                  );
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched("support", true, true);
                                }}
                              />
                              <ErrorMessage
                                name="support"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_code"
                            >
                              <Form.Label>Scrub Tech</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder=""
                                name="scrub_tech"
                                value={formik.values.scrub_tech}
                                onChange={(e) => {
                                  formik.setFieldTouched(
                                    "scrub_tech",
                                    true,
                                    true
                                  );
                                  formik.setFieldValue(
                                    "scrub_tech",
                                    String(e.target.value)
                                  );
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched(
                                    "scrub_tech",
                                    true,
                                    true
                                  );
                                }}
                              />
                              <ErrorMessage
                                name="scrub_tech"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_code"
                            >
                              <Form.Label>Rn Preceptor</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder=""
                                name="rn_preceptor"
                                value={formik.values.rn_preceptor}
                                onChange={(e) => {
                                  formik.setFieldTouched(
                                    "rn_preceptor",
                                    true,
                                    true
                                  );
                                  formik.setFieldValue(
                                    "rn_preceptor",
                                    String(e.target.value)
                                  );
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched(
                                    "rn_preceptor",
                                    true,
                                    true
                                  );
                                }}
                              />
                              <ErrorMessage
                                name="rn_preceptor"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_code"
                            >
                              <Form.Label>Rn Board Runner</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder=""
                                name="rn_board_runner"
                                value={formik.values.rn_board_runner}
                                onChange={(e) => {
                                  formik.setFieldTouched(
                                    "rn_board_runner",
                                    true,
                                    true
                                  );
                                  formik.setFieldValue(
                                    "rn_board_runner",
                                    String(e.target.value)
                                  );
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched(
                                    "rn_board_runner",
                                    true,
                                    true
                                  );
                                }}
                              />
                              <ErrorMessage
                                name="rn_board_runner"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_code"
                            >
                              <Form.Label>Scrub Tech Preceptor</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder=""
                                name="scrub_tech_preceptor"
                                value={formik.values.scrub_tech_preceptor}
                                onChange={(e) => {
                                  formik.setFieldTouched(
                                    "scrub_tech_preceptor",
                                    true,
                                    true
                                  );
                                  formik.setFieldValue(
                                    "scrub_tech_preceptor",
                                    String(e.target.value)
                                  );
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched(
                                    "scrub_tech_preceptor",
                                    true,
                                    true
                                  );
                                }}
                              />
                              <ErrorMessage
                                name="scrub_tech_preceptor"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_code"
                            >
                              <Form.Label>Scrub Tech Board Runner</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder=""
                                name="scrub_tech_board_runner"
                                value={formik.values.scrub_tech_board_runner}
                                onChange={(e) => {
                                  formik.setFieldTouched(
                                    "scrub_tech_board_runner",
                                    true,
                                    true
                                  );
                                  formik.setFieldValue(
                                    "scrub_tech_board_runner",
                                    String(e.target.value)
                                  );
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched(
                                    "scrub_tech_board_runner",
                                    true,
                                    true
                                  );
                                }}
                              />
                              <ErrorMessage
                                name="scrub_tech_board_runner"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_code"
                            >
                              <Form.Label>Rnfa Stfa</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder=""
                                name="rnfa_stfa"
                                value={formik.values.rnfa_stfa}
                                onChange={(e) => {
                                  formik.setFieldTouched(
                                    "rnfa_stfa",
                                    true,
                                    true
                                  );
                                  formik.setFieldValue(
                                    "rnfa_stfa",
                                    String(e.target.value)
                                  );
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched(
                                    "rnfa_stfa",
                                    true,
                                    true
                                  );
                                }}
                              />
                              <ErrorMessage
                                name="rnfa_stfa"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_code"
                            >
                              <Form.Label>Rcu Rn</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder=""
                                name="rcu_rn"
                                value={formik.values.rcu_rn}
                                onChange={(e) => {
                                  formik.setFieldTouched("rcu_rn", true, true);
                                  formik.setFieldValue(
                                    "rcu_rn",
                                    String(e.target.value)
                                  );
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched("rcu_rn", true, true);
                                }}
                              />
                              <ErrorMessage
                                name="rcu_rn"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group className="col-md-12 mb-3 d-flex justify-content-end">
                              <Button
                                className="ms-3"
                                variant="success"
                                type="submit"
                                disabled={isLoading}
                              >
                                {isLoading ? "Saving..." : "Save"}
                              </Button>
                            </Form.Group>
                          </div>
                        </div>
                      </FormikForm>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Formik>
      ) : (
        <div className="d-flex align-items-center justify-content-center w-100vw h-100vh">
          <span class="loader" style={{ width: "50px", height: "50px" }}></span>
        </div>
      )}
    </>
  );
}

export default EditNursingGridForm;
