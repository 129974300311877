import React, { useEffect, useState, useCallback, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useSelector, useDispatch } from "react-redux";
import Modal from "react-bootstrap/Modal";
import DataTable from "../../components/DataTable";
import { ChartExample } from "../shared/PieChart/m-pei-chart";
import { Link } from "react-router-dom";
import LineChart from "../shared/LineChart";
import AddModal from "../AddModal";
import SuccessModal from "../shared/SuccessModal";
import UpdateModal from "../UpdateModal";
import PageLayout from "../../layouts/PageLayout";
import { API_CALL_URL_PUBLIC } from "../../constants/";
import axios from "../../axios";
// import { SocketContext } from "../../socket";
import { activedatefilter } from "../../store/actions/Compensate";
import { autoFillgetDirectDataById, getdataService } from "../../services";
import moment from "moment";
import ContentLoader from "react-content-loader";
import "./index.css";

const MyLoader = () => (
  <div className="col-md-4 width-1">
    <ContentLoader viewBox="0 0 100 100">
      {/* Only SVG shapes */}
      <rect x="1.2" y="2" rx="4" ry="4" width="87" height="90" />
    </ContentLoader>
  </div>
);

function CurrentPrevModal({
  isShow,
  callbackfunc,
  id,
  activedate,
  reloaddata,
  isLoader
}) {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  const detail_data = selector.compensate.departmentdetail;
  const multichart = detail_data?.left_sidebar
    ? detail_data.left_sidebar.sidebar_1
    : {};
  const selectedshift = selector.selectedshift;
  const dataTable = detail_data?.right_sidebar?.tableData
    ? detail_data.right_sidebar.tableData
    : [];
  const minMaxData = detail_data?.right_sidebar?.minMaxData
    ? detail_data.right_sidebar.minMaxData
    : [];
  const headcount = detail_data?.header ? detail_data.header : {};
  const getprevAndCurrentShift = selector.compensate.previousAndCurrentShift;
  const lastShiftEntry = detail_data?.content?.lastShiftEntry;
  const lastShiftGridData = detail_data?.content?.lastShiftGridData;
  const contentone = detail_data?.content ? detail_data.content.content_1 : {};

  const antadmitlinechartat = dataTable.map((item) => item.shift_time);
  const antadmitlinechartseries = dataTable.map((item) =>
    item.ant_admit >= 0 ? item.ant_admit : null
  );
  const admitlinechartseries = dataTable.map((item) =>
    item.actual_admit >= 0 ? item.actual_admit : null
  );
  const telemetrylinechartseries = dataTable.map((item) =>
    item.telemetry >= 0 ? item.telemetry : null
  );
  const vasolinechartseries = dataTable.map((item) =>
    item.vaso_active_drips >= 0 ? item.vaso_active_drips : null
  );
  const linedata = [
    antadmitlinechartseries,
    admitlinechartseries,
    telemetrylinechartseries,
    vasolinechartseries,
  ];
  const linecolor = ["#458FFF", "#28A745", "#FFA800", "#dc0b0b"];

  //const [isLoader, setIsLoader] = useState(false);
  const [addmodalshow, setAddmodalshow] = useState(false);
  const [showsucess, setShowsucess] = useState(false);
  const [showupdate, setShowupdate] = useState(false);

  const [sucessmsg, setSucessmsg] = useState("");
  const [selectedcontentid, setSelectedcontentid] = useState({});

  const reportnotecallback = (obj, type) => {
    dispatch(activedatefilter(obj));
    if (type == 1) {
      autoFillgetDirectData(obj.id, false);
      setShowupdate(true);
      //handleShowUpdate();
    } else {
      autoFillgetDirectData(obj._id, false);
      setAddmodalshow(true);
    }
  };

  const autoFillgetDirectData = async (contentId, isdate) => {
    try {
      const resp = autoFillgetDirectDataById(dispatch, contentId, isdate);
      if (contentId) {
        setSelectedcontentid({ id: contentId });
      } else {
        setSelectedcontentid({ id: false });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const callBackhide = (value, issuceess, msg) => {
    setAddmodalshow(value);
    if (issuceess) {
      setShowsucess(true);
      setSucessmsg(msg);
      setTimeout(() => {
        setShowsucess(false);
      }, 2000);
      //setShowsuccess(false);
    }
  };

  const addupdatereportnotecallback = () => {
    const datearr = activedate.split("/");
    const filterdate = `${datearr[1]}-${datearr[0]}-${datearr[2]}`;
    const params = {
      cost_center_number: id,
      fromdate: {
        date: filterdate,
        shift_time: "03:00",
      },
      todate: {
        date: filterdate,
        shift_time: "23:00",
      },
    };
    const resp = getdataService(dispatch, params);
    reloaddata();
  };

  const closeHandle = () => {
    reloaddata();
    callbackfunc(false)
  }
  return (
    <div>
      <Modal
        show={isShow}
        className="modelcurrprev"
        onHide={closeHandle}
        transparent={true}
        centered
      >
        <button
          type="button"
          onClick={closeHandle}
          className="btn-close ms-5 closeabsolute right-20"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          X
        </button>
        <PageLayout
          data={headcount}
          issearch={false}
          isshow={true}
          isLoader={false}
          callbackfunc={""}
          isCallback={false}
          cost_center_number={null}
          filterdate={""}
          islogostrip={true}
        >
          <div className="row mt-2 gx-2">
            <div className="col-md-8">
              <div className="row gx-2 h-100">
                <div className="col-md-4 width-1 ">
                  <div className="white-bg shadow-box h-100 1">
                    <div class="box-heading text-center">
                    Productivity & Completion Status
                    </div>
                    {Object.keys(multichart).length > 0 && !isLoader ? (
                      //<Ampiechart data={detail_data.left_sidebar?.sidebar_1} callbackfunc={reportnotecallback} data1={multichart} isdetail={true} />
                      <ChartExample
                        data={multichart}
                        callbackfunc={reportnotecallback}
                        data1={multichart}
                        isdetail={true}
                        directId={id}
                      />
                    ) : (
                      // <ProgressChart isPieChart="true" data={multichart} piechart={pieichart} />
                      <MyLoader />
                    )}
                    {/* <div className="d-flex text-center flex-wrap">
                                        <Link className={"flex-grow-1 me-1 border-btn f-12 cursor-pointer green-b active w-100"} to={``}>Real Time</Link>
                                        <Link className={"flex-grow-1 me-1 border-btn f-12 cursor-pointer green-b"} to={``}>Current Period</Link>
                                        <Link className={"flex-grow-1 border-btn f-12 cursor-pointer green-b"} to={``}>Previous Period</Link>
                                    </div> */}
                    <div>
                      <div className="col-md-12 mt-3">
                        <div className="recordshowing text-center">
                          {activedate}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-8 width-2">
                  <div className="h-100">
                    <DataTable
                      cost_center_name={multichart.cost_center_name}
                      callbackfunc={reportnotecallback}
                      directId={id}
                      realtimeshift={selectedshift}
                      isLoader={isLoader}
                      data={dataTable}
                      cost_center_number={id}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 plr-4">
              <div class="white-bg shadow-box h-100">
                <div class="box-heading text-center mb-2">Workdrivers</div>
                {/* {linedata.map((item, index) => {
                    console.log(item, "Graph Item")
                  return (
                    <LineChart
                      admitlinechartat={antadmitlinechartat}
                      title={"Anticipated Admits"}
                      minData={minMaxData[0]?.ant_admit_min}
                      maxMaxData={minMaxData[0]?.ant_admit_max}
                      admitlinechartseries={item}
                      color={linecolor[index]}
                      isLoader={isLoader}
                    />
                  );
                })} */}
                <LineChart
                  admitlinechartat={antadmitlinechartat}
                  title={"Anticipated Admits"}
                  minData={minMaxData[0]?.ant_admit_min}
                  maxMaxData={minMaxData[0]?.ant_admit_max}
                  admitlinechartseries={antadmitlinechartseries}
                  color={"#458FFF"}
                  isLoader={isLoader}
                />
                <LineChart
                  admitlinechartat={antadmitlinechartat}
                  title={"Admits"}
                  minData={minMaxData[0]?.admit_min}
                  maxMaxData={minMaxData[0]?.admit_max}
                  admitlinechartseries={admitlinechartseries}
                  color={"#28A745"}
                  isLoader={isLoader}
                />
                <LineChart
                  admitlinechartat={antadmitlinechartat}
                  title={"Telemetry Patients"}
                  admitlinechartseries={telemetrylinechartseries}
                  color={"#FFA800"}
                  minData={minMaxData[0]?.telemetry_min}
                  maxMaxData={minMaxData[0]?.telemetry_max}
                  isLoader={isLoader}
                />
                <LineChart
                  admitlinechartat={antadmitlinechartat}
                  title={"Vaso-Active Drips"}
                  admitlinechartseries={vasolinechartseries}
                  color={"#dc0b0b"}
                  minData={minMaxData[0]?.vaso_active_drips_min}
                  maxMaxData={minMaxData[0]?.vaso_active_drips_max}
                  isLoader={isLoader}
                />
              </div>
            </div>
          </div>
        </PageLayout>
      </Modal>
      <AddModal
        isShow={addmodalshow}
        cost_center_name={multichart.cost_center_name}
        numbers={headcount?.box3?.total_rn_variance}
        callbackfunc={callBackhide}
        contentId={selectedcontentid}
        previousShiftData={lastShiftEntry}
        getprevAndCurrentShift={getprevAndCurrentShift}
        addgridData={lastShiftGridData}
        reloaddata={addupdatereportnotecallback}
        cost_center_number={id}
        getPreAndCurr={[]}
      />
      {contentone.id !== undefined ? (
        <UpdateModal
          isShow={showupdate}
          cost_center_name={multichart.cost_center_name}
          numbers={headcount?.box3?.total_rn_variance}
          previousShiftData={lastShiftEntry}
          getprevAndCurrentShift={getprevAndCurrentShift}
          gridData={lastShiftGridData}
          callbackfunc={() => setShowupdate(false)}
          reloaddata={addupdatereportnotecallback}
          data_id={selectedcontentid}
          cost_center_number={id}
          getPreAndCurr={[]}
        />
      ) : null}

      <SuccessModal
        isShow={showsucess}
        callbackfunc={() => setShowsucess(false)}
        title="Success"
        content={sucessmsg}
      />
    </div>
  );
}

export default CurrentPrevModal;
