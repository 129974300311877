import React, { useContext, useEffect, useState } from "react";
import "./index.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ErrorMessage, Formik, Form as FormikForm } from "formik";
import { toast } from "react-toastify";
import axios from "../../axios";
import { API_CALL_URL_PRIVATE, DOMAIN_URL } from "../../constants/compensate";
import Texterror from "../Texterror";
import { departmentValidation } from "../../utils/validator";
import ReactDatePicker from "react-datepicker";
import { Switch } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setLoadingPage } from "../../store/actions";
import Select from "react-select";
import Ico1 from "../../assets/img/edit.png";
import { get, isEmpty } from "lodash";
import ReactQuill from "react-quill";
import TextEditor from "../TextEditor/TextEditor";
import { ThemeContext } from "../../providers/ThemeProvider";
import calcIcon from "../../assets/img/calculator_hires.png";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Accordion from "react-bootstrap/Accordion";
import PatientDataAccordian from "./PatientDataAccordian";
import StaffingDataAccordian from "./StaffingDataAccordian";
import AdditionalDataAccordian from "./AdditionalDataAccordian";

function AddCNewCostCenter() {
  const { costCenter } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [theme, setTheme] = useContext(ThemeContext);
  const [isLoading, setIsLoading] = useState(false);
  const customer_code = useSelector((state) => state.compensate.customerCode);
  const loading_page = useSelector((state) => state.compensate.LoadingPage);
  // const [FormInitialData, setFormInitialData] = useState();
  // const [selectedImage, setSelectedImage] = useState(null);
  // const [initialImageUrl, setInitialImageUrl] = useState(null);
  // const [costCenterMaster, setCostCenterMaster] = useState(null);
  // const [dropCostCenterMaster, setDropCostCenterMaster] = useState([]);
  const [facilityMaster, setFacilityMaster] = useState(null);
  const [targetTypeMaster, setTargetTypeMaster] = useState(null);
  const [categoryList, setCategoryList] = useState([]);
  // const [applications, setApplications] = useState(null);
  // const [hireDate, setHireDate] = useState(null);
  // const [isInitialData, setISInitialData] = useState(false);
  // const [terminateDate, setTerminateDate] = useState(null);
  // const [selectAllApplication, setSelectAllApplication] = useState(false);
  // const [DeselectAllApplication, setDeselectAllApplication] = useState(false);
  // const [selectAllCostCenter, setSelectAllCostCenter] = useState(false);
  // const [DeselectAllCostCenter, setDeselectAllCostCenter] = useState(false);
  const [realtimeFormulaHeaders, setRealtimeFormulaHeaders] = useState(null);
  const [isRealtime, setIsRealtime] = useState(false);

  const [formControl, setFormControl] = useState({});
  const [formDetailData, setFormDetailData] = useState({});
  const [categoryId, setCategoryId] = useState({});
  const [showAllPatient, setShowAllPatient] = useState(false);
  const [showAllStaffing, setShowAllStaffing] = useState(false);
  const [showAllAdditional, setShowAllAdditional] = useState(false);
  // const checkFormFields = async () => {
  //   return new Promise((resolve, reject) => {
  //     const sections = ["patient_data", "staffing", "additional_data_tracking"];
  //     for (const section of sections) {
  //       for (const item of formControl[section]) {
  //         if (!item.field_label || item.field_label.trim() === "") {
  //           reject(new Error("All Label fields are mandatory"));
  //         }
  //       }
  //     }
  //     resolve("All fields are valid");
  //   });
  // };
  const checkFormFields = async () => {
    return new Promise((resolve, reject) => {
      const sections = ["patient_data", "staffing", "additional_data_tracking"];
      for (const section of sections) {
        for (const item of formControl[section]) {
          // Skip checking item.field_label if item.display is 0
          if (item.display === 0) {
            continue;
          }

          // Check item.field_label if item.display is not 0
          if (!item.field_label || item.field_label.trim() === "") {
            reject(new Error("All Label fields are mandatory"));
            return; // Exit early on failure to prevent further execution
          }
        }
      }
      resolve("All fields are valid");
    });
  };

  function generateTimeSlots() {
    const timeSlots = [];
    for (let hour = 0; hour < 24; hour++) {
      const formattedHour = hour < 10 ? `0${hour}` : `${hour}`;
      timeSlots.push(`${formattedHour}:00`);
    }
    return timeSlots;
  }

  const timeSlots = generateTimeSlots();

  const [initialValues, setInitialValues] = useState({
    cost_center_name: "",
    cost_center_number: "",
    cost_center_category: "",
    business_unit_description: "",
    target: "",
    target_type: "",
    tier2: "",
    tier3: "",
    tier4: "",
    tier5: "",
    uos_description: "",
    realtime: 0,
    customer_code: "",
    facility_id: "",
    facility_masters: "",
    fixed_or_variable: "",
    director: "",
    manager: "",
    is10day: "0",
    adm: "0",
    accepted_acuity_per_nurse: "0",
    first_entry: "03:00",
    interval: "4",
    standard_unit_ratio: "0",
    validate_census: "0",
    budget1: {
      beds: "0",
      fte_max: "0",
      fte_min: "0",
      nurse_uos: "0",
      required_fte: "0",
    },
    patient_data_label: "",
    staffing_data_label: "",
    additional_data_label: "",
    warm_up: 30,
    number_of_days: "",
    census_grid: "",
    show_productivity_ring: "1",
    show_compliance_ring: "1",
    overtime_threshold: "0.25",
    display_on_nursing_status: "1",
    status: "1",
    is_ambulatory: "0"
  });

  // const getFormatDate = (val) => {
  //   const inputDate = new Date(`${val}`);
  //   const year = inputDate.getFullYear();
  //   const month = String(inputDate.getMonth() + 1).padStart(2, "0");
  //   const day = String(inputDate.getDate()).padStart(2, "0");
  //   return val ? `${day}/${month}/${year}` : "";
  // };

  console.log("categoryId", categoryId);
  const getFormatDate = (val) => {
    if (!val) return ""; // Handle empty or undefined input

    let inputDate;

    if (typeof val === "string") {
      // If the input is a string, attempt to parse it
      const dateComponents = val.split("/");
      if (dateComponents.length !== 3) return ""; // Handle invalid date format

      const [month, day, year] = dateComponents.map((component) =>
        parseInt(component, 10)
      );

      // Check if the parsed components are valid numbers
      if (isNaN(month) || isNaN(day) || isNaN(year)) {
        return ""; // Handle invalid numeric values
      }

      inputDate = new Date(year, month - 1, day); // Note: months are zero-based in JavaScript dates
    } else if (val instanceof Date) {
      // If the input is a Date object, use it directly
      inputDate = val;
    } else {
      return ""; // Handle unsupported input type
    }

    const formattedYear = inputDate.getFullYear();
    const formattedMonth = String(inputDate.getMonth() + 1).padStart(2, "0");
    const formattedDay = String(inputDate.getDate()).padStart(2, "0");

    return `${formattedDay}/${formattedMonth}/${formattedYear}`;
  };

  const getFacilityMaster = async () => {
    const resp = await axios.post(
      `${API_CALL_URL_PRIVATE}/facility/facilityList`,
      {
        customer_code: parseInt(customer_code),
        limit: "100000",
      }
    );
    console.log(resp);
    setFacilityMaster(
      resp?.data?.data?.data?.docs?.map((e) => {
        return {
          facility_id: e.facility_id,
          facility_name: e.facility_name,
        };
      })
    );
    setInitialValues((prev) => {
      return {
        ...prev,
        facility_id: resp?.data?.data?.data?.docs[0]?.facility_id,
      };
    });
    console.log(resp);
  };

  const getTargetTypeMaster = async () => {
    const resp = await axios.post(
      `${API_CALL_URL_PRIVATE}/targettype/targettypeList`,
      {
        customer_code: parseInt(customer_code),
        sort_column: "_id",
        sort_order: 1,
        limit: "100000",
      }
    );
    console.log(">>resp of target type", resp);
    setTargetTypeMaster(
      resp?.data?.data?.data?.docs?.map((e) => {
        return {
          target_type_id: e.target_type_id,
          target_type: e.target_type,
        };
      })
    );
    console.log();
    setInitialValues((prev) => {
      return {
        ...prev,
        target_type_id: resp?.data?.data?.data?.docs[0]?.target_type_id,
      };
    });
    console.log(resp);
    console.log(">>resp of target type", resp);
  };

  const getRealtimeformula = async () => {
    try {
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/department/getRealTimeFormula`
      );
      setRealtimeFormulaHeaders(resp.data.formula || []);
      // setISInitialData(true);
    } catch (error) {
      console.log(error);
    }
  };
  const getCostCenterCategoryList = async () => {
    try {
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/costcentercategory/list`,
        {
          customer_code: parseInt(customer_code),
          searchKeyword: "",
          sort_column: "_id",
          sort_order: 1,
          page: 1,
          limit: 10000,
        }
      );
      const category_list = resp.data.data.data.docs?.map((e) => {
        return {
          cost_center_category_id: e.cost_center_category_id,
          cost_center_category: e.cost_center_category,
          note: e.note,
        };
      });
      setCategoryList(category_list);
      // setRealtimeFormulaHeaders(resp.data.formula);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getFacilityMaster();
    getTargetTypeMaster();
    getRealtimeformula();
    getCostCenterCategoryList();
    getInitalData();
  }, [customer_code]);

  //   const getCostCenter = async () => {
  //     const resp = await axios.post(
  //       `${API_CALL_URL_PRIVATE}/department/departmentList`,
  //       {
  //         customer_code: parseInt(customer_code),
  //         limit: "100000",
  //       }
  //     );
  //     setCostCenterMaster(
  //       resp?.data?.data?.data?.docs?.map((e) => {
  //         return {
  //           cost_center_name: e.cost_center_name,
  //           cost_center_number: e.cost_center_number,
  //         };
  //       })
  //     );
  //     // setISInitialData(true);
  //     console.log(resp);
  //     setDropCostCenterMaster(
  //       resp?.data?.data?.data?.docs?.map((e) => {
  //         return {
  //           label: e.cost_center_name,
  //           value: e.cost_center_number,
  //         };
  //       })
  //     );
  //     dispatch(setLoadingPage(false));
  //   };
  //   useEffect(() => {
  //     getCostCenter();
  //   }, [customer_code]);

  //Patient Data
  const handlePatientCheck = (val) => {
    const person = formControl?.patient_data?.find((p) => p.field_name === val);
    if (person) {
      return person?.display === 1;
    }
    return false;
  };
  const handlePatientSwitchcheck = (val) => {
    const person = formControl?.patient_data?.find((p) => p.field_name === val);
    return parseInt(person?.display) > 0 && parseInt(person?.required) === 1
      ? true
      : false;
  };
  const handlePatientSort = (fieldName, val) => {
    setFormControl((prevFormControl) => {
      const fieldExists = prevFormControl.patient_data.some(
        (item) => item.field_name === fieldName
      );

      let updatedPatientData;
      if (fieldExists) {
        updatedPatientData = prevFormControl.patient_data.map((item) => {
          if (item.field_name === fieldName) {
            if (parseInt(val) === 0) {
              return {
                ...item,
                display: parseInt(val),
                required: parseInt(val),
              };
            } else return { ...item, display: parseInt(val) };
          }
          return item;
        });
      } else {
        updatedPatientData = [
          ...prevFormControl.patient_data,
          {
            field_name: fieldName,
            display: parseInt(val), // Assuming you want to store the parsed int or the original value
            required: 0, // This seems to imply that `required` is true when val is parseable as a non-zero integer
          },
        ];
      }

      return {
        ...prevFormControl,
        patient_data: updatedPatientData,
      };
    });
  };

  const handlePatientLabel = (fieldName, val) => {
    setFormControl((prevFormControl) => {
      const fieldExists = prevFormControl.patient_data.some(
        (item) => item.field_name === fieldName
      );

      let updatedPatientData;
      if (fieldExists) {
        updatedPatientData = prevFormControl.patient_data.map((item) => {
          if (item.field_name === fieldName) {
            return { ...item, field_label: val };
          }
          return item;
        });
      } else {
        updatedPatientData = [
          ...prevFormControl.patient_data,
          {
            field_name: fieldName,
            field_label: val,
            display: 0, // Assuming you want to store the parsed int or the original value
            required: 0, // This seems to imply that `required` is true when val is parseable as a non-zero integer
          },
        ];
      }

      return {
        ...prevFormControl,
        patient_data: updatedPatientData,
      };
    });
  };
  const handlePatientOnChange = (fieldName, val) => {
    setFormControl((prevFormControl) => {
      const updatedPatientData = prevFormControl.patient_data.map((item) => {
        if (item.field_name === fieldName) {
          console.log(item.field_name);
          return parseInt(val)
            ? { ...item, display: parseInt(val) }
            : { ...item, display: parseInt(val), required: parseInt(val) };
        }
        return item;
      });

      return {
        ...prevFormControl,
        patient_data: updatedPatientData,
      };
    });
  };
  const handlePatientSwitchOnChange = (fieldName, val) => {
    setFormControl((prevFormControl) => {
      const updatedPatientData = prevFormControl.patient_data.map((item) => {
        if (item.field_name === fieldName) {
          if (parseInt(item?.display) > 0) {
            return { ...item, required: parseInt(val ? "1" : "0") };
          } else {
            return { ...item, required: 0 };
          }
          // Toggle the display value (0 to 1, or 1 to 0)
        }
        return item;
      });

      return {
        ...prevFormControl,
        patient_data: updatedPatientData,
      };
    });
  };

  //Staffing
  const handleStaffingCheck = (val) => {
    const person = formControl?.staffing?.find((p) => p.field_name === val);
    if (person) {
      return person.display === 1;
    }
    return false;
  };
  const handleStaffingSort = (fieldName, val) => {
    setFormControl((prevFormControl) => {
      const fieldExists = prevFormControl.staffing.some(
        (item) => item.field_name === fieldName
      );

      let updatedStaffingData;
      if (fieldExists) {
        updatedStaffingData = prevFormControl.staffing.map((item) => {
          if (item.field_name === fieldName) {
            if (parseInt(val) === 0) {
              return {
                ...item,
                display: parseInt(val),
                required: parseInt(val),
              };
            } else return { ...item, display: parseInt(val) };
          }
          return item;
        });
      } else {
        updatedStaffingData = [
          ...prevFormControl.staffing,
          {
            field_name: fieldName,
            display: parseInt(val),
            required: 0,
          },
        ];
      }

      return {
        ...prevFormControl,
        staffing: updatedStaffingData,
      };
    });
  };

  const handleStaffingLabel = (fieldName, val) => {
    setFormControl((prevFormControl) => {
      const fieldExists = prevFormControl.staffing.some(
        (item) => item.field_name === fieldName
      );

      let updatedStaffingData;
      if (fieldExists) {
        updatedStaffingData = prevFormControl.staffing.map((item) => {
          if (item.field_name === fieldName) {
            return { ...item, field_label: val };
          }
          return item;
        });
      } else {
        updatedStaffingData = [
          ...prevFormControl.staffing,
          {
            field_name: fieldName,
            field_label: val,
            display: 0, // Assuming you want to store the parsed int or the original value
            required: 0, // This seems to imply that `required` is true when val is parseable as a non-zero integer
          },
        ];
      }

      return {
        ...prevFormControl,
        staffing: updatedStaffingData,
      };
    });
  };
  const handleStaffingSwitchcheck = (val) => {
    const person = formControl?.staffing?.find((p) => p?.field_name === val);
    return parseInt(person?.display) > 0 && parseInt(person?.required) === 1
      ? true
      : false;
  };
  const handleStaffingOnChange = (fieldName, val) => {
    setFormControl((prevFormControl) => {
      const updatedstaffingData = prevFormControl.staffing.map((item) => {
        if (item.field_name === fieldName) {
          console.log(item.field_name);
          return parseInt(val)
            ? { ...item, display: parseInt(val) }
            : { ...item, display: parseInt(val), required: parseInt(val) };
        }
        return item;
      });

      return {
        ...prevFormControl,
        staffing: updatedstaffingData,
      };
    });
  };
  const handleStaffingSwitchOnChange = (fieldName, val) => {
    setFormControl((prevFormControl) => {
      const updatedstaffingData = prevFormControl.staffing.map((item) => {
        if (item.field_name === fieldName) {
          if (item?.display > 0) {
            return { ...item, required: parseInt(val ? "1" : "0") };
          } else {
            return { ...item, required: 0 };
          }
          // Toggle the display value (0 to 1, or 1 to 0)
        }
        return item;
      });

      return {
        ...prevFormControl,
        staffing: updatedstaffingData,
      };
    });
  };

  //Additional Data Tracking
  // const handleAddDataGraph = (fieldName, val) => {
  //   setFormControl((prevFormControl) => {
  //     const fieldExists = prevFormControl.additional_data_tracking.some(
  //       (item) => item.field_name === fieldName
  //     );

  //     let updatedAddDataData;
  //     if (fieldExists) {
  //       updatedAddDataData = prevFormControl.additional_data_tracking.map(
  //         (item) => {
  //           if (item.field_name === fieldName) {
  //             return { ...item, display_graph: parseInt(val) };
  //           }
  //           return item;
  //         }
  //       );
  //     } else {
  //       updatedAddDataData = [
  //         ...prevFormControl.additional_data_tracking,
  //         {
  //           field_name: fieldName,
  //           display_graph: parseInt(val),
  //           display: 0,
  //           required: 0,
  //         },
  //       ];
  //     }

  //     return {
  //       ...prevFormControl,
  //       additional_data_tracking: updatedAddDataData,
  //     };
  //   });
  // };

  function debounce(func, wait) {
    let timeout;

    return function executedFunction(...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func(...args);
      }, wait);
    };
  }
  const showErrorToast = debounce((message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
    });
  }, 500);
  const handleAddDataGraph = (fieldName, val) => {
    setFormControl((prevFormControl) => {
      const currentCount = prevFormControl.additional_data_tracking.reduce(
        (acc, item) => acc + (item.display_graph === 1 ? 1 : 0),
        0
      );

      const targetItem = prevFormControl.additional_data_tracking.find(
        (item) => item.field_name === fieldName
      );

      const isFieldCurrentlyOne = targetItem?.display_graph === 1;

      if (parseInt(val) === 1 && currentCount >= 4 && !isFieldCurrentlyOne) {
        showErrorToast("Only 4 items can have 'Display Graph' set to Yes.");
        return prevFormControl;
      }

      const updatedAddDataData = prevFormControl.additional_data_tracking.map(
        (item) => {
          if (item.field_name === fieldName) {
            const displayGraphValue = item.display > 0 ? parseInt(val) : 0;
            return { ...item, display_graph: displayGraphValue };
          }
          return item;
        }
      );

      return {
        ...prevFormControl,
        additional_data_tracking: updatedAddDataData,
      };
    });
  };
  const handleAddDataSort = (fieldName, val) => {
    setFormControl((prevFormControl) => {
      const fieldExists = prevFormControl.additional_data_tracking.some(
        (item) => item.field_name === fieldName
      );

      let updatedAddDataData;
      if (fieldExists) {
        updatedAddDataData = prevFormControl.additional_data_tracking.map(
          (item) => {
            if (item.field_name === fieldName) {
              if (parseInt(val) === 0) {
                return {
                  ...item,
                  display: parseInt(val),
                  required: parseInt(val),
                  display_graph: parseInt(val),
                };
              } else return { ...item, display: parseInt(val) };
            }
            return item;
          }
        );
      } else {
        updatedAddDataData = [
          ...prevFormControl.additional_data_tracking,
          {
            field_name: fieldName,
            display: parseInt(val),
            required: 0,
          },
        ];
      }

      return {
        ...prevFormControl,
        additional_data_tracking: updatedAddDataData,
      };
    });
  };

  const handleAddDataLabel = (fieldName, val) => {
    setFormControl((prevFormControl) => {
      const fieldExists = prevFormControl.additional_data_tracking.some(
        (item) => item.field_name === fieldName
      );

      let updatedAddDataData;
      if (fieldExists) {
        updatedAddDataData = prevFormControl.additional_data_tracking.map(
          (item) => {
            if (item.field_name === fieldName) {
              return { ...item, field_label: val };
            }
            return item;
          }
        );
      } else {
        updatedAddDataData = [
          ...prevFormControl.additional_data_tracking,
          {
            field_name: fieldName,
            field_label: val,
            display: 0,
            required: 0,
          },
        ];
      }

      return {
        ...prevFormControl,
        additional_data_tracking: updatedAddDataData,
      };
    });
  };
  const handleAddDataCheck = (val) => {
    const person = formControl?.additional_data_tracking?.find(
      (p) => p.field_name === val
    );
    if (person) {
      return person.display === 1;
    }
    return false;
  };
  const handleAddDataSwitchcheck = (val) => {
    const person = formControl?.additional_data_tracking?.find(
      (p) => p?.field_name === val
    );
    return parseInt(person?.display) > 0 && parseInt(person?.required) === 1
      ? true
      : false;
  };
  const handleDisplayGraphSwitchcheck = (val) => {
    const person = formControl?.additional_data_tracking?.find(
      (p) => p?.field_name === val
    );
    return parseInt(person?.display_graph) === 1 ? true : false;
  };
  const handleAddDataOnChange = (fieldName, val) => {
    setFormControl((prevFormControl) => {
      const updatedAddDataData = prevFormControl.additional_data_tracking.map(
        (item) => {
          if (item.field_name === fieldName) {
            console.log(item.field_name);
            return parseInt(val)
              ? { ...item, display: parseInt(val) }
              : { ...item, display: parseInt(val), required: parseInt(val) };
          }
          return item;
        }
      );

      return {
        ...prevFormControl,
        additional_data_tracking: updatedAddDataData,
      };
    });
  };
  const handleAddDataSwitchOnChange = (fieldName, val) => {
    setFormControl((prevFormControl) => {
      const updatedAddData = prevFormControl.additional_data_tracking.map(
        (item) => {
          if (item.field_name === fieldName) {
            if (item?.display > 0) {
              return { ...item, required: parseInt(val ? "1" : "0") };
            } else {
              return { ...item, required: 0 };
            }
            // Toggle the display value (0 to 1, or 1 to 0)
          }
          return item;
        }
      );

      return {
        ...prevFormControl,
        additional_data_tracking: updatedAddData,
      };
    });
  };

  const onSubmit = async (values, onSubmittingProps) => {
    console.log("form Values", values);
    if (parseInt(values.realtime) === 1) {
      try {
        await checkFormFields();
        formSubmit(values);
      } catch (error) {
        toast.error(error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      }
    } else {
      formSubmit(values);
    }
  };
  const formSubmit = async (values) => {
    console.log("formControl", formControl);
    const obj = {
      cost_center_name: values.cost_center_name,
      cost_center_number: parseInt(values.cost_center_number),
      cost_center_category:
        values.cost_center_category !== ""
          ? parseInt(values.cost_center_category)
          : null,
      business_unit_description: values.business_unit_description,
      target: parseFloat(values.target),
      target_type: Number(values.target_type_id),
      tier2: values.tier2,
      tier3: values.tier3,
      tier4: values.tier4,
      tier5: values.tier5,
      uos_description: values.uos_description,
      realtime: parseInt(values.realtime),
      customer_code: parseInt(customer_code),
      facility_id: parseInt(values.facility_id),
      fixed_or_variable: values.fixed_or_variable,
      form_control: formControl,
      manager: values.manager,
      director: values.director,
      is10day: parseInt(values.is10day),
      adm: parseInt(values.adm),
      first_entry: values.first_entry,
      interval: parseInt(values.interval),
      validate_census: parseInt(values.validate_census),
      standard_unit_ratio: parseInt(values.standard_unit_ratio),
      patient_data_label: values.patient_data_label,
      staffing_data_label: values.staffing_data_label,
      additional_data_label: values.additional_data_label,
      warm_up: parseInt(values.warm_up),
      census_grid: values.census_grid,
      number_of_days: parseInt(values.number_of_days),
      show_productivity_ring: parseInt(values.show_productivity_ring),
      show_compliance_ring: parseInt(values.show_compliance_ring),
      display_on_nursing_status: parseInt(values.display_on_nursing_status),
      status: parseInt(values.status),
      is_ambulatory: parseInt(values.is_ambulatory),
      budget1: {
        beds: parseInt(values.budget1.beds),
        fte_max: parseInt(values.budget1.fte_max),
        fte_min: parseInt(values.budget1.fte_min),
        nurse_uos: parseInt(values.budget1.nurse_uos),
        required_fte: parseInt(values.budget1.required_fte),
      },
      accepted_acuity_per_nurse:
        values.accepted_acuity_per_nurse !== ""
          ? parseFloat(values.accepted_acuity_per_nurse)
          : 0,
      overtime_threshold:
        values.overtime_threshold !== ""
          ? parseFloat(values.overtime_threshold)
          : 0,
    };
    console.log(obj);

    try {
      setIsLoading(true);
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/department/departmentAddUpdate`,
        obj
      );
      console.log(resp);
      toast.success(resp?.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
      navigate("/admin/department");
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });

      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getInitalData = async () => {
    try {
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/department/interval_form_field_list`
      );
      // console.log("thy", resp?.data?.data?.form_control);
      setFormControl(resp?.data?.data?.form_control);
      setFormDetailData(resp?.data?.data?.form_control);
      // setISInitialData(true);
    } catch (error) {
      console.log(error);
    }
  };

  //Patient Data
  const sortAndFilterPatientData = () => {
    const sortedPatientData = formDetailData?.patient_data;

    const visiblePatientRows = sortedPatientData?.filter((data) =>
      data?.category_ids.some(
        (category) => category?.category_id === +categoryId
      )
    );

    const hiddenPatientRows = sortedPatientData?.filter(
      (data) =>
        !data?.category_ids.some(
          (category) => category?.category_id === +categoryId
        )
    );

    return {
      visiblePatientRows,
      hiddenPatientRows,
    };
  };

  const { visiblePatientRows, hiddenPatientRows } =
    formDetailData && sortAndFilterPatientData();

  const toggleShowMorePatient = () => {
    setShowAllPatient((prevShowAll) => !prevShowAll);
  };

  // Staffing Data
  const sortAndFilterStaffingData = () => {
    const sortedStaffingData = formDetailData?.staffing;

    const visibleStaffingRows = sortedStaffingData?.filter((data) =>
      data?.category_ids.some(
        (category) => category?.category_id === +categoryId
      )
    );

    const hiddenStaffingRows = sortedStaffingData?.filter(
      (data) =>
        !data?.category_ids.some(
          (category) => category?.category_id === +categoryId
        )
    );

    return {
      visibleStaffingRows,
      hiddenStaffingRows,
    };
  };

  const { visibleStaffingRows, hiddenStaffingRows } =
    formDetailData && sortAndFilterStaffingData();

  const toggleShowMoreStaffing = () => {
    setShowAllStaffing((prevShowAll) => !prevShowAll);
  };

  // Additional Data
  const sortAndFilterAdditionalData = () => {
    const sortedAdditionalData = formDetailData?.additional_data_tracking;

    const visibleAdditionalRows = sortedAdditionalData?.filter((data) =>
      data?.category_ids.some(
        (category) => category?.category_id === +categoryId
      )
    );
    
    const hiddenAdditionalRows = sortedAdditionalData?.filter((data) =>
        !data?.category_ids.some(
          (category) => category?.category_id === +categoryId
        )
    );

    return {
      visibleAdditionalRows,
      hiddenAdditionalRows
    };
  };

  const {
    visibleAdditionalRows,
    hiddenAdditionalRows
  } = formDetailData && sortAndFilterAdditionalData();

  const toggleShowMoreAdditional = () => {
    setShowAllAdditional((prevShowAll) => !prevShowAll);
  };

     const disabledFields = [
       "fixed_position_5_1",
       "fixed_position_5_2",
       "fixed_position_5_3",
       "fixed_position_5_4",
       "fixed_position_5_5",
       "fixed_position_5_8",
       "fixed_position_5_5_2",
       "fixed_position_7_1",
       "fixed_position_7_2",
       "fixed_position_7_3",
       "fixed_position_7_4",
       "fixed_position_7_5",
       "fixed_position_7_5_2",
       "fixed_position_7_8",
     ];

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={departmentValidation}
        enableReinitialize
        validateOnChange
      >
        {(formik) => (
          <div className="main-panel ">
            {console.log(formik)}
            <div className="content-wrapper ">
              <div className="page-header">
                <h3 className="page-title">
                  <a href="#" className="badge badge-dark"></a>
                </h3>
                <div className></div>
              </div>
              <div className="row ">
                <div class="col-12 grid-margin stretch-card">
                  <div class="card">
                    <FormikForm className="forms-sample">
                      <div class="card-body">
                        <div className="row align-items-center">
                          <div className="col-3">
                            <Link
                              to={`/admin/department`}
                              className="f-14 white-text-dark-mode"
                            >
                              <FontAwesomeIcon icon="fa-solid fa-arrow-left " />{" "}
                              Back
                            </Link>
                          </div>
                          <div className="col-6">
                            <h4 class="card-title text-center">
                              Add Cost Center
                            </h4>
                          </div>
                          <div className="col-3 text-end">
                            <Button
                              className="ms-3"
                              variant="success"
                              type="submit"
                              disabled={isLoading}
                            >
                              {isLoading ? "Saving..." : "Save"}
                            </Button>
                          </div>
                        </div>

                        <Form.Group className="col-md-12 mb-3 d-flex justify-content-end">
                          {/* <Button variant="primary" type="submit">
                              Reset
                            </Button> */}
                        </Form.Group>
                        <div class="row">
                          <Form.Group
                            className="col-md-3 mb-3"
                            controlId="exampleForm.c_code"
                          >
                            <Form.Label>
                              Cost center number
                              <span style={{ color: "red" }}>&nbsp;*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              name="cost_center_number"
                              getFacilityMaster
                              getTargetTypeMaster
                              {...formik.getFieldProps("cost_center_number")}
                            />
                            <ErrorMessage
                              name="cost_center_number"
                              component={Texterror}
                            />
                          </Form.Group>
                          <Form.Group
                            className="col-md-3 mb-3"
                            controlId="exampleForm.c_name"
                          >
                            <Form.Label>
                              Cost Center Name
                              <span style={{ color: "red" }}>&nbsp;*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              name="cost_center_name"
                              {...formik.getFieldProps("cost_center_name")}
                            />
                            <ErrorMessage
                              name="cost_center_name"
                              component={Texterror}
                            />
                          </Form.Group>
                          <Form.Group
                            className="col-md-3 mb-3"
                            controlId="exampleForm.c_name"
                          >
                            <Form.Label>Director</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              name="director"
                              {...formik.getFieldProps("director")}
                            />
                            <ErrorMessage
                              name="director"
                              component={Texterror}
                            />
                          </Form.Group>
                          <Form.Group
                            className="col-md-3 mb-3"
                            controlId="exampleForm.c_name"
                          >
                            <Form.Label>Manager</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              name="manager"
                              {...formik.getFieldProps("manager")}
                            />
                            <ErrorMessage
                              name="manager"
                              component={Texterror}
                            />
                          </Form.Group>
                          <Form.Group
                            className="col-md-3 mb-3 position-relative"
                            controlId="exampleForm.c_email"
                          >
                            <Form.Label>Cost center category</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              className="white"
                              name="cost_center_category"
                              value={
                                categoryList?.find(
                                  (e) =>
                                    e.cost_center_category_id ===
                                    formik.values.cost_center_category
                                )?.cost_center_category_id
                              }
                              onChange={(e) => {
                                formik.setFieldTouched(
                                  "cost_center_category",
                                  true,
                                  true
                                );
                                formik.setFieldValue(
                                  "cost_center_category",
                                  e.target.value
                                );
                                setCategoryId(e.target.value);
                                sortAndFilterPatientData();
                                sortAndFilterStaffingData();
                                sortAndFilterAdditionalData();
                              }}
                              // onChange={formik.handleChange}
                            >
                              <option value={""}>select</option>
                              {categoryList?.map((e) => {
                                return (
                                  <option
                                    key={e?.cost_center_category}
                                    value={e?.cost_center_category_id}
                                  >
                                    {e?.cost_center_category}
                                  </option>
                                );
                              })}
                            </Form.Select>
                            <ErrorMessage
                              name="cost_center_category"
                              component={Texterror}
                            />
                            <span
                              className="position-absolute"
                              style={{
                                width: "30px",
                                height: "30px",
                                textAlign: "left",
                                lineHeight: "43px",
                                top: "26px",
                                right: "52px",
                              }}
                            >
                              <>
                                {
                                  <OverlayTrigger
                                    key={"top"}
                                    placement={"top"}
                                    overlay={
                                      <Tooltip id={`tooltip-${"top"}`}>
                                        <strong>{"Note:"}</strong>
                                        &nbsp;
                                        {
                                          <span
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                categoryList?.find(
                                                  (e) =>
                                                    e?.cost_center_category_id ===
                                                    parseInt(
                                                      formik.values
                                                        .cost_center_category
                                                    )
                                                )?.note || "N/A",
                                            }}
                                          />
                                        }
                                      </Tooltip>
                                    }
                                  >
                                    <img
                                      src={calcIcon}
                                      alt=""
                                      style={{
                                        width: "30px",
                                        height: "auto",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </OverlayTrigger>
                                }
                              </>
                            </span>
                          </Form.Group>

                          <Form.Group
                            className="col-md-3 mb-3"
                            controlId="exampleForm.c_email"
                          >
                            <Form.Label>Business Unit Description</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              name="business_unit_description"
                              {...formik.getFieldProps(
                                "business_unit_description"
                              )}
                            />
                            <ErrorMessage
                              name="business_unit_description"
                              component={Texterror}
                            />
                          </Form.Group>

                          <Form.Group
                            className="col-md-3 mb-3"
                            controlId="exampleForm.c_email"
                          >
                            <Form.Label>
                              Target
                              <span style={{ color: "red" }}>&nbsp;*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              name="target"
                              {...formik.getFieldProps("target")}
                            />
                            <ErrorMessage name="target" component={Texterror} />
                          </Form.Group>

                          <Form.Group
                            className="col-md-3 mb-3"
                            controlId="exampleForm.c_email"
                          >
                            <Form.Label>Target type</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              className="white"
                              name="target_type"
                              value={
                                targetTypeMaster?.find(
                                  (e) =>
                                    e.target_type === formik.values.target_type
                                )?.target_type_id
                              }
                              onChange={(e) => {
                                formik.setFieldTouched(
                                  "target_type",
                                  true,
                                  true
                                );
                                formik.setFieldValue(
                                  "target_type",
                                  e.target.value
                                );
                              }}
                              // onChange={formik.handleChange}
                            >
                              <option value={""}>select</option>
                              {targetTypeMaster?.map((e) => {
                                return (
                                  <option
                                    key={e?.target_type}
                                    value={e?.target_type_id}
                                  >
                                    {e?.target_type}
                                  </option>
                                );
                              })}
                            </Form.Select>
                            <ErrorMessage
                              name="target_type"
                              component={Texterror}
                            />
                          </Form.Group>

                          <Form.Group
                            className="col-md-3 mb-3"
                            controlId="exampleForm.c_email"
                          >
                            <Form.Label>Tier 2</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              name="tier2"
                              {...formik.getFieldProps("tier2")}
                            />
                            <ErrorMessage name="tier2" component={Texterror} />
                          </Form.Group>
                          <Form.Group
                            className="col-md-3 mb-3"
                            controlId="exampleForm.c_email"
                          >
                            <Form.Label>Tier 3</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              name="tier3"
                              {...formik.getFieldProps("tier3")}
                            />
                            <ErrorMessage name="tier3" component={Texterror} />
                          </Form.Group>
                          <Form.Group
                            className="col-md-3 mb-3"
                            controlId="exampleForm.c_email"
                          >
                            <Form.Label>Tier 4</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              name="tier4"
                              {...formik.getFieldProps("tier4")}
                            />
                            <ErrorMessage name="tier4" component={Texterror} />
                          </Form.Group>
                          <Form.Group
                            className="col-md-3 mb-3"
                            controlId="exampleForm.c_email"
                          >
                            <Form.Label>Tier 5</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              name="tier5"
                              {...formik.getFieldProps("tier5")}
                            />
                            <ErrorMessage name="tier5" component={Texterror} />
                          </Form.Group>
                          <Form.Group
                            className="col-md-3 mb-3"
                            controlId="exampleForm.c_email"
                          >
                            <Form.Label>Accepted Acuity per Nurse</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              name="accepted_acuity_per_nurse"
                              {...formik.getFieldProps(
                                "accepted_acuity_per_nurse"
                              )}
                            />
                            <ErrorMessage
                              name="accepted_acuity_per_nurse"
                              component={Texterror}
                            />
                          </Form.Group>
                          <Form.Group
                            className="col-md-3 mb-3"
                            controlId="exampleForm.c_email"
                          >
                            <Form.Label>UOS Description</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              name="uos_description"
                              {...formik.getFieldProps("uos_description")}
                            />
                            <ErrorMessage
                              name="uos_description"
                              component={Texterror}
                            />
                          </Form.Group>

                          <Form.Group
                            className="col-md-3 mb-3"
                            controlId="exampleForm.c_email"
                          >
                            <Form.Label>Realtime</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              className="white"
                              name="realtime"
                              value={`${formik.values.realtime}`}
                              onChange={(e) => {
                                // formik.setFieldTouched(
                                //   "customer_status",
                                //   true,
                                //   true
                                // );
                                formik.setFieldValue(
                                  "realtime",
                                  e.target.value
                                );
                                setIsRealtime(parseInt(e.target.value) === 1);
                              }}
                              // onChange={formik.handleChange}
                            >
                              <option value="1">Yes</option>
                              <option value="0">No</option>
                            </Form.Select>
                            <ErrorMessage
                              name="realtime"
                              component={Texterror}
                            />
                          </Form.Group>
                          <Form.Group
                            className="col-md-3 mb-3"
                            controlId="exampleForm.c_email"
                          >
                            <Form.Label>ADM</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              className="white"
                              name="adm"
                              value={`${formik.values.adm}`}
                              onChange={(e) => {
                                // formik.setFieldTouched(
                                //   "customer_status",
                                //   true,
                                //   true
                                // );
                                formik.setFieldValue("adm", e.target.value);
                                // setIsRealtime(parseInt(e.target.value) === 1);
                              }}
                              // onChange={formik.handleChange}
                            >
                              <option value="1">Yes</option>
                              <option value="0">No</option>
                            </Form.Select>
                            <ErrorMessage name="adm" component={Texterror} />
                          </Form.Group>

                          <Form.Group
                            className="col-md-3 mb-3"
                            controlId="exampleForm.c_email"
                          >
                            <Form.Label>
                              Facility Masters
                              <span style={{ color: "red" }}>&nbsp;*</span>
                            </Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              className="white"
                              name="facility_id"
                              value={`${formik.values.facility_id}`}
                              onChange={(e) => {
                                formik.setFieldTouched(
                                  "facility_id",
                                  true,
                                  true
                                );
                                formik.setFieldValue(
                                  "facility_id",
                                  e.target.value
                                );
                              }}
                              // onChange={formik.handleChange}
                            >
                              <option value={""}>Select</option>
                              {facilityMaster?.map((e) => {
                                return (
                                  <option
                                    key={e?.facility_id}
                                    value={e?.facility_id}
                                  >
                                    {e?.facility_name}
                                  </option>
                                );
                              })}
                            </Form.Select>
                            <ErrorMessage
                              name="facility_id"
                              component={Texterror}
                            />
                          </Form.Group>
                          <Form.Group
                            className="col-md-3 mb-3"
                            controlId="exampleForm.c_email"
                          >
                            <Form.Label>
                              Fixed or Variable
                              <span style={{ color: "red" }}>&nbsp;*</span>
                            </Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              className="white"
                              name="fixed_or_variable"
                              value={`${formik.values.fixed_or_variable}`}
                              onChange={(e) => {
                                formik.setFieldTouched(
                                  "fixed_or_variable",
                                  true,
                                  true
                                );
                                formik.setFieldValue(
                                  "fixed_or_variable",
                                  e.target.value
                                );
                              }}
                              onBlur={() => {
                                formik.setFieldTouched(
                                  "fixed_or_variable",
                                  true,
                                  true
                                );
                              }}
                              // onChange={formik.handleChange}
                            >
                              <option value="">Select</option>
                              <option value="Fixed">Fixed</option>
                              <option value="Variable">Variable</option>
                            </Form.Select>
                            <ErrorMessage
                              name="fixed_or_variable"
                              component={Texterror}
                            />
                          </Form.Group>
                          <Form.Group
                            className="col-md-3 mb-3"
                            controlId="exampleForm.c_email"
                          >
                            <Form.Label>Is10day</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              className="white"
                              name="is10day"
                              value={`${formik.values.is10day}`}
                              onChange={(e) => {
                                formik.setFieldTouched("is10day", true, true);
                                formik.setFieldValue("is10day", e.target.value);
                              }}
                              onBlur={() => {
                                formik.setFieldTouched("is10day", true, true);
                              }}
                              // onChange={formik.handleChange}
                            >
                              <option value="0">No</option>
                              <option value="1">Yes</option>
                            </Form.Select>
                            <ErrorMessage
                              name="is10day"
                              component={Texterror}
                            />
                          </Form.Group>
                          <Form.Group
                            className="col-md-3 mb-3"
                            controlId="exampleForm.c_email"
                          >
                            <Form.Label>Standard Unit Ratio</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              className="white"
                              name="standard_unit_ratio"
                              value={`${formik.values.standard_unit_ratio}`}
                              onChange={(e) => {
                                formik.setFieldTouched(
                                  "standard_unit_ratio",
                                  true,
                                  true
                                );
                                formik.setFieldValue(
                                  "standard_unit_ratio",
                                  e.target.value
                                );
                              }}
                              onBlur={() => {
                                formik.setFieldTouched(
                                  "standard_unit_ratio",
                                  true,
                                  true
                                );
                              }}
                              // onChange={formik.handleChange}
                            >
                              <option value="0">Select</option>
                              <option value="2">1:2</option>
                              <option value="3">1:3</option>
                              <option value="4">1:4</option>
                              <option value="5">1:5</option>
                            </Form.Select>
                            <ErrorMessage
                              name="standard_unit_ratio"
                              component={Texterror}
                            />
                          </Form.Group>
                          <Form.Group
                            className="col-md-3 mb-3"
                            controlId="exampleForm.c_email"
                          >
                            <Form.Label>Validate Census</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              className="white"
                              name="validate_census"
                              value={`${formik.values.validate_census}`}
                              onChange={(e) => {
                                formik.setFieldTouched(
                                  "validate_census",
                                  true,
                                  true
                                );
                                formik.setFieldValue(
                                  "validate_census",
                                  e.target.value
                                );
                              }}
                              onBlur={() => {
                                formik.setFieldTouched(
                                  "validate_census",
                                  true,
                                  true
                                );
                              }}
                              // onChange={formik.handleChange}
                            >
                              <option value="0">No</option>
                              <option value="1">Yes</option>
                            </Form.Select>
                            <ErrorMessage
                              name="validate_census"
                              component={Texterror}
                            />
                          </Form.Group>
                          <Form.Group
                            className="col-md-3 mb-3"
                            controlId="exampleForm.c_email"
                          >
                            <Form.Label>Warm Up (In Minutes)</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              name="warm_up"
                              {...formik.getFieldProps("warm_up")}
                            />
                            <ErrorMessage
                              name="warm_up"
                              component={Texterror}
                            />
                          </Form.Group>
                          <Form.Group
                            className="col-md-3 mb-3"
                            controlId="exampleForm.c_email"
                          >
                            <Form.Label>
                              No of Days
                              <span style={{ color: "red" }}>&nbsp;*</span>
                            </Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              className="white"
                              name="validate_census"
                              value={`${formik.values.number_of_days}`}
                              onChange={(e) => {
                                formik.setFieldTouched(
                                  "number_of_days",
                                  true,
                                  true
                                );
                                formik.setFieldValue(
                                  "number_of_days",
                                  e.target.value
                                );
                              }}
                              onBlur={() => {
                                formik.setFieldTouched(
                                  "number_of_days",
                                  true,
                                  true
                                );
                              }}
                              // onChange={formik.handleChange}
                            >
                              <option value="">select</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                            </Form.Select>
                            <ErrorMessage
                              name="number_of_days"
                              component={Texterror}
                            />
                          </Form.Group>
                          <Form.Group
                            className="col-md-3 mb-3"
                            controlId="exampleForm.c_email"
                          >
                            <Form.Label>
                              Census Grid
                              <span style={{ color: "red" }}>&nbsp;*</span>
                            </Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              className="white"
                              name="census_grid"
                              value={`${formik.values.census_grid}`}
                              onChange={(e) => {
                                formik.setFieldTouched(
                                  "census_grid",
                                  true,
                                  true
                                );
                                formik.setFieldValue(
                                  "census_grid",
                                  e.target.value
                                );
                              }}
                              onBlur={() => {
                                formik.setFieldTouched(
                                  "census_grid",
                                  true,
                                  true
                                );
                              }}
                              // onChange={formik.handleChange}
                            >
                              <option value="">Select</option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </Form.Select>
                            <ErrorMessage
                              name="census_grid"
                              component={Texterror}
                            />
                          </Form.Group>
                          <Form.Group
                            className="col-md-3 mb-3"
                            controlId="exampleForm.c_email"
                          >
                            <Form.Label>Show Productivity Ring</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              className="white"
                              name="show_productivity_ring"
                              value={`${formik.values.show_productivity_ring}`}
                              onChange={(e) => {
                                formik.setFieldTouched(
                                  "show_productivity_ring",
                                  true,
                                  true
                                );
                                formik.setFieldValue(
                                  "show_productivity_ring",
                                  e.target.value
                                );
                              }}
                              onBlur={() => {
                                formik.setFieldTouched(
                                  "show_productivity_ring",
                                  true,
                                  true
                                );
                              }}
                              // onChange={formik.handleChange}
                            >
                              <option value="0">No</option>
                              <option value="1">Yes</option>
                            </Form.Select>
                            <ErrorMessage
                              name="show_productivity_ring"
                              component={Texterror}
                            />
                          </Form.Group>
                          <Form.Group
                            className="col-md-3 mb-3"
                            controlId="exampleForm.c_email"
                          >
                            <Form.Label>Show Compliance Ring</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              className="white"
                              name="show_compliance_ring"
                              value={`${formik.values.show_compliance_ring}`}
                              onChange={(e) => {
                                formik.setFieldTouched(
                                  "show_compliance_ring",
                                  true,
                                  true
                                );
                                formik.setFieldValue(
                                  "show_compliance_ring",
                                  e.target.value
                                );
                              }}
                              onBlur={() => {
                                formik.setFieldTouched(
                                  "show_compliance_ring",
                                  true,
                                  true
                                );
                              }}
                              // onChange={formik.handleChange}
                            >
                              <option value="0">No</option>
                              <option value="1">Yes</option>
                            </Form.Select>
                            <ErrorMessage
                              name="show_compliance_ring"
                              component={Texterror}
                            />
                          </Form.Group>
                          <Form.Group
                            className="col-md-3 mb-3"
                            controlId="exampleForm.c_email"
                          >
                            <Form.Label>Overtime Threshold</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              name="overtime_threshold"
                              {...formik.getFieldProps("overtime_threshold")}
                            />
                            <ErrorMessage
                              name="overtime_threshold"
                              component={Texterror}
                            />
                          </Form.Group>
                          <Form.Group
                            className="col-md-3 mb-3"
                            controlId="exampleForm.c_email"
                          >
                            <Form.Label>
                              Display on Nursing Status Page
                            </Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              className="white"
                              name="display_on_nursing_status"
                              value={`${formik.values.display_on_nursing_status}`}
                              onChange={(e) => {
                                formik.setFieldTouched(
                                  "display_on_nursing_status",
                                  true,
                                  true
                                );
                                formik.setFieldValue(
                                  "display_on_nursing_status",
                                  e.target.value
                                );
                              }}
                              onBlur={() => {
                                formik.setFieldTouched(
                                  "display_on_nursing_status",
                                  true,
                                  true
                                );
                              }}
                              // onChange={formik.handleChange}
                            >
                              <option value="0">No</option>
                              <option value="1">Yes</option>
                            </Form.Select>
                            <ErrorMessage
                              name="display_on_nursing_status"
                              component={Texterror}
                            />
                          </Form.Group>
                          <Form.Group
                            className="col-md-3 mb-3"
                            controlId="exampleForm.c_email"
                          >
                            <Form.Label>Status</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              className="white"
                              name="status"
                              value={`${formik.values.status}`}
                              onChange={(e) => {
                                formik.setFieldTouched("status", true, true);
                                formik.setFieldValue("status", e.target.value);
                              }}
                              onBlur={() => {
                                formik.setFieldTouched("status", true, true);
                              }}
                              // onChange={formik.handleChange}
                            >
                              <option value="0">Inactive</option>
                              <option value="1">Active</option>
                            </Form.Select>
                            <ErrorMessage name="status" component={Texterror} />
                          </Form.Group>
                          <Form.Group
                            className="col-md-3 mb-3"
                            controlId="exampleForm.c_email"
                          >
                            <Form.Label>Ambulatory</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              className="white"
                              name="is_ambulatory"
                              value={`${formik.values.is_ambulatory}`}
                              onChange={(e) => {
                                formik.setFieldTouched(
                                  "is_ambulatory",
                                  true,
                                  true
                                );
                                formik.setFieldValue(
                                  "is_ambulatory",
                                  e.target.value
                                );
                              }}
                              onBlur={() => {
                                formik.setFieldTouched(
                                  "is_ambulatory",
                                  true,
                                  true
                                );
                              }}
                              // onChange={formik.handleChange}
                            >
                              <option value="0">No</option>
                              <option value="1">Yes</option>
                            </Form.Select>
                            <ErrorMessage
                              name="is_ambulatory"
                              component={Texterror}
                            />
                          </Form.Group>
                          <div>
                            <div className="row">
                              <div class="col-12 grid-margin stretch-card">
                                <div class="card">
                                  <div class="card-body py-4">
                                    <div className="d-flex justify-content-between">
                                      <div className="d-flex">
                                        <h4 class="card-title fs-5 mb-0">
                                          Budget 1
                                        </h4>
                                      </div>
                                    </div>

                                    <div class="row">
                                      <div className="col-md-12 m-0">
                                        <hr />
                                      </div>
                                      <div className="col-md-12">
                                        <div className="row">
                                          <div className="col mb-3">
                                            <Form.Group controlId="exampleForm.c_code">
                                              <Form.Label>
                                                Beds
                                                {/* <span style={{ color: "red" }}>
                                                &nbsp;*
                                              </span> */}
                                              </Form.Label>
                                              <Form.Control
                                                type="number"
                                                placeholder=""
                                                name="budget1.beds"
                                                value={
                                                  formik.values.budget1.beds
                                                }
                                                onChange={(e) => {
                                                  formik.setFieldTouched(
                                                    "budget1.beds",
                                                    true,
                                                    true
                                                  );
                                                  formik.setFieldValue(
                                                    "budget1.beds",
                                                    String(e.target.value)
                                                  );
                                                }}
                                                onBlur={() => {
                                                  formik.setFieldTouched(
                                                    "budget1.beds",
                                                    true,
                                                    true
                                                  );
                                                }}
                                                // {...formik.getFieldProps(
                                                //   "budget1.beds"
                                                // )}
                                              />
                                              <ErrorMessage
                                                name="budget1.beds"
                                                component={Texterror}
                                              />
                                            </Form.Group>
                                          </div>

                                          <Form.Group
                                            className="col mb-3"
                                            controlId="exampleForm.c_code"
                                          >
                                            <Form.Label>Fte Max</Form.Label>
                                            <Form.Control
                                              type="number"
                                              placeholder=""
                                              name="budget1.fte_max"
                                              // {...formik.getFieldProps(
                                              //   "budget1.fte_max"
                                              // )}
                                              value={
                                                formik.values.budget1.fte_max
                                              }
                                              onChange={(e) => {
                                                formik.setFieldTouched(
                                                  "budget1.fte_max",
                                                  true,
                                                  true
                                                );
                                                formik.setFieldValue(
                                                  "budget1.fte_max",
                                                  String(e.target.value)
                                                );
                                              }}
                                              onBlur={() => {
                                                formik.setFieldTouched(
                                                  "budget1.fte_max",
                                                  true,
                                                  true
                                                );
                                              }}
                                            />
                                            <ErrorMessage
                                              name="budget1.fte_max"
                                              component={Texterror}
                                            />
                                          </Form.Group>
                                          <Form.Group
                                            className="col mb-3"
                                            controlId="exampleForm.c_code"
                                          >
                                            <Form.Label>Fte Min</Form.Label>
                                            <Form.Control
                                              type="number"
                                              placeholder=""
                                              name="budget1.fte_min"
                                              // {...formik.getFieldProps(
                                              //   "budget1.fte_min"
                                              // )}
                                              value={
                                                formik.values.budget1.fte_min
                                              }
                                              onChange={(e) => {
                                                formik.setFieldTouched(
                                                  "budget1.fte_min",
                                                  true,
                                                  true
                                                );
                                                formik.setFieldValue(
                                                  "budget1.fte_min",
                                                  String(e.target.value)
                                                );
                                              }}
                                              onBlur={() => {
                                                formik.setFieldTouched(
                                                  "budget1.fte_min",
                                                  true,
                                                  true
                                                );
                                              }}
                                            />
                                            <ErrorMessage
                                              name="budget1.fte_min"
                                              component={Texterror}
                                            />
                                          </Form.Group>
                                          <Form.Group
                                            className="col mb-3"
                                            controlId="exampleForm.c_code"
                                          >
                                            <Form.Label>Nurse Uos</Form.Label>
                                            <Form.Control
                                              type="number"
                                              placeholder=""
                                              name="budget1.nurse_uos"
                                              // {...formik.getFieldProps(
                                              //   "budget1.nurse_uos"
                                              // )}
                                              value={
                                                formik.values.budget1.nurse_uos
                                              }
                                              onChange={(e) => {
                                                formik.setFieldTouched(
                                                  "budget1.nurse_uos",
                                                  true,
                                                  true
                                                );
                                                formik.setFieldValue(
                                                  "budget1.nurse_uos",
                                                  String(e.target.value)
                                                );
                                              }}
                                              onBlur={() => {
                                                formik.setFieldTouched(
                                                  "budget1.nurse_uos",
                                                  true,
                                                  true
                                                );
                                              }}
                                            />
                                            <ErrorMessage
                                              name="budget1.nurse_uos"
                                              component={Texterror}
                                            />
                                          </Form.Group>
                                          <Form.Group
                                            className="col mb-3"
                                            controlId="exampleForm.c_code"
                                          >
                                            <Form.Label>
                                              Required Fte
                                            </Form.Label>
                                            <Form.Control
                                              type="number"
                                              placeholder=""
                                              name="budget1.required_fte"
                                              // {...formik.getFieldProps(
                                              //   "budget1.required_fte"
                                              // )}
                                              value={
                                                formik.values.budget1
                                                  .required_fte
                                              }
                                              onChange={(e) => {
                                                formik.setFieldTouched(
                                                  "budget1.required_fte",
                                                  true,
                                                  true
                                                );
                                                formik.setFieldValue(
                                                  "budget1.required_fte",
                                                  String(e.target.value)
                                                );
                                              }}
                                              onBlur={() => {
                                                formik.setFieldTouched(
                                                  "budget1.required_fte",
                                                  true,
                                                  true
                                                );
                                              }}
                                            />
                                            <ErrorMessage
                                              name="budget1.required_fte"
                                              component={Texterror}
                                            />
                                          </Form.Group>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {isRealtime && (
                            <>
                              {/* <div>
                                <div className="row mt-2">
                                  <div class="col-12 grid-margin stretch-card">
                                    <div class="card">
                                      <div class="card-body py-4">
                                        <div className="d-flex justify-content-between">
                                          <div className="d-flex">
                                            <h4 class="card-title fs-5 mb-0">
                                              Realtime Formula
                                            </h4>
                                          </div>
                                        </div>

                                        <div class="row">
                                          <div className="col-md-12 m-0">
                                            <hr />
                                          </div>
                                          <div className="col-md-12">
                                            <div className="row">
                                              <div className="col-md-12 editor-styles">
                                                
                                                <TextEditor
                                                value={text}
                                                onChange={handleChangeText}
                                                themeProp={theme.theme === "LIGHT"}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div> */}
                              <div>
                                <div className="row mt-2">
                                  <div class="col-12 grid-margin stretch-card">
                                    <div class="card">
                                      <div class="card-body py-4">
                                        <div className="d-flex justify-content-between">
                                          <div className="d-flex">
                                            <h4 class="card-title fs-5 mb-0">
                                              Shift Interval Configuration
                                            </h4>
                                          </div>
                                        </div>

                                        <div class="row">
                                          <div className="col-md-12 m-0">
                                            <hr />
                                          </div>
                                          <div className="col-md-12">
                                            <div className="row">
                                              <Form.Group
                                                className="col mb-3"
                                                controlId="exampleForm.c_email"
                                              >
                                                <Form.Label>
                                                  First Entry
                                                </Form.Label>
                                                <Form.Select
                                                  aria-label="Default select example"
                                                  className="white"
                                                  name="first_entry"
                                                  value={`${formik.values.first_entry}`}
                                                  onChange={(e) => {
                                                    formik.setFieldTouched(
                                                      "first_entry",
                                                      true,
                                                      true
                                                    );
                                                    formik.setFieldValue(
                                                      "first_entry",
                                                      e.target.value
                                                    );
                                                  }}
                                                  onBlur={() => {
                                                    formik.setFieldTouched(
                                                      "first_entry",
                                                      true,
                                                      true
                                                    );
                                                  }}
                                                  // onChange={formik.handleChange}
                                                >
                                                  {timeSlots?.map((e) => {
                                                    return (
                                                      <option key={e} value={e}>
                                                        {e}
                                                      </option>
                                                    );
                                                  })}
                                                </Form.Select>
                                                <ErrorMessage
                                                  name="first_entry"
                                                  component={Texterror}
                                                />
                                              </Form.Group>
                                              <Form.Group
                                                className="col mb-3"
                                                controlId="exampleForm.c_email"
                                              >
                                                <Form.Label>
                                                  Duration (in hours)
                                                </Form.Label>
                                                <Form.Select
                                                  aria-label="Default select example"
                                                  className="white"
                                                  name="interval"
                                                  value={`${formik.values.interval}`}
                                                  onChange={(e) => {
                                                    formik.setFieldTouched(
                                                      "interval",
                                                      true,
                                                      true
                                                    );
                                                    formik.setFieldValue(
                                                      "interval",
                                                      e.target.value
                                                    );
                                                  }}
                                                  onBlur={() => {
                                                    formik.setFieldTouched(
                                                      "interval",
                                                      true,
                                                      true
                                                    );
                                                  }}
                                                  // onChange={formik.handleChange}
                                                >
                                                  {Array.from(
                                                    { length: 24 },
                                                    (x, i) => i + 1
                                                  )?.map((e) => {
                                                    return (
                                                      <option key={e} value={e}>
                                                        {e}
                                                      </option>
                                                    );
                                                  })}
                                                </Form.Select>
                                                <ErrorMessage
                                                  name="interval"
                                                  component={Texterror}
                                                />
                                              </Form.Group>
                                              <Form.Group
                                                className="col mb-3"
                                                controlId="exampleForm.c_code"
                                              >
                                                <Form.Label>
                                                  Patient Data Label
                                                </Form.Label>
                                                <Form.Control
                                                  type="text"
                                                  placeholder=""
                                                  name="patient_data_label"
                                                  // {...formik.getFieldProps(
                                                  //   "budget1.required_fte"
                                                  // )}
                                                  value={
                                                    formik.values
                                                      .patient_data_label
                                                  }
                                                  onChange={(e) => {
                                                    formik.setFieldTouched(
                                                      "patient_data_label",
                                                      true,
                                                      true
                                                    );
                                                    formik.setFieldValue(
                                                      "patient_data_label",
                                                      String(e.target.value)
                                                    );
                                                  }}
                                                  onBlur={() => {
                                                    formik.setFieldTouched(
                                                      "patient_data_label",
                                                      true,
                                                      true
                                                    );
                                                  }}
                                                />
                                                <ErrorMessage
                                                  name="patient_data_label"
                                                  component={Texterror}
                                                />
                                              </Form.Group>
                                              <Form.Group
                                                className="col mb-3"
                                                controlId="exampleForm.c_code"
                                              >
                                                <Form.Label>
                                                  Staffing Data Label
                                                </Form.Label>
                                                <Form.Control
                                                  type="text"
                                                  placeholder=""
                                                  name="staffing_data_label"
                                                  // {...formik.getFieldProps(
                                                  //   "budget1.required_fte"
                                                  // )}
                                                  value={
                                                    formik.values
                                                      .staffing_data_label
                                                  }
                                                  onChange={(e) => {
                                                    formik.setFieldTouched(
                                                      "staffing_data_label",
                                                      true,
                                                      true
                                                    );
                                                    formik.setFieldValue(
                                                      "staffing_data_label",
                                                      String(e.target.value)
                                                    );
                                                  }}
                                                  onBlur={() => {
                                                    formik.setFieldTouched(
                                                      "staffing_data_label",
                                                      true,
                                                      true
                                                    );
                                                  }}
                                                />
                                                <ErrorMessage
                                                  name="staffing_data_label"
                                                  component={Texterror}
                                                />
                                              </Form.Group>
                                              <Form.Group
                                                className="col mb-3"
                                                controlId="exampleForm.c_code"
                                              >
                                                <Form.Label>
                                                  Additional Data Label
                                                </Form.Label>
                                                <Form.Control
                                                  type="text"
                                                  placeholder=""
                                                  name="additional_data_label"
                                                  // {...formik.getFieldProps(
                                                  //   "budget1.required_fte"
                                                  // )}
                                                  value={
                                                    formik.values
                                                      .additional_data_label
                                                  }
                                                  onChange={(e) => {
                                                    formik.setFieldTouched(
                                                      "additional_data_label",
                                                      true,
                                                      true
                                                    );
                                                    formik.setFieldValue(
                                                      "additional_data_label",
                                                      String(e.target.value)
                                                    );
                                                  }}
                                                  onBlur={() => {
                                                    formik.setFieldTouched(
                                                      "additional_data_label",
                                                      true,
                                                      true
                                                    );
                                                  }}
                                                />
                                                <ErrorMessage
                                                  name="additional_data_label"
                                                  component={Texterror}
                                                />
                                              </Form.Group>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div>
                                {/* Patient Data */}
                                <div>
                                  <div className="row mt-2">
                                    <div class="col-12 grid-margin stretch-card">
                                      <Accordion
                                        className="mb-2"
                                        // defaultActiveKey="0"
                                      >
                                        <Accordion.Item eventKey="0">
                                          <Accordion.Header>
                                            Patient Data
                                          </Accordion.Header>

                                          <Accordion.Body>
                                            <>
                                              <div class="row">
                                                <div className="col-md-12 m-0">
                                                  <hr />
                                                </div>
                                                <div className="col-md-12">
                                                  <div className="row">
                                                    <div className="col-md-12">
                                                      <div class="table-responsive">
                                                        <table class="table">
                                                          <thead>
                                                            <tr>
                                                              <th>
                                                                Show/Hide/Sort
                                                              </th>
                                                              <th></th>
                                                              <th>Key</th>
                                                              <th>Label</th>
                                                              <th>Mandatory</th>
                                                            </tr>
                                                          </thead>
                                                          <>
                                                            {isEmpty(
                                                              categoryId
                                                            ) ? (
                                                              <tbody>
                                                                {formControl?.patient_data?.map(
                                                                  (
                                                                    val,
                                                                    ind
                                                                  ) => {
                                                                    const foundPatientData =
                                                                      formControl?.patient_data?.find(
                                                                        (e) =>
                                                                          e?.field_name ===
                                                                          val?.field_name
                                                                      );
                                                                    return (
                                                                      <tr
                                                                        key={
                                                                          ind
                                                                        }
                                                                      >
                                                                        <td
                                                                          className="sm-transparent-input"
                                                                          key={
                                                                            ind
                                                                          }
                                                                        >
                                                                          <input
                                                                            type="text"
                                                                            class="form-control"
                                                                            name={
                                                                              val?.field_name
                                                                            }
                                                                            value={
                                                                              foundPatientData?.display
                                                                            }
                                                                            onChange={(
                                                                              e
                                                                            ) => {
                                                                              handlePatientSort(
                                                                                val?.field_name,
                                                                                e
                                                                                  .target
                                                                                  .value !==
                                                                                  ""
                                                                                  ? e
                                                                                      .target
                                                                                      .value
                                                                                  : "0"
                                                                              );
                                                                            }}
                                                                          />
                                                                        </td>
                                                                        {val?.notes ? (
                                                                          <td
                                                                            style={{
                                                                              width:
                                                                                "30px",
                                                                              height:
                                                                                "30px",
                                                                              textAlign:
                                                                                "left",
                                                                              lineHeight:
                                                                                "35px",
                                                                            }}
                                                                          >
                                                                            <>
                                                                              {
                                                                                <OverlayTrigger
                                                                                  key={
                                                                                    "top"
                                                                                  }
                                                                                  placement={
                                                                                    "top"
                                                                                  }
                                                                                  overlay={
                                                                                    <Tooltip
                                                                                      id={`tooltip-${"top"}`}
                                                                                    >
                                                                                      <strong>
                                                                                        {
                                                                                          "Formula:"
                                                                                        }
                                                                                      </strong>
                                                                                      &nbsp;
                                                                                      {
                                                                                        val?.notes
                                                                                      }
                                                                                    </Tooltip>
                                                                                  }
                                                                                >
                                                                                  <img
                                                                                    src={
                                                                                      calcIcon
                                                                                    }
                                                                                    alt=""
                                                                                    style={{
                                                                                      width:
                                                                                        "36px",
                                                                                      height:
                                                                                        "auto",
                                                                                      cursor:
                                                                                        "pointer",
                                                                                    }}
                                                                                  />
                                                                                </OverlayTrigger>
                                                                              }
                                                                            </>
                                                                          </td>
                                                                        ) : (
                                                                          <td></td>
                                                                        )}
                                                                        <td className="pe-5">
                                                                          <div className="d-flex align-items-center">
                                                                            <input
                                                                              type="text"
                                                                              class="form-control mx-w-340"
                                                                              value={
                                                                                val?.field_name
                                                                              }
                                                                              readOnly
                                                                            />
                                                                          </div>
                                                                        </td>
                                                                        <td className="pe-5">
                                                                          <div className="d-flex align-items-center">
                                                                            <input
                                                                              type="text"
                                                                              class="form-control mx-w-340"
                                                                              value={
                                                                                foundPatientData?.field_label
                                                                              }
                                                                              onChange={(
                                                                                e
                                                                              ) => {
                                                                                handlePatientLabel(
                                                                                  val?.field_name,
                                                                                  e
                                                                                    .target
                                                                                    .value
                                                                                );
                                                                              }}
                                                                            />
                                                                          </div>
                                                                        </td>
                                                                        <td className="vertical-aling-middle">
                                                                          <Switch
                                                                            checkedChildren="Yes"
                                                                            unCheckedChildren="No"
                                                                            checked={handlePatientSwitchcheck(
                                                                              foundPatientData?.field_name
                                                                            )}
                                                                            className="blue-b"
                                                                            onChange={(
                                                                              e
                                                                            ) => {
                                                                              handlePatientSwitchOnChange(
                                                                                val?.field_name,
                                                                                e
                                                                              );
                                                                            }}
                                                                          />
                                                                        </td>
                                                                      </tr>
                                                                    );
                                                                  }
                                                                )}
                                                              </tbody>
                                                            ) : (
                                                              <>
                                                                <tbody>
                                                                  {visiblePatientRows?.map(
                                                                    (
                                                                      val,
                                                                      ind
                                                                    ) => {
                                                                      const foundPatientData =
                                                                        formControl?.patient_data?.find(
                                                                          (e) =>
                                                                            e?.field_name ===
                                                                            val?.field_name
                                                                        );
                                                                      return (
                                                                        <tr
                                                                          key={
                                                                            ind
                                                                          }
                                                                        >
                                                                          <td
                                                                            className="sm-transparent-input"
                                                                            key={
                                                                              ind
                                                                            }
                                                                          >
                                                                            <input
                                                                              type="text"
                                                                              class="form-control"
                                                                              name={
                                                                                val?.field_name
                                                                              }
                                                                              value={
                                                                                foundPatientData?.display
                                                                              }
                                                                              onChange={(
                                                                                e
                                                                              ) => {
                                                                                handlePatientSort(
                                                                                  val?.field_name,
                                                                                  e
                                                                                    .target
                                                                                    .value !==
                                                                                    ""
                                                                                    ? e
                                                                                        .target
                                                                                        .value
                                                                                    : "0"
                                                                                );
                                                                              }}
                                                                            />
                                                                          </td>
                                                                          {val?.notes ? (
                                                                            <td
                                                                              style={{
                                                                                width:
                                                                                  "30px",
                                                                                height:
                                                                                  "30px",
                                                                                textAlign:
                                                                                  "left",
                                                                                lineHeight:
                                                                                  "35px",
                                                                              }}
                                                                            >
                                                                              <>
                                                                                {
                                                                                  <OverlayTrigger
                                                                                    key={
                                                                                      "top"
                                                                                    }
                                                                                    placement={
                                                                                      "top"
                                                                                    }
                                                                                    overlay={
                                                                                      <Tooltip
                                                                                        id={`tooltip-${"top"}`}
                                                                                      >
                                                                                        <strong>
                                                                                          {
                                                                                            "Formula:"
                                                                                          }
                                                                                        </strong>
                                                                                        &nbsp;
                                                                                        {
                                                                                          val?.notes
                                                                                        }
                                                                                      </Tooltip>
                                                                                    }
                                                                                  >
                                                                                    <img
                                                                                      src={
                                                                                        calcIcon
                                                                                      }
                                                                                      alt=""
                                                                                      style={{
                                                                                        width:
                                                                                          "36px",
                                                                                        height:
                                                                                          "auto",
                                                                                        cursor:
                                                                                          "pointer",
                                                                                      }}
                                                                                    />
                                                                                  </OverlayTrigger>
                                                                                }
                                                                              </>
                                                                            </td>
                                                                          ) : (
                                                                            <td></td>
                                                                          )}
                                                                          <td className="pe-5">
                                                                            <div className="d-flex align-items-center">
                                                                              <input
                                                                                type="text"
                                                                                class="form-control mx-w-340"
                                                                                value={
                                                                                  val?.field_name
                                                                                }
                                                                                readOnly
                                                                              />
                                                                            </div>
                                                                          </td>
                                                                          <td className="pe-5">
                                                                            <div className="d-flex align-items-center">
                                                                              <input
                                                                                type="text"
                                                                                class="form-control mx-w-340"
                                                                                value={
                                                                                  foundPatientData?.field_label
                                                                                }
                                                                                onChange={(
                                                                                  e
                                                                                ) => {
                                                                                  handlePatientLabel(
                                                                                    val?.field_name,
                                                                                    e
                                                                                      .target
                                                                                      .value
                                                                                  );
                                                                                }}
                                                                              />
                                                                            </div>
                                                                          </td>
                                                                          <td className="vertical-aling-middle">
                                                                            <Switch
                                                                              checkedChildren="Yes"
                                                                              unCheckedChildren="No"
                                                                              checked={handlePatientSwitchcheck(
                                                                                foundPatientData?.field_name
                                                                              )}
                                                                              className="blue-b"
                                                                              onChange={(
                                                                                e
                                                                              ) => {
                                                                                handlePatientSwitchOnChange(
                                                                                  val?.field_name,
                                                                                  e
                                                                                );
                                                                              }}
                                                                            />
                                                                          </td>
                                                                        </tr>
                                                                      );
                                                                    }
                                                                  )}
                                                                  {showAllPatient &&
                                                                    hiddenPatientRows?.map(
                                                                      (
                                                                        val,
                                                                        ind
                                                                      ) => {
                                                                        const foundPatientData =
                                                                          formControl?.patient_data?.find(
                                                                            (
                                                                              e
                                                                            ) =>
                                                                              e?.field_name ===
                                                                              val?.field_name
                                                                          );
                                                                        return (
                                                                          <tr
                                                                            key={
                                                                              ind
                                                                            }
                                                                          >
                                                                            <td
                                                                              className="sm-transparent-input"
                                                                              key={
                                                                                ind
                                                                              }
                                                                            >
                                                                              <input
                                                                                type="text"
                                                                                class="form-control"
                                                                                name={
                                                                                  val?.field_name
                                                                                }
                                                                                value={
                                                                                  foundPatientData?.display
                                                                                }
                                                                                onChange={(
                                                                                  e
                                                                                ) => {
                                                                                  handlePatientSort(
                                                                                    val?.field_name,
                                                                                    e
                                                                                      .target
                                                                                      .value !==
                                                                                      ""
                                                                                      ? e
                                                                                          .target
                                                                                          .value
                                                                                      : "0"
                                                                                  );
                                                                                }}
                                                                              />
                                                                            </td>
                                                                            {val?.notes ? (
                                                                              <td
                                                                                style={{
                                                                                  width:
                                                                                    "30px",
                                                                                  height:
                                                                                    "30px",
                                                                                  textAlign:
                                                                                    "left",
                                                                                  lineHeight:
                                                                                    "35px",
                                                                                }}
                                                                              >
                                                                                <>
                                                                                  {
                                                                                    <OverlayTrigger
                                                                                      key={
                                                                                        "top"
                                                                                      }
                                                                                      placement={
                                                                                        "top"
                                                                                      }
                                                                                      overlay={
                                                                                        <Tooltip
                                                                                          id={`tooltip-${"top"}`}
                                                                                        >
                                                                                          <strong>
                                                                                            {
                                                                                              "Formula:"
                                                                                            }
                                                                                          </strong>
                                                                                          &nbsp;
                                                                                          {
                                                                                            val?.notes
                                                                                          }
                                                                                        </Tooltip>
                                                                                      }
                                                                                    >
                                                                                      <img
                                                                                        src={
                                                                                          calcIcon
                                                                                        }
                                                                                        alt=""
                                                                                        style={{
                                                                                          width:
                                                                                            "36px",
                                                                                          height:
                                                                                            "auto",
                                                                                          cursor:
                                                                                            "pointer",
                                                                                        }}
                                                                                      />
                                                                                    </OverlayTrigger>
                                                                                  }
                                                                                </>
                                                                              </td>
                                                                            ) : (
                                                                              <td></td>
                                                                            )}
                                                                            <td className="pe-5">
                                                                              <div className="d-flex align-items-center">
                                                                                <input
                                                                                  type="text"
                                                                                  class="form-control mx-w-340"
                                                                                  value={
                                                                                    val?.field_name
                                                                                  }
                                                                                  readOnly
                                                                                />
                                                                              </div>
                                                                            </td>
                                                                            <td className="pe-5">
                                                                              <div className="d-flex align-items-center">
                                                                                <input
                                                                                  type="text"
                                                                                  class="form-control mx-w-340"
                                                                                  value={
                                                                                    foundPatientData?.field_label
                                                                                  }
                                                                                  onChange={(
                                                                                    e
                                                                                  ) => {
                                                                                    handlePatientLabel(
                                                                                      val?.field_name,
                                                                                      e
                                                                                        .target
                                                                                        .value
                                                                                    );
                                                                                  }}
                                                                                />
                                                                              </div>
                                                                            </td>
                                                                            <td className="vertical-aling-middle">
                                                                              <Switch
                                                                                checkedChildren="Yes"
                                                                                unCheckedChildren="No"
                                                                                checked={handlePatientSwitchcheck(
                                                                                  foundPatientData?.field_name
                                                                                )}
                                                                                className="blue-b"
                                                                                onChange={(
                                                                                  e
                                                                                ) => {
                                                                                  handlePatientSwitchOnChange(
                                                                                    val?.field_name,
                                                                                    e
                                                                                  );
                                                                                }}
                                                                              />
                                                                            </td>
                                                                          </tr>
                                                                        );
                                                                      }
                                                                    )}
                                                                </tbody>
                                                                {hiddenPatientRows?.length >
                                                                  0 && (
                                                                  <tfoot>
                                                                    <tr>
                                                                      <td
                                                                        colSpan="5"
                                                                        className="text-center"
                                                                      >
                                                                        <button
                                                                          type="button"
                                                                          onClick={
                                                                            toggleShowMorePatient
                                                                          }
                                                                          className="btn btn-success"
                                                                        >
                                                                          {showAllPatient
                                                                            ? "Show Less"
                                                                            : "Show More"}
                                                                        </button>
                                                                      </td>
                                                                    </tr>
                                                                  </tfoot>
                                                                )}
                                                              </>
                                                            )}
                                                          </>
                                                        </table>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1">
                                          <Accordion.Header>
                                            Staffing Data
                                          </Accordion.Header>

                                          <Accordion.Body>
                                            <>
                                              <div class="row">
                                                <div className="col-md-12 m-0">
                                                  <hr />
                                                </div>
                                                <div className="col-md-12">
                                                  <div className="row">
                                                    <div className="col-md-12">
                                                      <div class="table-responsive">
                                                        <table class="table">
                                                          <thead>
                                                            <tr>
                                                              <th>
                                                                Show/Hide/Sort
                                                              </th>
                                                              <th></th>
                                                              <th>Key</th>
                                                              <th>Label</th>
                                                              <th>Mandatory</th>
                                                            </tr>
                                                          </thead>
                                                          <>
                                                            {isEmpty(
                                                              categoryId
                                                            ) ? (
                                                              <tbody>
                                                                {formControl?.staffing?.map(
                                                                  (
                                                                    val,
                                                                    ind
                                                                  ) => {
                                                                    const foundStaffingData =
                                                                      formControl?.staffing?.find(
                                                                        (e) =>
                                                                          e?.field_name ===
                                                                          val?.field_name
                                                                      );
                                                                    return (
                                                                      <tr
                                                                        key={
                                                                          ind
                                                                        }
                                                                      >
                                                                        <td
                                                                          className="sm-transparent-input"
                                                                          key={
                                                                            ind
                                                                          }
                                                                        >
                                                                          <input
                                                                            type="text"
                                                                            class="form-control"
                                                                            name={
                                                                              val?.field_name
                                                                            }
                                                                            value={
                                                                              foundStaffingData?.display
                                                                            }
                                                                            disabled={disabledFields.includes(
                                                                              val?.field_name
                                                                            )}
                                                                            onChange={(
                                                                              e
                                                                            ) => {
                                                                              handleStaffingSort(
                                                                                val?.field_name,
                                                                                e
                                                                                  .target
                                                                                  .value !==
                                                                                  ""
                                                                                  ? e
                                                                                      .target
                                                                                      .value
                                                                                  : "0"
                                                                              );
                                                                            }}
                                                                          />
                                                                        </td>
                                                                        {val?.notes ? (
                                                                          <td
                                                                            style={{
                                                                              width:
                                                                                "30px",
                                                                              height:
                                                                                "30px",
                                                                              textAlign:
                                                                                "left",
                                                                              lineHeight:
                                                                                "35px",
                                                                            }}
                                                                          >
                                                                            <>
                                                                              {
                                                                                <OverlayTrigger
                                                                                  key={
                                                                                    "top"
                                                                                  }
                                                                                  placement={
                                                                                    "top"
                                                                                  }
                                                                                  overlay={
                                                                                    <Tooltip
                                                                                      id={`tooltip-${"top"}`}
                                                                                    >
                                                                                      <strong>
                                                                                        {
                                                                                          "Formula:"
                                                                                        }
                                                                                      </strong>
                                                                                      &nbsp;
                                                                                      {
                                                                                        val?.notes
                                                                                      }
                                                                                    </Tooltip>
                                                                                  }
                                                                                >
                                                                                  <img
                                                                                    src={
                                                                                      calcIcon
                                                                                    }
                                                                                    alt=""
                                                                                    style={{
                                                                                      width:
                                                                                        "36px",
                                                                                      height:
                                                                                        "auto",
                                                                                      cursor:
                                                                                        "pointer",
                                                                                    }}
                                                                                  />
                                                                                </OverlayTrigger>
                                                                              }
                                                                            </>
                                                                          </td>
                                                                        ) : (
                                                                          <td></td>
                                                                        )}
                                                                        <td className="pe-5">
                                                                          <div className="d-flex align-items-center">
                                                                            <input
                                                                              type="text"
                                                                              class="form-control mx-w-340"
                                                                              value={
                                                                                val?.field_name
                                                                              }
                                                                              readOnly
                                                                            />
                                                                          </div>
                                                                        </td>
                                                                        <td className="pe-5">
                                                                          <div className="d-flex align-items-center">
                                                                            <input
                                                                              type="text"
                                                                              class="form-control mx-w-340"
                                                                              value={
                                                                                foundStaffingData?.field_label
                                                                              }
                                                                              onChange={(
                                                                                e
                                                                              ) => {
                                                                                handleStaffingLabel(
                                                                                  val?.field_name,
                                                                                  e
                                                                                    .target
                                                                                    .value
                                                                                );
                                                                              }}
                                                                            />
                                                                          </div>
                                                                        </td>
                                                                        <td className="vertical-aling-middle">
                                                                          <Switch
                                                                            checkedChildren="Yes"
                                                                            unCheckedChildren="No"
                                                                            checked={handleStaffingSwitchcheck(
                                                                              foundStaffingData?.field_name
                                                                            )}
                                                                            className="blue-b"
                                                                            onChange={(
                                                                              e
                                                                            ) => {
                                                                              handleStaffingSwitchOnChange(
                                                                                val?.field_name,
                                                                                e
                                                                              );
                                                                            }}
                                                                          />
                                                                        </td>
                                                                      </tr>
                                                                    );
                                                                  }
                                                                )}
                                                              </tbody>
                                                            ) : (
                                                              <>
                                                                <tbody>
                                                                  {visibleStaffingRows?.map(
                                                                    (
                                                                      val,
                                                                      ind
                                                                    ) => {
                                                                      const foundStaffingData =
                                                                        formControl?.staffing?.find(
                                                                          (e) =>
                                                                            e?.field_name ===
                                                                            val?.field_name
                                                                        );
                                                                      return (
                                                                        <tr
                                                                          key={
                                                                            ind
                                                                          }
                                                                        >
                                                                          <td
                                                                            className="sm-transparent-input"
                                                                            key={
                                                                              ind
                                                                            }
                                                                          >
                                                                            <input
                                                                              type="text"
                                                                              class="form-control"
                                                                              name={
                                                                                val?.field_name
                                                                              }
                                                                              value={
                                                                                foundStaffingData?.display
                                                                              }
                                                                              disabled={disabledFields.includes(
                                                                                val?.field_name
                                                                              )}
                                                                              onChange={(
                                                                                e
                                                                              ) => {
                                                                                handleStaffingSort(
                                                                                  val?.field_name,
                                                                                  e
                                                                                    .target
                                                                                    .value !==
                                                                                    ""
                                                                                    ? e
                                                                                        .target
                                                                                        .value
                                                                                    : "0"
                                                                                );
                                                                              }}
                                                                            />
                                                                          </td>
                                                                          {val?.notes ? (
                                                                            <td
                                                                              style={{
                                                                                width:
                                                                                  "30px",
                                                                                height:
                                                                                  "30px",
                                                                                textAlign:
                                                                                  "left",
                                                                                lineHeight:
                                                                                  "35px",
                                                                              }}
                                                                            >
                                                                              <>
                                                                                {
                                                                                  <OverlayTrigger
                                                                                    key={
                                                                                      "top"
                                                                                    }
                                                                                    placement={
                                                                                      "top"
                                                                                    }
                                                                                    overlay={
                                                                                      <Tooltip
                                                                                        id={`tooltip-${"top"}`}
                                                                                      >
                                                                                        <strong>
                                                                                          {
                                                                                            "Formula:"
                                                                                          }
                                                                                        </strong>
                                                                                        &nbsp;
                                                                                        {
                                                                                          val?.notes
                                                                                        }
                                                                                      </Tooltip>
                                                                                    }
                                                                                  >
                                                                                    <img
                                                                                      src={
                                                                                        calcIcon
                                                                                      }
                                                                                      alt=""
                                                                                      style={{
                                                                                        width:
                                                                                          "36px",
                                                                                        height:
                                                                                          "auto",
                                                                                        cursor:
                                                                                          "pointer",
                                                                                      }}
                                                                                    />
                                                                                  </OverlayTrigger>
                                                                                }
                                                                              </>
                                                                            </td>
                                                                          ) : (
                                                                            <td></td>
                                                                          )}
                                                                          <td className="pe-5">
                                                                            <div className="d-flex align-items-center">
                                                                              <input
                                                                                type="text"
                                                                                class="form-control mx-w-340"
                                                                                value={
                                                                                  val?.field_name
                                                                                }
                                                                                readOnly
                                                                              />
                                                                            </div>
                                                                          </td>
                                                                          <td className="pe-5">
                                                                            <div className="d-flex align-items-center">
                                                                              <input
                                                                                type="text"
                                                                                class="form-control mx-w-340"
                                                                                value={
                                                                                  foundStaffingData?.field_label
                                                                                }
                                                                                onChange={(
                                                                                  e
                                                                                ) => {
                                                                                  handleStaffingLabel(
                                                                                    val?.field_name,
                                                                                    e
                                                                                      .target
                                                                                      .value
                                                                                  );
                                                                                }}
                                                                              />
                                                                            </div>
                                                                          </td>
                                                                          <td className="vertical-aling-middle">
                                                                            <Switch
                                                                              checkedChildren="Yes"
                                                                              unCheckedChildren="No"
                                                                              checked={handleStaffingSwitchcheck(
                                                                                foundStaffingData?.field_name
                                                                              )}
                                                                              className="blue-b"
                                                                              onChange={(
                                                                                e
                                                                              ) => {
                                                                                handleStaffingSwitchOnChange(
                                                                                  val?.field_name,
                                                                                  e
                                                                                );
                                                                              }}
                                                                            />
                                                                          </td>
                                                                        </tr>
                                                                      );
                                                                    }
                                                                  )}
                                                                  {showAllStaffing &&
                                                                    hiddenStaffingRows?.map(
                                                                      (
                                                                        val,
                                                                        ind
                                                                      ) => {
                                                                        const foundStaffingData =
                                                                          formControl?.staffing?.find(
                                                                            (
                                                                              e
                                                                            ) =>
                                                                              e?.field_name ===
                                                                              val?.field_name
                                                                          );
                                                                        return (
                                                                          <tr
                                                                            key={
                                                                              ind
                                                                            }
                                                                          >
                                                                            <td
                                                                              className="sm-transparent-input"
                                                                              key={
                                                                                ind
                                                                              }
                                                                            >
                                                                              <input
                                                                                type="text"
                                                                                class="form-control"
                                                                                name={
                                                                                  val?.field_name
                                                                                }
                                                                                value={
                                                                                  foundStaffingData?.display
                                                                                }
                                                                                disabled={disabledFields.includes(
                                                                                  val?.field_name
                                                                                )}
                                                                                onChange={(
                                                                                  e
                                                                                ) => {
                                                                                  handleStaffingSort(
                                                                                    val?.field_name,
                                                                                    e
                                                                                      .target
                                                                                      .value !==
                                                                                      ""
                                                                                      ? e
                                                                                          .target
                                                                                          .value
                                                                                      : "0"
                                                                                  );
                                                                                }}
                                                                              />
                                                                            </td>
                                                                            {val?.notes ? (
                                                                              <td
                                                                                style={{
                                                                                  width:
                                                                                    "30px",
                                                                                  height:
                                                                                    "30px",
                                                                                  textAlign:
                                                                                    "left",
                                                                                  lineHeight:
                                                                                    "35px",
                                                                                }}
                                                                              >
                                                                                <>
                                                                                  {
                                                                                    <OverlayTrigger
                                                                                      key={
                                                                                        "top"
                                                                                      }
                                                                                      placement={
                                                                                        "top"
                                                                                      }
                                                                                      overlay={
                                                                                        <Tooltip
                                                                                          id={`tooltip-${"top"}`}
                                                                                        >
                                                                                          <strong>
                                                                                            {
                                                                                              "Formula:"
                                                                                            }
                                                                                          </strong>
                                                                                          &nbsp;
                                                                                          {
                                                                                            val?.notes
                                                                                          }
                                                                                        </Tooltip>
                                                                                      }
                                                                                    >
                                                                                      <img
                                                                                        src={
                                                                                          calcIcon
                                                                                        }
                                                                                        alt=""
                                                                                        style={{
                                                                                          width:
                                                                                            "36px",
                                                                                          height:
                                                                                            "auto",
                                                                                          cursor:
                                                                                            "pointer",
                                                                                        }}
                                                                                      />
                                                                                    </OverlayTrigger>
                                                                                  }
                                                                                </>
                                                                              </td>
                                                                            ) : (
                                                                              <td></td>
                                                                            )}
                                                                            <td className="pe-5">
                                                                              <div className="d-flex align-items-center">
                                                                                <input
                                                                                  type="text"
                                                                                  class="form-control mx-w-340"
                                                                                  value={
                                                                                    val?.field_name
                                                                                  }
                                                                                  readOnly
                                                                                />
                                                                              </div>
                                                                            </td>
                                                                            <td className="pe-5">
                                                                              <div className="d-flex align-items-center">
                                                                                <input
                                                                                  type="text"
                                                                                  class="form-control mx-w-340"
                                                                                  value={
                                                                                    foundStaffingData?.field_label
                                                                                  }
                                                                                  onChange={(
                                                                                    e
                                                                                  ) => {
                                                                                    handleStaffingLabel(
                                                                                      val?.field_name,
                                                                                      e
                                                                                        .target
                                                                                        .value
                                                                                    );
                                                                                  }}
                                                                                />
                                                                              </div>
                                                                            </td>
                                                                            <td className="vertical-aling-middle">
                                                                              <Switch
                                                                                checkedChildren="Yes"
                                                                                unCheckedChildren="No"
                                                                                checked={handleStaffingSwitchcheck(
                                                                                  foundStaffingData?.field_name
                                                                                )}
                                                                                className="blue-b"
                                                                                onChange={(
                                                                                  e
                                                                                ) => {
                                                                                  handleStaffingSwitchOnChange(
                                                                                    val?.field_name,
                                                                                    e
                                                                                  );
                                                                                }}
                                                                              />
                                                                            </td>
                                                                          </tr>
                                                                        );
                                                                      }
                                                                    )}
                                                                </tbody>
                                                                {hiddenStaffingRows?.length >
                                                                  0 && (
                                                                  <tfoot>
                                                                    <tr>
                                                                      <td
                                                                        colSpan="5"
                                                                        className="text-center"
                                                                      >
                                                                        <button
                                                                          type="button"
                                                                          onClick={
                                                                            toggleShowMoreStaffing
                                                                          }
                                                                          className="btn btn-success"
                                                                        >
                                                                          {showAllStaffing
                                                                            ? "Show Less"
                                                                            : "Show More"}
                                                                        </button>
                                                                      </td>
                                                                    </tr>
                                                                  </tfoot>
                                                                )}
                                                              </>
                                                            )}
                                                          </>
                                                        </table>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2">
                                          <Accordion.Header>
                                            Additional Data Tracking
                                          </Accordion.Header>

                                          <Accordion.Body>
                                            <>
                                              <div class="row">
                                                <div className="col-md-12 m-0">
                                                  <hr />
                                                </div>
                                                <div className="col-md-12">
                                                  <div className="row">
                                                    <div className="col-md-12">
                                                      <div class="table-responsive">
                                                        <table class="table">
                                                          <thead>
                                                            <tr>
                                                              <th>
                                                                Show/Hide/Sort
                                                              </th>
                                                              <th></th>
                                                              <th>Key</th>
                                                              <th>Label</th>
                                                              <th>
                                                                Display Graph
                                                              </th>
                                                              <th>Mandatory</th>
                                                            </tr>
                                                          </thead>
                                                          <>
                                                            {isEmpty(
                                                              categoryId
                                                            ) ? (
                                                              <tbody>
                                                                {formControl?.additional_data_tracking?.map(
                                                                  (
                                                                    val,
                                                                    ind
                                                                  ) => {
                                                                    const foundAdditionalData =
                                                                      formControl?.additional_data_tracking?.find(
                                                                        (e) =>
                                                                          e?.field_name ===
                                                                          val?.field_name
                                                                      );
                                                                    return (
                                                                      <tr
                                                                        key={
                                                                          ind
                                                                        }
                                                                      >
                                                                        <td
                                                                          className="sm-transparent-input"
                                                                          key={
                                                                            ind
                                                                          }
                                                                        >
                                                                          <input
                                                                            type="text"
                                                                            class="form-control"
                                                                            name={
                                                                              val?.field_name
                                                                            }
                                                                            value={
                                                                              foundAdditionalData?.display
                                                                            }
                                                                            onChange={(
                                                                              e
                                                                            ) => {
                                                                              handleAddDataSort(
                                                                                val?.field_name,
                                                                                e
                                                                                  .target
                                                                                  .value !==
                                                                                  ""
                                                                                  ? e
                                                                                      .target
                                                                                      .value
                                                                                  : "0"
                                                                              );
                                                                            }}
                                                                          />
                                                                        </td>
                                                                        {val?.notes ? (
                                                                          <td
                                                                            style={{
                                                                              width:
                                                                                "30px",
                                                                              height:
                                                                                "30px",
                                                                              textAlign:
                                                                                "left",
                                                                              lineHeight:
                                                                                "35px",
                                                                            }}
                                                                          >
                                                                            <>
                                                                              {
                                                                                <OverlayTrigger
                                                                                  key={
                                                                                    "top"
                                                                                  }
                                                                                  placement={
                                                                                    "top"
                                                                                  }
                                                                                  overlay={
                                                                                    <Tooltip
                                                                                      id={`tooltip-${"top"}`}
                                                                                    >
                                                                                      <strong>
                                                                                        {
                                                                                          "Formula:"
                                                                                        }
                                                                                      </strong>
                                                                                      &nbsp;
                                                                                      {
                                                                                        val?.notes
                                                                                      }
                                                                                    </Tooltip>
                                                                                  }
                                                                                >
                                                                                  <img
                                                                                    src={
                                                                                      calcIcon
                                                                                    }
                                                                                    alt=""
                                                                                    style={{
                                                                                      width:
                                                                                        "36px",
                                                                                      height:
                                                                                        "auto",
                                                                                      cursor:
                                                                                        "pointer",
                                                                                    }}
                                                                                  />
                                                                                </OverlayTrigger>
                                                                              }
                                                                            </>
                                                                          </td>
                                                                        ) : (
                                                                          <td></td>
                                                                        )}
                                                                        <td className="pe-5">
                                                                          <div className="d-flex align-items-center">
                                                                            <input
                                                                              type="text"
                                                                              class="form-control mx-w-340"
                                                                              value={
                                                                                val?.field_name
                                                                              }
                                                                              readOnly
                                                                            />
                                                                          </div>
                                                                        </td>
                                                                        <td className="pe-5">
                                                                          <div className="d-flex align-items-center">
                                                                            <input
                                                                              type="text"
                                                                              class="form-control mx-w-340"
                                                                              value={
                                                                                foundAdditionalData?.field_label
                                                                              }
                                                                              onChange={(
                                                                                e
                                                                              ) => {
                                                                                handleAddDataLabel(
                                                                                  val?.field_name,
                                                                                  e
                                                                                    .target
                                                                                    .value
                                                                                );
                                                                              }}
                                                                            />
                                                                          </div>
                                                                        </td>
                                                                        <td className="vertical-aling-middle">
                                                                          <Switch
                                                                            checkedChildren="Yes"
                                                                            unCheckedChildren="No"
                                                                            checked={handleDisplayGraphSwitchcheck(
                                                                              foundAdditionalData?.field_name
                                                                            )}
                                                                            className="blue-b"
                                                                            onChange={(
                                                                              e
                                                                            ) => {
                                                                              handleAddDataGraph(
                                                                                val?.field_name,
                                                                                e
                                                                                  ? "1"
                                                                                  : "0"
                                                                              );
                                                                            }}
                                                                          />
                                                                        </td>
                                                                        <td className="vertical-aling-middle">
                                                                          <Switch
                                                                            checkedChildren="Yes"
                                                                            unCheckedChildren="No"
                                                                            checked={handleAddDataSwitchcheck(
                                                                              foundAdditionalData?.field_name
                                                                            )}
                                                                            className="blue-b"
                                                                            onChange={(
                                                                              e
                                                                            ) => {
                                                                              handleAddDataSwitchOnChange(
                                                                                val?.field_name,
                                                                                e
                                                                              );
                                                                            }}
                                                                          />
                                                                        </td>
                                                                      </tr>
                                                                    );
                                                                  }
                                                                )}
                                                              </tbody>
                                                            ) : (
                                                              <>
                                                                <tbody>
                                                                  {visibleAdditionalRows?.map(
                                                                    (
                                                                      val,
                                                                      ind
                                                                    ) => {
                                                                      const foundAdditionalData =
                                                                        formControl?.additional_data_tracking?.find(
                                                                          (e) =>
                                                                            e?.field_name ===
                                                                            val?.field_name
                                                                        );
                                                                      return (
                                                                        <tr
                                                                          key={
                                                                            ind
                                                                          }
                                                                        >
                                                                          <td
                                                                            className="sm-transparent-input"
                                                                            key={
                                                                              ind
                                                                            }
                                                                          >
                                                                            <input
                                                                              type="text"
                                                                              class="form-control"
                                                                              name={
                                                                                val?.field_name
                                                                              }
                                                                              value={
                                                                                foundAdditionalData?.display
                                                                              }
                                                                              onChange={(
                                                                                e
                                                                              ) => {
                                                                                handleAddDataSort(
                                                                                  val?.field_name,
                                                                                  e
                                                                                    .target
                                                                                    .value !==
                                                                                    ""
                                                                                    ? e
                                                                                        .target
                                                                                        .value
                                                                                    : "0"
                                                                                );
                                                                              }}
                                                                            />
                                                                          </td>
                                                                          {val?.notes ? (
                                                                            <td
                                                                              style={{
                                                                                width:
                                                                                  "30px",
                                                                                height:
                                                                                  "30px",
                                                                                textAlign:
                                                                                  "left",
                                                                                lineHeight:
                                                                                  "35px",
                                                                              }}
                                                                            >
                                                                              <>
                                                                                {
                                                                                  <OverlayTrigger
                                                                                    key={
                                                                                      "top"
                                                                                    }
                                                                                    placement={
                                                                                      "top"
                                                                                    }
                                                                                    overlay={
                                                                                      <Tooltip
                                                                                        id={`tooltip-${"top"}`}
                                                                                      >
                                                                                        <strong>
                                                                                          {
                                                                                            "Formula:"
                                                                                          }
                                                                                        </strong>
                                                                                        &nbsp;
                                                                                        {
                                                                                          val?.notes
                                                                                        }
                                                                                      </Tooltip>
                                                                                    }
                                                                                  >
                                                                                    <img
                                                                                      src={
                                                                                        calcIcon
                                                                                      }
                                                                                      alt=""
                                                                                      style={{
                                                                                        width:
                                                                                          "36px",
                                                                                        height:
                                                                                          "auto",
                                                                                        cursor:
                                                                                          "pointer",
                                                                                      }}
                                                                                    />
                                                                                  </OverlayTrigger>
                                                                                }
                                                                              </>
                                                                            </td>
                                                                          ) : (
                                                                            <td></td>
                                                                          )}
                                                                          <td className="pe-5">
                                                                            <div className="d-flex align-items-center">
                                                                              <input
                                                                                type="text"
                                                                                class="form-control mx-w-340"
                                                                                value={
                                                                                  val?.field_name
                                                                                }
                                                                                readOnly
                                                                              />
                                                                            </div>
                                                                          </td>
                                                                          <td className="pe-5">
                                                                            <div className="d-flex align-items-center">
                                                                              <input
                                                                                type="text"
                                                                                class="form-control mx-w-340"
                                                                                value={
                                                                                  foundAdditionalData?.field_label
                                                                                }
                                                                                onChange={(
                                                                                  e
                                                                                ) => {
                                                                                  handleAddDataLabel(
                                                                                    val?.field_name,
                                                                                    e
                                                                                      .target
                                                                                      .value
                                                                                  );
                                                                                }}
                                                                              />
                                                                            </div>
                                                                          </td>
                                                                          <td className="vertical-aling-middle">
                                                                            <Switch
                                                                              checkedChildren="Yes"
                                                                              unCheckedChildren="No"
                                                                              checked={handleDisplayGraphSwitchcheck(
                                                                                foundAdditionalData?.field_name
                                                                              )}
                                                                              className="blue-b"
                                                                              onChange={(
                                                                                e
                                                                              ) => {
                                                                                handleAddDataGraph(
                                                                                  val?.field_name,
                                                                                  e
                                                                                    ? "1"
                                                                                    : "0"
                                                                                );
                                                                              }}
                                                                            />
                                                                          </td>
                                                                          <td className="vertical-aling-middle">
                                                                            <Switch
                                                                              checkedChildren="Yes"
                                                                              unCheckedChildren="No"
                                                                              checked={handleAddDataSwitchcheck(
                                                                                foundAdditionalData?.field_name
                                                                              )}
                                                                              className="blue-b"
                                                                              onChange={(
                                                                                e
                                                                              ) => {
                                                                                handleAddDataSwitchOnChange(
                                                                                  val?.field_name,
                                                                                  e
                                                                                );
                                                                              }}
                                                                            />
                                                                          </td>
                                                                        </tr>
                                                                      );
                                                                    }
                                                                  )}
                                                                  {showAllAdditional &&
                                                                    hiddenAdditionalRows?.map(
                                                                      (
                                                                        val,
                                                                        ind
                                                                      ) => {
                                                                        const foundAdditionalData =
                                                                          formControl?.additional_data_tracking?.find(
                                                                            (
                                                                              e
                                                                            ) =>
                                                                              e?.field_name ===
                                                                              val?.field_name
                                                                          );
                                                                        return (
                                                                          <tr
                                                                            key={
                                                                              ind
                                                                            }
                                                                          >
                                                                            <td
                                                                              className="sm-transparent-input"
                                                                              key={
                                                                                ind
                                                                              }
                                                                            >
                                                                              <input
                                                                                type="text"
                                                                                class="form-control"
                                                                                name={
                                                                                  val?.field_name
                                                                                }
                                                                                value={
                                                                                  foundAdditionalData?.display
                                                                                }
                                                                                onChange={(
                                                                                  e
                                                                                ) => {
                                                                                  handleAddDataSort(
                                                                                    val?.field_name,
                                                                                    e
                                                                                      .target
                                                                                      .value !==
                                                                                      ""
                                                                                      ? e
                                                                                          .target
                                                                                          .value
                                                                                      : "0"
                                                                                  );
                                                                                }}
                                                                              />
                                                                            </td>
                                                                            {val?.notes ? (
                                                                              <td
                                                                                style={{
                                                                                  width:
                                                                                    "30px",
                                                                                  height:
                                                                                    "30px",
                                                                                  textAlign:
                                                                                    "left",
                                                                                  lineHeight:
                                                                                    "35px",
                                                                                }}
                                                                              >
                                                                                <>
                                                                                  {
                                                                                    <OverlayTrigger
                                                                                      key={
                                                                                        "top"
                                                                                      }
                                                                                      placement={
                                                                                        "top"
                                                                                      }
                                                                                      overlay={
                                                                                        <Tooltip
                                                                                          id={`tooltip-${"top"}`}
                                                                                        >
                                                                                          <strong>
                                                                                            {
                                                                                              "Formula:"
                                                                                            }
                                                                                          </strong>
                                                                                          &nbsp;
                                                                                          {
                                                                                            val?.notes
                                                                                          }
                                                                                        </Tooltip>
                                                                                      }
                                                                                    >
                                                                                      <img
                                                                                        src={
                                                                                          calcIcon
                                                                                        }
                                                                                        alt=""
                                                                                        style={{
                                                                                          width:
                                                                                            "36px",
                                                                                          height:
                                                                                            "auto",
                                                                                          cursor:
                                                                                            "pointer",
                                                                                        }}
                                                                                      />
                                                                                    </OverlayTrigger>
                                                                                  }
                                                                                </>
                                                                              </td>
                                                                            ) : (
                                                                              <td></td>
                                                                            )}
                                                                            <td className="pe-5">
                                                                              <div className="d-flex align-items-center">
                                                                                <input
                                                                                  type="text"
                                                                                  class="form-control mx-w-340"
                                                                                  value={
                                                                                    val?.field_name
                                                                                  }
                                                                                  readOnly
                                                                                />
                                                                              </div>
                                                                            </td>
                                                                            <td className="pe-5">
                                                                              <div className="d-flex align-items-center">
                                                                                <input
                                                                                  type="text"
                                                                                  class="form-control mx-w-340"
                                                                                  value={
                                                                                    foundAdditionalData?.field_label
                                                                                  }
                                                                                  onChange={(
                                                                                    e
                                                                                  ) => {
                                                                                    handleAddDataLabel(
                                                                                      val?.field_name,
                                                                                      e
                                                                                        .target
                                                                                        .value
                                                                                    );
                                                                                  }}
                                                                                />
                                                                              </div>
                                                                            </td>
                                                                            <td className="vertical-aling-middle">
                                                                              <Switch
                                                                                checkedChildren="Yes"
                                                                                unCheckedChildren="No"
                                                                                checked={handleDisplayGraphSwitchcheck(
                                                                                  foundAdditionalData?.field_name
                                                                                )}
                                                                                className="blue-b"
                                                                                onChange={(
                                                                                  e
                                                                                ) => {
                                                                                  handleAddDataGraph(
                                                                                    val?.field_name,
                                                                                    e
                                                                                      ? "1"
                                                                                      : "0"
                                                                                  );
                                                                                }}
                                                                              />
                                                                            </td>
                                                                            <td className="vertical-aling-middle">
                                                                              <Switch
                                                                                checkedChildren="Yes"
                                                                                unCheckedChildren="No"
                                                                                checked={handleAddDataSwitchcheck(
                                                                                  foundAdditionalData?.field_name
                                                                                )}
                                                                                className="blue-b"
                                                                                onChange={(
                                                                                  e
                                                                                ) => {
                                                                                  handleAddDataSwitchOnChange(
                                                                                    val?.field_name,
                                                                                    e
                                                                                  );
                                                                                }}
                                                                              />
                                                                            </td>
                                                                          </tr>
                                                                        );
                                                                      }
                                                                    )}
                                                                </tbody>
                                                                {hiddenAdditionalRows?.length >
                                                                  0 && (
                                                                  <tfoot>
                                                                    <tr>
                                                                      <td
                                                                        colSpan="8"
                                                                        className="text-center"
                                                                      >
                                                                        <button
                                                                          type="button"
                                                                          onClick={
                                                                            toggleShowMoreAdditional
                                                                          }
                                                                          className="btn btn-success"
                                                                        >
                                                                          {showAllAdditional
                                                                            ? "Show Less"
                                                                            : "Show More"}
                                                                        </button>
                                                                      </td>
                                                                    </tr>
                                                                  </tfoot>
                                                                )}
                                                              </>
                                                            )}
                                                          </>
                                                        </table>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="3">
                                          <Accordion.Header>
                                            Realtime Formula
                                          </Accordion.Header>
                                          <Accordion.Body>
                                            <div class="row">
                                              <div className="col-md-12 m-0">
                                                <hr />
                                              </div>
                                              <div className="col-md-12">
                                                <div className="row">
                                                  {/* <div className="col-md-12 editor-styles">
                                                    <div
                                                      dangerouslySetInnerHTML={{
                                                        __html: realtimeFormula,
                                                      }}
                                                    />
                                                    
                                                  </div> */}
                                                  <div className="col-md-12 editor-styles">
                                                    {Object.keys(
                                                      realtimeFormulaHeaders
                                                    )?.map((e) => (
                                                      <div
                                                        key={e}
                                                        style={{
                                                          lineHeight: "40px",
                                                          fontSize: "1rem",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontWeight: "500",
                                                            fontSize: "1.1rem",
                                                          }}
                                                        >
                                                          {e}&nbsp;:&nbsp;
                                                        </span>

                                                        {
                                                          realtimeFormulaHeaders[
                                                            e
                                                          ]
                                                        }
                                                      </div>
                                                    ))}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </Accordion.Body>
                                        </Accordion.Item>
                                      </Accordion>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                          <Form.Group className="col-md-12 mb-3 d-flex justify-content-end ll">
                            {/* <Button variant="primary" type="submit">
                              Reset
                            </Button> */}
                            <Button
                              className="ms-3"
                              variant="success"
                              type="submit"
                              disabled={isLoading}
                            >
                              {isLoading ? "Saving..." : "Save"}
                            </Button>
                          </Form.Group>
                        </div>
                      </div>{" "}
                    </FormikForm>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Formik>
    </>
  );
}

export default AddCNewCostCenter;
