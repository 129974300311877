import { useContext } from "react";
import Chart from "react-apexcharts";
import ContentLoader from "react-content-loader";
import { ThemeContext } from "../../../providers/ThemeProvider";


import moment from "moment";

const MyLoader = ({ themeMode }) => (
  <ContentLoader viewBox="0 0 100 25"
    backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
    foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
  >
    {/* Only SVG shapes */}
    <rect x="0" y="1" rx="2" ry="2" width="100" height="24" />
  </ContentLoader>
);

function LineChart({
  admitlinechartat,
  admitlinechartseries,
  isLoader,
  color,
  title,
  data,
  minData,
  maxMaxData,
}) {
  const [themeMode, setTheme] = useContext(ThemeContext);
  var options = {
    chart: {
      // height: 90,
      type: "line",
      parentHeightOffset: 0,
      dropShadow: {
        enabled: false,
        color: "#000",   
        top: 1,
        left: 7,
        blur: 10,
        opacity: 0.2,
      },
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false, // Disable zooming behavior
      },
    },
    colors: [color],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    grid: {
      borderColor: themeMode.theme === "DARK" ? "#FFF" : "#DDD",
      row: {
        colors: [themeMode.theme === "DARK" ? "#555" : "#f3f3f3", "transparent"],
        opacity: 0.0,
      },
      padding: {
        left: 15,
        right: 0,
      },
    },
    markers: {
      size: 6,
      hover: {
        size: 6,
        sizeOffset: 3,
      },
    },
    tooltip: {
      x: {
        show: false,
      },
    },
    xaxis: {
      categories: admitlinechartat,
      title: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
      labels: {
        style: {
          colors: themeMode.theme === "DARK" ? "white" : "black"
        }
      }
    },
    yaxis: {
      show: false,
      color: themeMode.theme === "DARK" ? "white" : "black",
    },
    legend: {
      show: false,
      position: "top",
      horizontalAlign: "left",
      floating: true,
      offsetY: 25,
      offsetX: 5,
      show: false,
    },
  };
  return (
    <div>
      {!isLoader ? (
        <div className="bg-white pb-0 ps-2 pe-2">
          <div class="f-12 text-start mb-minus-10" style={{ color }}>
            {title}
          </div>
          <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "left", alignItems: "center" }}>
            <div
              style={{
                height: "58px",
                // backgroundColor: "aqua",
              //  color: "black",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                // borderRight: "1px solid lightgray",
                marginRight: "-8px", fontSize: "10px",
                paddingRight: "3px"
              }}
            >
              <div style={{ fontWeight: "500" }}>{maxMaxData === 0 ? "" : maxMaxData}</div>
              <div style={{ fontWeight: "500" }}>{minData}</div>
            </div>
            <div style={{ width: "100%" }}>
              <Chart
                options={options}
                series={[
                  {
                    name: title,
                    data: admitlinechartseries,
                  },
                ]}
                type="line"
                width={"100%"}
                height={135}
              />
            </div>
          </div>
        </div>
      ) : (
          <MyLoader themeMode={themeMode} />
      )}
    </div>
  );
}

export default LineChart;
