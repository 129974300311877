import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useContext } from 'react';
import ContentLoader from 'react-content-loader';
import { ThemeContext } from "../../providers/ThemeProvider";


const LinkLoader = ({ themeMode }) => (
    <div style={{ width: 40, height: 32 }}>
        <ContentLoader viewBox="0 0 80 64"
            backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
            foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
        >
            {/* Only SVG shapes */}
            <rect x="0" y="0" rx="0" ry="0" width="100" height="100" />
        </ContentLoader>
    </div>
)

const Loadertwo = () => (
    <div style={{ width: 300, height: 60 }}>
        <ContentLoader viewBox="0 0 100 50">
            {/* Only SVG shapes */}
            <rect x="4" y="4" rx="0" ry="0" width="48" height="4" />
            <rect x="56" y="4" rx="0" ry="0" width="48" height="4" />
            <rect x="4" y="12" rx="0" ry="0" width="48" height="4" />
            <rect x="56" y="12" rx="0" ry="0" width="48" height="4" />
        </ContentLoader>
    </div>
)

function DashboardCounter({ title, icon, color, box, data, isLoader, isshow, issearch }) {
    const [themeMode, setTheme] = useContext(ThemeContext);
    
    let total = null;
    if (box == 'boxone') {
        var { availble_beds, ...rest } = data ? data : {};
        total = availble_beds;
    } else if (box == 'boxtwo') {
        var { total_compliance, ...rest } = data ? data : {};
        total = total_compliance;
    } else if (box == 'boxthree') {
        var { total_variance, ...rest } = data ? data : {};
        total = total_variance;
    }

    const txtcolor = 'd-block fw-600 big-num f-25 lh-1 ' + color;

    const styleEl = !issearch && box === 'boxtwo' ? {flex : '1 11.65%'} : null;

    return (
        <div class="col" style={styleEl}>
            <div class="position-relative  h-100 box-h col-bg">
                <div class="d-flex align-items-center">
                    <div class="i d-flex align-items-center"><img src={icon} alt="" class="img-fluid" /> &nbsp;
                        {
                            !isLoader ? <span className={'fw-600 big-num  f-25 lh-1 ' + color}>{total}</span> : <LinkLoader themeMode={themeMode} />
                        }
                    </div>
                    <div class="ps-2"><div class="pe-0 primary-font"><span className={'f-16 color-black fw-600 ' + color}>{title}</span>&nbsp; {isshow ? <i class="fa fa-angle-down" aria-hidden="true"></i> : null }</div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default DashboardCounter;