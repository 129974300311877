import "./index.css";
import { useEffect, useContext, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DashboardCounter from "../../components/DashboardCounter/index";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import PageLayout from "../../layouts/PageLayout";
import Ico1 from "../../assets/img/edit.png";
import Ico2 from "../../assets/img/delete.png";
import Ico3 from "../../assets/img/s.png";
import Ico4 from "../../assets/img/d.png";
import Clap from "../../assets/img/clap.png";
import Diconetwo from "../../assets/img/real_time.svg";
import Diconethree from "../../assets/img/active_daily_mgmt.svg";
import Iconone from "../../assets/img/beds.png";
import Icontwo from "../../assets/img/compliance.png";
import Iconthree from "../../assets/img/variance.png";
import Iconfour from "../../assets/img/hpuoc.png";
import Ico22 from "../../assets/img/info.png";
import Ico33 from "../../assets/img/warning.png";
import Ico44 from "../../assets/img/critical.png";
import { get } from "lodash";
import { API_CALL_URL_PRIVATE } from "../../constants";
import axios from "../../axios";
import { DOMAIN_URL } from "../../constants/compensate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactPaginate from "react-paginate";
import { setLoadingPage } from "../../store/actions";

function Applications(props) {
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem("psAdmin"));
  const customer_code = useSelector((state) => state.compensate.customerCode);
  const loading_page = useSelector((state) => state.compensate.LoadingPage);
  console.log(customer_code, "customercode");
  const [dashboardData, setDashboardData] = useState([]);
  const [customerData, setCustomerData] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [pageLimit, setPageLimit] = useState(50);
  const [totalPages, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [hasPrev, setHasPrev] = useState(false);
  const [hasNext, setHasNext] = useState(false);
  const [sortKey, setSortKey] = useState("");
  const [isInitialData, setISInitialData] = useState(false);
  const [sortOrder, setSortOrder] = useState(1);
  const [searchTempKeyword, setSearchTempKeyword] = useState("");
  const boxdata = props.data;
  const isLoader = props.isLoader;
  const { issearch } = props;
  const [userDatas, setUserDatas] = useState(get(userData, "data", []));
  const [pageCounter, setPageCounter] = useState("");

  const getDate = (e) => {
    const inputDate = new Date(e);
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, "0");
    const day = String(inputDate.getDate()).padStart(2, "0");
    return `${month}/${day}/${year}`;
  };

  const renderElements = () => {
    return Array.from({ length: totalPages }, (_, index) => (
      <span
        key={index + 1}
        className={
          index + 1 === currentPage
            ? "paginate_button current"
            : "paginate_button"
        }
        aria-controls="example"
        role="link"
        aria-current="page"
        data-dt-idx="0"
        tabindex="0"
        onClick={() => {
          setCurrentPage(index + 1);
        }}
      >
        {index + 1}
      </span>
    ));
  };

  // useEffect(() => {
  //   const getCustomerDetailList = async () => {
  //     try {
  //       const resp = await axios.post(
  //         `${API_CALL_URL_PRIVATE}/facility/facilityList`,
  //         {
  //           customer_code: parseInt(customer_code),
  //           searchKeyword: searchKeyword,
  //           limit: pageLimit,
  //           page: currentPage,
  //           sort_column: sortKey,
  //           sort_order: sortOrder,
  //         }
  //       );

  //       const Data = resp?.data?.data?.data;
  //       setCustomerData(Data);
  //       setPageLimit(Data?.limit);
  //       setTotalPages(Data?.totalPages);
  //       setCurrentPage(Data?.page);
  //       setHasNext(Data?.hasNextPage);
  //       setHasPrev(Data?.hasPrevPage);
  //       setPageCounter(Data?.pagingCounter);
  //       setISInitialData(true);
  //       dispatch(setLoadingPage(false));
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  //   getCustomerDetailList();
  // }, [searchKeyword, pageLimit, currentPage, sortOrder, customer_code]);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  return (
    <>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="page-header">
            <h3 className="page-title">
              <a href="#" className="badge badge-dark"></a>
            </h3>
            <div className></div>
          </div>
          <div className="row">
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between mb-4">
                    <h4 className="card-title">Form Control</h4>

                    <Link
                      className="boxlink"
                      to="/admin/form-control/add-control"
                    >
                      {" "}
                      <button type="button" class="btn btn-success">
                        Add Control
                      </button>
                    </Link>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div class="dataTables_length" id="example_length">
                      <label>
                        Show &nbsp;
                        <select
                          name="example_length"
                          aria-controls="example"
                          class=""
                          value={pageLimit}
                          onChange={(e) => {
                            setPageLimit(e.target.value);
                          }}
                        >
                          <option value="5">5</option>
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>{" "}
                        &nbsp;entries
                      </label>
                    </div>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault(); // Prevent the default form submission behavior
                        setSearchKeyword(searchTempKeyword);
                      }}
                    >
                      <div
                        id="example_filter"
                        class="dataTables_filter d-flex align-items-center justify-content-center "
                      >
                        <label>
                          Search: &nbsp;
                          <input
                            type="text"
                            class=""
                            placeholder=""
                            aria-controls="example"
                            onChange={(e) => {
                              setSearchTempKeyword(e.target.value);
                            }}
                            // onKeyDown={(e) => {
                            //   if (e.key === "Enter") {
                            //     setSearchKeyword(e.target.value);
                            //   }
                            // }}
                          />
                        </label>

                        <div
                          style={{
                            border: "1px solid rgba(255,255,255,0.5)",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "9px",
                            marginBottom: "7px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setSearchKeyword(searchTempKeyword);
                          }}
                        >
                          <i class="ph ph-magnifying-glass"></i>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="table-responsive">
                    <table className="table filter-table">
                      <thead>
                        <tr>
                          <th>No.</th>
                          <th
                            onClick={() => {
                              setSortKey("customer_code");
                              setSortOrder(sortOrder === 1 ? -1 : 1);
                            }}
                          >
                            Cost Center Number
                          </th>
                          <th
                            onClick={() => {
                              setSortKey("customer_code");
                              setSortOrder(sortOrder === 1 ? -1 : 1);
                            }}
                          >
                            Cost Center Name
                          </th>
                          <th
                            onClick={() => {
                              setSortKey("customer_code");
                              setSortOrder(sortOrder === 1 ? -1 : 1);
                            }}
                          >
                            Updated At
                          </th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1.</td>
                          <td>70907</td>
                          <td>LIVER BOS-BW</td>
                          <td>{getDate("2023-12-10T17:16:53.734Z")}</td>

                          {/* <td>
                              <label
                                className={
                                  Ele?.status === 1
                                    ? "text-success"
                                    : "text-danger"
                                }
                              >
                                {Ele?.status === 1 ? "Active" : "Inactive"}
                              </label>
                            </td> */}
                          <td>
                            <label className="edit">
                              <Link
                                className="boxlink"
                                to={`/admin/form-control/add-control/`}
                              >
                                <img src={Ico1} className="w-a" />
                              </Link>
                            </label>{" "}
                            &nbsp;
                            <label className="edit">
                              <img src={Ico2} className="w-a" />
                            </label>
                          </td>
                        </tr>
                        <tr>
                          <td>2.</td>
                          <td>71896</td>
                          <td>PORTOLA VALLEY - OPHTHALMOLOGY</td>
                          <td>{getDate("2023-12-11T17:03:37.014Z")}</td>

                          {/* <td>
                              <label
                                className={
                                  Ele?.status === 1
                                    ? "text-success"
                                    : "text-danger"
                                }
                              >
                                {Ele?.status === 1 ? "Active" : "Inactive"}
                              </label>
                            </td> */}
                          <td>
                            <label className="edit">
                              <Link
                                className="boxlink"
                                to={`/admin/form-control/add-control/`}
                              >
                                <img src={Ico1} className="w-a" />
                              </Link>
                            </label>{" "}
                            &nbsp;
                            <label className="edit">
                              <img src={Ico2} className="w-a" />
                            </label>
                          </td>
                        </tr>
                        <tr>
                          <td>3.</td>
                          <td>59647</td>
                          <td>Radiology</td>
                          <td>{getDate("2023-12-06T08:16:48.611Z")}</td>

                          {/* <td>
                              <label
                                className={
                                  Ele?.status === 1
                                    ? "text-success"
                                    : "text-danger"
                                }
                              >
                                {Ele?.status === 1 ? "Active" : "Inactive"}
                              </label>
                            </td> */}
                          <td>
                            <label className="edit">
                              <Link
                                className="boxlink"
                                to={`/admin/form-control/add-control/`}
                              >
                                <img src={Ico1} className="w-a" />
                              </Link>
                            </label>{" "}
                            &nbsp;
                            <label className="edit">
                              <img src={Ico2} className="w-a" />
                            </label>
                          </td>
                        </tr>
                        <tr>
                          <td>4.</td>
                          <td>95426</td>
                          <td>Echocardiography</td>
                          <td>{getDate("2023-12-06T08:16:48.611Z")}</td>

                          {/* <td>
                              <label
                                className={
                                  Ele?.status === 1
                                    ? "text-success"
                                    : "text-danger"
                                }
                              >
                                {Ele?.status === 1 ? "Active" : "Inactive"}
                              </label>
                            </td> */}
                          <td>
                            <label className="edit">
                              <Link
                                className="boxlink"
                                to={`/admin/form-control/add-control/`}
                              >
                                <img src={Ico1} className="w-a" />
                              </Link>
                            </label>{" "}
                            &nbsp;
                            <label className="edit">
                              <img src={Ico2} className="w-a" />
                            </label>
                          </td>
                        </tr>
                        <tr>
                          <td>5.</td>
                          <td>10001</td>
                          <td>3 West</td>
                          <td>{getDate("2023-12-06T08:16:48.611Z")}</td>

                          {/* <td>
                              <label
                                className={
                                  Ele?.status === 1
                                    ? "text-success"
                                    : "text-danger"
                                }
                              >
                                {Ele?.status === 1 ? "Active" : "Inactive"}
                              </label>
                            </td> */}
                          <td>
                            <label className="edit">
                              <Link
                                className="boxlink"
                                to={`/admin/form-control/add-control/`}
                              >
                                <img src={Ico1} className="w-a" />
                              </Link>
                            </label>
                            {/* {" "}
                            &nbsp;
                            <label className="edit">
                              <img src={Ico2} className="w-a" />
                            </label> */}
                          </td>
                        </tr>

                        {/* <tr>
                        <td>1</td>
                        <td>0892345</td>
                        <td>Henry Klein</td>
                        <td>henryklein@gmail.com</td>
                        <td>9897205046</td>
                        <td>Doctor</td>
                        <td>
                          <label className="text-danger">Inactive</label>
                        </td>
                        <td>
                          <label className="edit">
                            <Link
                              className="boxlink"
                              to={`/admin/customer/edit-customer`}
                            >
                              <img src={Ico1} className="w-a" />
                            </Link>
                          </label>{" "}
                          &nbsp;
                          <label className="edit">
                            <img src={Ico2} className="w-a" />
                          </label>
                        </td>
                      </tr> */}
                      </tbody>
                    </table>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div
                      class="dataTables_info"
                      id="example_info"
                      role="status"
                      aria-live="polite"
                    >
                      Showing {pageCounter} to {pageCounter + pageLimit - 1} of
                      total {customerData?.totalDocs} entries
                    </div>
                    <div
                      class="dataTables_paginate paging_simple_numbers"
                      id="example_paginate"
                    >
                      <span>
                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          pageCount={customerData?.totalPages}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination"}
                          previousLinkClassName={"paginationLink"}
                          nextLinkClassName={"paginationLink"}
                          disabledClassName={"paginationDisabled"}
                          activeClassName={"paginationActive"}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Applications;
