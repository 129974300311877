import { useState } from "react";
import * as TL from "../../Styled/Todoliststyle";

import TodoItems from "./TodoItem";

const Todolist = () => {
    const [task, setTask] = useState();
    const [dynnamicContent, setDynamicContent] = useState(["Pick up the boys from the school"]);

    const addTask = () => {
        setDynamicContent((prev) => [...prev, task]);
        setTask("");
    };


    const deleteItem = (id) => {
        setDynamicContent((prev) => {
            return prev.filter((elem, index) => {
                return index !== id;
            })
        })
    };

    return (
        <>
            <TL.Parent>
                <TL.heading>Todo List</TL.heading>
                <TL.Inputsection>
                    <TL.Input
                        type="text"
                        value={task}
                        onChange={(e) => setTask(e.target.value)}
                        placeholder="Enter task.."
                    />
                    <TL.Add onClick={addTask}>Add</TL.Add>
                </TL.Inputsection>
                <TL.Listbox id="listbox">
                    {dynnamicContent.map((elem, index) => {
                        return (
                            <TodoItems
                                text={elem}
                                key={index}
                                id={index}
                                onSelect={deleteItem}
                            />
                        );
                    })}
                </TL.Listbox>
            </TL.Parent>
        </>
    );
};

export default Todolist;
