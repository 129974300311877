import "./index.css";
import {
  useEffect,
  useContext,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import { get } from "lodash";
import { API_CALL_URL_PRIVATE } from "../../constants";
import axios from "../../axios";
import { DOMAIN_URL } from "../../constants/compensate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactPaginate from "react-paginate";
import { setLoadingPage, setNavigate } from "../../store/actions";
import AscIcon from "../../assets/img/desc.png";
import DescIcon from "../../assets/img/asc.png";
import { AiOutlineDelete } from "react-icons/ai";
import { toast } from "react-toastify";
import { decrypt } from "../../utils/CryptoUtils";
import MultiSelect from "../MultiSelectCustom/MultiSelect";
import Multiselect from "multiselect-react-dropdown";
import { ThemeContext } from "../../providers/ThemeProvider";
import { nanoid } from "nanoid";
import MultiSelectTwo from "../MultiSelectCustom/MultiSelectTwo";

function EditNursingGridNewForm(props) {
  const parentRef = useRef(null);
  const { ID } = useParams();
  // console.log(parseInt(decrypt(ID)),"Decrypted Id")
  const [theme, setTheme] = useContext(ThemeContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem("psAdmin"));
  const customer_code = useSelector((state) => state.compensate.customerCode);
  const IsNavigate = useSelector((state) => state.compensate.IsNavigate);
  const loading_page = useSelector((state) => state.compensate.LoadingPage);
  console.log(customer_code, "customercode");

  const [searchKeyword, setSearchKeyword] = useState("");
  const [pageLimit, setPageLimit] = useState(50);

  const [currentPage, setCurrentPage] = useState(1);

  const [sortKey, setSortKey] = useState("");
  const [isInitialData, setISInitialData] = useState(false);
  const [sortOrder, setSortOrder] = useState(1);

  const [listLoader, setListLoader] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [addRowLoader, setAddRowLoader] = useState(false);
  const [currentCustomerCode, setCurrentCustomerCode] = useState(null);
  const [CostCenterData, setCostCenterData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [dropShiftTime, setDropShiftTime] = useState([]);
  const [currentShiftTime, setCurrentShiftTime] = useState("");
  const [dropShiftYear, setDropShiftYear] = useState([]);
  const [currentShiftYear, setCurrentShiftYear] = useState("");
  const [headers, setHeaders] = useState({});
  const [multiValues, setMultiValues] = useState([]);
  const [detailKeys, setDetailKeys] = useState([]);
  const multiValuesRef = useRef({});

  console.log(multiValues, "data");
  const memoizedCostCenterData = useMemo(
    () => CostCenterData,
    [CostCenterData]
  );

  function getDate(inputDate) {
    const timeZone = "America/Los_Angeles";
    const date = new Date(inputDate).toLocaleString("en-US", {
      timeZone,
      hour12: true,
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    });

    return date;
  }

  useEffect(() => {
    if (IsNavigate) {
      navigate("/admin/new-nursing-grid");
    }
  }, [customer_code]);

  useEffect(() => {
    const getGridDetailList = async () => {
      try {
        const resp = await axios.post(
          `${API_CALL_URL_PRIVATE}/nursing-grid/costCenterDetail`,
          {
            customer_code: parseInt(customer_code),
            cost_center_number: ID,
            shift_time: currentShiftTime,
            year: currentShiftYear,
          }
        );
        const Data = resp?.data?.data;
        setHeaders(resp?.data?.data?.headers || []);
        setDetailKeys(resp?.data?.data?.detailWithKeys || []);
        const drop_shift_time = resp?.data?.data?.shiftTime?.map((e) => {
          return {
            label: e,
            value: e,
          };
        });
        setDropShiftTime(
          drop_shift_time?.length > 0
            ? [{ value: "", label: "Select All" }, ...drop_shift_time]
            : []
        );
        const drop_year = resp?.data?.data?.years?.map((e) => {
          return {
            label: e,
            value: e,
          };
        });
        setDropShiftYear(
          drop_year?.length > 0
            ? [{ value: "", label: "Select All" }, ...drop_year]
            : []
        );

        setCurrentCustomerCode(
          resp?.data?.data?.details
            ? resp?.data?.data?.details[0].customer_code
            : ""
        );

        const updatedData = Data?.detailWithKeys.map((item) => {
          const updatedItem = { ...item };
          const validShiftTime = item.shift_time.options;
          const validYears = item.year.options;

          if (!validShiftTime.includes(item.shift_time.value)) {
            updatedItem.shift_time.value = "";
          }

          if (!validYears.includes(item.year.value)) {
            updatedItem.year.value = "";
          }

          return updatedItem;
        });
        setCostCenterData(updatedData || []);
        setISInitialData(true);

        dispatch(setLoadingPage(false));
      } catch (error) {
        console.log(error);
      } finally {
        setListLoader(false);
      }
    };

    getGridDetailList();
  }, [
    searchKeyword,
    pageLimit,
    currentPage,
    sortOrder,
    customer_code,
    currentShiftTime,
    currentShiftYear,
    sortKey,
    refresh,
  ]);

  const getDataForAddRow = async () => {
    try {
      setAddRowLoader(true);
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/nursing-grid/costCenterDefaultData`,
        {
          customer_code: parseInt(customer_code),
          cost_center_number: parseInt(ID),
          shift_time: currentShiftTime,
          year: parseInt(currentShiftYear),
        }
      );
      console.log(resp, "Resp");
      setHeaders(resp?.data?.data?.headers);
      const Data = resp?.data?.data?.detailWithKeys;
      setCostCenterData(Data || []);
    } catch (error) {
      console.log(error);
    } finally {
      setAddRowLoader(false);
    }
  };

  function convertDateStringToMMDDYYYY(dateString) {
    // Check if the input date string is valid
    if (!dateString) {
      return "";
    }

    const parts = dateString.split("-");
    return `${parts[1]}/${parts[0]}/${parts[2]}`;
  }
  const handleInputChange = (e, rowIndex, key, type, validation) => {
    if (type !== "checkbox") {
      const newValue = e.target.value;
      console.log(newValue);
      setCostCenterData((prevData) => {
        const newData = [...prevData];

        newData[rowIndex][key].value =
          type === "input"
            ? validation === "number"
              ? parseInt(newValue)
              : newValue
            : newValue;
        return newData;
      });
    } else {
      const newValue = e.target.checked;
      setCostCenterData((prevData) => {
        const newData = [...prevData];

        newData[rowIndex][key].value = newValue === true ? 1 : 0;
        return newData;
      });
    }
  };
  const handleCheckboxDropChange = (selected, rowIndex, key) => {
    console.log(selected?.map((e) => e.value));
    setCostCenterData((prevData) => {
      const newData = [...prevData];

      newData[rowIndex][key].value = selected?.map((e) => e.value);
      console.log("newData", newData);
      return newData;
    });

  
  };
  

  // const handleCheckboxDropChange = useCallback(
  //   (selected, rowIndex, key) => {
  //     setMultiValues((prev) => ({
  //       ...prev,
  //       [rowIndex]: selected.map((e) => e.value),
  //     }));
  //   },
  //   [setMultiValues]
  // );

  const addRow = () => {
    if (CostCenterData?.length > 0) {
      const keys = Object.keys(CostCenterData[0]);
      const newRow = keys.reduce((acc, key) => {
        const item = CostCenterData[0][key];
        key === "_id"
          ? (acc[key] = {
              value: "",
              fieldType: "hidden",
              validation: "object",
            })
          : key === "delete_row"
          ? (acc[key] = {
              value: 0,
              fieldType: "checkbox",
              validation: "number",
            })
          : key === "cost_center_number"
          ? (acc[key] = {
              value: CostCenterData[0].cost_center_number.value,
              fieldType: "input",
              validation: "number",
            })
          : key === "day"
          ? (acc[key] = {
              value: CostCenterData[0].day.value,
              fieldType: "dropdown_ckeckbox",
              validation: "string",
            })
          : (acc[key] = {
              value:
                item.fieldType === "input"
                  ? item.validation === "number"
                    ? 0
                    : item.validation === "string"
                    ? key === "added_date"
                      ? CostCenterData[CostCenterData?.length - 1]?.added_date
                          ?.value
                      : ""
                    : ""
                  : key === "shift_time"
                  ? currentShiftTime || currentShiftTime !== ""
                    ? currentShiftTime
                    : item.options[0]
                  : key === "year"
                  ? currentShiftYear || currentShiftYear !== ""
                    ? currentShiftYear
                    : new Date().getFullYear().toString()
                  : item.options[0],
              fieldType: item.fieldType,
              validation: item.validation,
            });
        if (item.fieldType !== "input") {
          acc[key].options = item.options || [];
        }
        return acc;
      }, {});

      setCostCenterData([...CostCenterData, newRow]);
    }
  };
  const handleDelete = (row) => {
    const data = CostCenterData;
    const newData = data.filter((item, i) => i !== row);
    setCostCenterData(newData);
  };

  const handleSave = async () => {
    const obj = {
      customer_code: customer_code,
      data: CostCenterData.map((item) => {
        const transformedItem = {};
        for (const key in item) {
          if (item.hasOwnProperty(key)) {
            // if (key === "year") {
            //   transformedItem[key] = parseInt(item[key].value);
            // } else transformedItem[key] = item[key].value;
            transformedItem[key] = item[key].value;
          }
        }
        return transformedItem;
      }),
    };
    console.log(obj);
    try {
      setIsLoading(true);
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/nursing-grid/bulkAddOrUpdate`,
        obj
      );
      console.log(resp);
      toast.success(resp?.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
      setRefresh(!refresh);
    } catch (error) {
      toast.error(
        error.response.data.message ||
          error.message ||
          "Error Occured. Refrsh or try again later..",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        }
      );
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isInitialData ? (
        <div className="main-panel">
          <div className="content-wrapper">
            <div className="page-header">
              <h3 className="page-title">
                <a href="#" className="badge badge-dark"></a>
              </h3>
              <div className></div>
            </div>
            <div className="row">
              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <div className="row align-items-center mb-2">
                      <div className="col-1">
                        <Link
                          to={`/admin/staffing-grid`}
                          className="f-14 white-text-dark-mode"
                        >
                          <FontAwesomeIcon icon="fa-solid fa-arrow-left " />{" "}
                          Back
                        </Link>
                      </div>
                      <div className="col-9">
                        <div className="d-flex justify-content-between">
                          <h4 class="card-title text-center m-0">
                            Updating details for &nbsp;:&nbsp;&nbsp;{ID}
                            {/* {CostCenterData.length > 0
                              ? CostCenterData[0].cost_center_number.value
                              : ""} */}
                          </h4>
                          <div
                            className="ms-4 d-flex mobile-w-auto"
                            style={{ width: "30%" }}
                          >
                            <h5 class="mx-2 my-0 fs-6">Year</h5>
                            <Select
                              isSearchable="true"
                              options={dropShiftYear}
                              className="custom-select flex-fill mx-2"
                              onChange={(e) => {
                                setCurrentShiftYear(e.value);
                                setListLoader(true);
                              }}
                            />
                          </div>
                          <div
                            className="ms-4 d-flex mobile-w-auto"
                            style={{ width: "30%" }}
                          >
                            <h5 class="mx-2 my-0 fs-6">Intervals</h5>
                            <Select
                              isSearchable="true"
                              options={dropShiftTime}
                              className="custom-select flex-fill mx-2"
                              onChange={(e) => {
                                setCurrentShiftTime(e.value);
                                setListLoader(true);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-2 d-flex justify-content-end">
                        {/* {!listLoader && CostCenterData.length > 0 ? ( */}
                        <>
                          <button
                            type="button"
                            class="btn btn-success"
                            onClick={addRow}
                          >
                            {!addRowLoader ? (
                              "Add Row"
                            ) : (
                              <div
                                style={{ width: "60px" }}
                                className="d-flex justify-content-center align-items-center p-1"
                              >
                                <span
                                  class="loader"
                                  style={{ width: "20px", height: "20px" }}
                                ></span>
                              </div>
                            )}
                          </button>
                          {!listLoader && memoizedCostCenterData.length > 0 ? (
                            <Button
                              className="ms-3"
                              variant="success"
                              onClick={handleSave}
                              disabled={isLoading}
                            >
                              {isLoading ? "Saving..." : "Save"}
                            </Button>
                          ) : (
                            ""
                          )}
                        </>
                      </div>
                    </div>
                    <div className="row align-items-center justify-content-center mb-3"></div>

                    {!listLoader ? (
                      <>
                        <div class="table-wrapper">
                          <table className="border-bottom-table">
                            {memoizedCostCenterData?.length > 0 ? (
                              <>
                                <thead>
                                  <tr>
                                    <th style={{ cursor: "default" }}>No.</th>

                                    {Object.keys(memoizedCostCenterData[0]).map(
                                      (item, i) => (
                                        <>
                                          {item !== "_id" ? (
                                            <th
                                              key={i}
                                              style={{ cursor: "default" }}
                                            >
                                              {headers[item]}
                                            </th>
                                          ) : (
                                            ""
                                          )}
                                        </>
                                      )
                                    )}
                                  </tr>
                                </thead>

                                <>
                                  <tbody className="hhh" ref={parentRef}>
                                    {memoizedCostCenterData?.map(
                                      (row, rowIndex) => (
                                        <tr
                                          key={rowIndex}
                                          style={{
                                            backgroundColor:
                                              row["delete_row"].value === 1
                                                ? "rgba(127, 143, 166,0.5)"
                                                : "",
                                            opacity:
                                              row["delete_row"].value === 1
                                                ? "0.5"
                                                : "",
                                            cursor:
                                              row["delete_row"].value === 1
                                                ? "not-allowed"
                                                : "",
                                          }}
                                        >
                                          <td>
                                            <div className="my-1">
                                              {rowIndex + 1}
                                            </div>
                                          </td>

                                          {Object.keys(row)?.map(
                                            (key, colIndex) => (
                                              <>
                                                {row[key].fieldType !==
                                                "hidden" ? (
                                                  <td key={colIndex}>
                                                    {row[key].fieldType ===
                                                    "input" ? (
                                                      <Form.Group
                                                        className="col-md-3 mb-0"
                                                        controlId="exampleForm.c_code"
                                                        style={{
                                                          minWidth:
                                                            row[key]
                                                              .validation ===
                                                            "number"
                                                              ? "90px"
                                                              : "160px",
                                                        }}
                                                      >
                                                        <Form.Control
                                                          type={
                                                            row[key]
                                                              .validation ===
                                                            "number"
                                                              ? "number"
                                                              : "text"
                                                          }
                                                          placeholder=""
                                                          value={row[key].value}
                                                          readOnly={
                                                            key ===
                                                              "cost_center_number" ||
                                                            row["delete_row"]
                                                              .value === 1
                                                              ? true
                                                              : false
                                                          }
                                                          onChange={(e) =>
                                                            handleInputChange(
                                                              e,
                                                              rowIndex,
                                                              key,
                                                              row[key]
                                                                .fieldType,
                                                              row[key]
                                                                .validation
                                                            )
                                                          }
                                                        />
                                                      </Form.Group>
                                                    ) : row[key].fieldType ===
                                                      "dropdown" ? (
                                                      <Form.Group
                                                        className="col-md-3 mb-0"
                                                        controlId="exampleForm.c_email"
                                                        style={{
                                                          minWidth: "120px",
                                                        }}
                                                      >
                                                        <Form.Select
                                                          aria-label="Default select example"
                                                          className="white h-30"
                                                          value={`${row[key].value}`}
                                                          style={{
                                                            cursor:
                                                              row["delete_row"]
                                                                .value === 1
                                                                ? "not-allowed"
                                                                : "auto",
                                                          }}
                                                          onChange={(e) =>
                                                            !(
                                                              row["delete_row"]
                                                                .value === 1
                                                            ) &&
                                                            handleInputChange(
                                                              e,
                                                              rowIndex,
                                                              key,
                                                              row[key].fieldType
                                                            )
                                                          }
                                                        >
                                                          <option value={""}>
                                                            select
                                                          </option>
                                                          {row[
                                                            key
                                                          ].options?.map(
                                                            (
                                                              option,
                                                              optionIndex
                                                            ) => (
                                                              <option
                                                                key={
                                                                  optionIndex
                                                                }
                                                                value={option}
                                                              >
                                                                {option}
                                                              </option>
                                                            )
                                                          )}
                                                        </Form.Select>
                                                      </Form.Group>
                                                    ) : row[key].fieldType ===
                                                      "checkbox" ? (
                                                      <>
                                                        <div class="checkbox-item">
                                                          <input
                                                            type="checkbox"
                                                            class="custom-checkbox"
                                                            checked={
                                                              row[key].value ===
                                                              1
                                                            }
                                                            onChange={(e) => {
                                                              handleInputChange(
                                                                e,
                                                                rowIndex,
                                                                key,
                                                                row[key]
                                                                  .fieldType
                                                              );
                                                            }}
                                                          />
                                                        </div>
                                                      </>
                                                    ) : row[key].fieldType ===
                                                      "dropdown_ckeckbox" ? (
                                                      <>
                                                        <div
                                                          style={{
                                                            minWidth: "150px",
                                                          }}
                                                        >
                                                          <MultiSelectTwo
                                                            placeholder="Day"
                                                            parentRef={
                                                              parentRef
                                                            }
                                                            options={row[
                                                              key
                                                            ]?.options?.map(
                                                              (e) => {
                                                                return {
                                                                  value: e,
                                                                  label: e,
                                                                };
                                                              }
                                                            )}
                                                            onChange={(e) => {
                                                              handleCheckboxDropChange(
                                                                e,
                                                                rowIndex,
                                                                key
                                                              );
                                                            }}
                                                            value={row[
                                                              key
                                                            ]?.value?.map(
                                                              (e) => {
                                                                return {
                                                                  value: e,
                                                                  label: e,
                                                                };
                                                              }
                                                            )}
                                                            isSelectAll={true}
                                                            menuPlacement={
                                                              "bottom"
                                                            }
                                                            theme={
                                                              theme.theme ===
                                                              "LIGHT"
                                                            }
                                                            closeMenuOnSelect={
                                                              false
                                                            }
                                                          />
                                                        </div>
                                                      </>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </td>
                                                ) : (
                                                  ""
                                                )}
                                              </>
                                            )
                                          )}
                                        </tr>
                                        
                                      )
                                    )}
                                    <tr colSpan="40"><span style={{opacity: "0"}}>test</span></tr>
                                    <tr colSpan="40"><span style={{opacity: "0"}}>test</span></tr>
                                    <tr colSpan="40"><span style={{opacity: "0"}}>test</span></tr>
                                    <tr colSpan="40"><span style={{opacity: "0"}}>test</span></tr>
                                    <tr colSpan="40"><span style={{opacity: "0"}}>test</span></tr>
                                    <tr colSpan="40"><span style={{opacity: "0"}}>test</span></tr>
                                    <tr colSpan="40"><span style={{opacity: "0"}}>test</span></tr>
                                  </tbody>
                                </>
                              </>
                            ) : (
                              <>
                                <thead>
                                  <tr>
                                    <th>No.</th>

                                    {Object.keys(headers).map((item, i) => (
                                      <>
                                        {item !== "_id" ? (
                                          <th key={i}>
                                            {/* {item.replace(/_/g, " ")} */}
                                            {headers[item]}
                                          </th>
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    ))}
                                    {/* <th></th> */}
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td colSpan="25">
                                      <div
                                        className="d-flex justify-content-center align-items-center"
                                        style={{ height: "350px" }}
                                      >
                                        <p class="text-secondary display-6">
                                          Data Not found
                                        </p>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </>
                            )}
                          </table>
                        </div>
                        {memoizedCostCenterData.length > 0 ? (
                          <Form.Group className="col-md-12 my-3 d-flex justify-content-end">
                            <button
                              type="button"
                              class="btn btn-success"
                              onClick={addRow}
                            >
                              Add Row
                            </button>
                            <Button
                              className="ms-3"
                              variant="success"
                              onClick={handleSave}
                              disabled={isLoading}
                            >
                              {isLoading ? "Saving..." : "Save"}
                            </Button>
                          </Form.Group>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{ height: "400px" }}
                      >
                        <span
                          class="loader"
                          style={{ width: "50px", height: "50px" }}
                        ></span>
                      </div>
                    )}

                    {/* {!listLoader ? (
                      <>
                        <div>
                          <div className="table-responsive">
                            <table className="table filter-table wraping-table tpi scroll-table min-h-580 custom-width-col">
                              {memoizedCostCenterData?.length > 0 ? (
                                <>
                                  <thead>
                                    <tr>
                                      <th style={{ cursor: "default" }}>No.</th>

                                      {Object.keys(
                                        memoizedCostCenterData[0]
                                      ).map((item, i) => (
                                        <>
                                          {item !== "_id" ? (
                                            <th
                                              key={i}
                                              style={{ cursor: "default" }}
                                            >
                                             
                                              {headers[item]}
                                            </th>
                                          ) : (
                                            ""
                                          )}
                                        </>
                                      ))}
                                     
                                    </tr>
                                  </thead>

                                  <>
                                    <tbody className="hhh" ref={parentRef}>
                                      {memoizedCostCenterData?.map(
                                        (row, rowIndex) => (
                                          <tr
                                            key={rowIndex}
                                            style={{
                                              backgroundColor:
                                                row["delete_row"].value === 1
                                                  ? "rgba(127, 143, 166,0.5)"
                                                  : "",
                                              opacity:
                                                row["delete_row"].value === 1
                                                  ? "0.5"
                                                  : "",
                                              cursor:
                                                row["delete_row"].value === 1
                                                  ? "not-allowed"
                                                  : "",
                                            }}
                                          >
                                            <td>
                                              <div className="my-1">
                                                {rowIndex + 1}
                                              </div>
                                            </td>

                                            {Object.keys(row)?.map(
                                              (key, colIndex) => (
                                                <>
                                                  {row[key].fieldType !==
                                                  "hidden" ? (
                                                    <td key={colIndex}>
                                                      {row[key].fieldType ===
                                                      "input" ? (
                                                        <Form.Group
                                                          className="col-md-3 mb-0"
                                                          controlId="exampleForm.c_code"
                                                          style={{
                                                            minWidth:
                                                              row[key]
                                                                .validation ===
                                                              "number"
                                                                ? "90px"
                                                                : "160px",
                                                          }}
                                                        >
                                                          <Form.Control
                                                            type={
                                                              row[key]
                                                                .validation ===
                                                              "number"
                                                                ? "number"
                                                                : "text"
                                                            }
                                                            placeholder=""
                                                            // name="census"
                                                            value={
                                                              row[key].value
                                                            }
                                                            readOnly={
                                                              key ===
                                                                "cost_center_number" ||
                                                              row["delete_row"]
                                                                .value === 1
                                                                ? true
                                                                : false
                                                            }
                                                          
                                                            onChange={(e) =>
                                                              handleInputChange(
                                                                e,
                                                                rowIndex,
                                                                key,
                                                                row[key]
                                                                  .fieldType,
                                                                row[key]
                                                                  .validation
                                                              )
                                                            }
                                                          />
                                                        </Form.Group>
                                                      ) : row[key].fieldType ===
                                                        "dropdown" ? (
                                                        <Form.Group
                                                          className="col-md-3 mb-0"
                                                          controlId="exampleForm.c_email"
                                                          style={{
                                                            minWidth: "120px",
                                                          }}
                                                        >
                                                          <Form.Select
                                                            aria-label="Default select example"
                                                            className="white h-30"
                                                          
                                                            value={`${row[key].value}`}
                                                            
                                                            style={{
                                                              cursor:
                                                                row[
                                                                  "delete_row"
                                                                ].value === 1
                                                                  ? "not-allowed"
                                                                  : "auto",
                                                            }}
                                                            onChange={(e) =>
                                                              !(
                                                                row[
                                                                  "delete_row"
                                                                ].value === 1
                                                              ) &&
                                                              handleInputChange(
                                                                e,
                                                                rowIndex,
                                                                key,
                                                                row[key]
                                                                  .fieldType
                                                              )
                                                            }
                                                          >
                                                            <option value={""}>
                                                              select
                                                            </option>
                                                            {row[
                                                              key
                                                            ].options?.map(
                                                              (
                                                                option,
                                                                optionIndex
                                                              ) => (
                                                                <option
                                                                  key={
                                                                    optionIndex
                                                                  }
                                                                  value={option}
                                                                >
                                                                  {option}
                                                                </option>
                                                              )
                                                            )}
                                                          </Form.Select>
                                                        </Form.Group>
                                                      ) : row[key].fieldType ===
                                                        "checkbox" ? (
                                                        <>
                                                          <td>
                                                            <div class="checkbox-item">
                                                              <input
                                                                type="checkbox"
                                                                class="custom-checkbox"
                                                                checked={
                                                                  row[key]
                                                                    .value === 1
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleInputChange(
                                                                    e,
                                                                    rowIndex,
                                                                    key,
                                                                    row[key]
                                                                      .fieldType
                                                                  );
                                                                }}
                                                              />
                                                            </div>
                                                          </td>
                                                        </>
                                                      ) : row[key].fieldType ===
                                                        "dropdown_ckeckbox" ? (
                                                        <>
                                                          <td>
                                                            <div
                                                              style={{
                                                                minWidth:
                                                                  "150px",
                                                              }}
                                                            >
                                                              <MultiSelectTwo
                                                               
                                                                placeholder="Day"
                                                                parentRef={
                                                                  parentRef
                                                                }
                                                                options={row[
                                                                  key
                                                                ]?.options?.map(
                                                                  (e) => {
                                                                    return {
                                                                      value: e,
                                                                      label: e,
                                                                    };
                                                                  }
                                                                )}
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleCheckboxDropChange(
                                                                    e,
                                                                    rowIndex,
                                                                    key
                                                                  );
                                                                }}
                                                                value={row[
                                                                  key
                                                                ]?.value?.map(
                                                                  (e) => {
                                                                    return {
                                                                      value: e,
                                                                      label: e,
                                                                    };
                                                                  }
                                                                )}
                                                                isSelectAll={
                                                                  true
                                                                }
                                                                menuPlacement={
                                                                  "bottom"
                                                                }
                                                                theme={
                                                                  theme.theme ===
                                                                  "LIGHT"
                                                                }
                                                                closeMenuOnSelect={
                                                                  false
                                                                }
                                                               
                                                              />

                                                              
                                                            </div>
                                                          </td>
                                                        </>
                                                      ) : (
                                                        ""
                                                      )}
                                                    </td>
                                                  ) : (
                                                    ""
                                                  )}
                                                </>
                                              )
                                            )}
                                          
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </>
                                </>
                              ) : (
                                <>
                                  <thead>
                                    <tr>
                                      <th>No.</th>

                                      {Object.keys(headers).map((item, i) => (
                                        <>
                                          {item !== "_id" ? (
                                            <th key={i}>
                                             
                                              {headers[item]}
                                            </th>
                                          ) : (
                                            ""
                                          )}
                                        </>
                                      ))}
                                     
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td colSpan="25">
                                        <div
                                          className="d-flex justify-content-center align-items-center"
                                          style={{ height: "350px" }}
                                        >
                                          <p class="text-secondary display-6">
                                            Data Not found
                                          </p>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </>
                              )}
                            </table>
                          </div>
                          {memoizedCostCenterData.length > 0 ? (
                            <Form.Group className="col-md-12 my-3 d-flex justify-content-end">
                              <button
                                type="button"
                                class="btn btn-success"
                                onClick={addRow}
                              >
                                Add Row
                              </button>
                              <Button
                                className="ms-3"
                                variant="success"
                                onClick={handleSave}
                                disabled={isLoading}
                              >
                                {isLoading ? "Saving..." : "Save"}
                              </Button>
                            </Form.Group>
                          ) : (
                            ""
                          )}
                        </div>
                      </>
                    ) : (
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{ height: "400px" }}
                      >
                        <span
                          class="loader"
                          style={{ width: "50px", height: "50px" }}
                        ></span>
                      </div>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex align-items-center justify-content-center w-100vw h-100vh">
          <span class="loader" style={{ width: "50px", height: "50px" }}></span>
        </div>
      )}
    </>
  );
}

export default EditNursingGridNewForm;
