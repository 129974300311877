import Chart from "react-apexcharts";

function MultiChart({chartdata}) {
    const ftecolor = chartdata?.fte_variance < 0 ? '#FF4560' : '#5E8E28';
    const rncolor = chartdata?.rn_variance < 0 ? '#FF4560' : '#5E8E28';
    const fte_variance = chartdata?.fte_variance < 0 ? Math.abs(chartdata.fte_variance) : (chartdata?.fte_variance > 0) ? chartdata.fte_variance : 0; 
    const rn_variance = chartdata?.rn_variance < 0 ? Math.abs(chartdata.rn_variance) : (chartdata?.rn_variance > 0) ? chartdata.rn_variance : 0;     

    const options = {
        chart: {height: 240, type: "radialBar" },
        colors: [ftecolor, rncolor],
        tooltip: {
            custom: function({series, seriesIndex, dataPointIndex, w}) {
              return 1
            }
        },
        plotOptions: {
            radialBar: {
                dataLabels: {
                    value: {
                        show: true,
                        fontSize: '14px',
                        fontFamily: undefined,
                        fontWeight: 400,
                        color: undefined,
                        offsetY: 16,
                        formatter: function (val, {config, ...rest}) {
                            const checkcolorfte = config?.colors[0];
                            const checkcolorrn = config?.colors[1];
                            if(checkcolorfte == '#FF4560'){
                                return val;//-Math.abs(val);
                            } else {
                                return val;
                            }
                        }
                    },
                    total: {
                        show: true, 
                        distributed:false, 
                        label: '', 
                        color: '#5E8E28', 
                        fontWeight: 700,
                        //formatter: function (w) {
                            //console.log(w, "label");
                            //const checkcolorfte = w?.config?.colors[0];
                            //const checkcolorrn = w?.config?.colors[1];
                            //console.log(checkcolorfte, "checkcolorfte");
                            //console.log(checkcolorrn, "checkcolorrn");
                            //return 1
                        //}
                    },   
                }
            }
        },
        labels: ['FTE Variance', 'RN Variance']
    }

    return (
        <div className="m-auto">
            <Chart
              options={options}
              series={[fte_variance, rn_variance]}
              type="radialBar" 
              width={'100%'}
              height={245}
            />
        </div>
    );
}

export default MultiChart;