import * as DS from "../../Styled/DashStyle"

const CardOne = () => {
  return (
    <>
    <DS.CardOneParent>
        <DS.CardOneRow>
            26 Available beds
        </DS.CardOneRow>
    </DS.CardOneParent>
    </>
  )
}

export default CardOne