import React from "react";
import { Switch } from "antd";
import "./index.css";

const AdditionalDataAccordian = ({
  formControl,
  handleAddDataSwitchOnChange,
  handleAddDataSwitchcheck,
  handleAddDataLabel,
  handleAddDataSort,
  handleDisplayGraphSwitchcheck,
  handleAddDataGraph,
}) => {
  return (
    <>
      <div class="row">
        <div className="col-md-12 m-0">
          <hr />
        </div>
        <div className="col-md-12">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>Show/Hide/Sort</th>

                  <th>Key</th>
                  <th>Label</th>
                  <th>Display Graph</th>
                  <th>Mandatory</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) => e.field_name === "ant_admit"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "ant_admit",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "ant_admit"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "ant_admit"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel("ant_admit", e.target.value);
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck("ant_admit")}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph("ant_admit", e ? "1" : "0");
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck("ant_admit")}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange("ant_admit", e);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) => e.field_name === "actual_admit"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "actual_admit",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "actual_admit"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "actual_admit"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel("actual_admit", e.target.value);
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck("actual_admit")}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph("actual_admit", e ? "1" : "0");
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck("actual_admit")}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange("actual_admit", e);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) => e.field_name === "telemetry"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "telemetry",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "telemetry"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "telemetry"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel("telemetry", e.target.value);
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck("telemetry")}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph("telemetry", e ? "1" : "0");
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck("telemetry")}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange("telemetry", e);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) => e.field_name === "vaso_active_drips"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "vaso_active_drips",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "vaso_active_drips"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "vaso_active_drips"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel(
                            "vaso_active_drips",
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck(
                        "vaso_active_drips"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph("vaso_active_drips", e ? "1" : "0");
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck("vaso_active_drips")}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange("vaso_active_drips", e);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) => e.field_name === "isolation_patients"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "isolation_patients",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "isolation_patients"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "isolation_patients"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel(
                            "isolation_patients",
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck(
                        "isolation_patients"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph("isolation_patients", e ? "1" : "0");
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck("isolation_patients")}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange("isolation_patients", e);
                      }}
                    />
                  </td>
                </tr>

                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) => e.field_name === "of_isolation"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "of_isolation",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "of_isolation"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "of_isolation"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel("of_isolation", e.target.value);
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck("of_isolation")}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph("of_isolation", e ? "1" : "0");
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck("of_isolation")}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange("of_isolation", e);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) => e.field_name === "of_rrs_codes"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "of_rrs_codes",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "of_rrs_codes"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "of_rrs_codes"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel("of_rrs_codes", e.target.value);
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck("of_rrs_codes")}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph("of_rrs_codes", e ? "1" : "0");
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck("of_rrs_codes")}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange("of_rrs_codes", e);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) => e.field_name === "of_video_monitors"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "of_video_monitors",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "of_video_monitors"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "of_video_monitors"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel(
                            "of_video_monitors",
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck(
                        "of_video_monitors"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph("of_video_monitors", e ? "1" : "0");
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck("of_video_monitors")}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange("of_video_monitors", e);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) => e.field_name === "did_blt_cover_obs"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "did_blt_cover_obs",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "did_blt_cover_obs"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "did_blt_cover_obs"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel(
                            "did_blt_cover_obs",
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck(
                        "did_blt_cover_obs"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph("did_blt_cover_obs", e ? "1" : "0");
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck("did_blt_cover_obs")}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange("did_blt_cover_obs", e);
                      }}
                    />
                  </td>
                </tr>

                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) =>
                            e.field_name ===
                            "of_patients_w_video_monitor_last_six_hours"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "of_patients_w_video_monitor_last_six_hours",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) =>
                              e.field_name ===
                              "of_patients_w_video_monitor_last_six_hours"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) =>
                              e.field_name ===
                              "of_patients_w_video_monitor_last_six_hours"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel(
                            "of_patients_w_video_monitor_last_six_hours",
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck(
                        "of_patients_w_video_monitor_last_six_hours"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph(
                          "of_patients_w_video_monitor_last_six_hours",
                          e ? "1" : "0"
                        );
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck(
                        "of_patients_w_video_monitor_last_six_hours"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange(
                          "of_patients_w_video_monitor_last_six_hours",
                          e
                        );
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) =>
                            e.field_name ===
                            "of_patients_in_seclusion_last_six_hours"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "of_patients_in_seclusion_last_six_hours",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) =>
                              e.field_name ===
                              "of_patients_in_seclusion_last_six_hours"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) =>
                              e.field_name ===
                              "of_patients_in_seclusion_last_six_hours"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel(
                            "of_patients_in_seclusion_last_six_hours",
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck(
                        "of_patients_in_seclusion_last_six_hours"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph(
                          "of_patients_in_seclusion_last_six_hours",
                          e ? "1" : "0"
                        );
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck(
                        "of_patients_in_seclusion_last_six_hours"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange(
                          "of_patients_in_seclusion_last_six_hours",
                          e
                        );
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) =>
                            e.field_name ===
                            "of_patients_in_restraints_last_six_hours"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "of_patients_in_restraints_last_six_hours",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) =>
                              e.field_name ===
                              "of_patients_in_restraints_last_six_hours"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) =>
                              e.field_name ===
                              "of_patients_in_restraints_last_six_hours"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel(
                            "of_patients_in_restraints_last_six_hours",
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck(
                        "of_patients_in_restraints_last_six_hours"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph(
                          "of_patients_in_restraints_last_six_hours",
                          e ? "1" : "0"
                        );
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck(
                        "of_patients_in_restraints_last_six_hours"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange(
                          "of_patients_in_restraints_last_six_hours",
                          e
                        );
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) =>
                            e.field_name === "of_icu_patients_waiting_in_er"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "of_icu_patients_waiting_in_er",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) =>
                              e.field_name === "of_icu_patients_waiting_in_er"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) =>
                              e.field_name === "of_icu_patients_waiting_in_er"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel(
                            "of_icu_patients_waiting_in_er",
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck(
                        "of_icu_patients_waiting_in_er"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph(
                          "of_icu_patients_waiting_in_er",
                          e ? "1" : "0"
                        );
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck(
                        "of_icu_patients_waiting_in_er"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange(
                          "of_icu_patients_waiting_in_er",
                          e
                        );
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) =>
                            e.field_name ===
                            "of_anticipated_transfers_in_next_four_hours"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "of_anticipated_transfers_in_next_four_hours",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) =>
                              e.field_name ===
                              "of_anticipated_transfers_in_next_four_hours"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) =>
                              e.field_name ===
                              "of_anticipated_transfers_in_next_four_hours"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel(
                            "of_anticipated_transfers_in_next_four_hours",
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck(
                        "of_anticipated_transfers_in_next_four_hours"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph(
                          "of_anticipated_transfers_in_next_four_hours",
                          e ? "1" : "0"
                        );
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck(
                        "of_anticipated_transfers_in_next_four_hours"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange(
                          "of_anticipated_transfers_in_next_four_hours",
                          e
                        );
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) =>
                            e.field_name ===
                            "of_anticipated_transfers_out_next_four_hours"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "of_anticipated_transfers_out_next_four_hours",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) =>
                              e.field_name ===
                              "of_anticipated_transfers_out_next_four_hours"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) =>
                              e.field_name ===
                              "of_anticipated_transfers_out_next_four_hours"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel(
                            "of_anticipated_transfers_out_next_four_hours",
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck(
                        "of_anticipated_transfers_out_next_four_hours"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph(
                          "of_anticipated_transfers_out_next_four_hours",
                          e ? "1" : "0"
                        );
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck(
                        "of_anticipated_transfers_out_next_four_hours"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange(
                          "of_anticipated_transfers_out_next_four_hours",
                          e
                        );
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) =>
                            e.field_name ===
                            "of_rns_wa_wit_higher_than_shift_standard"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "of_rns_wa_wit_higher_than_shift_standard",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) =>
                              e.field_name ===
                              "of_rns_wa_wit_higher_than_shift_standard"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) =>
                              e.field_name ===
                              "of_rns_wa_wit_higher_than_shift_standard"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel(
                            "of_rns_wa_wit_higher_than_shift_standard",
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck(
                        "of_rns_wa_wit_higher_than_shift_standard"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph(
                          "of_rns_wa_wit_higher_than_shift_standard",
                          e ? "1" : "0"
                        );
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck(
                        "of_rns_wa_wit_higher_than_shift_standard"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange(
                          "of_rns_wa_wit_higher_than_shift_standard",
                          e
                        );
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) => e.field_name === "of_pcu_pts_waiting_in_er"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "of_pcu_pts_waiting_in_er",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "of_pcu_pts_waiting_in_er"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "of_pcu_pts_waiting_in_er"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel(
                            "of_pcu_pts_waiting_in_er",
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck(
                        "of_pcu_pts_waiting_in_er"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph(
                          "of_pcu_pts_waiting_in_er",
                          e ? "1" : "0"
                        );
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck(
                        "of_pcu_pts_waiting_in_er"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange(
                          "of_pcu_pts_waiting_in_er",
                          e
                        );
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) =>
                            e.field_name ===
                            "of_c_sections_since_last_time_stamp"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "of_c_sections_since_last_time_stamp",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) =>
                              e.field_name ===
                              "of_c_sections_since_last_time_stamp"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) =>
                              e.field_name ===
                              "of_c_sections_since_last_time_stamp"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel(
                            "of_c_sections_since_last_time_stamp",
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck(
                        "of_c_sections_since_last_time_stamp"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph(
                          "of_c_sections_since_last_time_stamp",
                          e ? "1" : "0"
                        );
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck(
                        "of_c_sections_since_last_time_stamp"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange(
                          "of_c_sections_since_last_time_stamp",
                          e
                        );
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) =>
                            e.field_name ===
                            "of_inductions_since_last_time_stamp"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "of_inductions_since_last_time_stamp",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) =>
                              e.field_name ===
                              "of_inductions_since_last_time_stamp"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) =>
                              e.field_name ===
                              "of_inductions_since_last_time_stamp"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel(
                            "of_inductions_since_last_time_stamp",
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck(
                        "of_inductions_since_last_time_stamp"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph(
                          "of_inductions_since_last_time_stamp",
                          e ? "1" : "0"
                        );
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck(
                        "of_inductions_since_last_time_stamp"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange(
                          "of_inductions_since_last_time_stamp",
                          e
                        );
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) =>
                            e.field_name === "of_births_since_last_time_stamp"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "of_births_since_last_time_stamp",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) =>
                              e.field_name === "of_births_since_last_time_stamp"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) =>
                              e.field_name === "of_births_since_last_time_stamp"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel(
                            "of_births_since_last_time_stamp",
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck(
                        "of_births_since_last_time_stamp"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph(
                          "of_births_since_last_time_stamp",
                          e ? "1" : "0"
                        );
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck(
                        "of_births_since_last_time_stamp"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange(
                          "of_births_since_last_time_stamp",
                          e
                        );
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) =>
                            e.field_name ===
                            "of_triage_patients_since_last_time_stamp"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "of_triage_patients_since_last_time_stamp",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) =>
                              e.field_name ===
                              "of_triage_patients_since_last_time_stamp"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) =>
                              e.field_name ===
                              "of_triage_patients_since_last_time_stamp"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel(
                            "of_triage_patients_since_last_time_stamp",
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck(
                        "of_triage_patients_since_last_time_stamp"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph(
                          "of_triage_patients_since_last_time_stamp",
                          e ? "1" : "0"
                        );
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck(
                        "of_triage_patients_since_last_time_stamp"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange(
                          "of_triage_patients_since_last_time_stamp",
                          e
                        );
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) =>
                            e.field_name === "of_pp_hemorrhage_ob_emergency"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "of_pp_hemorrhage_ob_emergency",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) =>
                              e.field_name === "of_pp_hemorrhage_ob_emergency"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) =>
                              e.field_name === "of_pp_hemorrhage_ob_emergency"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel(
                            "of_pp_hemorrhage_ob_emergency",
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck(
                        "of_pp_hemorrhage_ob_emergency"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph(
                          "of_pp_hemorrhage_ob_emergency",
                          e ? "1" : "0"
                        );
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck(
                        "of_pp_hemorrhage_ob_emergency"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange(
                          "of_pp_hemorrhage_ob_emergency",
                          e
                        );
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) => e.field_name === "of_resuscitations"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "of_resuscitations",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "of_resuscitations"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "of_resuscitations"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel(
                            "of_resuscitations",
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck(
                        "of_resuscitations"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph("of_resuscitations", e ? "1" : "0");
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck("of_resuscitations")}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange("of_resuscitations", e);
                      }}
                    />
                  </td>
                </tr>

                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) => e.field_name === "of_op_dialysis_patients"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "of_op_dialysis_patients",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "of_op_dialysis_patients"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "of_op_dialysis_patients"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel(
                            "of_op_dialysis_patients",
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck(
                        "of_op_dialysis_patients"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph(
                          "of_op_dialysis_patients",
                          e ? "1" : "0"
                        );
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck(
                        "of_op_dialysis_patients"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange(
                          "of_op_dialysis_patients",
                          e
                        );
                      }}
                    />
                  </td>
                </tr>

                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) => e.field_name === "cx_no_shows_w_i_24_hrs"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "cx_no_shows_w_i_24_hrs",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "cx_no_shows_w_i_24_hrs"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "cx_no_shows_w_i_24_hrs"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel(
                            "cx_no_shows_w_i_24_hrs",
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck(
                        "cx_no_shows_w_i_24_hrs"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph(
                          "cx_no_shows_w_i_24_hrs",
                          e ? "1" : "0"
                        );
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck(
                        "cx_no_shows_w_i_24_hrs"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange(
                          "cx_no_shows_w_i_24_hrs",
                          e
                        );
                      }}
                    />
                  </td>
                </tr>

                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) => e.field_name === "downtime_hrs"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "downtime_hrs",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "downtime_hrs"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "downtime_hrs"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel("downtime_hrs", e.target.value);
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck("downtime_hrs")}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph("downtime_hrs", e ? "1" : "0");
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck("downtime_hrs")}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange("downtime_hrs", e);
                      }}
                    />
                  </td>
                </tr>

                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) => e.field_name === "hrs_non_cath_proc"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "hrs_non_cath_proc",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "hrs_non_cath_proc"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "hrs_non_cath_proc"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel(
                            "hrs_non_cath_proc",
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck(
                        "hrs_non_cath_proc"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph("hrs_non_cath_proc", e ? "1" : "0");
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck("hrs_non_cath_proc")}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange("hrs_non_cath_proc", e);
                      }}
                    />
                  </td>
                </tr>

                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) =>
                            e.field_name === "of_proc_cx_no_shows_w_i_24_hrs"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "of_proc_cx_no_shows_w_i_24_hrs",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) =>
                              e.field_name === "of_proc_cx_no_shows_w_i_24_hrs"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) =>
                              e.field_name === "of_proc_cx_no_shows_w_i_24_hrs"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel(
                            "of_proc_cx_no_shows_w_i_24_hrs",
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck(
                        "of_proc_cx_no_shows_w_i_24_hrs"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph(
                          "of_proc_cx_no_shows_w_i_24_hrs",
                          e ? "1" : "0"
                        );
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck(
                        "of_proc_cx_no_shows_w_i_24_hrs"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange(
                          "of_proc_cx_no_shows_w_i_24_hrs",
                          e
                        );
                      }}
                    />
                  </td>
                </tr>

                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) => e.field_name === "hrs_equip_down"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "hrs_equip_down",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "hrs_equip_down"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "hrs_equip_down"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel("hrs_equip_down", e.target.value);
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck("hrs_equip_down")}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph("hrs_equip_down", e ? "1" : "0");
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck("hrs_equip_down")}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange("hrs_equip_down", e);
                      }}
                    />
                  </td>
                </tr>

                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) => e.field_name === "on_call_hrs_paid"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "on_call_hrs_paid",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "on_call_hrs_paid"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "on_call_hrs_paid"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel(
                            "on_call_hrs_paid",
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck(
                        "on_call_hrs_paid"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph("on_call_hrs_paid", e ? "1" : "0");
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck("on_call_hrs_paid")}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange("on_call_hrs_paid", e);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) => e.field_name === "times_called_in"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "times_called_in",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "times_called_in"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "times_called_in"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel("times_called_in", e.target.value);
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck("times_called_in")}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph("times_called_in", e ? "1" : "0");
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck("times_called_in")}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange("times_called_in", e);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) => e.field_name === "of_hrs_in_surgery"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "of_hrs_in_surgery",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "of_hrs_in_surgery"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "of_hrs_in_surgery"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel(
                            "of_hrs_in_surgery",
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck(
                        "of_hrs_in_surgery"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph("of_hrs_in_surgery", e ? "1" : "0");
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck("of_hrs_in_surgery")}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange("of_hrs_in_surgery", e);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) => e.field_name === "active_work_queue"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "active_work_queue",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "active_work_queue"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "active_work_queue"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel(
                            "active_work_queue",
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck(
                        "active_work_queue"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph("active_work_queue", e ? "1" : "0");
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck("active_work_queue")}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange("active_work_queue", e);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) => e.field_name === "total_work_queue"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "total_work_queue",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "total_work_queue"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "total_work_queue"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel(
                            "total_work_queue",
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck(
                        "total_work_queue"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph("total_work_queue", e ? "1" : "0");
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck("total_work_queue")}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange("total_work_queue", e);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) =>
                            e.field_name ===
                            "uncontacted_referral_grater_7_days"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "uncontacted_referral_grater_7_days",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) =>
                              e.field_name ===
                              "uncontacted_referral_grater_7_days"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) =>
                              e.field_name ===
                              "uncontacted_referral_grater_7_days"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel(
                            "uncontacted_referral_grater_7_days",
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck(
                        "uncontacted_referral_grater_7_days"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph(
                          "uncontacted_referral_grater_7_days",
                          e ? "1" : "0"
                        );
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck(
                        "uncontacted_referral_grater_7_days"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange(
                          "uncontacted_referral_grater_7_days",
                          e
                        );
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) => e.field_name === "surgery_hrs"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "surgery_hrs",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "surgery_hrs"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "surgery_hrs"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel("surgery_hrs", e.target.value);
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck("surgery_hrs")}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph("surgery_hrs", e ? "1" : "0");
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck("surgery_hrs")}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange("surgery_hrs", e);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) => e.field_name === "fluoro_hrs"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "fluoro_hrs",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "fluoro_hrs"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "fluoro_hrs"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel("fluoro_hrs", e.target.value);
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck("fluoro_hrs")}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph("fluoro_hrs", e ? "1" : "0");
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck("fluoro_hrs")}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange("fluoro_hrs", e);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) => e.field_name === "ip_same_day_add_ons"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "ip_same_day_add_ons",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "ip_same_day_add_ons"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "ip_same_day_add_ons"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel(
                            "ip_same_day_add_ons",
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck(
                        "ip_same_day_add_ons"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph(
                          "ip_same_day_add_ons",
                          e ? "1" : "0"
                        );
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck("ip_same_day_add_ons")}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange("ip_same_day_add_ons", e);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) => e.field_name === "actual_rn_worked_hrs"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "actual_rn_worked_hrs",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "actual_rn_worked_hrs"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "actual_rn_worked_hrs"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel(
                            "actual_rn_worked_hrs",
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck(
                        "actual_rn_worked_hrs"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph(
                          "actual_rn_worked_hrs",
                          e ? "1" : "0"
                        );
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck("actual_rn_worked_hrs")}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange("actual_rn_worked_hrs", e);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) => e.field_name === "uae_echos"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "uae_echos",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "uae_echos"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "uae_echos"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel("uae_echos", e.target.value);
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck("uae_echos")}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph("uae_echos", e ? "1" : "0");
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck("uae_echos")}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange("uae_echos", e);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) => e.field_name === "providers_out"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "providers_out",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "providers_out"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "providers_out"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel("providers_out", e.target.value);
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck("providers_out")}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph("providers_out", e ? "1" : "0");
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck("providers_out")}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange("providers_out", e);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) => e.field_name === "cancellations_within_24_hrs"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "cancellations_within_24_hrs",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) =>
                              e.field_name === "cancellations_within_24_hrs"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) =>
                              e.field_name === "cancellations_within_24_hrs"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel(
                            "cancellations_within_24_hrs",
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck(
                        "cancellations_within_24_hrs"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph(
                          "cancellations_within_24_hrs",
                          e ? "1" : "0"
                        );
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck(
                        "cancellations_within_24_hrs"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange(
                          "cancellations_within_24_hrs",
                          e
                        );
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) => e.field_name === "ip_add_ons"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "ip_add_ons",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "ip_add_ons"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "ip_add_ons"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel("ip_add_ons", e.target.value);
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck("ip_add_ons")}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph("ip_add_ons", e ? "1" : "0");
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck("ip_add_ons")}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange("ip_add_ons", e);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) => e.field_name === "dac_hrs_spent"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "dac_hrs_spent",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "dac_hrs_spent"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "dac_hrs_spent"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel("dac_hrs_spent", e.target.value);
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck("dac_hrs_spent")}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph("dac_hrs_spent", e ? "1" : "0");
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck("dac_hrs_spent")}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange("dac_hrs_spent", e);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) => e.field_name === "no_shows"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "no_shows",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "no_shows"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "no_shows"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel("no_shows", e.target.value);
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck("no_shows")}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph("no_shows", e ? "1" : "0");
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck("no_shows")}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange("no_shows", e);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) => e.field_name === "cx_w_i_24_hrs"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "cx_w_i_24_hrs",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "cx_w_i_24_hrs"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "cx_w_i_24_hrs"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel("cx_w_i_24_hrs", e.target.value);
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck("cx_w_i_24_hrs")}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph("cx_w_i_24_hrs", e ? "1" : "0");
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck("cx_w_i_24_hrs")}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange("cx_w_i_24_hrs", e);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) =>
                            e.field_name === "patients_w_stay_grater_70_min"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "patients_w_stay_grater_70_min",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) =>
                              e.field_name === "patients_w_stay_grater_70_min"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) =>
                              e.field_name === "patients_w_stay_grater_70_min"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel(
                            "patients_w_stay_grater_70_min",
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck(
                        "patients_w_stay_grater_70_min"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph(
                          "patients_w_stay_grater_70_min",
                          e ? "1" : "0"
                        );
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck(
                        "patients_w_stay_grater_70_min"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange(
                          "patients_w_stay_grater_70_min",
                          e
                        );
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) => e.field_name === "crash_carts_set_up_restocked"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "crash_carts_set_up_restocked",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) =>
                              e.field_name === "crash_carts_set_up_restocked"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) =>
                              e.field_name === "crash_carts_set_up_restocked"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel(
                            "crash_carts_set_up_restocked",
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck(
                        "crash_carts_set_up_restocked"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph(
                          "crash_carts_set_up_restocked",
                          e ? "1" : "0"
                        );
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck(
                        "crash_carts_set_up_restocked"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange(
                          "crash_carts_set_up_restocked",
                          e
                        );
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) => e.field_name === "instruments_returned_unused"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "instruments_returned_unused",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) =>
                              e.field_name === "instruments_returned_unused"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) =>
                              e.field_name === "instruments_returned_unused"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel(
                            "instruments_returned_unused",
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck(
                        "instruments_returned_unused"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph(
                          "instruments_returned_unused",
                          e ? "1" : "0"
                        );
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck(
                        "instruments_returned_unused"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange(
                          "instruments_returned_unused",
                          e
                        );
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) => e.field_name === "flashed_items"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "flashed_items",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "flashed_items"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "flashed_items"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel("flashed_items", e.target.value);
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck("flashed_items")}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph("flashed_items", e ? "1" : "0");
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck("flashed_items")}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange("flashed_items", e);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) =>
                            e.field_name ===
                            "procedures_requiring_staff_grater_normal_ortho"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "procedures_requiring_staff_grater_normal_ortho",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) =>
                              e.field_name ===
                              "procedures_requiring_staff_grater_normal_ortho"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) =>
                              e.field_name ===
                              "procedures_requiring_staff_grater_normal_ortho"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel(
                            "procedures_requiring_staff_grater_normal_ortho",
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck(
                        "procedures_requiring_staff_grater_normal_ortho"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph(
                          "procedures_requiring_staff_grater_normal_ortho",
                          e ? "1" : "0"
                        );
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck(
                        "procedures_requiring_staff_grater_normal_ortho"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange(
                          "procedures_requiring_staff_grater_normal_ortho",
                          e
                        );
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) =>
                            e.field_name ===
                            "procedures_requiring_grater_normal_plastics"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "procedures_requiring_grater_normal_plastics",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) =>
                              e.field_name ===
                              "procedures_requiring_grater_normal_plastics"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) =>
                              e.field_name ===
                              "procedures_requiring_grater_normal_plastics"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel(
                            "procedures_requiring_grater_normal_plastics",
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck(
                        "procedures_requiring_grater_normal_plastics"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph(
                          "procedures_requiring_grater_normal_plastics",
                          e ? "1" : "0"
                        );
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck(
                        "procedures_requiring_grater_normal_plastics"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange(
                          "procedures_requiring_grater_normal_plastics",
                          e
                        );
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) =>
                            e.field_name ===
                            "procedures_requiring_grater_normal_for_ophthamolgy"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "procedures_requiring_grater_normal_for_ophthamolgy",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) =>
                              e.field_name ===
                              "procedures_requiring_grater_normal_for_ophthamolgy"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) =>
                              e.field_name ===
                              "procedures_requiring_grater_normal_for_ophthamolgy"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel(
                            "procedures_requiring_grater_normal_for_ophthamolgy",
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck(
                        "procedures_requiring_grater_normal_for_ophthamolgy"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph(
                          "procedures_requiring_grater_normal_for_ophthamolgy",
                          e ? "1" : "0"
                        );
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck(
                        "procedures_requiring_grater_normal_for_ophthamolgy"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange(
                          "procedures_requiring_grater_normal_for_ophthamolgy",
                          e
                        );
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) =>
                            e.field_name ===
                            "procedures_requiring_grater_normal_general_baritics_other"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "procedures_requiring_grater_normal_general_baritics_other",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) =>
                              e.field_name ===
                              "procedures_requiring_grater_normal_general_baritics_other"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) =>
                              e.field_name ===
                              "procedures_requiring_grater_normal_general_baritics_other"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel(
                            "procedures_requiring_grater_normal_general_baritics_other",
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck(
                        "procedures_requiring_grater_normal_general_baritics_other"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph(
                          "procedures_requiring_grater_normal_general_baritics_other",
                          e ? "1" : "0"
                        );
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck(
                        "procedures_requiring_grater_normal_general_baritics_other"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange(
                          "procedures_requiring_grater_normal_general_baritics_other",
                          e
                        );
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) => e.field_name === "ip_status_patients_held_over"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "ip_status_patients_held_over",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) =>
                              e.field_name === "ip_status_patients_held_over"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) =>
                              e.field_name === "ip_status_patients_held_over"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel(
                            "ip_status_patients_held_over",
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck(
                        "ip_status_patients_held_over"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph(
                          "ip_status_patients_held_over",
                          e ? "1" : "0"
                        );
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck(
                        "ip_status_patients_held_over"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange(
                          "ip_status_patients_held_over",
                          e
                        );
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) =>
                            e.field_name ===
                            "of_hours_the_rcu_was_open_last_4_hrs"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "of_hours_the_rcu_was_open_last_4_hrs",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) =>
                              e.field_name ===
                              "of_hours_the_rcu_was_open_last_4_hrs"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) =>
                              e.field_name ===
                              "of_hours_the_rcu_was_open_last_4_hrs"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel(
                            "of_hours_the_rcu_was_open_last_4_hrs",
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck(
                        "of_hours_the_rcu_was_open_last_4_hrs"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph(
                          "of_hours_the_rcu_was_open_last_4_hrs",
                          e ? "1" : "0"
                        );
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck(
                        "of_hours_the_rcu_was_open_last_4_hrs"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange(
                          "of_hours_the_rcu_was_open_last_4_hrs",
                          e
                        );
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) =>
                            e.field_name ===
                            "one_1_and_1_2_pts_currently_awaiting_placement"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "one_1_and_1_2_pts_currently_awaiting_placement",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) =>
                              e.field_name ===
                              "one_1_and_1_2_pts_currently_awaiting_placement"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) =>
                              e.field_name ===
                              "one_1_and_1_2_pts_currently_awaiting_placement"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel(
                            "one_1_and_1_2_pts_currently_awaiting_placement",
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck(
                        "one_1_and_1_2_pts_currently_awaiting_placement"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph(
                          "one_1_and_1_2_pts_currently_awaiting_placement",
                          e ? "1" : "0"
                        );
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck(
                        "one_1_and_1_2_pts_currently_awaiting_placement"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange(
                          "one_1_and_1_2_pts_currently_awaiting_placement",
                          e
                        );
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) =>
                            e.field_name ===
                            "code_3s_stemi_or_strokes_last_4_hours"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "code_3s_stemi_or_strokes_last_4_hours",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) =>
                              e.field_name ===
                              "code_3s_stemi_or_strokes_last_4_hours"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) =>
                              e.field_name ===
                              "code_3s_stemi_or_strokes_last_4_hours"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel(
                            "code_3s_stemi_or_strokes_last_4_hours",
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck(
                        "code_3s_stemi_or_strokes_last_4_hours"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph(
                          "code_3s_stemi_or_strokes_last_4_hours",
                          e ? "1" : "0"
                        );
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck(
                        "code_3s_stemi_or_strokes_last_4_hours"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange(
                          "code_3s_stemi_or_strokes_last_4_hours",
                          e
                        );
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) => e.field_name === "of_psa_si_pts"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "of_psa_si_pts",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "of_psa_si_pts"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "of_psa_si_pts"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel("of_psa_si_pts", e.target.value);
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck("of_psa_si_pts")}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph("of_psa_si_pts", e ? "1" : "0");
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck("of_psa_si_pts")}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange("of_psa_si_pts", e);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) => e.field_name === "of_lost_doses"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "of_lost_doses",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "of_lost_doses"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "of_lost_doses"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel("of_lost_doses", e.target.value);
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck("of_lost_doses")}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph("of_lost_doses", e ? "1" : "0");
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck("of_lost_doses")}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange("of_lost_doses", e);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) =>
                            e.field_name === "active_cases_in_the_work_queue"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "active_cases_in_the_work_queue",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) =>
                              e.field_name === "active_cases_in_the_work_queue"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) =>
                              e.field_name === "active_cases_in_the_work_queue"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel(
                            "active_cases_in_the_work_queue",
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck(
                        "active_cases_in_the_work_queue"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph(
                          "active_cases_in_the_work_queue",
                          e ? "1" : "0"
                        );
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck(
                        "active_cases_in_the_work_queue"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange(
                          "active_cases_in_the_work_queue",
                          e
                        );
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) => e.field_name === "of_ir_vas_procedures"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "of_ir_vas_procedures",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "of_ir_vas_procedures"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "of_ir_vas_procedures"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel(
                            "of_ir_vas_procedures",
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck(
                        "of_ir_vas_procedures"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph(
                          "of_ir_vas_procedures",
                          e ? "1" : "0"
                        );
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck("of_ir_vas_procedures")}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange("of_ir_vas_procedures", e);
                      }}
                    />
                  </td>
                </tr>

                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) => e.field_name === "hi_flow_in_us"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "hi_flow_in_us",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "hi_flow_in_us"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "hi_flow_in_us"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel("hi_flow_in_us", e.target.value);
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck("hi_flow_in_us")}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph("hi_flow_in_us", e ? "1" : "0");
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck("hi_flow_in_us")}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange("hi_flow_in_us", e);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) => e.field_name === "of_vents_in_use"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "of_vents_in_use",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "of_vents_in_use"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "of_vents_in_use"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel("of_vents_in_use", e.target.value);
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck("of_vents_in_use")}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph("of_vents_in_use", e ? "1" : "0");
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck("of_vents_in_use")}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange("of_vents_in_use", e);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) => e.field_name === "of_bi_paps_in_use"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "of_bi_paps_in_use",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "of_bi_paps_in_use"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "of_bi_paps_in_use"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel(
                            "of_bi_paps_in_use",
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck(
                        "of_bi_paps_in_use"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph("of_bi_paps_in_use", e ? "1" : "0");
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck("of_bi_paps_in_use")}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange("of_bi_paps_in_use", e);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) => e.field_name === "of_c_paps_in_use_noc_only"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "of_c_paps_in_use_noc_only",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "of_c_paps_in_use_noc_only"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "of_c_paps_in_use_noc_only"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel(
                            "of_c_paps_in_use_noc_only",
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck(
                        "of_c_paps_in_use_noc_only"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph(
                          "of_c_paps_in_use_noc_only",
                          e ? "1" : "0"
                        );
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck(
                        "of_c_paps_in_use_noc_only"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange(
                          "of_c_paps_in_use_noc_only",
                          e
                        );
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) => e.field_name === "of_loaner_trays_processed"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "of_loaner_trays_processed",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "of_loaner_trays_processed"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "of_loaner_trays_processed"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel(
                            "of_loaner_trays_processed",
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck(
                        "of_loaner_trays_processed"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph(
                          "of_loaner_trays_processed",
                          e ? "1" : "0"
                        );
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck(
                        "of_loaner_trays_processed"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange(
                          "of_loaner_trays_processed",
                          e
                        );
                      }}
                    />
                  </td>
                </tr>

                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) =>
                            e.field_name === "urgent_cases_added_to_schedule"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "urgent_cases_added_to_schedule",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) =>
                              e.field_name === "urgent_cases_added_to_schedule"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) =>
                              e.field_name === "urgent_cases_added_to_schedule"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel(
                            "urgent_cases_added_to_schedule",
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck(
                        "urgent_cases_added_to_schedule"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph(
                          "urgent_cases_added_to_schedule",
                          e ? "1" : "0"
                        );
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck(
                        "urgent_cases_added_to_schedule"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange(
                          "urgent_cases_added_to_schedule",
                          e
                        );
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) => e.field_name === "unplanned_providers_absent"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "unplanned_providers_absent",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "unplanned_providers_absent"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "unplanned_providers_absent"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel(
                            "unplanned_providers_absent",
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck(
                        "unplanned_providers_absent"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph(
                          "unplanned_providers_absent",
                          e ? "1" : "0"
                        );
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck(
                        "unplanned_providers_absent"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange(
                          "unplanned_providers_absent",
                          e
                        );
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) =>
                            e.field_name ===
                            "no_show_patients_same_day_cancelation"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "no_show_patients_same_day_cancelation",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) =>
                              e.field_name ===
                              "no_show_patients_same_day_cancelation"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) =>
                              e.field_name ===
                              "no_show_patients_same_day_cancelation"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel(
                            "no_show_patients_same_day_cancelation",
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleDisplayGraphSwitchcheck(
                        "no_show_patients_same_day_cancelation"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataGraph(
                          "no_show_patients_same_day_cancelation",
                          e ? "1" : "0"
                        );
                      }}
                    />
                  </td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck(
                        "no_show_patients_same_day_cancelation"
                      )}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange(
                          "no_show_patients_same_day_cancelation",
                          e
                        );
                      }}
                    />
                  </td>
                </tr>

                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) => e.field_name === "comment"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "comment",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "comment"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "comment"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel("comment", e.target.value);
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle"></td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck("comment")}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange("comment", e);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="sm-transparent-input">
                    <input
                      type="text"
                      class="form-control"
                      value={
                        formControl.additional_data_tracking?.find(
                          (e) => e.field_name === "submitted_by_text"
                        )?.display
                      }
                      onChange={(e) => {
                        handleAddDataSort(
                          "submitted_by_text",
                          e.target.value !== "" ? e.target.value : "0"
                        );
                      }}
                    />
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "submitted_by_text"
                          )?.field_name
                        }
                        readOnly
                      />
                    </div>
                  </td>

                  <td className="pe-5">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control mx-w-340"
                        value={
                          formControl.additional_data_tracking?.find(
                            (e) => e.field_name === "submitted_by_text"
                          )?.field_label
                        }
                        onChange={(e) => {
                          handleAddDataLabel(
                            "submitted_by_text",
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  </td>
                  <td className="vertical-aling-middle"></td>
                  <td className="vertical-aling-middle">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={handleAddDataSwitchcheck("submitted_by_text")}
                      className="blue-b"
                      onChange={(e) => {
                        handleAddDataSwitchOnChange("submitted_by_text", e);
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdditionalDataAccordian;
