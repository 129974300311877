import Chart from "react-apexcharts";
import moment from 'moment';
import { useContext } from "react";
import { ThemeContext } from "../../../providers/ThemeProvider";


function BarChart({barchartseries, barchartcat, ids, callbackfunc}) {
  const [themeMode, setTheme] = useContext(ThemeContext);
    var options = {
       chart: {
        type: 'bar',
        height: 150,
      //  stacked: false,
        color: themeMode.theme === "DARK" ? "white" : "black",
      
        toolbar: {
          tools: {
            download: false,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false | '<img src="/static/icons/reset.png" width="20">',
            customIcons: []
          }
        },
        events: {
          dataPointSelection: function(event, chartContext, config) {
            const getid = config?.w?.config?.plotOptions?.ids[config?.dataPointIndex]; 
            callbackfunc(getid);
          }
        },
        zoom: {
          enabled: true
        }
      },
      series: [{
        data: [{
          x: 'category A',
          y: 10
        }, {
          x: 'category B',
          y: 18
        }, {
          x: 'category C',
          y: 13
        }]
      }],
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0
          }
        }
      }],
      plotOptions: {
        
          bar: {
            columnWidth: '70%',
            barHeight: '70%',
            dataLabels: {
              position: "top", // top, center, bottom
    
            },
          },
    
      
        ids:ids
      },
      dataLabels: {
        enabled: true,
        formatter: (value) => {
          return value.toFixed(0)
        },
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: themeMode.theme === "DARK" ? ["white"] : ["black"],
          padding: {
            top: 30
          },
        },
      
      },
      xaxis: {
       // position: 'bottom',
        labels: {
          show: true,
          style: {
              colors: themeMode.theme === "DARK" ? ["#FFF", "#FFF", "#FFF", "#FFF", "#FFF", "#FFF"] : ["#191C24", "#191C24", "#191C24", "#191C24", "#191C24", "#191C24"], 
          },
      },
      
      },
      yaxis: {
        // position: 'bottom',
         labels: {
           show: true,
           formatter: (value) => {
            return value.toFixed(0)
          },
           style: {
               colors: themeMode.theme === "DARK" ? ["#FFF", "#FFF", "#FFF", "#FFF", "#FFF", "#FFF"] : ["#191C24", "#191C24", "#191C24", "#191C24", "#191C24", "#191C24"],
           },   
       },  
       
       },
      legend: {
        show: false,
        position: 'right',
        offsetY: 40
      },
      tooltip: {
        enabled: true,
        x: {
          show: false,
        },
      
        //  x: {
        //     show: false,
        //     format: 'dd MMM',
        //     formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
        //       const getarr = w?.config?.xaxis.categories[dataPointIndex] ? w.config.xaxis.categories[dataPointIndex].split(' ') : [];
        //       const getdate = getarr.length > 0 ? getarr[0] : '';
        //       const gettime = getarr.length > 0 ? moment(getarr[1], ["HH:mm"]).format("hh:mm A") : '';
        //       return `${getdate} ${gettime}`; 
        //     },
        // }
      },
      fill: {
        opacity: 1
      }
      };

    return (
        <div className="mt-2 pb-0">
            <Chart
                options={options}
                series={[{
                  name: "Workdrivers",
                  data: [5, 20, 38, 45, 19, 23, 54]
                }]}
               // series={barchartseries}
                type="bar"
                width={'100%'}
                height={250}
            />
        </div>
    );
}

export default BarChart;