"use strict";
import React, { useEffect, useState, useContext } from "react";
import { AgChartsReact } from "ag-charts-react";
import { Link } from "react-router-dom";
import { getPreviousShiftData } from "../../../services";
import { useSelector, useDispatch } from "react-redux";
import { ThemeContext } from "../../../providers/ThemeProvider";

export const ChartExample = ({
  data,
  callbackfunc,
  data1,
  boxColor,
  isdetail,
  directId,
}) => {
  const [urltype, setUrltype] = useState("realtime");
  const [themeMode, setTheme] = useContext(ThemeContext);
  const dispatch = useDispatch();
  const colorStatus = {
    entry_on_time: "#48b461",
    entry_missing: "#ff2626",
    entry_upcoming: "#d0cece",
    entry_delayed: "#fe9b26",
    entry_due: "#FFEF00",
  };

  const inner_ring = data?.inner_ring;
  const show_inner_ring = data?.show_inner_ring
  const arr1 = [];
  const arr2 = [];

  const outerringval = (outer_ring_val) => {
    const seriesarr = [];
    if (outer_ring_val >= 100) {
      arr1.push("#0a9d2c");
      arr1.push("#48b461");
      let caldata = outer_ring_val % 100;
      //setOutervalue(outer_ring_val);
      seriesarr.push({ calval: outer_ring_val, count: caldata });
      seriesarr.push({ calval: outer_ring_val, count: 100 - caldata });
    } else if (outer_ring_val < 100 && outer_ring_val >= 98) {
      arr1.push("#ffff26");
      arr1.push("#ffffb1");
      //setOutervalue(outer_ring_val);
      seriesarr.push({ calval: outer_ring_val, count: outer_ring_val });
      seriesarr.push({ calval: outer_ring_val, count: 100 - outer_ring_val });
    } else {
      arr1.push("#ff2626");
      arr1.push("#fde9e9");
      //setOutervalue(outer_ring_val);
      seriesarr.push({
        name: "Covered",
        calval: outer_ring_val,
        count: outer_ring_val,
      });
      seriesarr.push({
        name: "Not Covered",
        calval: outer_ring_val,
        count: 100 - outer_ring_val,
      });
    }
    return seriesarr;
  };

  const seriesdata = outerringval(data?.outer_ring);


  if (data?.inner_ring) {
    data?.inner_ring.map((v) => {
      //console.log({ v })
      if (colorStatus[v.status]) {
        arr2.push(colorStatus[v.status]);
      }
    });
  }

  const options = {
    series: [
      {
        type: "pie",

        theme: 'ag-default-dark',
        calloutLabelKey: "",
        data: seriesdata,
        angleKey: "count",
        outerRadiusRatio: show_inner_ring === 1 ? 0.83 : 0.72,
        innerRadiusRatio: 0.58,
        showInLegend: false,
        strokeWidth: 0,
        fills: ["red", "yellow"],
        tooltip: { renderer: renderer1 },
        highlightStyle: {
          item: {
            fill: "#CCCCCC00",
            //    stroke: 'maroon',
            strokeWidth: 0,
          },
          series: {
            dimOpacity: 1,
            strokeWidth: 0,
          },
        },
        plotOptions: {

          radialBar: {

            hollow: {
              margin: 0,
              size: "70%",
              // background: "#293450",

            },
            track: {
              dropShadow: {
                enabled: true,
                top: 2,
                left: 0,
                blur: 4,
                opacity: 0.15,
              },
              stroke: {
                lineCap: "round",
              },
            },
            dataLabels: {
              name: {
                offsetY: -10,
                color: "#fff",
                // fontSize: "13px",
              },
              value: {
                color: "#fff",
                fontSize: "30px",
                show: true,
              },
            },
            pie: {
              expandOnClick: true,

            },
          },
        },
        fills: arr1,
      },
      {
        type: "pie",
        data: show_inner_ring === 1 ? inner_ring : [],
        // innerCircle: {
        //   fill: '#191C24',
        // },
        // calloutLabelKey: 'shift',
        // angleKey: "percentage",
        angleKey: "percentage",
        calloutLabelKey: "shift_time",
        calloutLabel: {
          fontSize: 9,
          color: themeMode.theme === "DARK" ? "#FFF" : "black",
          // fontWeight: 'light',
        },
        //  calloutLabel: {
        //   formatter: ({ datum, calloutLabelKey, angleKey }) => {
        //     return `${datum[calloutLabelKey]}: ${datum[angleKey]}`;
        //    },
        //  },
        //  sectorLabelKey: 'percentage',
        //  sectorLabel: {
        //    color: 'white',
        //    fontWeight: 'bold',
        //  },
        outerRadiusRatio: 1.1,
        innerRadiusRatio: 0.86,

        showInLegend: false,
        strokes: themeMode.theme === "DARK" ? ["#191C24", "#191C24", "#191C24", "#191C24", "#191C24", "#191C24"] : ["#FFF", "#FFF", "#FFF", "#FFF", "#FFF", "#FFF"],
        strokeWidth: 3,
        listeners: {
          nodeClick: (e) => {
            if (e.datum.status != "entry_upcoming") {
              getPreviousShiftData(dispatch, directId, e.datum.shift_date, e.datum.shift_time);
              callbackfunc(
                // e.datum.id == null ? false : e.datum.id,
                // e.datum.shift_time + ":00"
                e.datum
              );
            }
          },
        },
        highlightStyle: {
          item: {
            fill: "transparent",
            scale: 1.5,
            strokeOpacity: "#000",

            strokeWidth: 1,
          //  stroke: '#000',
            stroke: themeMode.theme === "DARK" ? "white" : "black"

          },
          series: {
            dimOpacity: 1,
          },
        },

        //   sectorLabelKey: "shift",
        // sectorLabel: {
        //   //color: color(),
        //   color: "#000",
        //   fontWeight: "600",
        //   fontSize: 15,
        //   textShadow: "5px",
        // },
        tooltip: { renderer: renderer },
        fills: arr2, // ['red', 'green', 'blue', 'yellow', 'black', 'gray'],
        // fills:  ['red', 'green', 'blue', 'yellow', 'black', 'gray'],
      },
    ],
    // backgroundColor: { fill: "yellow" },
    background: {
      fill: themeMode.theme === "DARK" ? "#191C24" : "white",
    },
  };



  const chartOptions = {
    container: { background: { fill: 'yellow' } }, // Set the background color for the chart container
  };

  function renderer(params) {
    //getColorFun(params.color)
    return `<div class="ag-chart-tooltip-title" >
          ${params.datum.message}
        </div>`;
  }

  function renderer1(params) {
    return `<div class="ag-chart-tooltip-title" >
        ${params.datum?.calval}%
        </div>`;
  }



  return (
    <div
      className={isdetail ? "" : "col-md-3 UU"}
      style={{ backgroundColor: boxColor }}
    >
      {/* {testText+donutColor} */}
      
        <div
          className={
            isdetail
              ? "white-bg undefined p-0"
              : "white-bg shadow-box undefined p-0"
          }
        >
          <div className=" text-center pt-2 pb-2">
            <strong className="m-f14 primary-font ">
              {data1.cost_center_name}{" "}
            </strong>
            <div className="row align-items-center">
            <div className="twopx col-7">
            <Link
        className="boxlink"
        to={`/realtime/department-details/${data1?.slug}/${urltype}`}
      >
              <AgChartsReact
                options={options}
                backgroundColor="yellow"
              // nodeMouseEnter={(event) =>
              //   handleHover(event, true, "black")
              // }
              // nodeMouseLeave={(event) => handleHover(event, false)}
              />
              </Link>
            </div>
            <div className="col-5 width-160">
              <ul className="realtime-details">
                <li>Census - 12</li>
                <li>Available Beds - 12</li>
                <li>RN Available Hours - 22</li>
                <li>Grid RN Variance - 5</li>
                <li>Epic Score - 127</li>
              </ul>

            </div>
            </div>
          </div>
        </div>
      
    </div>
  );
};
