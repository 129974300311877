import { useEffect } from "react";
import Chart from "react-apexcharts";



function SmallRadialChart({color, series, colorData}) {
    const options = {
        chart: { width: 70, type: "radialBar", stroke: {
            lineCap: "round"
          }},
          colors: [ "#28a745", colorData],
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    height: 0,
                    width:50,
                },
            },
            breakpoint: 1441,
            options: {
                chart: {
                    height: 60
                },
            }
        }],
        plotOptions: {
            radialBar: {
                hollow: {
                    margin: 0,
                    size: "45%",
                    background: "transparent"
                },
                track: {
                    show: true,
                    background: "#FFFFFF",
                    strokeWidth: "1%",
                    opacity: 1,
                    margin: 0 // margin is in pixels
                },
                
                dataLabels: {
                    name: {
                        fontSize: '0px',
                    },
                    value: {
                        fontSize: '0px',
                    },
                }
            }
        },
        labels: ["", ""]
    }

    return (
        <div className="m-auto">
            <Chart
                options={options}
                series={series}
                type="radialBar"
                width={40}
                height={70}
            />
        </div>
    );
}

export default SmallRadialChart;