import "./index.css";
import { useEffect, useContext, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DashboardCounter from "../../components/DashboardCounter/index";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import Select from "react-select";
import Form from "react-bootstrap/Form";
import PageLayout from "../../layouts/PageLayout";
import Ico1 from "../../assets/img/edit.png";
import AscIcon from "../../assets/img/desc.png";
import DescIcon from "../../assets/img/asc.png";
import { get } from "lodash";
import { API_CALL_URL_PRIVATE } from "../../constants";
import axios from "../../axios";
import { DOMAIN_URL } from "../../constants/compensate";
import ReactPaginate from "react-paginate";
import { setLoadingPage } from "../../store/actions";
import { flushSync } from "react-dom";

function Applications(props) {
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem("psAdmin"));
  const customer_code = useSelector((state) => state.compensate.customerCode);
  const loading_page = useSelector((state) => state.compensate.LoadingPage);
  const [facilityFilterOptions, setFacilityFilterOptions] = useState([]);
  const [facilityType, setFacilityType] = useState(null);
  console.log(customer_code, "customercode");
  const [dashboardData, setDashboardData] = useState([]);
  const [customerData, setCustomerData] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [pageLimit, setPageLimit] = useState(50);
  const [totalPages, setTotalPages] = useState();
  // const [currentPage, setCurrentPage] = useState(1);
  const [hasPrev, setHasPrev] = useState(false);
  const [hasNext, setHasNext] = useState(false);
  const [sortKey, setSortKey] = useState("");
  const [sortOrder, setSortOrder] = useState(1);
  const boxdata = props.data;
  const isLoader = props.isLoader;
  const { issearch } = props;
  const [userDatas, setUserDatas] = useState(get(userData, "data", []));
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCounter, setPageCounter] = useState("");
  const [isInitialData, setISInitialData] = useState(false);
  const [searchTempKeyword, setSearchTempKeyword] = useState("");
  const [listLoader, setListLoader] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const [applicationFilterOptions, setApplicationFilterOptions] = useState([
    {
      label: "Realtime",
      value: 101,
    },
    {
      label: "ADM",
      value: 105,
    },
  ]);
  const [applicationType, setApplicationType] = useState(null);

  //date function for 24 hour format
  // function getDate(inputDate) {
  //   const timeZone = "America/Los_Angeles";
  //   const date = new Date(
  //     new Date(inputDate).toLocaleString("en-US", { timeZone })
  //   );

  //   const day = String(date.getDate()).padStart(2, "0");
  //   const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
  //   const year = date.getFullYear();
  //   const hours = String(date.getHours()).padStart(2, "0"); // 24-hour format
  //   const minutes = String(date.getMinutes()).padStart(2, "0");

  //   return `${month}/${day}/${year}, ${hours}:${minutes}`;
  // }

  //date function for 12 hour format
  function getDate(inputDate) {
    const timeZone = "America/Los_Angeles";
    const date = new Date(inputDate).toLocaleString("en-US", {
      timeZone,
      hour12: true, // Enable 12-hour format
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    });
    return date;
  }
  // const sortingHandle = (key, bindname) => {
  //   const sortdata = getTableRecordWithSorting(get(tableData, bindname, []), key, sortType);
  //   setSortType(sortType === 'asc' ? 'desc' : 'asc');
  //   setTableData({...tableData, cost_centers : sortdata});
  //   setsortName(key);
  // }

  const renderElements = () => {
    return Array.from({ length: totalPages }, (_, index) => (
      <span
        key={index + 1}
        className={
          index + 1 === currentPage
            ? "paginate_button current"
            : "paginate_button"
        }
        aria-controls="example"
        role="link"
        aria-current="page"
        data-dt-idx="0"
        tabindex="0"
        onClick={() => {
          setCurrentPage(index + 1);
        }}
      >
        {index + 1}
      </span>
    ));
  };

  useEffect(() => {
    const getCustomerDetailList = async () => {
      try {
        const resp = await axios.post(
          `${API_CALL_URL_PRIVATE}/department/departmentList`,
          {
            customer_code: parseInt(customer_code),
            searchKeyword: searchKeyword,
            limit: pageLimit,
            page: searchActive ? 1 : currentPage,
            sort_column: searchActive ? "" : sortKey,
            sort_order: searchActive ? 1 : sortOrder,
            log_activity: "yes",
            facility_id: parseInt(facilityType),
            application_id: parseInt(applicationType),
          }
        );

        const Data = resp?.data?.data?.data;
        setSearchActive(false);
        setCustomerData(Data);
        setPageLimit(Data?.limit);
        setTotalPages(Data?.totalPages);
        setCurrentPage(Data?.page);
        setHasNext(Data?.hasNextPage);
        setHasPrev(Data?.hasPrevPage);
        setPageCounter(Data?.pagingCounter);
        setListLoader(false);
        setISInitialData(true);
        dispatch(setLoadingPage(false));
      } catch (error) {
        console.log(error);
      }
    };

    const getFacilityList = async () => {
      try {
        const resp = await axios.post(
          `${API_CALL_URL_PRIVATE}/general/facilityMaster`,
          {
            customer_code: parseInt(customer_code),
          }
        );
        const Detail = resp?.data?.data;

        const drop_facility = Detail?.map((e) => {
          return {
            label: e?.facility_name,
            value: e?.facility_id,
          };
        });
        setFacilityFilterOptions([...drop_facility]);
      } catch (error) {
        console.log(error);
      }
    };

    getFacilityList();
    getCustomerDetailList();
  }, [
    searchKeyword,
    pageLimit,
    currentPage,
    sortOrder,
    customer_code,
    sortKey,
    facilityType,
    applicationType
  ]);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected + 1);
    setListLoader(true);
  };

  const renderSortIcon = (key) => {
    if (sortKey !== key) return <img src={DescIcon} alt="Descending" />;
    return sortOrder === -1 ? (
      <img src={DescIcon} alt="Descending" />
    ) : sortOrder === 1 ? (
      <img src={AscIcon} alt="Ascending" />
    ) : null;
  };

  // const renderSortIcon = (key) => {
  //   if (sortKey === key) {
  //     return sortOrder === 1 ? (
  //       <img src={AscIcon} alt="Ascending" />
  //     ) : (
  //       <img src={DescIcon} alt="Descending" />
  //     );
  //   } else {
  //     // Display down arrow for non-active sort columns
  //     return <img src={AscIcon} alt="Descending" />;
  //   }
  // };

  const handleSort = (key) => {
    if (sortKey === key) {
      setSortOrder(sortOrder === -1 ? 1 : -1);
      setCurrentPage(1);
      setListLoader(true);
    } else {
      setSortKey(key);
      setSortOrder(1);
      setCurrentPage(1);
      setListLoader(true);
    }
  };

  return (
    <>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="page-header">
            <h3 className="page-title">
              <a href="#" className="badge badge-dark"></a>
            </h3>
            <div className></div>
          </div>
          <div className="row">
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between mb-4 mobile-wrap">
                    <h4 className="card-title">All Cost Centers</h4>
                    
                      <div className="ms-4 d-flex w-25 mobile-w-auto">
                        <h5 class="m-0 fs-6">Facility Type</h5>
                        <Select
                          isSearchable="true"
                          options={facilityFilterOptions}
                          className="custom-select flex-fill mx-2"
                          onChange={(e) => {
                            setFacilityType(e.value);
                            setListLoader(true);
                          }}
                        />
                      </div>
                      <div className="ms-4 d-flex w-25 mobile-w-auto">
                        <h5 class="m-0 fs-6">Application Type</h5>
                        <Select
                          isSearchable="true"
                          options={applicationFilterOptions}
                          className="custom-select flex-fill mx-2"
                          onChange={(e) => {
                            setApplicationType(e.value);
                            setListLoader(true);
                          }}
                        />
                      </div>
                   
                    <Link
                      className="boxlink"
                      to="/admin/department/add-department-new"
                    >
                      {" "}
                      <button type="button" class="btn btn-success">
                        Add Cost Centers
                      </button>
                    </Link>
                  </div>
                  <div className="d-flex justify-content-between mobile-wrap">
                    <div class="dataTables_length" id="example_length">
                      <label>
                        Show &nbsp;
                        <select
                          name="example_length"
                          aria-controls="example"
                          class=""
                          value={pageLimit}
                          onChange={(e) => {
                            setPageLimit(e.target.value);
                            setListLoader(true);
                          }}
                        >
                          <option value="5">5</option>
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>{" "}
                        &nbsp;entries
                      </label>
                    </div>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault(); // Prevent the default form submission behavior
                        if (searchKeyword !== searchTempKeyword) {
                          flushSync(() => {
                            setSearchActive(true);
                            setSortKey("");
                          });
                          setListLoader(true);
                          setSearchKeyword(searchTempKeyword);
                        }
                      }}
                    >
                      <div
                        id="example_filter"
                        class="dataTables_filter d-flex align-items-center justify-content-center "
                      >
                        <label>
                          Search: &nbsp;
                          <input
                            type="text"
                            class=""
                            placeholder=""
                            aria-controls="example"
                            onChange={(e) => {
                              setSearchTempKeyword(e.target.value);
                              if (e.target.value === "") {
                                setListLoader(true);
                                setSearchKeyword("");
                              }
                            }}
                            // onKeyDown={(e) => {
                            //   if (e.key === "Enter") {
                            //     setSearchKeyword(e.target.value);
                            //   }
                            // }}
                          />
                        </label>

                        <div
                          style={{
                            border: "1px solid #aaa",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "9px",
                            marginBottom: "7px",
                            cursor: "pointer",
                            marginTop: "-1px",
                            marginLeft: "-1px",
                          }}
                          onClick={() => {
                            if (searchKeyword !== searchTempKeyword) {
                              flushSync(() => {
                                setSearchActive(true);
                                setSortKey("");
                              });
                              setListLoader(true);
                              setSearchKeyword(searchTempKeyword);
                            }
                          }}
                        >
                          <i class="ph ph-magnifying-glass"></i>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div>
                    <div className="table-responsive">
                      <table className="table filter-table">
                        <thead>
                          <tr>
                            <th>No.</th>
                            <th
                              onClick={() => {
                                // setSortKey("cost_center_number");
                                // setSortOrder(sortOrder === 1 ? -1 : 1);
                                handleSort("cost_center_number");
                              }}
                            >
                              <span>Number</span>
                              <span className="ms-1 bright">
                                {renderSortIcon("cost_center_number")}
                              </span>
                            </th>
                            <th>
                              <div
                                onClick={() => {
                                  // setSortKey("cost_center_name");
                                  // setSortOrder(sortOrder === 1 ? -1 : 1);
                                  handleSort("cost_center_name");
                                }}
                              >
                                <span>Name</span>
                                {/* <span className="ms-1 bright">
                                      {" "}
                                      {sortOrder === 1 ? (
                                        <img src={AscIcon} alt="" />
                                      ) : (
                                        <img src={DescIcon} alt="" />
                                      )}{" "}
                                    </span> */}
                                <span className="ms-1 bright">
                                  {renderSortIcon("cost_center_name")}
                                </span>
                              </div>
                            </th>
                            <th>
                              <div
                                onClick={() => {
                                  // setSortKey("cost_center_category");
                                  // setSortOrder(sortOrder === 1 ? -1 : 1);
                                  handleSort("cost_center_category");
                                }}
                              >
                                <span>Category</span>

                                {/* <span className="ms-1 bright">
                                      {" "}
                                      {sortOrder === 1 ? (
                                        <img src={AscIcon} alt="" />
                                      ) : (
                                        <img src={DescIcon} alt="" />
                                      )}{" "}
                                    </span> */}
                                <span className="ms-1 bright">
                                  {renderSortIcon("cost_center_category")}
                                </span>
                              </div>
                            </th>

                            <th>
                              <div
                                onClick={() => {
                                  // setSortKey("facility_name");
                                  // setSortOrder(sortOrder === 1 ? -1 : 1);
                                  handleSort("facility_masters.facility_name");
                                }}
                              >
                                <span>Facility Name</span>
                                {/* <span className="ms-1 bright">
                                      {" "}
                                      {sortOrder === 1 ? (
                                        <img src={AscIcon} alt="" />
                                      ) : (
                                        <img src={DescIcon} alt="" />
                                      )}{" "}
                                    </span> */}
                                <span className="ms-1 bright">
                                  {renderSortIcon(
                                    "facility_masters.facility_name"
                                  )}
                                </span>
                              </div>
                            </th>
                            {/* <th>
                                  <div
                                    onClick={() => {
                                      setSortKey("director");
                                      setSortOrder(sortOrder === 1 ? -1 : 1);
                                    }}
                                  >
                                    <span>Director</span>

                                    
                                  </div>
                                </th>
                                <th>
                                  <div
                                    onClick={() => {
                                      setSortKey("manager");
                                      setSortOrder(sortOrder === 1 ? -1 : 1);
                                    }}
                                  >
                                    <span>Manager</span>

                                    
                                  </div>
                                </th> */}
                            <th>
                              <div
                                onClick={() => {
                                  // setSortKey("target");
                                  // setSortOrder(sortOrder === 1 ? -1 : 1);
                                  handleSort("target");
                                }}
                              >
                                <span>Target</span>

                                {/* <span className="ms-1 bright">
                                      {" "}
                                      {sortOrder === 1 ? (
                                        <img src={AscIcon} alt="" />
                                      ) : (
                                        <img src={DescIcon} alt="" />
                                      )}{" "}
                                    </span> */}
                                <span className="ms-1 bright">
                                  {renderSortIcon("target")}
                                </span>
                              </div>
                            </th>
                            <th>
                              <div
                                onClick={() => {
                                  // setSortKey("realtime");
                                  // setSortOrder(sortOrder === 1 ? -1 : 1);
                                  handleSort("realtime");
                                }}
                              >
                                <span>Realtime</span>

                                {/* <span className="ms-1 bright">
                                      {" "}
                                      {sortOrder === 1 ? (
                                        <img src={AscIcon} alt="" />
                                      ) : (
                                        <img src={DescIcon} alt="" />
                                      )}{" "}
                                    </span> */}
                                <span className="ms-1 bright">
                                  {renderSortIcon("realtime")}
                                </span>
                              </div>
                            </th>
                            <th>
                              <div
                                onClick={() => {
                                  handleSort("adm");
                                }}
                              >
                                <span>ADM</span>
                                <span className="ms-1 bright">
                                  {renderSortIcon("adm")}
                                </span>
                              </div>
                            </th>
                            <th>
                              <div
                                onClick={() => {
                                  // setSortKey("is10day");
                                  // setSortOrder(sortOrder === 1 ? -1 : 1);
                                  handleSort("is10day");
                                }}
                              >
                                <span>Is10day</span>

                                {/* <span className="ms-1 bright">
                                      {" "}
                                      {sortOrder === 1 ? (
                                        <img src={AscIcon} alt="" />
                                      ) : (
                                        <img src={DescIcon} alt="" />
                                      )}{" "}
                                    </span> */}
                                <span className="ms-1 bright">
                                  {renderSortIcon("is10day")}
                                </span>
                              </div>
                            </th>

                            <th>
                              <div
                                onClick={() => {
                                  // setSortKey("updatedAt");
                                  // setSortOrder(sortOrder === 1 ? -1 : 1);
                                  handleSort("updatedAt");
                                }}
                              >
                                <span>Updated At</span>

                                {/* <span className="ms-1 bright">
                                      {" "}
                                      {sortOrder === 1 ? (
                                        <img src={AscIcon} alt="" />
                                      ) : (
                                        <img src={DescIcon} alt="" />
                                      )}{" "}
                                    </span> */}
                                <span className="ms-1 bright">
                                  {renderSortIcon("updatedAt")}
                                </span>
                              </div>
                            </th>
                            <th>
                              <div
                                onClick={() => {
                                  // setSortKey("createdAt");
                                  // setSortOrder(sortOrder === 1 ? -1 : 1);
                                  handleSort("createdAt");
                                }}
                              >
                                <span>Created At</span>

                                {/* <span className="ms-1 bright">
                                      {" "}
                                      {sortType === "asc" && sortName === "cost_center_number" ? (
                                        <img src={AscIcon} alt="" />
                                      ) : (
                                        <img src={DescIcon} alt="" />
                                      )}{" "}
                                    </span> */}
                                {/* <span className="ms-1 bright">
                                      {" "}
                                      {sortOrder === 1 ? (
                                        <img src={AscIcon} alt="" />
                                      ) : (
                                        <img src={DescIcon} alt="" />
                                      )}{" "}
                                    </span> */}
                                <span className="ms-1 bright">
                                  {renderSortIcon("createdAt")}
                                </span>
                              </div>
                            </th>

                            <th></th>
                          </tr>
                        </thead>
                        {isInitialData && !loading_page && !listLoader ? (
                          <>
                            {customerData?.docs.length > 0 ? (
                              <tbody>
                                {customerData?.docs?.map((Ele, i) => {
                                  return (
                                    <tr key={Ele._id}>
                                      <td>{i + pageCounter}</td>
                                      <td>{Ele?.cost_center_number}</td>
                                      <td>{Ele?.cost_center_name}</td>
                                      <td>{Ele?.cost_center_categories?.cost_center_category || ""}</td>
                                      <td>
                                        {Ele?.facility_masters?.facility_name}
                                      </td>
                                      {/* <td>{Ele?.director}</td>
                                      <td>{Ele?.manager}</td> */}
                                      <td>
                                        {Ele?.target === 0 ? "No" : "Yes"}
                                      </td>
                                      <td>
                                        {Ele?.realtime === 0 ? "No" : "Yes"}
                                      </td>
                                      <td>{Ele?.adm === 0 ? "No" : "Yes"}</td>
                                      <td>
                                        {Ele?.is10day === 0 ? "No" : "Yes"}
                                      </td>

                                      <td>{getDate(Ele?.updatedAt)}</td>
                                      <td>{getDate(Ele?.createdAt)}</td>

                                      <td>
                                        <label className="edit">
                                          <Link
                                            className="boxlink"
                                            to={`/admin/department/edit-department-new/${Ele?._id}/${Ele?.cost_center_number}`}
                                          >
                                            <img
                                              src={Ico1}
                                              className="w-a"
                                              alt="icon"
                                            />
                                          </Link>
                                        </label>
                                        {/* {" "}
                                    &nbsp;
                                    <label className="edit">
                                      <img src={Ico2} className="w-a" />
                                    </label> */}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            ) : (
                              <tbody>
                                <tr>
                                  <td colSpan="12">
                                    <div
                                      className="d-flex justify-content-center align-items-center"
                                      style={{ height: "200px" }}
                                    >
                                      <p class="text-secondary display-6">
                                        Oops! Data Not found
                                      </p>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            )}
                          </>
                        ) : (
                          <>
                            <tbody>
                              <tr>
                                <td colSpan="12">
                                  <div
                                    className="d-flex align-items-center justify-content-center w-100"
                                    style={{ height: "400px" }}
                                  >
                                    <span
                                      class="loader"
                                      style={{
                                        width: "50px",
                                        height: "50px",
                                      }}
                                    ></span>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </>
                        )}
                      </table>
                    </div>
                    {isInitialData && !loading_page && !listLoader ? (
                      <>
                        <div className="d-flex justify-content-between">
                          <div
                            class="dataTables_info"
                            id="example_info"
                            role="status"
                            aria-live="polite"
                          >
                            Showing {pageCounter} to{" "}
                            {Math.min(
                              pageCounter + pageLimit - 1,
                              customerData.totalDocs
                            )}{" "}
                            of total {customerData?.totalDocs} entries
                          </div>
                          <div
                            class="dataTables_paginate paging_simple_numbers"
                            id="example_paginate"
                          >
                            {/* {hasPrev && (
                      <span
                        className="paginate_button previous disabled"
                        aria-controls="example"
                        aria-disabled="true"
                        role="link"
                        data-dt-idx="previous"
                        tabindex="-1"
                        id="example_previous"
                        onClick={() => {
                          setCurrentPage(customerData?.prevPage);
                        }}
                      >
                        Previous
                      </span>
                    )} */}
                            <span>
                              {/* {renderElements()} */}
                              {/* <a
                        class="paginate_button "
                        aria-controls="example"
                        role="link"
                        aria-current="page"
                        data-dt-idx="0"
                        tabindex="0"
                      >
                        1
                      </a> */}
                            </span>
                            {/* {hasNext && (
                      <span
                        className="paginate_button next"
                        aria-controls="example"
                        role="link"
                        data-dt-idx="next"
                        tabindex="0"
                        id="example_next"
                        onClick={() => {
                          setCurrentPage(customerData?.nextPage);
                        }}
                      >
                        Next
                      </span>
                    )} */}

                            <span>
                              <ReactPaginate
                                previousLabel={"Previous"}
                                nextLabel={"Next"}
                                breakLabel={"..."}
                                pageCount={customerData?.totalPages}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                previousLinkClassName={"paginationLink"}
                                nextLinkClassName={"paginationLink"}
                                // disabledClassName={"paginationDisabled"}
                                activeClassName={"paginationActive"}
                                forcePage={currentPage - 1}
                                // activeClassName={"active"}
                              />
                            </span>
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Applications;
