import 'bootstrap/dist/css/bootstrap.min.css';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faAngleLeft, faAngleDoubleRight, faAngleRight, faPlus, faMinus } from '@fortawesome/fontawesome-free-solid';
import React, { useState } from 'react';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import './index.css';
import InputRange from 'react-input-range';
import "react-input-range/lib/css/index.css"
import 'font-awesome/css/font-awesome.min.css';
import { API_CALL_URL_PUBLIC } from '../../constants';
import { API_CALL_URL_PRIVATE } from '../../constants';
import axios from '../../axios';


function SliderFilter({ setSliderFilterData, isactivefilter, activetab, active, seCsvUpdateEvent, cost_center_number }) {
    const [startDate, setStartDate] = useState(new Date());
    //const [active, setActive] = useState("");
    const [isslidershow, setIsslidershow] = useState(true);
    const [value, setValue] = useState([30, 60]);
    const [InputRangeValue, setInputRange] = useState({ min: 0, max: 0 })
    const [InputRangeStartValue, setInputRangeStartValue] = useState({ min: 0, max: 0 })
    const [lowerUpperBound, setLowerUpperBound] = useState({})
    const [payPeriodDates, setPayPeriodDates] = useState([])

    const swiftMange = () => {
        const currentHours = moment(startDate).format("hh");
        if (currentHours >= "3") {
            // setValue([7, 7]);
            setInputRange({ min: 7, max: 7 })
        }
        if (currentHours >= "11") {
            setValue([15, 15]);
            setInputRange({ min: 15, max: 15 })
        }
        if (currentHours >= "19") {
            setValue([23, 23]);
            setInputRange({ min: 19, max: 19 })
        }
    }

    const handleClick = (event) => {
        //setActive(event.target.id);
        activetab(event.target.id);
        window?.localStorage?.setItem("activefiltertab", event.target.id);
        if (event.target.id == "realtime") {
            setInputRangeStartValue({ min: 0, max: 0 });
            setInputRange({ min: 0, max: 0 })
            setSliderFilterData({ ...InputRangeValue, active: 'realtime' })
            isactivefilter('');
            setIsslidershow(true);
        } else if (event.target.id == "payperiod") {
            setInputRangeStartValue({ min: lowerUpperBound?.lowerbound?.current, max: lowerUpperBound?.upperbound?.current });
            isactivefilter('');
            if (lowerUpperBound?.lowerbound?.current == 0 && lowerUpperBound?.upperbound?.current == 0) {
                setInputRange({ min: 0, max: 0 });
                setIsslidershow(true);
            } else {
                setIsslidershow(false);
                setInputRange({ min: lowerUpperBound?.lowerbound?.current, max: lowerUpperBound?.lowerbound?.current + 1 })
                setSliderFilterData({ min: lowerUpperBound?.lowerbound?.current, max: lowerUpperBound?.lowerbound?.current + 1, payPeriodDates, active })
            }
        } 
        else if (event.target.id == "day") {
            setInputRangeStartValue({ min: lowerUpperBound?.lowerbound?.days, max: lowerUpperBound?.upperbound?.days });
            isactivefilter('');
            if (lowerUpperBound?.lowerbound?.days == 0 && lowerUpperBound?.upperbound?.days == 0) {
                setInputRange({ min: 0, max: 0 });
                setIsslidershow(true);
            } else {
                setIsslidershow(false);
                setInputRange({ min: lowerUpperBound?.lowerbound?.days, max: lowerUpperBound?.lowerbound?.days + 1 })
                setSliderFilterData({ min: lowerUpperBound?.lowerbound?.days, max: lowerUpperBound?.lowerbound?.days + 1, payPeriodDates, active })
            }
        }
        else if (event.target.id == "hourly") {
            setInputRangeStartValue({ min: lowerUpperBound?.lowerbound?.hours, max: lowerUpperBound?.upperbound?.hours });
            isactivefilter('');
            if (lowerUpperBound?.lowerbound?.hours == 0 && lowerUpperBound?.upperbound?.hours == 0) {
                setIsslidershow(true);
                setInputRange({ min: 0, max: 0 })
            } else {
                setIsslidershow(false);
                setInputRange({ min: lowerUpperBound?.lowerbound?.hours, max: lowerUpperBound?.lowerbound?.hours + 1 })
                setSliderFilterData({ min: lowerUpperBound?.lowerbound?.hours, max: lowerUpperBound?.lowerbound?.hours + 1, payPeriodDates, active })
            }
        }
        else if (event.target.id == "week") {
            // setBoxColor({
            //     color:"#ffeded"
            // })
            setInputRangeStartValue({ min: lowerUpperBound?.lowerbound?.weeks, max: lowerUpperBound?.upperbound?.weeks });
            isactivefilter('');
            if (lowerUpperBound?.lowerbound?.weeks == 0 && lowerUpperBound?.upperbound?.weeks == 0) {
                setIsslidershow(true);
                setInputRange({ min: 0, max: 0 })
            } else {
                setIsslidershow(false);
                setInputRange({ min: lowerUpperBound?.lowerbound?.weeks, max: lowerUpperBound?.lowerbound?.weeks + 1 })
                setSliderFilterData({ min: lowerUpperBound?.lowerbound?.weeks, max: lowerUpperBound?.lowerbound?.weeks + 1, payPeriodDates, active })
            }
        }


        // if(event.target.id == "realtime"){
        //     seCsvUpdateEvent(true)
        // }
        // else{
        //     seCsvUpdateEvent(false)
        //     // alert("false")
        // }


    }
    function onchangeHandle() {

    }

    const getSliderDates = async () => {
        const resp = await axios.post(API_CALL_URL_PRIVATE + '/utils/getSliderDates', { cost_center_number });
        let { data } = await resp.data;
        const lowerbound = data.lowerbound;
        const upperbound = data.upperbound;
        const payPeriodDatesData = data.payPeriodDates;
        setLowerUpperBound({ lowerbound, upperbound });
        setPayPeriodDates(payPeriodDatesData);
    }

    useState(() => {
        //setActive("realtime");
        //swiftMange();
        getSliderDates();
    }, [])




    const minusHandler = () => {
        const min = InputRangeValue.min - 1;
        const max = InputRangeValue.max - 1;
        if (InputRangeStartValue.min !== 0 && InputRangeStartValue.max !== 0) {
            setInputRange({ min, max });
            setSliderFilterData({ min, max, payPeriodDates, active })
        }
    }

    const plusHandler = () => {
        const min = InputRangeValue.min + 1;
        const max = InputRangeValue.max + 1;
        if (InputRangeStartValue.min !== 0 && InputRangeStartValue.max !== 0) {
            setInputRange({ min, max });
            setSliderFilterData({ min, max, payPeriodDates, active })
        }
    }

    const leftStartHandler = () => {
        const minval = active == 'hourly' ? 3 : 1;
        if (InputRangeStartValue.min !== 0 && InputRangeStartValue.max !== 0) {
            setInputRange({ ...InputRangeValue, min: minval });
            setSliderFilterData({ ...InputRangeValue, min: minval, payPeriodDates, active })
        }
    }

    const leftHandler = () => {
        const minval = InputRangeValue.min - 1;
        if (InputRangeStartValue.min !== 0 && InputRangeStartValue.max !== 0) {
            setInputRange({ ...InputRangeValue, min: minval });
            setSliderFilterData({ ...InputRangeValue, min: minval, payPeriodDates, active })
        }
    }

    const rightHandler = () => {
        const maxval = InputRangeValue.max + 1;
        if (InputRangeStartValue.min !== 0 && InputRangeStartValue.max !== 0) {
            setInputRange({ ...InputRangeValue, max: maxval });
            setSliderFilterData({ ...InputRangeValue, max: maxval, payPeriodDates, active })
        }
    }

    const rightEndHandler = () => {
        const maxval = active == 'hourly' ? 23 : (active == 'day') ? 31 : (active == 'week') ? 52 : 1;
        if (InputRangeStartValue.min !== 0 && InputRangeStartValue.max !== 0) {
            setInputRange({ ...InputRangeValue, max: maxval });
            setSliderFilterData({ ...InputRangeValue, max: maxval, payPeriodDates, active })
        }
    }

    return (
        <div className="d-flex timeline mt-2">
            <ul className="col flex-grow-0 me-4 fitcontent nav nav-pills " id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button key={"week"} onClick={handleClick} id={"week"} className={active == "week" ? "nav-link me-1 red-c active" : "nav-link me-1 primary-font"} data-bs-toggle="pill" data-bs-target="#pills-w" type="button" aria-controls="pills-w">
                        Weeks
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button key={"day"} onClick={handleClick} id={"day"} className={active === "day" ? "nav-link me-1 blue-c active" : "nav-link me-1 primary-font"} data-bs-toggle="pill" data-bs-target="#pills-d" type="button" aria-controls="pills-d">
                        Days
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button key={"hourly"} onClick={handleClick} id={"hourly"} className={active === "hourly" ? "nav-link  purple-c  active" : "nav-link me-1 primary-font"} data-bs-toggle="pill" data-bs-target="#pills-h" type="button" aria-controls="pills-h">
                        Hours
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button key={"payperiod"} onClick={handleClick} id={"payperiod"} className={active == "payperiod" ? "nav-link me-1 active" : "nav-link me-1 primary-font"} data-bs-toggle="pill" data-bs-target="#pills-w" type="button" aria-controls="pills-w">
                        Current
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button key={"realtime"} onClick={handleClick} id={"realtime"} className={active == "realtime" ? "nav-link me-1 active" : "nav-link me-1 primary-font"} data-bs-toggle="pill" data-bs-target="#pills-w" type="button" aria-controls="pills-w">
                        Realtime
                    </button>
                </li>
            </ul>
            <div className="col mt-3" id="pills-tabContent">
                {
                    <div data-role="main" className="sliderfilder d-flex">
                        <div><input type="number" disabled={isslidershow} style={{ marginRight: 10 }} className='form-control leftrightinput' value={InputRangeStartValue.min} /></div>
                        <InputRange
                            draggableTrack
                            maxValue={InputRangeStartValue.max}
                            minValue={InputRangeStartValue.min}
                            value={InputRangeValue}
                            disabled={isslidershow}
                            onChange={value => {
                                if (value.min < 1) {
                                    value.min = 1
                                }
                                // this.setState({ value })
                                // let option ={
                                //     type:
                                // }
                                // console.table({value})
                                setInputRange(value);
                                // setTimeout(()=>{
                                //     setSliderFilterData({...value,active})
                                // },2000)

                            }}
                            onChangeComplete={(e) => {
                                console.table('InputRangeValue', e);
                                console.table('InputRangeValue', InputRangeValue);
                                setSliderFilterData({ ...InputRangeValue, active, payPeriodDates })
                            }}
                        />
                        <div><input type="number" disabled={isslidershow} style={{ marginLeft: 10 }} className='form-control leftrightinput' value={InputRangeStartValue.max} /></div>
                    </div>
                }
                <div>
                    <div className='d-flex justify-content-between mt-0'>
                        <div>
                            <button disabled={isslidershow} className="btn btn-light btn-sm arrowdes" onClick={leftStartHandler}><FontAwesomeIcon icon={faAngleDoubleLeft} /></button>
                            <button disabled={isslidershow} className="btn btn-light btn-sm arrowdes" onClick={leftHandler}><FontAwesomeIcon icon={faAngleLeft} /></button>
                        </div>
                        <div>
                            <button disabled={isslidershow} className="btn btn-light btn-sm arrowdes" onClick={minusHandler}><FontAwesomeIcon icon={faMinus} /></button>
                            <button disabled={isslidershow} className="btn btn-light btn-sm arrowdes" onClick={plusHandler}><FontAwesomeIcon icon={faPlus} /></button>
                        </div>
                        <div>
                            <button disabled={isslidershow} className="btn btn-light btn-sm arrowdes" onClick={rightHandler}><FontAwesomeIcon icon={faAngleRight} /></button>
                            <button disabled={isslidershow} className="btn btn-light btn-sm arrowdes" onClick={rightEndHandler}><FontAwesomeIcon icon={faAngleDoubleRight} /></button>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    );
}

export default SliderFilter;