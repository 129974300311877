import React, { useState } from "react";
import OtpInput from "react-otp-input";
import "./index.css";
import logo from "../../../assets/img/perfectshift-logo.png";
import { Link } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import axios from "axios";
// import { API_CALL_URL_PUBLIC } from "../../../constants/compensate";
import { API_CALL_URL_PUBLIC } from "../../../constants/compensate";
import { forgotValidator } from "../../../utils/validator";

function Forgot({ history }) {
  const [loginValues, setLoginValues] = useState({
    email: "",
  });
  const [errormsg, setErrormsg] = useState("");

  const handleLogin = async (values) => {
    try {
      const { data } = await axios.post(
        `${API_CALL_URL_PUBLIC}/authentication/forgotPassword`,
        {
          username: values.email,
        }
      );
      history("/reset", { state: data?.data });
    } catch (error) {
      setErrormsg(error.response.data.message);
      console.log(error);
    }
  };
  return (
    <>
      <div class="dashboard-wrapper full-width h-100vh overflow-hidden">
        <div class="container py-3 pb-0 h-100vh ">
          <div class="row height-100 justify-content-center align-items-center h-100vh">
            <div class="login-screen max-width-300 ">
              <div class="text-center">
                <img src={logo} alt="" class="img-fluid mb-5" />
              </div>
              <Formik
                enableReinitialize
                initialValues={loginValues}
                validateOnChange
                validate={forgotValidator}
                onSubmit={(values) => handleLogin(values, false)}
              >
                {(formikBag) => {
                  return (
                    <Form style={{ width: "100%" }}>
                      <div class="mb-3">
                        <label>Username or Email *</label>
                        <Field name="email">
                          {({ field }) => (
                            <input
                              {...field}
                              type="username"
                              name="username"
                              class="form-control"
                              id="exampleInputEmail1"
                              placeholder="Username or Email"
                              value={formikBag.values.email}
                              onChange={(e) => {
                                formikBag.setFieldValue(
                                  "email",
                                  e.target.value
                                );
                              }}
                            />
                          )}
                        </Field>
                        {formikBag.touched.email && formikBag.errors.email ? (
                          <>
                            <p
                              style={{
                                marginTop: 5,
                                fontSize: 13,
                                color: "red",
                              }}
                            >
                              {formikBag.errors.email}
                            </p>
                          </>
                        ) : null}
                      </div>
                      <div className="col-12 d-flex align-items-center justify-content-left text-danger f-12 mt-1 mb-2">
                          {errormsg}
                        </div>
                      <div class="col-12">
                        <button
                          type="submit"
                          class="btn btm btn-success w-100 text-capitalize"
                          style={{ marginBottom: "8px" }}
                        >
                          Genrate OTP
                        </button>
                      </div>
                      <div
                        class="col-12 d-flex align-items-center justify-content-center"
                        style={{ color: "lightgray", marginBottom: "5px" }}
                      >
                        or
                      </div>
                      <div class="col-12 d-flex align-items-center justify-content-center hover-text">
                        <Link
                          to="/"
                          class="forgot-pass f-18"
                          style={{ color: "#5e8e28" }}
                        >
                          Login Here
                        </Link>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Forgot;
