import React from "react";
import "./index.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

function CustomerForm() {
  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <div className="page-header">
          <h3 className="page-title">
            <a href="#" className="badge badge-dark"></a>
          </h3>
          <div className></div>
        </div>
        <div className="row">
          <div class="col-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <div className="d-flex justify-content-between">
                  <h4 class="card-title">Add Customer</h4>
                  <Link
                    to={`/admin/customer/`}
                    className="f-14 white-text-dark-mode"
                  >
                    <FontAwesomeIcon icon="fa-solid fa-arrow-left " /> Back
                  </Link>
                </div>

                <form class="forms-sample">
                  <div class="row">
                    <Form.Group
                      className="col-md-6 mb-3"
                      controlId="exampleForm.c_code"
                    >
                      <Form.Label>Customer Code</Form.Label>
                      <Form.Control type="text" placeholder="" />
                    </Form.Group>
                    <Form.Group
                      className="col-md-6 mb-3"
                      controlId="exampleForm.c_name"
                    >
                      <Form.Label>Customer Name</Form.Label>
                      <Form.Control type="text" placeholder="" />
                    </Form.Group>
                    <Form.Group
                      className="col-md-6 mb-3"
                      controlId="exampleForm.c_number"
                    >
                      <Form.Label>Customer Number</Form.Label>
                      <Form.Control type="text" placeholder="" />
                    </Form.Group>
                    <Form.Group
                      className="col-md-6 mb-3"
                      controlId="exampleForm.c_email"
                    >
                      <Form.Label>Customer Email</Form.Label>
                      <Form.Control type="email" placeholder="" />
                    </Form.Group>

                    <Form.Group
                      className="col-md-6 mb-3"
                      controlId="exampleForm.c_email"
                    >
                      <Form.Label>Status</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        className="white"
                      >
                        <option value="1">Active</option>
                        <option value="2">Inactive</option>
                      </Form.Select>
                    </Form.Group>

                    <Form.Group controlId="formFile" className="mb-3 col-md-6">
                      <Form.Label>Default file input example</Form.Label>
                      <Form.Control type="file" />
                    </Form.Group>
                    <Form.Group
                      className="col-md-12 mb-3"
                      controlId="exampleForm.c_email"
                    >
                      <Form.Label>Address</Form.Label>
                      <Form.Control as="textarea" rows={2} />
                    </Form.Group>
                    <Form.Group className="col-md-12 mb-3">
                      <Button variant="primary" type="submit">
                        Reset
                      </Button>
                      <Button className="ms-3" variant="success" type="submit">
                        Save
                      </Button>
                    </Form.Group>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomerForm;
