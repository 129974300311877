import * as DS from "../../Styled/DashStyle"

const CardTwo = () => {
  return (
    <>
        <DS.CardTwoParent>
            <DS.CardTwoRow>
                1 West
            </DS.CardTwoRow>
        </DS.CardTwoParent>
    </>
  )
}

export default CardTwo