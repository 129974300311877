import { useEffect } from "react";
import Chart from "react-apexcharts";
import { useContext } from "react";
import { ThemeContext } from "../../../providers/ThemeProvider";

function RadialBarChart({ color, series, newColor }) {
  const [themeMode, setTheme] = useContext(ThemeContext);
  const options = {
    chart: { height: 170, type: "radialBar" },
    colors: [ "#28a745", newColor],
    states: {
    hover: {
        filter: {
            type: 'none',
        }
    },
},
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            height: 110,
            width: 50,
          },
        },
        breakpoint: 1441,
        options: {
          chart: {
            height: 350,
          },
        },
      },
    ],
    plotOptions: {
      radialBar: {
        hollow: { size: 45 },
        track: { background: themeMode.theme === "DARK" ? "#1f1f1f" : "#efefef", size: 50 },
        dataLabels: {
          name: { offsetY: -10, color: color, fontSize: "18px" },
          value: {
            offsetY: 15,
            color: color,
            fontSize: "22px",
            fontWeight: "400",
            show: true,
          },
          total: {
            show: false,
            label: "Productivity",
            color: "#fe8e26",
            fontSize: "16px",
            fontWeight: 600,
            formatter: function (w) {
              return w.globals.initialSeries[0] + "%";
              //console.log(w.globals?.initialSeries[0], 'w.globals');
              // return w.globals.seriesTotals.reduce((a, b) => {
              //   return a
              // }, 0)
            },
          },
        },
      },
    },
    labels: ["Compliance", "Productivity"],
  };



  return (
    <div className="m-auto">
      <Chart
        options={options}
        series={series}
        type="radialBar"
        width={"100%"}
        height={350}
      />
    </div>
  );
}

export default RadialBarChart;
