
import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './index.css';
import Table from "react-bootstrap/Table";
import Ico2 from "../../../../assets/img/info.png";
import Ico3 from "../../../../assets/img/warning.png";
import Ico4 from "../../../../assets/img/critical.png";

function InfoModal(props) {
  const { dataFooter } = props;
  return (
    <Modal
    {...props}
    size="md"
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <Modal.Header closeButton className='custom-font-size'>
      <Modal.Title id="contained-modal-title-vcenter" className='fw-normal text-center w-100 color-black f-18'>
      Active Daily Management Refresh Schedule
      </Modal.Title>
    </Modal.Header>
    <Modal.Body className='custom-font-size text-indent-15 py-5'>
    <Table
              responsive
              className="table  mb-0    exceptions-table ft-table-v"
            >
        <thead>
          <tr>
            <th className='f-18 text-center'>File Type</th>
            <th className='f-18 text-center center-border'>Showing Data as of</th>
            <th className='f-18 text-center'>Next Scheduled Refresh</th>
          </tr>
        </thead>
        <tbody>
        <tr>

            <td className='f-18 text-center big-font f-18'>Exception<br/><small className='f-12'>Refreshed 4x Daily</small></td>
            <td className='f-18 text-center center-border'>{dataFooter?.current_file_date}</td>
            <td className='f-18 text-center'>{dataFooter?.upcomming_file_date}</td>

           
          </tr>
        </tbody>
      </Table>
    </Modal.Body>
    
  </Modal>
  )
}

export default InfoModal

