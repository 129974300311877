import React from "react";
import { Switch } from "antd";
import "./index.css";
import calcIcon from "../../assets/img/calculator_hires.png";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const StaffingDataAccordian = ({
  formControl,
  realtimeFormulaHeaders,
  handleStaffingSort,
  handleStaffingLabel,
  handleStaffingSwitchcheck,
  handleStaffingSwitchOnChange,
}) => {
  return (
    <>
      <div class="row">
        <div className="col-md-12 m-0">
          <hr />
        </div>
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-12">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Show/Hide/Sort</th>
                      <th></th>
                      <th>Key</th>
                      <th>Label</th>
                      <th>Mandatory</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.staffing?.find(
                              (e) => e.field_name === "charge"
                            ).display
                          }
                          onChange={(e) => {
                            handleStaffingSort(
                              "charge",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["charge"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["charge"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "charge"
                              ).field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "charge"
                              ).field_label
                            }
                            onChange={(e) => {
                              handleStaffingLabel("charge", e.target.value);
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handleStaffingSwitchcheck("charge")}
                          className="blue-b"
                          onChange={(e) => {
                            handleStaffingSwitchOnChange("charge", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.staffing?.find(
                              (e) => e.field_name === "cna"
                            ).display
                          }
                          onChange={(e) => {
                            handleStaffingSort(
                              "cna",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["cna"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["cna"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "cna"
                              ).field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "cna"
                              ).field_label
                            }
                            onChange={(e) => {
                              handleStaffingLabel("cna", e.target.value);
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handleStaffingSwitchcheck("cna")}
                          className="blue-b"
                          onChange={(e) => {
                            handleStaffingSwitchOnChange("cna", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.staffing?.find(
                              (e) => e.field_name === "orientation"
                            ).display
                          }
                          onChange={(e) => {
                            handleStaffingSort(
                              "orientation",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["orientation"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["orientation"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "orientation"
                              ).field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "orientation"
                              ).field_label
                            }
                            onChange={(e) => {
                              handleStaffingLabel(
                                "orientation",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handleStaffingSwitchcheck("orientation")}
                          className="blue-b"
                          onChange={(e) => {
                            handleStaffingSwitchOnChange("orientation", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.staffing?.find(
                              (e) => e.field_name === "nurses"
                            ).display
                          }
                          onChange={(e) => {
                            handleStaffingSort(
                              "nurses",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["nurses"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["nurses"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "nurses"
                              ).field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "nurses"
                              ).field_label
                            }
                            onChange={(e) => {
                              handleStaffingLabel("nurses", e.target.value);
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handleStaffingSwitchcheck("nurses")}
                          className="blue-b"
                          onChange={(e) => {
                            handleStaffingSwitchOnChange("nurses", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.staffing?.find(
                              (e) => e.field_name === "res_brk_rn"
                            ).display
                          }
                          onChange={(e) => {
                            handleStaffingSort(
                              "res_brk_rn",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["res_brk_rn"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["res_brk_rn"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "res_brk_rn"
                              ).field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "res_brk_rn"
                              ).field_label
                            }
                            onChange={(e) => {
                              handleStaffingLabel("res_brk_rn", e.target.value);
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handleStaffingSwitchcheck("res_brk_rn")}
                          className="blue-b"
                          onChange={(e) => {
                            handleStaffingSwitchOnChange("res_brk_rn", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.staffing?.find(
                              (e) => e.field_name === "unit_sec"
                            ).display
                          }
                          onChange={(e) => {
                            handleStaffingSort(
                              "unit_sec",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["unit_sec"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["unit_sec"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "unit_sec"
                              ).field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "unit_sec"
                              ).field_label
                            }
                            onChange={(e) => {
                              handleStaffingLabel("unit_sec", e.target.value);
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handleStaffingSwitchcheck("unit_sec")}
                          className="blue-b"
                          onChange={(e) => {
                            handleStaffingSwitchOnChange("unit_sec", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.staffing?.find(
                              (e) => e.field_name === "sitter"
                            ).display
                          }
                          onChange={(e) => {
                            handleStaffingSort(
                              "sitter",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["sitter"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["sitter"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "sitter"
                              ).field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "sitter"
                              ).field_label
                            }
                            onChange={(e) => {
                              handleStaffingLabel("sitter", e.target.value);
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handleStaffingSwitchcheck("sitter")}
                          className="blue-b"
                          onChange={(e) => {
                            handleStaffingSwitchOnChange("sitter", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.staffing?.find(
                              (e) => e.field_name === "techs_cna"
                            ).display
                          }
                          onChange={(e) => {
                            handleStaffingSort(
                              "techs_cna",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["techs_cna"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["techs_cna"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "techs_cna"
                              ).field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "techs_cna"
                              ).field_label
                            }
                            onChange={(e) => {
                              handleStaffingLabel("techs_cna", e.target.value);
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handleStaffingSwitchcheck("techs_cna")}
                          className="blue-b"
                          onChange={(e) => {
                            handleStaffingSwitchOnChange("techs_cna", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.staffing?.find(
                              (e) => e.field_name === "psa_si"
                            ).display
                          }
                          onChange={(e) => {
                            handleStaffingSort(
                              "psa_si",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["psa_si"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["psa_si"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "psa_si"
                              ).field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "psa_si"
                              ).field_label
                            }
                            onChange={(e) => {
                              handleStaffingLabel("psa_si", e.target.value);
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handleStaffingSwitchcheck("psa_si")}
                          className="blue-b"
                          onChange={(e) => {
                            handleStaffingSwitchOnChange("psa_si", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.staffing?.find(
                              (e) => e.field_name === "psa_medical"
                            ).display
                          }
                          onChange={(e) => {
                            handleStaffingSort(
                              "psa_medical",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["psa_medical"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["psa_medical"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "psa_medical"
                              ).field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "psa_medical"
                              ).field_label
                            }
                            onChange={(e) => {
                              handleStaffingLabel(
                                "psa_medical",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handleStaffingSwitchcheck("psa_medical")}
                          className="blue-b"
                          onChange={(e) => {
                            handleStaffingSwitchOnChange("psa_medical", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.staffing?.find(
                              (e) => e.field_name === "blt"
                            )?.display
                          }
                          onChange={(e) => {
                            handleStaffingSort(
                              "blt",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["blt"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["blt"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "blt"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "blt"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handleStaffingLabel("blt", e.target.value);
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handleStaffingSwitchcheck("blt")}
                          className="blue-b"
                          onChange={(e) => {
                            handleStaffingSwitchOnChange("blt", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.staffing?.find(
                              (e) => e.field_name === "other"
                            )?.display
                          }
                          onChange={(e) => {
                            handleStaffingSort(
                              "other",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["other"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["other"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "other"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "other"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handleStaffingLabel("other", e.target.value);
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handleStaffingSwitchcheck("other")}
                          className="blue-b"
                          onChange={(e) => {
                            handleStaffingSwitchOnChange("other", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.staffing?.find(
                              (e) => e.field_name === "soc_worker_ft"
                            )?.display
                          }
                          onChange={(e) => {
                            handleStaffingSort(
                              "soc_worker_ft",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["soc_worker_ft"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["soc_worker_ft"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "soc_worker_ft"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "soc_worker_ft"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handleStaffingLabel(
                                "soc_worker_ft",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handleStaffingSwitchcheck("soc_worker_ft")}
                          className="blue-b"
                          onChange={(e) => {
                            handleStaffingSwitchOnChange("soc_worker_ft", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.staffing?.find(
                              (e) => e.field_name === "soc_worker_pt"
                            )?.display
                          }
                          onChange={(e) => {
                            handleStaffingSort(
                              "soc_worker_pt",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["soc_worker_pt"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["soc_worker_pt"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "soc_worker_pt"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "soc_worker_pt"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handleStaffingLabel(
                                "soc_worker_pt",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handleStaffingSwitchcheck("soc_worker_pt")}
                          className="blue-b"
                          onChange={(e) => {
                            handleStaffingSwitchOnChange("soc_worker_pt", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.staffing?.find(
                              (e) => e.field_name === "occ_therapist"
                            )?.display
                          }
                          onChange={(e) => {
                            handleStaffingSort(
                              "occ_therapist",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["occ_therapist"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["occ_therapist"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "occ_therapist"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "occ_therapist"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handleStaffingLabel(
                                "occ_therapist",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handleStaffingSwitchcheck("occ_therapist")}
                          className="blue-b"
                          onChange={(e) => {
                            handleStaffingSwitchOnChange("occ_therapist", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.staffing?.find(
                              (e) => e.field_name === "pp_rn"
                            )?.display
                          }
                          onChange={(e) => {
                            handleStaffingSort(
                              "pp_rn",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["pp_rn"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["pp_rn"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "pp_rn"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "pp_rn"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handleStaffingLabel("pp_rn", e.target.value);
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handleStaffingSwitchcheck("pp_rn")}
                          className="blue-b"
                          onChange={(e) => {
                            handleStaffingSwitchOnChange("pp_rn", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.staffing?.find(
                              (e) => e.field_name === "l_and_d_rn"
                            )?.display
                          }
                          onChange={(e) => {
                            handleStaffingSort(
                              "l_and_d_rn",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["l_and_d_rn"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["l_and_d_rn"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "l_and_d_rn"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "l_and_d_rn"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handleStaffingLabel("l_and_d_rn", e.target.value);
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handleStaffingSwitchcheck("l_and_d_rn")}
                          className="blue-b"
                          onChange={(e) => {
                            handleStaffingSwitchOnChange("l_and_d_rn", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.staffing?.find(
                              (e) => e.field_name === "nicu_peds_rn"
                            )?.display
                          }
                          onChange={(e) => {
                            handleStaffingSort(
                              "nicu_peds_rn",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["nicu_peds_rn"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["nicu_peds_rn"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "nicu_peds_rn"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "nicu_peds_rn"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handleStaffingLabel(
                                "nicu_peds_rn",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handleStaffingSwitchcheck("nicu_peds_rn")}
                          className="blue-b"
                          onChange={(e) => {
                            handleStaffingSwitchOnChange("nicu_peds_rn", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.staffing?.find(
                              (e) => e.field_name === "triage_rn"
                            )?.display
                          }
                          onChange={(e) => {
                            handleStaffingSort(
                              "triage_rn",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["triage_rn"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["triage_rn"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "triage_rn"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "triage_rn"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handleStaffingLabel("triage_rn", e.target.value);
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handleStaffingSwitchcheck("triage_rn")}
                          className="blue-b"
                          onChange={(e) => {
                            handleStaffingSwitchOnChange("triage_rn", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.staffing?.find(
                              (e) => e.field_name === "lead_tech"
                            )?.display
                          }
                          onChange={(e) => {
                            handleStaffingSort(
                              "lead_tech",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["lead_tech"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["lead_tech"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "lead_tech"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "lead_tech"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handleStaffingLabel("lead_tech", e.target.value);
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handleStaffingSwitchcheck("lead_tech")}
                          className="blue-b"
                          onChange={(e) => {
                            handleStaffingSwitchOnChange("lead_tech", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.staffing?.find(
                              (e) => e.field_name === "clinical_coordinator"
                            )?.display
                          }
                          onChange={(e) => {
                            handleStaffingSort(
                              "clinical_coordinator",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["clinical_coordinator"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {
                                      realtimeFormulaHeaders?.[
                                        "clinical_coordinator"
                                      ]
                                    }
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "clinical_coordinator"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "clinical_coordinator"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handleStaffingLabel(
                                "clinical_coordinator",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handleStaffingSwitchcheck(
                            "clinical_coordinator"
                          )}
                          className="blue-b"
                          onChange={(e) => {
                            handleStaffingSwitchOnChange(
                              "clinical_coordinator",
                              e
                            );
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.staffing?.find(
                              (e) => e.field_name === "front_desk"
                            )?.display
                          }
                          onChange={(e) => {
                            handleStaffingSort(
                              "front_desk",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["front_desk"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["front_desk"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "front_desk"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "front_desk"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handleStaffingLabel("front_desk", e.target.value);
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handleStaffingSwitchcheck("front_desk")}
                          className="blue-b"
                          onChange={(e) => {
                            handleStaffingSwitchOnChange("front_desk", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.staffing?.find(
                              (e) => e.field_name === "ivs"
                            )?.display
                          }
                          onChange={(e) => {
                            handleStaffingSort(
                              "ivs",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["ivs"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["ivs"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "ivs"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "ivs"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handleStaffingLabel("ivs", e.target.value);
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handleStaffingSwitchcheck("ivs")}
                          className="blue-b"
                          onChange={(e) => {
                            handleStaffingSwitchOnChange("ivs", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.staffing?.find(
                              (e) => e.field_name === "ed_reg"
                            )?.display
                          }
                          onChange={(e) => {
                            handleStaffingSort(
                              "ed_reg",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["ed_reg"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["ed_reg"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "ed_reg"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "ed_reg"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handleStaffingLabel("ed_reg", e.target.value);
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handleStaffingSwitchcheck("ed_reg")}
                          className="blue-b"
                          onChange={(e) => {
                            handleStaffingSwitchOnChange("ed_reg", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.staffing?.find(
                              (e) => e.field_name === "seven_ave_reg"
                            )?.display
                          }
                          onChange={(e) => {
                            handleStaffingSort(
                              "seven_ave_reg",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["seven_ave_reg"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["seven_ave_reg"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "seven_ave_reg"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "seven_ave_reg"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handleStaffingLabel(
                                "seven_ave_reg",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handleStaffingSwitchcheck("seven_ave_reg")}
                          className="blue-b"
                          onChange={(e) => {
                            handleStaffingSwitchOnChange("seven_ave_reg", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.staffing?.find(
                              (e) => e.field_name === "rt"
                            )?.display
                          }
                          onChange={(e) => {
                            handleStaffingSort(
                              "rt",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["rt"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["rt"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "rt"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "rt"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handleStaffingLabel("rt", e.target.value);
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handleStaffingSwitchcheck("rt")}
                          className="blue-b"
                          onChange={(e) => {
                            handleStaffingSwitchOnChange("rt", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.staffing?.find(
                              (e) => e.field_name === "support"
                            )?.display
                          }
                          onChange={(e) => {
                            handleStaffingSort(
                              "support",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["support"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["support"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "support"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "support"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handleStaffingLabel("support", e.target.value);
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handleStaffingSwitchcheck("support")}
                          className="blue-b"
                          onChange={(e) => {
                            handleStaffingSwitchOnChange("support", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.staffing?.find(
                              (e) => e.field_name === "scrub_tech"
                            )?.display
                          }
                          onChange={(e) => {
                            handleStaffingSort(
                              "scrub_tech",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["scrub_tech"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["scrub_tech"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "scrub_tech"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "scrub_tech"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handleStaffingLabel("scrub_tech", e.target.value);
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handleStaffingSwitchcheck("scrub_tech")}
                          className="blue-b"
                          onChange={(e) => {
                            handleStaffingSwitchOnChange("scrub_tech", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.staffing?.find(
                              (e) => e.field_name === "rn_preceptor"
                            )?.display
                          }
                          onChange={(e) => {
                            handleStaffingSort(
                              "rn_preceptor",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["rn_preceptor"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["rn_preceptor"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "rn_preceptor"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "rn_preceptor"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handleStaffingLabel(
                                "rn_preceptor",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handleStaffingSwitchcheck("rn_preceptor")}
                          className="blue-b"
                          onChange={(e) => {
                            handleStaffingSwitchOnChange("rn_preceptor", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.staffing?.find(
                              (e) => e.field_name === "rn_board_runner"
                            )?.display
                          }
                          onChange={(e) => {
                            handleStaffingSort(
                              "rn_board_runner",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["rn_board_runner"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {
                                      realtimeFormulaHeaders?.[
                                        "rn_board_runner"
                                      ]
                                    }
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "rn_board_runner"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "rn_board_runner"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handleStaffingLabel(
                                "rn_board_runner",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handleStaffingSwitchcheck("rn_board_runner")}
                          className="blue-b"
                          onChange={(e) => {
                            handleStaffingSwitchOnChange("rn_board_runner", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.staffing?.find(
                              (e) => e.field_name === "scrub_tech_preceptor"
                            )?.display
                          }
                          onChange={(e) => {
                            handleStaffingSort(
                              "scrub_tech_preceptor",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["scrub_tech_preceptor"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {
                                      realtimeFormulaHeaders?.[
                                        "scrub_tech_preceptor"
                                      ]
                                    }
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "scrub_tech_preceptor"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "scrub_tech_preceptor"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handleStaffingLabel(
                                "scrub_tech_preceptor",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handleStaffingSwitchcheck(
                            "scrub_tech_preceptor"
                          )}
                          className="blue-b"
                          onChange={(e) => {
                            handleStaffingSwitchOnChange(
                              "scrub_tech_preceptor",
                              e
                            );
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.staffing?.find(
                              (e) => e.field_name === "scrub_tech_board_runner"
                            )?.display
                          }
                          onChange={(e) => {
                            handleStaffingSort(
                              "scrub_tech_board_runner",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["scrub_tech_board_runner"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {
                                      realtimeFormulaHeaders?.[
                                        "scrub_tech_board_runner"
                                      ]
                                    }
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) =>
                                  e.field_name === "scrub_tech_board_runner"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) =>
                                  e.field_name === "scrub_tech_board_runner"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handleStaffingLabel(
                                "scrub_tech_board_runner",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handleStaffingSwitchcheck(
                            "scrub_tech_board_runner"
                          )}
                          className="blue-b"
                          onChange={(e) => {
                            handleStaffingSwitchOnChange(
                              "scrub_tech_board_runner",
                              e
                            );
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.staffing?.find(
                              (e) => e.field_name === "rnfa_stfa"
                            )?.display
                          }
                          onChange={(e) => {
                            handleStaffingSort(
                              "rnfa_stfa",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["rnfa_stfa"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["rnfa_stfa"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "rnfa_stfa"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "rnfa_stfa"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handleStaffingLabel("rnfa_stfa", e.target.value);
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handleStaffingSwitchcheck("rnfa_stfa")}
                          className="blue-b"
                          onChange={(e) => {
                            handleStaffingSwitchOnChange("rnfa_stfa", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.staffing?.find(
                              (e) => e.field_name === "rcu_rn"
                            )?.display
                          }
                          onChange={(e) => {
                            handleStaffingSort(
                              "rcu_rn",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["rcu_rn"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["rcu_rn"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "rcu_rn"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "rcu_rn"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handleStaffingLabel("rcu_rn", e.target.value);
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handleStaffingSwitchcheck("rcu_rn")}
                          className="blue-b"
                          onChange={(e) => {
                            handleStaffingSwitchOnChange("rcu_rn", e);
                          }}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.staffing?.find(
                              (e) => e.field_name === "fixed_position_5_1"
                            )?.display
                          }
                          readOnly
                          // onChange={(
                          //   e
                          // ) => {
                          //   handleStaffingSort(
                          //     "fixed_position_5_1",
                          //     e.target
                          //       .value !==
                          //       ""
                          //       ? e.target
                          //           .value
                          //       : "0"
                          //   );
                          // }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["fixed_position_5_1"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {
                                      realtimeFormulaHeaders?.[
                                        "fixed_position_5_1"
                                      ]
                                    }
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "fixed_position_5_1"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "fixed_position_5_1"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handleStaffingLabel(
                                "fixed_position_5_1",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handleStaffingSwitchcheck(
                            "fixed_position_5_1"
                          )}
                          className="blue-b"
                          onChange={(e) => {
                            handleStaffingSwitchOnChange(
                              "fixed_position_5_1",
                              e
                            );
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.staffing?.find(
                              (e) => e.field_name === "fixed_position_5_2"
                            )?.display
                          }
                          readOnly
                          // onChange={(
                          //   e
                          // ) => {
                          //   handleStaffingSort(
                          //     "fixed_position_5_2",
                          //     e.target
                          //       .value !==
                          //       ""
                          //       ? e.target
                          //           .value
                          //       : "0"
                          //   );
                          // }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["fixed_position_5_2"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {
                                      realtimeFormulaHeaders?.[
                                        "fixed_position_5_2"
                                      ]
                                    }
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "fixed_position_5_2"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "fixed_position_5_2"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handleStaffingLabel(
                                "fixed_position_5_2",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handleStaffingSwitchcheck(
                            "fixed_position_5_2"
                          )}
                          className="blue-b"
                          onChange={(e) => {
                            handleStaffingSwitchOnChange(
                              "fixed_position_5_2",
                              e
                            );
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.staffing?.find(
                              (e) => e.field_name === "fixed_position_5_3"
                            )?.display
                          }
                          readOnly
                          // onChange={(
                          //   e
                          // ) => {
                          //   handleStaffingSort(
                          //     "fixed_position_5_3",
                          //     e.target
                          //       .value !==
                          //       ""
                          //       ? e.target
                          //           .value
                          //       : "0"
                          //   );
                          // }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["fixed_position_5_3"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {
                                      realtimeFormulaHeaders?.[
                                        "fixed_position_5_3"
                                      ]
                                    }
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "fixed_position_5_3"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "fixed_position_5_3"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handleStaffingLabel(
                                "fixed_position_5_3",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handleStaffingSwitchcheck(
                            "fixed_position_5_3"
                          )}
                          className="blue-b"
                          onChange={(e) => {
                            handleStaffingSwitchOnChange(
                              "fixed_position_5_3",
                              e
                            );
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.staffing?.find(
                              (e) => e.field_name === "fixed_position_5_4"
                            )?.display
                          }
                          readOnly
                          // onChange={(
                          //   e
                          // ) => {
                          //   handleStaffingSort(
                          //     "fixed_position_5_4",
                          //     e.target
                          //       .value !==
                          //       ""
                          //       ? e.target
                          //           .value
                          //       : "0"
                          //   );
                          // }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["fixed_position_5_4"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {
                                      realtimeFormulaHeaders?.[
                                        "fixed_position_5_4"
                                      ]
                                    }
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "fixed_position_5_4"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "fixed_position_5_4"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handleStaffingLabel(
                                "fixed_position_5_4",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handleStaffingSwitchcheck(
                            "fixed_position_5_4"
                          )}
                          className="blue-b"
                          onChange={(e) => {
                            handleStaffingSwitchOnChange(
                              "fixed_position_5_4",
                              e
                            );
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.staffing?.find(
                              (e) => e.field_name === "fixed_position_5_5"
                            )?.display
                          }
                          readOnly
                          // onChange={(
                          //   e
                          // ) => {
                          //   handleStaffingSort(
                          //     "fixed_position_5_5",
                          //     e.target
                          //       .value !==
                          //       ""
                          //       ? e.target
                          //           .value
                          //       : "0"
                          //   );
                          // }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["fixed_position_5_5"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {
                                      realtimeFormulaHeaders?.[
                                        "fixed_position_5_5"
                                      ]
                                    }
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "fixed_position_5_5"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "fixed_position_5_5"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handleStaffingLabel(
                                "fixed_position_5_5",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handleStaffingSwitchcheck(
                            "fixed_position_5_5"
                          )}
                          className="blue-b"
                          onChange={(e) => {
                            handleStaffingSwitchOnChange(
                              "fixed_position_5_5",
                              e
                            );
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.staffing?.find(
                              (e) => e.field_name === "fixed_position_5_8"
                            )?.display
                          }
                          readOnly
                          // onChange={(
                          //   e
                          // ) => {
                          //   handleStaffingSort(
                          //     "fixed_position_5_8",
                          //     e.target
                          //       .value !==
                          //       ""
                          //       ? e.target
                          //           .value
                          //       : "0"
                          //   );
                          // }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["fixed_position_5_8"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {
                                      realtimeFormulaHeaders?.[
                                        "fixed_position_5_8"
                                      ]
                                    }
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "fixed_position_5_8"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "fixed_position_5_8"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handleStaffingLabel(
                                "fixed_position_5_8",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handleStaffingSwitchcheck(
                            "fixed_position_5_8"
                          )}
                          className="blue-b"
                          onChange={(e) => {
                            handleStaffingSwitchOnChange(
                              "fixed_position_5_8",
                              e
                            );
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.staffing?.find(
                              (e) => e.field_name === "fixed_position_5_5_2"
                            )?.display
                          }
                          readOnly
                          // onChange={(e) => {
                          //   handleStaffingSort(
                          //     "fixed_position_5_5_2",
                          //     e.target
                          //       .value !== ""
                          //       ? e.target
                          //           .value
                          //       : "0"
                          //   );
                          // }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["fixed_position_5_5_2"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {
                                      realtimeFormulaHeaders?.[
                                        "fixed_position_5_5_2"
                                      ]
                                    }
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "fixed_position_5_5_2"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "fixed_position_5_5_2"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handleStaffingLabel(
                                "fixed_position_5_5_2",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handleStaffingSwitchcheck(
                            "fixed_position_5_5_2"
                          )}
                          className="blue-b"
                          onChange={(e) => {
                            handleStaffingSwitchOnChange(
                              "fixed_position_5_5_2",
                              e
                            );
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.staffing?.find(
                              (e) => e.field_name === "fixed_position_7_1"
                            )?.display
                          }
                          readOnly
                          // onChange={(e) => {
                          //   handleStaffingSort(
                          //     "fixed_position_7_1",
                          //     e.target
                          //       .value !== ""
                          //       ? e.target
                          //           .value
                          //       : "0"
                          //   );
                          // }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["fixed_position_7_1"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {
                                      realtimeFormulaHeaders?.[
                                        "fixed_position_7_1"
                                      ]
                                    }
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "fixed_position_7_1"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "fixed_position_7_1"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handleStaffingLabel(
                                "fixed_position_7_1",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handleStaffingSwitchcheck(
                            "fixed_position_7_1"
                          )}
                          className="blue-b"
                          onChange={(e) => {
                            handleStaffingSwitchOnChange(
                              "fixed_position_7_1",
                              e
                            );
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.staffing?.find(
                              (e) => e.field_name === "fixed_position_7_2"
                            )?.display
                          }
                          readOnly
                          // onChange={(e) => {
                          //   handleStaffingSort(
                          //     "fixed_position_7_2",
                          //     e.target
                          //       .value !== ""
                          //       ? e.target
                          //           .value
                          //       : "0"
                          //   );
                          // }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["fixed_position_7_2"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {
                                      realtimeFormulaHeaders?.[
                                        "fixed_position_7_2"
                                      ]
                                    }
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "fixed_position_7_2"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "fixed_position_7_2"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handleStaffingLabel(
                                "fixed_position_7_2",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handleStaffingSwitchcheck(
                            "fixed_position_7_2"
                          )}
                          className="blue-b"
                          onChange={(e) => {
                            handleStaffingSwitchOnChange(
                              "fixed_position_7_2",
                              e
                            );
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.staffing?.find(
                              (e) => e.field_name === "fixed_position_7_3"
                            )?.display
                          }
                          readOnly
                          // onChange={(e) => {
                          //   handleStaffingSort(
                          //     "fixed_position_7_3",
                          //     e.target
                          //       .value !== ""
                          //       ? e.target
                          //           .value
                          //       : "0"
                          //   );
                          // }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["fixed_position_7_3"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {
                                      realtimeFormulaHeaders?.[
                                        "fixed_position_7_3"
                                      ]
                                    }
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "fixed_position_7_3"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "fixed_position_7_3"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handleStaffingLabel(
                                "fixed_position_7_3",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handleStaffingSwitchcheck(
                            "fixed_position_7_3"
                          )}
                          className="blue-b"
                          onChange={(e) => {
                            handleStaffingSwitchOnChange(
                              "fixed_position_7_3",
                              e
                            );
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.staffing?.find(
                              (e) => e.field_name === "fixed_position_7_4"
                            )?.display
                          }
                          readOnly
                          // onChange={(e) => {
                          //   handleStaffingSort(
                          //     "fixed_position_7_4",
                          //     e.target
                          //       .value !== ""
                          //       ? e.target
                          //           .value
                          //       : "0"
                          //   );
                          // }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["fixed_position_7_4"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {
                                      realtimeFormulaHeaders?.[
                                        "fixed_position_7_4"
                                      ]
                                    }
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "fixed_position_7_4"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "fixed_position_7_4"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handleStaffingLabel(
                                "fixed_position_7_4",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handleStaffingSwitchcheck(
                            "fixed_position_7_4"
                          )}
                          className="blue-b"
                          onChange={(e) => {
                            handleStaffingSwitchOnChange(
                              "fixed_position_7_4",
                              e
                            );
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.staffing?.find(
                              (e) => e.field_name === "fixed_position_7_5"
                            )?.display
                          }
                          readOnly
                          // onChange={(e) => {
                          //   handleStaffingSort(
                          //     "fixed_position_7_5",
                          //     e.target
                          //       .value !== ""
                          //       ? e.target
                          //           .value
                          //       : "0"
                          //   );
                          // }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["fixed_position_7_5"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {
                                      realtimeFormulaHeaders?.[
                                        "fixed_position_7_5"
                                      ]
                                    }
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "fixed_position_7_5"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "fixed_position_7_5"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handleStaffingLabel(
                                "fixed_position_7_5",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handleStaffingSwitchcheck(
                            "fixed_position_7_5"
                          )}
                          className="blue-b"
                          onChange={(e) => {
                            handleStaffingSwitchOnChange(
                              "fixed_position_7_5",
                              e
                            );
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.staffing?.find(
                              (e) => e.field_name === "fixed_position_7_5_2"
                            )?.display
                          }
                          readOnly
                          // onChange={(e) => {
                          //   handleStaffingSort(
                          //     "fixed_position_7_5_2",
                          //     e.target
                          //       .value !== ""
                          //       ? e.target
                          //           .value
                          //       : "0"
                          //   );
                          // }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["fixed_position_7_5_2"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {
                                      realtimeFormulaHeaders?.[
                                        "fixed_position_7_5_2"
                                      ]
                                    }
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "fixed_position_7_5_2"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "fixed_position_7_5_2"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handleStaffingLabel(
                                "fixed_position_7_5_2",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handleStaffingSwitchcheck(
                            "fixed_position_7_5_2"
                          )}
                          className="blue-b"
                          onChange={(e) => {
                            handleStaffingSwitchOnChange(
                              "fixed_position_7_5_2",
                              e
                            );
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.staffing?.find(
                              (e) => e.field_name === "fixed_position_7_8"
                            )?.display
                          }
                          readOnly
                          // onChange={(e) => {
                          //   handleStaffingSort(
                          //     "fixed_position_7_8",
                          //     e.target
                          //       .value !== ""
                          //       ? e.target
                          //           .value
                          //       : "0"
                          //   );
                          // }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["fixed_position_7_8"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {
                                      realtimeFormulaHeaders?.[
                                        "fixed_position_7_8"
                                      ]
                                    }
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "fixed_position_7_8"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "fixed_position_7_8"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handleStaffingLabel(
                                "fixed_position_7_8",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handleStaffingSwitchcheck(
                            "fixed_position_7_8"
                          )}
                          className="blue-b"
                          onChange={(e) => {
                            handleStaffingSwitchOnChange(
                              "fixed_position_7_8",
                              e
                            );
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.staffing?.find(
                              (e) => e.field_name === "lab_technologist"
                            )?.display
                          }
                          onChange={(e) => {
                            handleStaffingSort(
                              "lab_technologist",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["lab_technologist"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {
                                      realtimeFormulaHeaders?.[
                                        "lab_technologist"
                                      ]
                                    }
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "lab_technologist"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "lab_technologist"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handleStaffingLabel(
                                "lab_technologist",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handleStaffingSwitchcheck(
                            "lab_technologist"
                          )}
                          className="blue-b"
                          onChange={(e) => {
                            handleStaffingSwitchOnChange("lab_technologist", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.staffing?.find(
                              (e) =>
                                e.field_name ===
                                "phlebotomist_clinical_services"
                            )?.display
                          }
                          onChange={(e) => {
                            handleStaffingSort(
                              "phlebotomist_clinical_services",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.[
                        "phlebotomist_clinical_services"
                      ] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {
                                      realtimeFormulaHeaders?.[
                                        "phlebotomist_clinical_services"
                                      ]
                                    }
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) =>
                                  e.field_name ===
                                  "phlebotomist_clinical_services"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) =>
                                  e.field_name ===
                                  "phlebotomist_clinical_services"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handleStaffingLabel(
                                "phlebotomist_clinical_services",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handleStaffingSwitchcheck(
                            "phlebotomist_clinical_services"
                          )}
                          className="blue-b"
                          onChange={(e) => {
                            handleStaffingSwitchOnChange(
                              "phlebotomist_clinical_services",
                              e
                            );
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.staffing?.find(
                              (e) => e.field_name === "education_orientation"
                            )?.display
                          }
                          onChange={(e) => {
                            handleStaffingSort(
                              "education_orientation",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["education_orientation"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {
                                      realtimeFormulaHeaders?.[
                                        "education_orientation"
                                      ]
                                    }
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "education_orientation"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "education_orientation"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handleStaffingLabel(
                                "education_orientation",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handleStaffingSwitchcheck(
                            "education_orientation"
                          )}
                          className="blue-b"
                          onChange={(e) => {
                            handleStaffingSwitchOnChange(
                              "education_orientation",
                              e
                            );
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.staffing?.find(
                              (e) => e.field_name === "contract_labor_tech"
                            )?.display
                          }
                          onChange={(e) => {
                            handleStaffingSort(
                              "contract_labor_tech",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["contract_labor_tech"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {
                                      realtimeFormulaHeaders?.[
                                        "contract_labor_tech"
                                      ]
                                    }
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "contract_labor_tech"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "contract_labor_tech"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handleStaffingLabel(
                                "contract_labor_tech",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handleStaffingSwitchcheck(
                            "contract_labor_tech"
                          )}
                          className="blue-b"
                          onChange={(e) => {
                            handleStaffingSwitchOnChange(
                              "contract_labor_tech",
                              e
                            );
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.staffing?.find(
                              (e) =>
                                e.field_name === "contract_labor_phlebotomy"
                            )?.display
                          }
                          onChange={(e) => {
                            handleStaffingSort(
                              "contract_labor_phlebotomy",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["contract_labor_phlebotomy"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {
                                      realtimeFormulaHeaders?.[
                                        "contract_labor_phlebotomy"
                                      ]
                                    }
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) =>
                                  e.field_name === "contract_labor_phlebotomy"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) =>
                                  e.field_name === "contract_labor_phlebotomy"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handleStaffingLabel(
                                "contract_labor_phlebotomy",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handleStaffingSwitchcheck(
                            "contract_labor_phlebotomy"
                          )}
                          className="blue-b"
                          onChange={(e) => {
                            handleStaffingSwitchOnChange(
                              "contract_labor_phlebotomy",
                              e
                            );
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.staffing?.find(
                              (e) => e.field_name === "histology"
                            )?.display
                          }
                          onChange={(e) => {
                            handleStaffingSort(
                              "histology",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["histology"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["histology"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "histology"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "histology"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handleStaffingLabel("histology", e.target.value);
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handleStaffingSwitchcheck("histology")}
                          className="blue-b"
                          onChange={(e) => {
                            handleStaffingSwitchOnChange("histology", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.staffing?.find(
                              (e) => e.field_name === "client_services"
                            )?.display
                          }
                          onChange={(e) => {
                            handleStaffingSort(
                              "client_services",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["client_services"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {
                                      realtimeFormulaHeaders?.[
                                        "client_services"
                                      ]
                                    }
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "client_services"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "client_services"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handleStaffingLabel(
                                "client_services",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handleStaffingSwitchcheck("client_services")}
                          className="blue-b"
                          onChange={(e) => {
                            handleStaffingSwitchOnChange("client_services", e);
                          }}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.staffing?.find(
                              (e) => e.field_name === "receptionist"
                            )?.display
                          }
                          onChange={(e) => {
                            handleStaffingSort(
                              "receptionist",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["receptionist"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["receptionist"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "receptionist"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "receptionist"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handleStaffingLabel(
                                "receptionist",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handleStaffingSwitchcheck("receptionist")}
                          className="blue-b"
                          onChange={(e) => {
                            handleStaffingSwitchOnChange("receptionist", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.staffing?.find(
                              (e) => e.field_name === "health_plan_coordinator"
                            )?.display
                          }
                          onChange={(e) => {
                            handleStaffingSort(
                              "health_plan_coordinator",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["health_plan_coordinator"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {
                                      realtimeFormulaHeaders?.[
                                        "health_plan_coordinator"
                                      ]
                                    }
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) =>
                                  e.field_name === "health_plan_coordinator"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) =>
                                  e.field_name === "health_plan_coordinator"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handleStaffingLabel(
                                "health_plan_coordinator",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handleStaffingSwitchcheck(
                            "health_plan_coordinator"
                          )}
                          className="blue-b"
                          onChange={(e) => {
                            handleStaffingSwitchOnChange(
                              "health_plan_coordinator",
                              e
                            );
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.staffing?.find(
                              (e) => e.field_name === "medical_assistant"
                            )?.display
                          }
                          onChange={(e) => {
                            handleStaffingSort(
                              "medical_assistant",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["medical_assistant"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {
                                      realtimeFormulaHeaders?.[
                                        "medical_assistant"
                                      ]
                                    }
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "medical_assistant"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "medical_assistant"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handleStaffingLabel(
                                "medical_assistant",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handleStaffingSwitchcheck(
                            "medical_assistant"
                          )}
                          className="blue-b"
                          onChange={(e) => {
                            handleStaffingSwitchOnChange(
                              "medical_assistant",
                              e
                            );
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.staffing?.find(
                              (e) => e.field_name === "lead_medical_assistant"
                            )?.display
                          }
                          onChange={(e) => {
                            handleStaffingSort(
                              "lead_medical_assistant",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["lead_medical_assistant"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {
                                      realtimeFormulaHeaders?.[
                                        "lead_medical_assistant"
                                      ]
                                    }
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "lead_medical_assistant"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "lead_medical_assistant"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handleStaffingLabel(
                                "lead_medical_assistant",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handleStaffingSwitchcheck(
                            "lead_medical_assistant"
                          )}
                          className="blue-b"
                          onChange={(e) => {
                            handleStaffingSwitchOnChange(
                              "lead_medical_assistant",
                              e
                            );
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.staffing?.find(
                              (e) => e.field_name === "radiologist_technologist"
                            )?.display
                          }
                          onChange={(e) => {
                            handleStaffingSort(
                              "radiologist_technologist",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["radiologist_technologist"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {
                                      realtimeFormulaHeaders?.[
                                        "radiologist_technologist"
                                      ]
                                    }
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) =>
                                  e.field_name === "radiologist_technologist"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) =>
                                  e.field_name === "radiologist_technologist"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handleStaffingLabel(
                                "radiologist_technologist",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handleStaffingSwitchcheck(
                            "radiologist_technologist"
                          )}
                          className="blue-b"
                          onChange={(e) => {
                            handleStaffingSwitchOnChange(
                              "radiologist_technologist",
                              e
                            );
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.staffing?.find(
                              (e) => e.field_name === "athletic_trainer"
                            )?.display
                          }
                          onChange={(e) => {
                            handleStaffingSort(
                              "athletic_trainer",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["athletic_trainer"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {
                                      realtimeFormulaHeaders?.[
                                        "athletic_trainer"
                                      ]
                                    }
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "athletic_trainer"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.staffing?.find(
                                (e) => e.field_name === "athletic_trainer"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handleStaffingLabel(
                                "athletic_trainer",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handleStaffingSwitchcheck(
                            "athletic_trainer"
                          )}
                          className="blue-b"
                          onChange={(e) => {
                            handleStaffingSwitchOnChange("athletic_trainer", e);
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StaffingDataAccordian;
