import React, { useEffect, useState } from "react";
import "./index.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import { ErrorMessage, Formik, Form as FormikForm } from "formik";
import { toast } from "react-toastify";
import axios from "../../axios";
import { API_CALL_URL_PRIVATE } from "../../constants/compensate";
import { FacilityListValidation } from "../../utils/validator";
import Texterror from "../../components/Texterror";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { Switch } from "antd";
import { setLoadingPage } from "../../store/actions";

function AddFormControl() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const customer_code = useSelector((state) => state.compensate.customerCode);
  const loading_page = useSelector((state) => state.compensate.LoadingPage);
  const [selectedOption, setSelectedOption] = useState(null);
  const [costCenterMaster, setCostCenterMaster] = useState(null);
  const [dropCostCenterMaster, setDropCostCenterMaster] = useState([]);
  console.log(dropCostCenterMaster);
  const [initialValues, setInitialValues] = useState({
    facility_id: "",
    facility_name: "",
  });
  const [selectedImage, setSelectedImage] = useState(null);
  const onSubmit = (values, onSubmittingProps) => {
    console.log("form Values", values);
    formSubmit(values);
    // setTimeout(() => {
    //   onSubmittingProps.setSubmitting(false);
    // }, [2000]);
  };
  const formSubmit = async (values) => {
    const obj = {
      facility_id: parseInt(values.facility_id),
      customer_code: customer_code,
      facility_name: values.facility_name,
    };
    try {
      setIsLoading(true);
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/facility/facilityAddUpdate`,
        obj
      );
      console.log(resp);
      toast.success(resp?.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
      navigate("/admin/facility");
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });

      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  const getCostCenter = async () => {
    const resp = await axios.post(
      `${API_CALL_URL_PRIVATE}/department/departmentList`,
      {
        customer_code: parseInt(customer_code),
        limit: "100000",
      }
    );
    setCostCenterMaster(
      resp?.data?.data?.data?.docs?.map((e) => {
        return {
          cost_center_name: e.cost_center_name,
          cost_center_number: e.cost_center_number,
        };
      })
    );
    // setISInitialData(true);
    console.log(resp);
    setDropCostCenterMaster(
      resp?.data?.data?.data?.docs?.map((e) => {
        return {
          label: e.cost_center_name,
          value: e.cost_center_number,
        };
      })
    );
    dispatch(setLoadingPage(false));
  };
  useEffect(() => {
    getCostCenter();
  }, [customer_code]);
  return (
    <>
      {!loading_page ? (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          enableReinitialize
          validateOnChange
        >
          {(formik) => (
            <div className="main-panel">
              {console.log(formik)}
              <div className="content-wrapper">
                {/* <div className="page-header">
                  <h3 className="page-title">
                    <a href="#" className="badge badge-dark"></a>
                  </h3>
                  <div className></div>
                </div> */}
                <FormikForm className="forms-sample">
                  <div className="row mt-2">
                    <div class="col-12 grid-margin stretch-card">
                      <div class="card">
                        {/* <div class="card-body">
                       
                        </div> */}
                        {/* </div>
                    </div>
                    <div class="col-12 grid-margin stretch-card">
                      <div class="card"> */}
                        <div class="card-body py-4">
                          <div className="d-flex justify-content-between">
                            <div className="d-flex">
                              <h4 class="card-title fs-5">Patient Data</h4>
                            </div>
                            <div className="d-flex justify-content-between">
                              <div className="d-flex justify-content-center w-100">
                                <h5 class="card-title fs-5">
                                  Select Cost Center
                                </h5>
                                <div
                                  className="ms-4"
                                  style={{ width: "250px" }}
                                >
                                  <Select
                                    isSearchable="true"
                                    options={dropCostCenterMaster}
                                    className="custom-select"
                                  />
                                </div>
                              </div>
                            </div>

                            {/* <Link
                            to={`/admin/customer/`}
                            className="f-14 white-text-dark-mode"
                          >
                            <FontAwesomeIcon icon="fa-solid fa-arrow-left " />{" "}
                            Back
                          </Link> */}
                          </div>

                          <div class="row">
                            <div className="col-md-12">
                              <hr />
                            </div>
                            <Form.Group
                              className="col-md-4 mb-5"
                              controlId="exampleForm.c_name"
                            >
                              <div className="d-flex align-items-center">
                                <div class="checkbox-group">
                                  <div class="checkbox-item no-wrap d-flex fs-5 lh-1">
                                    <input
                                      type="checkbox"
                                      class="custom-checkbox "
                                    />
                                    Acuity
                                  </div>
                                </div>
                                <div className="d-flex align-items-center">
                                  <span className="ms-4 me-2 grey-color">
                                    Mandatory
                                  </span>
                                  <Switch
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                    defaultChecked
                                    className="blue-b"
                                  />
                                </div>
                              </div>
                            </Form.Group>
                            <Form.Group
                              className="col-md-4 mb-5"
                              controlId="exampleForm.c_name"
                            >
                              <div className="d-flex align-items-center">
                                <div class="checkbox-group">
                                  <div class="checkbox-item no-wrap d-flex fs-5 lh-1">
                                    <input
                                      type="checkbox"
                                      class="custom-checkbox "
                                    />
                                    Patient Acuity: (2:1)
                                  </div>
                                </div>
                                <div className="d-flex align-items-center">
                                  <span className="ms-4 me-2 grey-color">
                                    Mandatory
                                  </span>
                                  <Switch
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                    defaultChecked
                                    className="blue-b"
                                  />
                                </div>
                              </div>
                            </Form.Group>
                            <Form.Group
                              className="col-md-4 mb-5"
                              controlId="exampleForm.c_name"
                            >
                              <div className="d-flex align-items-center">
                                <div class="checkbox-group">
                                  <div class="checkbox-item no-wrap d-flex fs-5 lh-1">
                                    <input
                                      type="checkbox"
                                      class="custom-checkbox "
                                    />
                                    Patient Acuity: (1:1)
                                  </div>
                                </div>
                                <div className="d-flex align-items-center">
                                  <span className="ms-4 me-2 grey-color">
                                    Mandatory
                                  </span>
                                  <Switch
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                    defaultChecked
                                    className="blue-b"
                                  />
                                </div>
                              </div>
                            </Form.Group>
                            <Form.Group
                              className="col-md-4 mb-5"
                              controlId="exampleForm.c_name"
                            >
                              <div className="d-flex align-items-center">
                                <div class="checkbox-group">
                                  <div class="checkbox-item no-wrap d-flex fs-5 lh-1">
                                    <input
                                      type="checkbox"
                                      class="custom-checkbox "
                                    />
                                    Patient Acuity: (1:2)
                                  </div>
                                </div>
                                <div className="d-flex align-items-center">
                                  <span className="ms-4 me-2 grey-color">
                                    Mandatory
                                  </span>
                                  <Switch
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                    defaultChecked
                                    className="blue-b"
                                  />
                                </div>
                              </div>
                            </Form.Group>
                            <Form.Group
                              className="col-md-4 mb-5"
                              controlId="exampleForm.c_name"
                            >
                              <div className="d-flex align-items-center">
                                <div class="checkbox-group">
                                  <div class="checkbox-item no-wrap d-flex fs-5 lh-1">
                                    <input
                                      type="checkbox"
                                      class="custom-checkbox "
                                    />
                                    Patient Acuity: (1:3)
                                  </div>
                                </div>
                                <div className="d-flex align-items-center">
                                  <span className="ms-4 me-2 grey-color">
                                    Mandatory
                                  </span>
                                  <Switch
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                    defaultChecked
                                    className="blue-b"
                                  />
                                </div>
                              </div>
                            </Form.Group>
                            <Form.Group
                              className="col-md-4 mb-5"
                              controlId="exampleForm.c_name"
                            >
                              <div className="d-flex align-items-center">
                                <div class="checkbox-group">
                                  <div class="checkbox-item no-wrap d-flex fs-5 lh-1">
                                    <input
                                      type="checkbox"
                                      class="custom-checkbox "
                                    />
                                    Patient Acuity: (1:4)
                                  </div>
                                </div>
                                <div className="d-flex align-items-center">
                                  <span className="ms-4 me-2 grey-color">
                                    Mandatory
                                  </span>
                                  <Switch
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                    defaultChecked
                                    className="blue-b"
                                  />
                                </div>
                              </div>
                            </Form.Group>
                            <Form.Group
                              className="col-md-4 mb-5"
                              controlId="exampleForm.c_name"
                            >
                              <div className="d-flex align-items-center">
                                <div class="checkbox-group">
                                  <div class="checkbox-item no-wrap d-flex fs-5 lh-1">
                                    <input
                                      type="checkbox"
                                      class="custom-checkbox "
                                    />
                                    Patient Acuity: (1:5)
                                  </div>
                                </div>
                                <div className="d-flex align-items-center">
                                  <span className="ms-4 me-2 grey-color">
                                    Mandatory
                                  </span>
                                  <Switch
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                    defaultChecked
                                    className="blue-b"
                                  />
                                </div>
                              </div>
                            </Form.Group>
                            <Form.Group
                              className="col-md-4 mb-5"
                              controlId="exampleForm.c_name"
                            >
                              <div className="d-flex align-items-center">
                                <div class="checkbox-group">
                                  <div class="checkbox-item no-wrap d-flex fs-5 lh-1">
                                    <input
                                      type="checkbox"
                                      class="custom-checkbox "
                                    />
                                    Patient Acuity: (1:6)
                                  </div>
                                </div>
                                <div className="d-flex align-items-center">
                                  <span className="ms-4 me-2 grey-color">
                                    Mandatory
                                  </span>
                                  <Switch
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                    defaultChecked
                                    className="blue-b"
                                  />
                                </div>
                              </div>
                            </Form.Group>
                          </div>
                          {/* </FormikForm> */}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row"></div>
                  <div className="row my-4">
                    <div class="col-12 grid-margin stretch-card">
                      <div class="card">
                        <div class="card-body py-4">
                          <div className="d-flex justify-content-between">
                            <div className="d-flex">
                              <h4 class="card-title fs-5">Staffing</h4>
                            </div>

                            {/* <Link
                            to={`/admin/customer/`}
                            className="f-14 white-text-dark-mode"
                          >
                            <FontAwesomeIcon icon="fa-solid fa-arrow-left " />{" "}
                            Back
                          </Link> */}
                          </div>

                          <div class="row">
                            <Form.Group
                              className="col-md-3 mb-5"
                              controlId="exampleForm.c_name"
                            >
                              <div className="fs-5">Charge</div>
                              <div className="d-flex align-items-center my-2">
                                <div>
                                  <span className="me-3">Show</span>
                                  <Switch
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                    defaultChecked
                                  />
                                </div>
                                <div>
                                  <span className="mx-3">Mandatory</span>
                                  <Switch
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                    defaultChecked
                                  />
                                </div>
                              </div>
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_name"
                            >
                              <div className="fs-5">CNA</div>
                              <div className="d-flex align-items-center my-2">
                                <div>
                                  <span className="me-3">Show</span>
                                  <Switch
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                    defaultChecked
                                  />
                                </div>
                                <div>
                                  <span className="mx-3">Mandatory</span>
                                  <Switch
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                    defaultChecked
                                  />
                                </div>
                              </div>
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_name"
                            >
                              <div className="fs-5">Orientation</div>
                              <div className="d-flex align-items-center my-2">
                                <div>
                                  <span className="me-3">Show</span>
                                  <Switch
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                    defaultChecked
                                  />
                                </div>
                                <div>
                                  <span className="mx-3">Mandatory</span>
                                  <Switch
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                    defaultChecked
                                  />
                                </div>
                              </div>
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_name"
                            >
                              <div className="fs-5">RN</div>
                              <div className="d-flex align-items-center my-2">
                                <div>
                                  <span className="me-3">Show</span>
                                  <Switch
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                    defaultChecked
                                  />
                                </div>
                                <div>
                                  <span className="mx-3">Mandatory</span>
                                  <Switch
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                    defaultChecked
                                  />
                                </div>
                              </div>
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_name"
                            >
                              <div className="fs-5">Res/Brk RN</div>
                              <div className="d-flex align-items-center my-2">
                                <div>
                                  <span className="me-3">Show</span>
                                  <Switch
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                    defaultChecked
                                  />
                                </div>
                                <div>
                                  <span className="mx-3">Mandatory</span>
                                  <Switch
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                    defaultChecked
                                  />
                                </div>
                              </div>
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_name"
                            >
                              <div className="fs-5">Unit Sec</div>
                              <div className="d-flex align-items-center my-2">
                                <div>
                                  <span className="me-3">Show</span>
                                  <Switch
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                    defaultChecked
                                  />
                                </div>
                                <div>
                                  <span className="mx-3">Mandatory</span>
                                  <Switch
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                    defaultChecked
                                  />
                                </div>
                              </div>
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_name"
                            >
                              <div className="fs-5">Sitter</div>
                              <div className="d-flex align-items-center my-2">
                                <div>
                                  <span className="me-3">Show</span>
                                  <Switch
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                    defaultChecked
                                  />
                                </div>
                                <div>
                                  <span className="mx-3">Mandatory</span>
                                  <Switch
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                    defaultChecked
                                  />
                                </div>
                              </div>
                            </Form.Group>
                          </div>
                          {/* </FormikForm> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row my-4">
                    <div class="col-12 grid-margin stretch-card">
                      <div class="card">
                        <div class="card-body py-4">
                          <div className="d-flex justify-content-between">
                            <div className="d-flex">
                              <h4 class="card-title fs-5">
                                Additional Data Tracking
                              </h4>
                            </div>

                            {/* <Link
                            to={`/admin/customer/`}
                            className="f-14 white-text-dark-mode"
                          >
                            <FontAwesomeIcon icon="fa-solid fa-arrow-left " />{" "}
                            Back
                          </Link> */}
                          </div>

                          <div class="row">
                            <Form.Group
                              className="col-md-4 mb-5"
                              controlId="exampleForm.c_name"
                            >
                              <div className="fs-5">
                                Anticipated Admits/ Transfers in this Shift
                              </div>
                              <div className="d-flex align-items-center my-2">
                                <div>
                                  <span className="me-3">Show</span>
                                  <Switch
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                    defaultChecked
                                  />
                                </div>
                                <div>
                                  <span className="mx-3">Mandatory</span>
                                  <Switch
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                    defaultChecked
                                  />
                                </div>
                              </div>
                            </Form.Group>
                            <Form.Group
                              className="col-md-4 mb-3"
                              controlId="exampleForm.c_name"
                            >
                              <div className="fs-5">
                                Actual Admits/ Transfers in the previous Shift
                              </div>
                              <div className="d-flex align-items-center my-2">
                                <div>
                                  <span className="me-3">Show</span>
                                  <Switch
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                    defaultChecked
                                  />
                                </div>
                                <div>
                                  <span className="mx-3">Mandatory</span>
                                  <Switch
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                    defaultChecked
                                  />
                                </div>
                              </div>
                            </Form.Group>
                            <Form.Group
                              className="col-md-4 mb-3"
                              controlId="exampleForm.c_name"
                            >
                              <div className="fs-5">Telemetry Patients</div>
                              <div className="d-flex align-items-center my-2">
                                <div>
                                  <span className="me-3">Show</span>
                                  <Switch
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                    defaultChecked
                                  />
                                </div>
                                <div>
                                  <span className="mx-3">Mandatory</span>
                                  <Switch
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                    defaultChecked
                                  />
                                </div>
                              </div>
                            </Form.Group>
                            <Form.Group
                              className="col-md-4 mb-3"
                              controlId="exampleForm.c_name"
                            >
                              <div className="fs-5">Vaso-Active Drips</div>
                              <div className="d-flex align-items-center my-2">
                                <div>
                                  <span className="me-3">Show</span>
                                  <Switch
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                    defaultChecked
                                  />
                                </div>
                                <div>
                                  <span className="mx-3">Mandatory</span>
                                  <Switch
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                    defaultChecked
                                  />
                                </div>
                              </div>
                            </Form.Group>
                            <Form.Group
                              className="col-md-4 mb-3"
                              controlId="exampleForm.c_name"
                            >
                              <div className="fs-5">Isolation Patients</div>
                              <div className="d-flex align-items-center my-2">
                                <div>
                                  <span className="me-3">Show</span>
                                  <Switch
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                    defaultChecked
                                  />
                                </div>
                                <div>
                                  <span className="mx-3">Mandatory</span>
                                  <Switch
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                    defaultChecked
                                  />
                                </div>
                              </div>
                            </Form.Group>
                            <Form.Group
                              className="col-md-4 mb-3"
                              controlId="exampleForm.c_name"
                            >
                              <div className="fs-5">
                                Comments / Barriers / Action Plan
                              </div>
                              <div className="d-flex align-items-center my-2">
                                <div>
                                  <span className="me-3">Show</span>
                                  <Switch
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                    defaultChecked
                                  />
                                </div>
                                <div>
                                  <span className="mx-3">Mandatory</span>
                                  <Switch
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                    defaultChecked
                                  />
                                </div>
                              </div>
                            </Form.Group>

                            <Form.Group className="col-md-12 mb-3 d-flex justify-content-end">
                              {/* <Button variant="primary" type="submit">
                              Reset
                            </Button> */}
                              <Button
                                className="ms-3"
                                variant="success"
                                type="submit"
                                disabled={isLoading}
                              >
                                {isLoading ? "Saving..." : "Save"}
                              </Button>
                            </Form.Group>
                          </div>
                          {/* </FormikForm> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </FormikForm>
              </div>
            </div>
          )}
        </Formik>
      ) : (
        <div className="d-flex align-items-center justify-content-center w-100vw h-100vh">
          <span class="loader" style={{ width: "50px", height: "50px" }}></span>
        </div>
      )}
    </>
  );
}

export default AddFormControl;
