import CryptoJS from 'crypto-js';


const secretKey = "2FxXT1NTf9K1Mo4iA7OvteI";


export const encrypt = (text) => {
  return CryptoJS.AES.encrypt(text, secretKey).toString();
};


export const decrypt = (encryptedText) => {
  const bytes = CryptoJS.AES.decrypt(encryptedText, secretKey);
  return bytes.toString(CryptoJS.enc.Utf8);
};