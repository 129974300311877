import { useEffect, useState, useContext } from "react";
import ContentLoader from "react-content-loader";
import EditIcon from "../../assets/img/edit.png";
import AddIcon from "../../assets/img/add.png";

import EditIcon1 from "../../assets/img/edit1.png";
import AddIcon1 from "../../assets/img/add1.png";


import { useSelector, useDispatch } from "react-redux";
import { getPreviousShiftData } from "../../services";
import moment from "moment";
import { ThemeContext } from "../../providers/ThemeProvider";

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";

const LinkLoader = ({ themeMode }) => (
  <div
    style={{
      border: "0px solid #eee",
      width: "100%",
      borderRadius: 0,
      marginTop: 0,
      padding: 0,
    }}
  >
    <ContentLoader viewBox="0 0 280 145.6"
      backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
      foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
    >
      {/* Only SVG shapes */}
      <rect x="0" y="0" rx="5" ry="5" width="280" height="25" />
      <rect x="0" y="30" rx="5" ry="5" width="280" height="25" />
      <rect x="0" y="60" rx="5" ry="5" width="280" height="25" />
      <rect x="0" y="90" rx="5" ry="5" width="280" height="25" />
      <rect x="0" y="120" rx="5" ry="5" width="280" height="25" />
    </ContentLoader>
  </div>
);

function DataTable({ data, callbackfunc, directId, isLoader, realtimeshift, cost_center_number }) {
  const [themeMode, setTheme] = useContext(ThemeContext);
  const [showupdate, setShowupdate] = useState(false);
  const [id, setId] = useState(null);
  const dispatch = useDispatch();
  const [activeshift, setActiveshift] = useState("");

  useEffect(() => setId(directId.id), [directId]);
  useEffect(() => setActiveshift(realtimeshift), [realtimeshift]);

  const rowClickHandler = (obj, type) => {
    getPreviousShiftData(dispatch, cost_center_number, obj.shift_date, obj.shift_time);
    if (obj.add_edit_button == 1 || obj.add_edit_button == 2) {
      setActiveshift(obj.shift_time);
      setId(obj.id);
      callbackfunc(obj, type);
    }
  };

  //   Console
  return (
    <div className="white-bg shadow-box h-100">
      <div class="box-heading text-center">Interval Activity</div>
      <div class="overlfow-auto h-607">
        <table class="table activity-tabel mb-0  spacing-table tb1 table-hover">
          <thead>
            <tr>
              <th class="coll text-center width-100p">Shift</th>
              <th class="coll text-center">Submitted<br />by</th>
              <th class="coll text-center">RN to PT<br />Ratio</th>
              <th class="coll text-center">Report Notes</th>
            </tr>
          </thead>
          <tbody>
            {!isLoader ? (
              data.length > 0 ? (
                data.map((item, index) => {
                  if (item.status === "entry_upcoming") {
                    return (
                      <tr>
                        <td
                          key={index}
                          onClick={() => rowClickHandler(item, 2)}
                          className="p-0 noborder"
                          colSpan={4}
                        >
                          <button
                            className={
                              item.add_edit_button == 1
                                ? "btn button-shadow transparent-btn w-100 black-color position-relative"
                                : "btn button-disable transparent-btn grey-shade w-100 black-color d-flex justify-content-center position-relative"
                            }
                            disabled={item.add_edit_button == 1 ? false : true}
                          >
                            <span className="position-absolute pos-s">
                              {moment(item.shift_date).format("MM/DD")} <br />
                              {item.shift_time}
                            </span>
                            <span>Upcoming Shift</span>
                          </button>
                        </td>
                      </tr>
                    );
                  } else if (item.status == "entry_due") {
                    return (
                      <tr key={index} onClick={() => rowClickHandler(item, 2)}>
                        <td className="p-0 noborder text-center" colSpan={4}>
                          <button className="btn button-shadow transparent-btn w-100 black-color d-flex justify-content-center position-relative">
                            <span className="position-absolute pos-s">
                              {moment(item.shift_date).format("MM/DD")} <br />
                              {item.shift_time}
                            </span>
                            <span>Enter Shift Details</span>
                          </button>
                        </td>
                      </tr>
                    );
                  } else if (item.status == "entry_missing") {
                    return (
                      <tr key={index} onClick={() => rowClickHandler(item, 2)}>
                        <td className="p-0 noborder text-center" colSpan={4}>
                          <button className="btn button-shadow transparent-btn w-100 black-color d-flex justify-content-center position-relative">
                            <span className="position-absolute pos-s">
                              {moment(item.shift_date).format("MM/DD")} <br />
                              {item.shift_time}
                            </span>
                            <span>
                              {item.comment}{" "}
                              <span>
                                {/* <img
                                  src={AddIcon}
                                  alt=""
                                  className="align-bottom"
                                /> */}
                                <img src={themeMode.theme === "DARK" ? AddIcon1 : AddIcon} alt="" className="align-bottom"/>
                              </span>
                            </span>
                          </button>
                        </td>

                        {/* <td>
                                                        {moment(item.shift_date).format("MM/DD")}<br />{item.shift}
                                                    </td>
                                                    <td className='text-center'><span class="f-12 v-m">{item.entered_by}</span></td>
                                                    <td class="text-center">{item.rn_to_pt_ratio}</td>
                                                    <td class="f-row minheight60">
                                                        <div className='flex-grow-1'>
                                                            {item.comment}
                                                        </div>
                                                        <div>
                                                            <span><img src={AddIcon} alt="" /></span>
                                                        </div>
                                                    </td> */}
                      </tr>
                    );
                  } else {
                    return (
                      <tr key={index} onClick={() => rowClickHandler(item, 1)}>
                        <td className="text-center width-100p">
                          <div className="">
                            {" "}
                            {moment(item.shift_date).format("MM/DD")}
                            <br />
                            {item.shift_time}
                          </div>
                        </td>
                        <td className="text-center">
                          <span class="f-12 v-m">{item.entered_by}</span>
                        </td>
                        <td class="text-center">{item.rn_to_pt_ratio}</td>
                        <td class="f-row minheight60 text-center">
                          <div className="flex-grow-1">{item.comment}</div>
                          <div>
                            <span class="mb-2 d-block">
                              {/* <img src={EditIcon} alt="" className="mt-2" /> */}
                              <img src={themeMode.theme === "DARK" ? EditIcon1 : EditIcon} alt="" className="mt-2"/>
                            </span>
                          </div>
                        </td>
                      </tr>
                    );
                  }
                })
              ) : null
            ) : (
                <LinkLoader themeMode={themeMode} />
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default DataTable;
