import { API_CALL_URL_PUBLIC } from "../constants";
import { API_CALL_URL_PRIVATE } from "../constants";
import axios from "../axios";
import {
    selectedshift, setDepartmentDetail, previousshiftdatamodal, gridshiftdatamodal, getPrevShiftData
} from "../store/actions/Compensate";

export const getdata = async (param) => {
    const resp = await axios.post(
      API_CALL_URL_PRIVATE + "/department/getData",
        param
      );
    return resp;
}

export const getdataService = async (dispatch, param) => {
    const resp = await axios.post(
      API_CALL_URL_PRIVATE + "/department/getData",
        param
      );
    const {data} = await resp;
    dispatch(setDepartmentDetail(data.data));
    dispatch(selectedshift(data.data?.content?.content_1?.selectedShift));
    return data;
}

export const autoFillgetDirectDataById = async (dispatch, contentId, isdate) => {
    try{
        if (contentId) {
          const resp = await axios.post(API_CALL_URL_PRIVATE + "/department/getDirectDataById", { id: contentId });
            const { data } = resp;
            dispatch(previousshiftdatamodal(data.data.directData))
            dispatch(gridshiftdatamodal(data.data.gridData))
            //setPreviousShiftData(data.data.directData);
            //setGridData(data.data.gridData);
            //setContent({ id: contentId });
            
          } else {
            dispatch(previousshiftdatamodal([]))
            dispatch(gridshiftdatamodal([]))
            
            //setPreviousShiftData([]);
            //setGridData([]);
            //setContent({ id: false });
            
          }
    } catch(error){

    }
}

export const getPreviousShiftData = async (dispatch, ccn, shift_date, shift_time) => {
  // console.log(shift_date, 'new api shift_date');
  try{
      if (ccn) {    
          const changedate = shift_date.split('/');
          const date = `${changedate[1]}-${changedate[0]}-${changedate[2]}`;
        const resp = await axios.post(API_CALL_URL_PRIVATE + "/department/getPreviousShiftData", { 
            cost_center_number: ccn,
            shift_date:date,
            shift_time
          });
          const { data } = resp;
          dispatch(getPrevShiftData(data.data))
          //dispatch(gridshiftdatamodal(data.data.gridData))
        } else {
          dispatch(previousshiftdatamodal([]))
          dispatch(gridshiftdatamodal([]))
        }
  } catch(error){

  }
}



export const getTableRecordWithSorting = (datatable, key, type) => {
  let sortdata = [];
  if(type === 'asc'){
    sortdata = datatable.sort((a, b) => {return (a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : 0)})
  } else {
    sortdata = datatable.sort((a, b) => {return (a[key] > b[key] ? -1 : a[key] < b[key] ? 1 : 0)})  
  }
  //console.log(datatable, 'datatable');
  return sortdata;   
}

