import Todolist from "../../components/TodoList/index";
import ActivityCard from "../../components/cards/ActivityCard";
import CardOne from "../../components/cards/CardOne";
import CardTwo from "../../components/cards/CardTwo";
import NotificationCard from "../../components/cards/NotificationCard";
import * as DS from "../../Styled/DashStyle";
import PageLayout from "../../layouts/PageLayout";
import ReactDatePicker from "react-datepicker";
import { CiCalendarDate } from "react-icons/ci";
import Select from "react-select";
import { useState } from "react";
import { useEffect } from "react";
import { API_CALL_URL_PRIVATE } from "../../constants";
import axios from "../../axios";
import TopCostCenterGraph from "../../components/cards/TopCostCenterGraph";
import CostCenterGraphReal from "../../components/cards/CostCenterGraphReal";

const Dashboard = () => {
  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(new Date());
  const [userType, setUserType] = useState("");
  const [dropUserType, setDropUserType] = useState([]);

  useEffect(() => {
    const getUserType = async () => {
      try {
        const resp = await axios.post(
          `${API_CALL_URL_PRIVATE}/userType/userTypeList`,
          {
            searchKeyword: "",
            limit: 50,
            page: 1,
            sort_column: "",
            sort_order: 1,
          }
        );
        const Detail = resp?.data?.data?.data?.docs;

        const drop_user_type = Detail?.map((e) => {
          return {
            label: e?.user_type_title,
            value: e?.user_type_id,
          };
        });
        setDropUserType([
          { value: "", label: "Select User Type" },
          ...drop_user_type,
        ]);
      } catch (error) {
        console.log(error);
      }
    };
    getUserType();
  }, []);

  return (
    <>
      {/* <PageLayout> */}
      <DS.Parent>
        <div className="row justify-content-between w-100 mobile-half-col m-0 gx-3 white-bg pb-3 px-2 pt-2">
          <div className="d-flex flex-column col">
            <h5 class="m-0 fs-6 ">User Type</h5>
            <div
            //   className="ms-4"
            >
              <Select
                isSearchable="true"
                options={dropUserType}
                defaultValue={{ value: "", label: "Select User Type" }}
                className="custom-select"
                onChange={(e) => {
                  setUserType(e.value);
                }}
              />
            </div>
          </div>
          <div className="d-flex flex-column col dt-pi position-relative">
            <h5 class="m-0 fs-6 d-flex align-items-center">
              From Date
              <CiCalendarDate className="fs-5 mx-2" />
            </h5>
            <ReactDatePicker
              closeOnScroll={(e) => e.target === document}
              selected={dateFrom}
              dateFormat="MM/dd/yyyy"
              isClearable={true}
              onChange={(date) => {
                setDateFrom(date);
                // setListLoader(true);
              }}
            />
          </div>
          <div className="d-flex flex-column col dt-pi">
            <h5 class="m-0 fs-6 d-flex align-items-center">
              To Date
              <CiCalendarDate className="fs-5 mx-2" />
            </h5>
            <div
            //   className="ms-4"
            >
              <ReactDatePicker
                // showIcon={true}
                closeOnScroll={(e) => e.target === document}
                selected={dateTo}
                minDate={dateFrom || null}
                dateFormat="MM/dd/yyyy"
                isClearable={true}
                onChange={(date) => {
                  setDateTo(date);
                  // setListLoader(true);
                }}
              />
            </div>
          </div>
        </div>
        <DS.SectionTwo>
          <NotificationCard
            userType={userType}
            dateFrom={dateFrom}
            dateTo={dateTo}
          />
          <ActivityCard
            userType={userType}
            dateFrom={dateFrom}
            dateTo={dateTo}
          />
        </DS.SectionTwo>
        <DS.SectionTwo>
          <TopCostCenterGraph
            userType={userType}
            dateFrom={dateFrom}
            dateTo={dateTo}
          />
          <CostCenterGraphReal
            userType={userType}
            dateFrom={dateFrom}
            dateTo={dateTo}
          />
        </DS.SectionTwo>
      </DS.Parent>
      {/* </PageLayout> */}
    </>
  );
};

export default Dashboard;
