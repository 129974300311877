import { useState, useEffect } from 'react';

const useMenuPlacement = (ref, parentRef) => {
  const [menuPlacement, setMenuPlacement] = useState('bottom');

  useEffect(() => {
    const handleMenuPlacement = () => {
      if (ref?.current && parentRef?.current) {
        const selectRect = ref?.current?.getBoundingClientRect();
        const parentRect = parentRef?.current?.getBoundingClientRect();
        const spaceBelowParent = parentRect.bottom - selectRect.bottom;
        const spaceAboveParent = selectRect.top - parentRect.top;

        const viewportHeight = window.innerHeight;
        const spaceBelowWindow = viewportHeight - selectRect.bottom;
        const spaceAboveWindow = selectRect.top;

        const spaceBelow = Math.min(spaceBelowParent, spaceBelowWindow);
        const spaceAbove = Math.min(spaceAboveParent, spaceAboveWindow);

        if (spaceBelow < 300 && spaceAbove > spaceBelow) {
          setMenuPlacement('top');
        } else {
          setMenuPlacement('bottom');
        }
      }
    };

    handleMenuPlacement();
    window.addEventListener('resize', handleMenuPlacement);
    return () => window.removeEventListener('resize', handleMenuPlacement);
  }, [ref, parentRef]);

  return menuPlacement;
};

export default useMenuPlacement;
