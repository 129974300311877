import React, { useEffect, useState, useCallback, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useSelector, useDispatch } from "react-redux";

import Modal from "react-bootstrap/Modal";
import { API_CALL_URL_PUBLIC } from "../../constants/";
import { API_CALL_URL_PRIVATE } from "../../constants/";
import axios from "../../axios";
// import { SocketContext } from "../../socket";
import { getPreviousAndCurrentShift } from "../../store/actions/Compensate";
import BedIcon from "../../assets/img/bed-tb.png";
import AcuityIcon from "../../assets/img/acuity.png";
import ChargeIcon from "../../assets/img/charge.png";
import CnaIcon from "../../assets/img/cna.png";
import OrientationIcon from "../../assets/img/orientation.png";
import NurseIcon from "../../assets/img/nurse.png";
import ReseIcon from "../../assets/img/res-brk-en.png";
import UnitIcon from "../../assets/img/unit_sec.png";
import SitterIcon from "../../assets/img/sitter.png";
import AdmitsIcon from "../../assets/img/admits.png";
import DischargesIcon from "../../assets/img/discharges.png";
import TpatientsIcon from "../../assets/img/telemetry_patients.png";
import DripsIcon from "../../assets/img/drips.png";

// ----------------------Dark View Icon
import BedIcon1 from "../../assets/img/bed-tb1.png";
import AcuityIcon1 from "../../assets/img/acuity1.png";
import ChargeIcon1 from "../../assets/img/charge1.png";
import CnaIcon1 from "../../assets/img/cna1.png";
import OrientationIcon1 from "../../assets/img/orientation1.png";
import NurseIcon1 from "../../assets/img/nurse1.png";
import ReseIcon1 from "../../assets/img/res-brk-en1.png";
import UnitIcon1 from "../../assets/img/unit_sec1.png";
import SitterIcon1 from "../../assets/img/sitter1.png";
import AdmitsIcon1 from "../../assets/img/admits1.png";
import DischargesIcon1 from "../../assets/img/discharges1.png";
import TpatientsIcon1 from "../../assets/img/telemetry_patients1.png";
import DripsIcon1 from "../../assets/img/drips1.png";

import { ThemeContext } from "../../providers/ThemeProvider";
import { THEME_TYPE } from "../../constants/theme";

import moment from "moment";

const init = {
  acuity: "",
  actual_admit: "",
  ant_admit: "",
  charge: "",
  cna: "",
  comment: "",
  cost_center_number: "",
  currentShiftNumber: "",
  one_five: "",
  one_six: "",
  one_four: "",
  id: "",
  nurses: "",
  one_one: "",
  two_one: "",
  orientation: "",
  res_brk_rn: "",
  shift_time: "",
  sitter: "",
  telemetry: "",
  one_three: "",
  shift_date: "",
  total_patients: "",
  one_two: "",
  unit_sec: "",
  vaso_active_drips: "",
};

function UpdateModal({
  isShow,
  callbackfunc,
  reloaddata,
  data_id,
  previousShiftData,
  getprevAndCurrentShift,
  gridData,
  cost_center_number,
  cost_center_name,
  numbers,
  getPreAndCurr,
}) {
  const [themeMode, setTheme] = useContext(ThemeContext);
  const [formdata, setFormdata] = useState(init);
  // const socket = useContext(SocketContext);
  const [successMsg, setSuccessmsg] = useState("");
  const selector = useSelector((state) => state);
  const dispatch = useDispatch();
  const detail_data = selector.compensate.departmentdetail;
  const contentone = detail_data?.content ? detail_data.content.content_1 : {};
  const [variance, setVariance] = useState({});
  const [totalvariance, setTotalVar] = useState(0);
  const [dataid, setDataid] = useState(null);
  //console.log("sdsdds", contentone);
  const [gridUpdateData, setGridUpdateData] = useState({});
  const [patientData, setPatientData] = useState({});
  const [totalPatientvariance, setTotalPatientVar] = useState(0);
  const activedatefilter = selector.compensate?.activedatefilter;


  const handleThemeChange = async (e) => {
    if (themeMode.theme === THEME_TYPE.LIGHT) {
        setTheme({ theme: THEME_TYPE.DARK });
    }
    else {
        setTheme({ theme: THEME_TYPE.LIGHT });
    }
    // localStorage.setItem("psLang", themeMode.theme);
    const themeType = themeMode.theme === THEME_TYPE.LIGHT ? 1 : 0;
    console.log("themeType", themeType);

    try {
        const { data } = await axios.post(
            `${API_CALL_URL_PRIVATE}/user/updateThemeMode`,
            { theme_mode: themeType }
        );
      localStorage.setItem("psAdmin", JSON.stringify(data));
    } catch (error) {
        console.log(error);
    }

};




  const setvarianceValue = (event) => {
    //console.log(variance, "variance");

    if (event.target.name == "charge") {
      setVariance({
        ...variance,
        [event.target.name]:
          (gridUpdateData?.required_charge
            ? gridUpdateData?.required_charge
            : 0) - event.target.value,
      });
      const { charge, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        ((gridUpdateData?.required_charge
          ? gridUpdateData?.required_charge
          : 0) -
          event.target.value);
      setTotalVar(sum);
    } else if (event.target.name == "cna") {
      setVariance({
        ...variance,
        [event.target.name]:
          (gridUpdateData?.required_cna ? gridUpdateData?.required_cna : 0) -
          event.target.value,
      });
      const { cna, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        ((gridUpdateData?.required_cna ? gridUpdateData?.required_cna : 0) -
          event.target.value);
      setTotalVar(sum);
    } else if (event.target.name == "orientation") {
      setVariance({
        ...variance,
        [event.target.name]:
          (gridUpdateData?.orientation ? gridUpdateData?.orientation : 0) -
          event.target.value,
      });
      const { orientation, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        ((gridUpdateData?.orientation ? gridUpdateData?.orientation : 0) -
          event.target.value);
      setTotalVar(sum);
    } else if (event.target.name == "nurses") {
      setVariance({
        ...variance,
        [event.target.name]:
          (gridUpdateData?.required_nurses
            ? gridUpdateData?.required_nurses
            : 0) - event.target.value,
      });
      const { nurses, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        ((gridUpdateData?.required_nurses
          ? gridUpdateData?.required_nurses
          : 0) -
          event.target.value);
      setTotalVar(sum);
    } else if (event.target.name == "res_brk_rn") {
      setVariance({
        ...variance,
        [event.target.name]:
          (gridUpdateData?.required_res_brk_rn
            ? gridUpdateData?.required_res_brk_rn
            : 0) - event.target.value,
      });
      const { res_brk_rn, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        ((gridUpdateData?.required_res_brk_rn
          ? gridUpdateData?.required_res_brk_rn
          : 0) -
          event.target.value);
      setTotalVar(sum);
    } else if (event.target.name == "unit_sec") {
      setVariance({
        ...variance,
        [event.target.name]:
          (gridUpdateData?.unit_sec ? gridUpdateData?.unit_sec : 0) -
          event.target.value,
      });
      const { unit_sec, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        ((gridUpdateData?.unit_sec ? gridUpdateData?.unit_sec : 0) -
          event.target.value);
      setTotalVar(sum);
    } else if (event.target.name == "sitter") {
      setVariance({
        ...variance,
        [event.target.name]:
          (gridUpdateData?.sitter ? gridUpdateData?.sitter : 0) -
          event.target.value,
      });
      const { sitter, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        ((gridUpdateData?.sitter ? gridUpdateData?.sitter : 0) -
          event.target.value);
      setTotalVar(sum);
    }
  };
  useEffect(() => {
    setGridUpdateData(gridData);
  }, [gridData]);

  useEffect(() => {
    getEditData(data_id.id);
  }, [data_id]);

  const setpartValue = (event) => {
    if (event.target.name == "two_one") {
      setPatientData({
        ...patientData,
        [event.target.name]: event.target.value,
      });
      const { two_one, ...rest } = patientData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setTotalPatientVar(sum);
      //setFormdata(...formdata, one_one);
      getDirectDataById("Grid", sum, event);
    } else if (event.target.name == "one_one") {
      setPatientData({
        ...patientData,
        [event.target.name]: event.target.value,
      });
      const { one_one, ...rest } = patientData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setTotalPatientVar(sum);
      //setFormdata(...formdata, one_one);
      getDirectDataById("Grid", sum, event);
    } else if (event.target.name == "one_two") {
      setPatientData({
        ...patientData,
        [event.target.name]: event.target.value,
      });
      const { one_two, ...rest } = patientData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setTotalPatientVar(sum);
      getDirectDataById("Grid", sum, event);
    } else if (event.target.name == "one_three") {
      setPatientData({
        ...patientData,
        [event.target.name]: event.target.value,
      });
      const { one_three, ...rest } = patientData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setTotalPatientVar(sum);
      getDirectDataById("Grid", sum, event);
    } else if (event.target.name == "one_four") {
      setPatientData({
        ...patientData,
        [event.target.name]: event.target.value,
      });
      const { one_four, ...rest } = patientData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setTotalPatientVar(sum);
      getDirectDataById("Grid", sum, event);
    } else if (event.target.name == "one_five") {
      setPatientData({
        ...patientData,
        [event.target.name]: event.target.value,
      });
      const { one_five, ...rest } = patientData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setTotalPatientVar(sum);
      getDirectDataById("Grid", sum, event);
    } else if (event.target.name == "one_six") {
      setPatientData({
        ...patientData,
        [event.target.name]: event.target.value,
      });
      const { one_six, ...rest } = patientData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setTotalPatientVar(sum);
      getDirectDataById("Grid", sum, event);
    }
  };

  const getEditData = async (parmid) => {
    try {
      if (parmid) {
        const resp = await axios.post(
          API_CALL_URL_PRIVATE + "/department/getDirectDataById",
          { id: parmid }
        );
        const { data } = await resp;
        const gridapidata = data.data.gridData;
        //console.log("response update data", previousShiftData);
        //console.log("response update data", data.data);
        setTotalPatientVar(data.data.directData?.total_patients);
        setPatientData({
          two_one: data.data.directData?.two_one,
          one_one: data.data.directData?.one_one,
          one_two: data.data.directData?.one_two,
          one_three: data.data.directData?.one_three,
          one_four: data.data.directData?.one_four,
          one_five: data.data.directData?.one_five,
          one_six: data.data.directData?.one_six,
        });
        setFormdata(data.data.directData);
        const totalvar =
          (gridapidata?.required_charge ? gridapidata?.required_charge : 0) -
          data.data.directData?.charge +
          ((gridapidata?.required_cna ? gridapidata?.required_cna : 0) -
            data.data.directData?.cna) +
          ((gridapidata?.orientation ? gridapidata?.orientation : 0) -
            data.data.directData?.orientation) +
          ((gridapidata?.required_nurses ? gridapidata?.required_nurses : 0) -
            data.data.directData?.nurses) +
          ((gridapidata?.required_res_brk_rn
            ? gridapidata?.required_res_brk_rn
            : 0) -
            data.data.directData?.res_brk_rn) +
          ((gridapidata?.unit_sec ? gridapidata?.unit_sec : 0) -
            data.data.directData?.unit_sec) +
          ((gridapidata?.sitter ? gridapidata?.sitter : 0) -
            data.data.directData?.sitter);
        setTotalVar(totalvar);

        setVariance({
          charge:
            (gridapidata?.required_charge ? gridapidata?.required_charge : 0) -
            (data.data.directData?.charge ? data.data.directData?.charge : 0),
          cna:
            (gridapidata?.required_cna ? gridapidata?.required_cna : 0) -
            (data.data.directData?.cna ? data.data.directData?.cna : 0),
          orientation:
            (gridapidata?.orientation ? gridapidata?.orientation : 0) -
            (data.data.directData?.orientation
              ? data.data.directData?.orientation
              : 0),
          nurses:
            (gridapidata?.required_nurses ? gridapidata?.required_nurses : 0) -
            (data.data.directData?.nurses ? data.data.directData?.nurses : 0),
          res_brk_rn:
            (gridapidata?.required_res_brk_rn
              ? gridapidata?.required_res_brk_rn
              : 0) -
            (data.data.directData?.res_brk_rn
              ? data.data.directData?.res_brk_rn
              : 0),
          sitter:
            (gridapidata?.sitter ? gridapidata?.sitter : 0) -
            (data.data.directData?.sitter ? data.data.directData?.sitter : 0),
          unit_sec:
            (gridapidata?.unit_sec ? gridapidata?.unit_sec : 0) -
            (data.data.directData?.unit_sec
              ? data.data.directData?.unit_sec
              : 0),
        });
      } else {
        setFormdata(init);
        setTotalPatientVar(0);
      }

      //console.log(variance, 'variance variance');
      //dispatch(setEditDepartmentDetail(data.data.directData));
    } catch (error) {
      console.log(error);
    }
  };


  const handleChange = (event) => {
    setFormdata({
      ...formdata,
      [event.target.name]: event.target.value,
    });
    setpartValue(event);
    setvarianceValue(event);
  };

  const getDirectDataById = async (title, sum, event) => {
    try {
      // alert(title)
      //console.log(contentId, "contentId");
      let el = document.querySelectorAll(".apicall");
      if (event.target.value != "") {
        el.forEach((item) => item.setAttribute("disabled", true));
      }
      var dt = moment(previousShiftData?.shift_time, ["h:mm:ss A"]).format(
        "HH:mm:ss"
      );
      const getgridparams = {
        cost_center_number: cost_center_number,
        shift_time: dt,
        census: sum != null ? sum : totalPatientvariance,
      };
      let addgriddata = await axios.post(
        API_CALL_URL_PRIVATE + "/department/getGridData",
        getgridparams
      );
      let addgridresult = await addgriddata;
      const gridresult = addgridresult.data.data.gridData;
      setGridUpdateData(gridresult);
      //setDirectData(data.data)
      let StaffingDataObj = {
        charge:
          (gridresult?.required_charge ? gridresult?.required_charge : 0) -
          (formdata?.charge ? formdata?.charge : 0),
        cna:
          (gridresult?.required_cna ? gridresult?.required_cna : 0) -
          (formdata?.cna ? formdata?.cna : 0),
        orientation:
          (gridresult?.orientation ? gridresult?.orientation : 0) -
          (formdata?.orientation ? formdata?.orientation : 0),
        nurses:
          (gridresult?.required_nurses ? gridresult?.required_nurses : 0) -
          (formdata?.nurses ? formdata?.nurses : 0),
        res_brk_rn:
          (gridresult?.required_res_brk_rn
            ? gridresult?.required_res_brk_rn
            : 0) - (formdata?.res_brk_rn ? formdata?.res_brk_rn : 0),
        sitter:
          (gridresult?.sitter ? gridresult?.sitter : 0) -
          (formdata?.sitter ? formdata?.sitter : 0),
        unit_sec:
          (gridresult?.unit_sec ? gridresult?.unit_sec : 0) -
          (formdata?.unit_sec ? formdata?.unit_sec : 0),
      };


      setFormdata({
        ...formdata,
        total_patients: sum,
        [event.target.name]: event.target.value,
      });
      setVariance(StaffingDataObj);
      setTotalVar(Object.values(StaffingDataObj).reduce((a, b) => a + b, 0));
      el.forEach((item) => item.removeAttribute("disabled"));
    } catch (error) {
      console.log(error);
    }
  };

  //console.log("updated data", formdata);

  const submitHandle = async () => {
    let submitdata = formdata;
    let ischecked = [];
    submitdata["id"] = submitdata._id;
    const checkval = Object.keys(submitdata).map((key) => {
      if (key != "notes") {
        if (submitdata[key] === null || submitdata[key] === "") {
          //console.log("checked");
          ischecked.push(false);
          if (key == "comment") {
            var el = document.querySelector("textarea[name=" + key + "]");
          } else {
            var el = document.querySelector("input[name=" + key + "]");
          }
          //console.log(el, "checked");
          if (el != null) {
            el.classList.add("redborder");
          }
        } else {
          if (key == "comment") {
            var el = document.querySelector("textarea[name=" + key + "]");
          } else {
            var el = document.querySelector("input[name=" + key + "]");
          }
          //console.log(el, "checked");
          if (el != null) {
            el.classList.remove("redborder");
          }
          ischecked.push(true);
        }
      }
    });
    const checked = ischecked.every((element) => element === true);
    if (!checked) {
      return false;
    }
    try {
      const resp = await axios.post(
        API_CALL_URL_PRIVATE + "/department/addupdateDirectEntery",
        submitdata
      );
      const { data } = await resp;
      // socket.emit("csvUpdate", { csvAdd: false, csvUpdate: true });
      // socket.emit("csvUpdate",{csvAdd:false,csvUpdate:true})
      // setSuccessmsg(data.message);
      //   if (data.message == "Data updated succesfully") {
      reloaddata(data?.response?._id);
      callbackfunc(false, true, data.message);
      //   }
      //callbackfunc(false, true, data.message);
    } catch (error) {
      console.log(error);
    }
  };

  const closeHandle = () => {
    reloaddata();
    callbackfunc(false);
  };

  return (
    <Modal
      show={isShow}
      onHide={() => callbackfunc(false, false, successMsg)}
      transparent={true}
      centered
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content pb-4">
          <div className="modal-header border-0 pb-3 justify-content-center position-relative pe-5">
            <h5 class="modal-title fw-400 text-uppercase">
              Editing - {cost_center_name}&nbsp;-&nbsp;
              <span class="">
                {activedatefilter?.shift_date} {activedatefilter?.shift_time}
              </span>
            </h5>
            {/* <!-- <button type="button" className="ms-auto btn btn-blue text-capitalize f-12 fw-500"><i className="fa fa-plus-circle" aria-hidden="true"></i> Pull From Previous Shift (7AM)</button> --> */}
            <button
              type="button"
              onClick={closeHandle}
              className="btn-close ms-5 position-absolute right-20 bg-transparent"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              X
            </button>
          </div>

          <div className="modal-body inputnumbericonhide py-0 para-5">
            <div className="row">
              <div className="col-md-3 border-end w1">
                <div className=" text-center">
                  <h6 className="fw-400 fw lh-1">Patient Data</h6>
                </div>
                <table className="w-100 mb-2 m-g-2">
                  <tbody>
                    <tr className="hd">
                      <th></th>
                      <th></th>
                      {/* <th> Previous <br /> {contentone.shift}</th>  {previousShiftData?.shift}*/}
                      <th>{activedatefilter?.shift_time} Shift</th>
                    </tr>
                    <tr>
                      <td className="t">Acuity</td>
                      <td class="img">
                        {/* <img src={AcuityIcon} alt="" /> */}
                        <img src={themeMode.theme === "DARK" ? AcuityIcon1 : AcuityIcon} alt=""/>
                      </td>
                      {/* <td><input className="form-control" name="acuity" value={previousShiftData?.acuity} type="text" disabled aria-label=" input example" readonly /></td> */}
                      <td>
                        <input
                          className="form-control"
                          name="acuity"
                          value={
                            formdata?.acuity != null ? formdata.acuity : ""
                          }
                          type="number"
                          onChange={handleChange}
                          aria-label=" input example"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td class="t">Patient Acuity</td>
                      <td class="img">
                        <span class="circle-i">2:1</span>
                      </td>
                      {/* <td><input className="form-control" name="one_one" value={previousShiftData?.one_one} type="text" disabled onChange={handleChange} aria-label=" input example" readonly /></td> */}
                      <td>
                        <input
                          className="form-control apicall"
                          name="two_one"
                          value={
                            formdata?.two_one != null ? formdata.two_one : ""
                          }
                          type="number"
                          onChange={handleChange}
                          aria-label=" input example"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td class="t">Patient Acuity</td>
                      <td class="img">
                        <span class="circle-i">1:1</span>
                      </td>
                      {/* <td><input className="form-control" name="one_one" value={previousShiftData?.one_one} type="text" disabled onChange={handleChange} aria-label=" input example" readonly /></td> */}
                      <td>
                        <input
                          className="form-control apicall"
                          name="one_one"
                          value={
                            formdata?.one_one != null ? formdata.one_one : ""
                          }
                          type="number"
                          onChange={handleChange}
                          aria-label=" input example"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td class="t">Patient Acuity</td>
                      <td class="img">
                        <span class="circle-i">1:2</span>
                      </td>
                      {/* <td><input className="form-control" name="one_two" value={previousShiftData?.one_two} type="text" disabled onChange={handleChange} aria-label=" input example" readonly /></td> */}
                      <td>
                        <input
                          className="form-control apicall"
                          name="one_two"
                          value={
                            formdata?.one_two != null ? formdata.one_two : ""
                          }
                          type="number"
                          onChange={handleChange}
                          aria-label=" input example"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td class="t">Patient Acuity</td>
                      <td class="img">
                        <span class="circle-i">1:3</span>
                      </td>
                      {/* <td><input className="form-control" name="one_three" value={previousShiftData?.one_three} type="text" disabled onChange={handleChange} aria-label=" input example" readonly /></td> */}
                      <td>
                        <input
                          className="form-control apicall"
                          name="one_three"
                          value={
                            formdata?.one_three != null
                              ? formdata.one_three
                              : ""
                          }
                          type="number"
                          onChange={handleChange}
                          aria-label=" input example"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td class="t">Patient Acuity</td>
                      <td class="img">
                        <span class="circle-i">1:4</span>
                      </td>
                      {/* <td><input className="form-control" name="one_four" value={previousShiftData?.one_four} type="text" disabled onChange={handleChange} aria-label=" input example" readonly /></td> */}
                      <td>
                        <input
                          className="form-control apicall"
                          name="one_four"
                          value={
                            formdata?.one_four != null ? formdata?.one_four : ""
                          }
                          type="number"
                          onChange={handleChange}
                          aria-label=" input example"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td class="t">Patient Acuity</td>
                      <td class="img">
                        <span class="circle-i">1:5</span>
                      </td>
                      {/* <td><input className="form-control" name="one_five" value={previousShiftData?.one_five} type="text" disabled onChange={handleChange} aria-label=" input example" readonly /></td> */}
                      <td>
                        <input
                          className="form-control"
                          name="one_five"
                          value={
                            formdata?.one_five != null ? formdata.one_five : ""
                          }
                          type="number"
                          onChange={handleChange}
                          aria-label=" input example"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td class="t">Patient Acuity</td>
                      <td class="img">
                        <span class="circle-i">1:6</span>
                      </td>
                      {/* <td><input className="form-control" name="one_five" value={previousShiftData?.one_five} type="text" disabled onChange={handleChange} aria-label=" input example" readonly /></td> */}
                      <td>
                        <input
                          className="form-control"
                          name="one_six"
                          value={
                            formdata?.one_six != null ? formdata.one_six : ""
                          }
                          type="number"
                          onChange={handleChange}
                          aria-label=" input example"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="t">Total Patients</td>
                      <td class="img last-i">
                        {/* <img src={BedIcon} alt="" /> */}
                        <img src={themeMode.theme === "DARK" ? BedIcon1 : BedIcon} alt=""/>
                        
                        

                      </td>
                      <td>
                        <hr className="totalline my-1" />
                        <div
                          type="button"
                          className={`tooltip left-tool position-relative ${
                            totalPatientvariance < 0 ? "textdanger" : ""
                          }`}
                        >
                          {totalPatientvariance}
                          <span class="tooltiptext">Total</span>
                        </div>
                      </td>
                    </tr>
                    <tr></tr>
                  </tbody>
                </table>
                <div class="col mt-4">
                  <div class="f-14 text-uppercase text-start">
                    Last Updated By: PerfectShift
                  </div>
                </div>
              </div>
              <div className="col-md-5 w2">
                <div className=" text-center">
                  <h6 className="fw-400 fw lh-1">Staffing</h6>
                </div>
                <table className="w-100 mb-2 m-g-2">
                  <tbody>
                    <tr className="hd">
                      <th></th>
                      <th></th>
                      {/* <th> Previous <br /> {contentone.shift}</th> */}
                      <th>{activedatefilter?.shift_time} Shift</th>
                      <th>Grid</th>
                      <th>Variance</th>
                    </tr>
                    <tr>
                      <td className="t">Charge:</td>
                      <td class="img">
                        {/* <img src={ChargeIcon} alt="" /> */}
                        <img src={themeMode.theme === "DARK" ? ChargeIcon1 : ChargeIcon} alt=""/>
                      </td>
                      {/* <td><input className="form-control" type="text" value={previousShiftData?.charge}  disabled aria-label=" input example" readonly /></td> */}
                      <td>
                        <input
                          name="charge"
                          value={formdata?.charge}
                          className="form-control"
                          onChange={handleChange}
                          type="number"
                          aria-label="input example"
                        />
                      </td>
                      <td>
                        <input
                          className="form-control"
                          type="text"
                          value={
                            gridUpdateData?.required_charge
                              ? gridUpdateData.required_charge
                              : 0
                          }
                          disabled
                          aria-label=" input example"
                          readonly
                        />
                      </td>
                      <td>
                        {/* <input className={`form-control ${variance?.charge <0?"red-text":""}` } type="text" disabled value={gridData?.required_charge-previousShiftData?.charge }  aria-label=" input example" readonly /> */}
                        <div
                          type="button"
                          class="tooltip left-tool position-relative"
                        >
                          <input
                            className={`form-control ${
                              variance?.charge < 0 ? "red-text" : ""
                            }`}
                            type="text"
                            disabled
                            value={variance?.charge}
                            aria-label=" input example"
                            readonly
                          />
                          <span class="tooltiptext">Charge</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="t">CNA:</td>
                      <td class="img">
                        {/* <img src={CnaIcon} alt="" /> */}
                        <img src={themeMode.theme === "DARK" ? CnaIcon1 : CnaIcon} alt=""/>
                      </td>
                      {/* <td><input className="form-control" type="text" value={previousShiftData?.cna}  disabled aria-label=" input example" readonly /></td> */}
                      <td>
                        <input
                          className="form-control"
                          name="cna"
                          value={formdata?.cna}
                          type="number"
                          onChange={handleChange}
                          aria-label=" input example"
                        />
                      </td>
                      <td>
                        <input
                          className="form-control"
                          type="text"
                          value={
                            gridUpdateData?.required_cna
                              ? gridUpdateData?.required_cna
                              : 0
                          }
                          disabled
                          aria-label=" input example"
                          readonly
                        />
                      </td>
                      <td>
                        <div
                          type="button"
                          class="tooltip left-tool position-relative"
                        >
                          <input
                            className={`form-control ${
                              variance?.cna < 0 ? "red-text" : ""
                            }`}
                            type="text"
                            disabled
                            value={variance?.cna}
                            aria-label=" input example"
                            readonly
                          />
                          <span class="tooltiptext">CNA</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="t">Orientation:</td>
                      <td class="img">
                        {/* <img src={OrientationIcon} alt="" /> */}
                        <img src={themeMode.theme === "DARK" ? OrientationIcon1 : OrientationIcon} alt=""/>
                      </td>
                      {/* <td><input className="form-control" type="text" value={previousShiftData?.orientation}  disabled aria-label=" input example" readonly /></td> */}
                      <td>
                        <input
                          className="form-control"
                          name="orientation"
                          type="number"
                          value={formdata?.orientation}
                          onChange={handleChange}
                          aria-label=" input example"
                        />
                      </td>
                      <td>
                        <input
                          className="form-control"
                          type="text"
                          value={
                            gridUpdateData?.orientation
                              ? gridUpdateData?.orientation
                              : 0
                          }
                          disabled
                          aria-label=" input example"
                          readonly
                        />
                      </td>
                      <td>
                        <div
                          type="button"
                          class="tooltip left-tool position-relative"
                        >
                          <input
                            className={`form-control ${
                              variance?.orientation < 0 ? "red-text" : ""
                            }`}
                            type="text"
                            disabled
                            value={variance?.orientation}
                            aria-label=" input example"
                            readonly
                          />
                          <span class="tooltiptext">Orientation</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="t">RN:</td>
                      <td class="img">
                        {/* <img src={NurseIcon} alt="" /> */}
                        <img src={themeMode.theme === "DARK" ? NurseIcon1 : NurseIcon} alt=""/>
                      </td>
                      {/* <td><input className="form-control" type="text" value={previousShiftData?.nurses}  disabled aria-label=" input example" readonly /></td> */}
                      <td>
                        <input
                          className="form-control"
                          name="nurses"
                          type="number"
                          value={formdata?.nurses}
                          onChange={handleChange}
                          aria-label=" input example"
                        />
                      </td>
                      <td>
                        <input
                          className="form-control"
                          type="text"
                          value={
                            gridUpdateData?.required_nurses
                              ? gridUpdateData?.required_nurses
                              : 0
                          }
                          disabled
                          aria-label=" input example"
                          readonly
                        />
                      </td>
                      <td>
                        <div
                          type="button"
                          class="tooltip left-tool position-relative"
                        >
                          <input
                            className={`form-control ${
                              variance?.nurses < 0 ? "red-text" : ""
                            }`}
                            type="text"
                            disabled
                            value={variance?.nurses}
                            aria-label=" input example"
                            readonly
                          />
                          <span class="tooltiptext">RN</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="t">Res/Brk RN:</td>
                      <td class="img">
                        {/* <img src={ReseIcon} alt="" /> */}
                        <img src={themeMode.theme === "DARK" ? ReseIcon1 : ReseIcon} alt=""/>
                      </td>
                      {/* <td><input className="form-control" type="text" value={previousShiftData?.res_brk_rn}  disabled aria-label=" input example" readonly /></td> */}
                      <td>
                        <input
                          className="form-control"
                          name="res_brk_rn"
                          type="number"
                          value={formdata?.res_brk_rn}
                          onChange={handleChange}
                          aria-label=" input example"
                        />
                      </td>
                      <td>
                        <input
                          className="form-control"
                          type="text"
                          value={
                            gridUpdateData?.required_res_brk_rn
                              ? gridUpdateData?.required_res_brk_rn
                              : 0
                          }
                          disabled
                          aria-label=" input example"
                          readonly
                        />
                      </td>
                      <td>
                        <div
                          type="button"
                          class="tooltip left-tool position-relative"
                        >
                          <input
                            className={`form-control ${
                              variance?.res_brk_rn < 0 ? "red-text" : ""
                            }`}
                            type="text"
                            disabled
                            value={variance?.res_brk_rn}
                            aria-label=" input example"
                            readonly
                          />
                          <span class="tooltiptext">Res/Br/k RN</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="t">Unit Sec:</td>
                      <td class="img">
                        {/* <img src={UnitIcon} alt="" /> */}
                        <img src={themeMode.theme === "DARK" ? UnitIcon1 : UnitIcon} alt=""/>
                      </td>
                      {/* <td><input className="form-control" type="text" value={previousShiftData?.unit_sec}  disabled aria-label=" input example" readonly /></td> */}
                      <td>
                        <input
                          className="form-control"
                          name="unit_sec"
                          type="number"
                          value={formdata?.unit_sec}
                          onChange={handleChange}
                          aria-label=" input example"
                        />
                      </td>
                      <td>
                        <input
                          className="form-control"
                          type="text"
                          value={
                            gridUpdateData?.unit_sec
                              ? gridUpdateData?.unit_sec
                              : 0
                          }
                          disabled
                          aria-label=" input example"
                          readonly
                        />
                      </td>
                      <td>
                        <div
                          type="button"
                          class="tooltip left-tool position-relative"
                        >
                          <input
                            className={`form-control ${
                              variance?.unit_sec < 0 ? "red-text" : ""
                            }`}
                            type="text"
                            disabled
                            value={variance?.unit_sec}
                            aria-label=" input example"
                            readonly
                          />
                          <span class="tooltiptext">Unit Sec</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="t">Sitter:</td>
                      <td class="img last-i">
                        {/* <img src={SitterIcon} alt="" /> */}
                        <img src={themeMode.theme === "DARK" ? SitterIcon1 : SitterIcon} alt=""/>
                      </td>
                      {/* <td><input className="form-control" type="text" value={previousShiftData?.sitter}  disabled aria-label=" input example" readonly /></td> */}
                      <td>
                        <input
                          className="form-control"
                          name="sitter"
                          type="number"
                          value={formdata?.sitter}
                          onChange={handleChange}
                          aria-label=" input example"
                        />
                      </td>
                      <td>
                        <input
                          className="form-control"
                          type="text"
                          value={
                            gridUpdateData?.sitter ? gridUpdateData?.sitter : 0
                          }
                          disabled
                          aria-label=" input example"
                          readonly
                        />
                      </td>
                      <td>
                        <div
                          type="button"
                          class="tooltip left-tool position-relative"
                        >
                          <input
                            className={`form-control ${
                              variance?.sitter < 0 ? "red-text" : ""
                            }`}
                            type="text"
                            disabled
                            value={variance?.sitter}
                            aria-label=" input example"
                            readonly
                          />
                          <span class="tooltiptext">Sitter</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <hr className="totalline my-1" />
                        <div
                          type="button"
                          className={`tooltip left-tool position-relative ${
                            totalvariance < 0 ? "textdanger" : ""
                          }`}
                        >
                          {totalvariance}
                          <span class="tooltiptext">Total</span>
                        </div>
                      </td>
                    </tr>
                    {/* <tr>
                                    <td className="t">Unit Sec:</td>
                                    <td><input className="form-control" type="text" value="" aria-label=" input example" readonly /></td>
                                    <td><input className="form-control" type="text" value="" aria-label=" input example" /></td>
                                    <td><input className="form-control" type="text" value={gridData?.orientation} disabled  aria-label=" input example" readonly /></td>
                                    <td><input className="form-control" type="text" value="" aria-label=" input example" readonly /></td>
                                </tr> */}
                  </tbody>
                </table>
              </div>
              <div className="col-md-4 border-start">
                <div className=" text-center">
                  <h6 className="fw-400 fw lh-1">Additional Data Tracking</h6>
                </div>

                <table className="w-100 mb-2 m-g-2">
                  <tbody>
                    <tr className="hd">
                      <th></th>
                      <th></th>
                      {/* <th> Previous <br /> {contentone.shift}</th> */}
                      <th>{activedatefilter?.shift_time} Shift</th>
                    </tr>
                    <tr>
                      <td className="t">
                        # Anticipated Admits/Transfers in this Shift
                      </td>
                      <td class="img">
                        {/* <img src={AdmitsIcon} alt="" /> */}
                        <img src={themeMode.theme === "DARK" ? AdmitsIcon1 : AdmitsIcon} alt=""/>
                      </td>
                      {/* <td><input className="form-control" type="text" value={previousShiftData?.ant_admit} disabled aria-label=" input example" readonly /></td> */}
                      <td>
                        <input
                          className="form-control"
                          type="number"
                          onChange={handleChange}
                          name="ant_admit"
                          value={formdata?.ant_admit}
                          aria-label=" input example"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="t">
                        # Actual Admits/Transfers in previous Shift
                      </td>
                      <td class="img">
                        {/* <img src={DischargesIcon} alt="" /> */}
                        <img src={themeMode.theme === "DARK" ? DischargesIcon1 : DischargesIcon} alt=""/>
                      
                      </td>
                      {/* <td><input className="form-control" type="text" value={previousShiftData?.admit} disabled aria-label=" input example" readonly /></td> */}
                      <td>
                        <input
                          className="form-control"
                          type="number"
                          onChange={handleChange}
                          name="actual_admit"
                          value={formdata?.actual_admit}
                          aria-label=" input example"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="t">Telemetry Patients</td>
                      <td class="img">
                        {/* <img src={TpatientsIcon} alt="" /> */}
                        <img src={themeMode.theme === "DARK" ? TpatientsIcon1 : TpatientsIcon} alt=""/>
                      </td>
                      {/* <td><input className="form-control" type="text" value={previousShiftData?.telemetry} disabled aria-label=" input example" readonly /></td> */}
                      <td>
                        <input
                          className="form-control"
                          type="number"
                          onChange={handleChange}
                          name="telemetry"
                          value={formdata?.telemetry}
                          aria-label=" input example"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="t">Vaso-Active Drips</td>
                      <td class="img last-i">
                        {/* <img src={DripsIcon} alt="" /> */}
                        <img src={themeMode.theme === "DARK" ? DripsIcon1 : DripsIcon} alt=""/>
                      </td>
                      {/* <td><input className="form-control" type="text" value={previousShiftData?.vaso_active_drips} disabled aria-label=" input example" readonly /></td> */}
                      <td>
                        <input
                          className="form-control"
                          type="number"
                          onChange={handleChange}
                          name="vaso_active_drips"
                          value={formdata?.vaso_active_drips}
                          aria-label=" input example"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="row">
                  <div className="col-md-12">
                    <div class="col">
                      <div className="m-f14">
                        Comments / Barriers / Action Plan{" "}
                      </div>
                      <div className="mt-1">
                        <textarea
                          className="form-control full"
                          onChange={handleChange}
                          name="comment"
                          value={formdata?.comment}
                          placeholder="Type Here..."
                          style={{ height: "70px" }}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col">
                    <button
                      type="button"
                      onClick={closeHandle}
                      className="btn btn-red text-capitalize  f-12 fw-400 w-100"
                    >
                      {" "}
                      Cancel
                    </button>
                  </div>
                  <div className="col">
                    <button
                      type="button"
                      onClick={submitHandle}
                      className="btn btn-success text-capitalize  f-12 fw-400 w-100 finsh"
                    >
                      {" "}
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default UpdateModal;
