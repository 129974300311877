import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

function ProgressCount({data}) {

    //console.log(data);

    const stylesheet = {
        width:data.performance_ratio+'%',
        backgroundColor: '#5E8E28'
    }
    return (
        <div className="col">
            <div className="border-right position-relative">
                <div className="progress">
                    <div className="progress-bar" role="progressbar" aria-valuenow={12} aria-valuemin="0" aria-valuemax="100" style={stylesheet}>
                    </div>
                </div>
                <div>
                    <div className="d-flex my-1 f-14 ml-e position-relative">
                        <div><strong className="f-14 tooltip">{data.performance_ratio}  <span className="tooltiptext">Performance Ratio</span></strong></div>
                        <div><strong className="f-14 tooltip">{data.staffing_ratio} <span className="tooltiptext">Staffing Ratio</span></strong></div>
                        <div><strong className="f-14 tooltip">{data.fte_performance_ratio} <span className="tooltiptext">FTE Performance Ratio</span></strong></div>
                    </div>
                    <p className="f-12 m-0 lh-1">{data.cost_center_name}</p>
                </div>
                <div className="d-flex my-1 f-14 ml-e pe-3 position-relative">
                    <div className='tooltip'>{data.total_fte} <span className="tooltiptext">Actual FTE</span></div>
                    <div className='tooltip'>{data.budgted_fte} <span className="tooltiptext">Budgeted FTE</span></div>
                    <div className='tooltip'>{data.actual_uos} <span className="tooltiptext">Actual UOS</span></div>
                    <div className='tooltip'>{data.budgted_uos} <span className="tooltiptext">Budgeted UOS</span></div>
                </div>
            </div>
        </div>
    );
}

export default ProgressCount;