import * as DS from "../../Styled/DashStyle";
import { SiSpeedtest } from "react-icons/si";
import React, { useContext, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { ThemeContext } from "../../providers/ThemeProvider";
import { useSelector, useDispatch } from "react-redux";
import axios from "../../axios";
import { API_CALL_URL_PRIVATE } from "../../constants";
import { CiCalendarDate } from "react-icons/ci";
import ReactDatePicker from "react-datepicker";

const TopCostCenterGraph = ({ dateFrom, dateTo, userType }) => {
  const [themeMode, setTheme] = useContext(ThemeContext);
  const customer_code = useSelector((state) => state.compensate.customerCode);
  // const [dateFrom, setDateFrom] = useState(new Date());
  // const [dateTo, setDateTo] = useState(new Date());
  const [apiData, setApiData] = useState(null);
  const [xData, setXData] = useState([]);
  const [yData, setYData] = useState([]);
  const [maxYAxisValue, setMaxYAxisValue] = useState(null);

  console.log(apiData, "apiData");

  function formatDate(date) {
    // Check if the date is available and is a valid Date object
    if (date instanceof Date && !isNaN(date.getTime())) {
      // Get the day, month, and year components
      const day = date.getDate().toString().padStart(2, "0"); // Add leading zero if needed
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
      const year = date.getFullYear();

      // Return the formatted date string
      return `${month}/${day}/${year}`;
    } else {
      // If the date is not available or is not a valid Date object, return an empty string
      return "";
    }
  }

  useEffect(() => {
    const getGraphData = async () => {
      try {
        const resp = await axios.post(
          `${API_CALL_URL_PRIVATE}/home/getTopCostCentersADM`,
          {
            customer_code: customer_code,
            user_type: userType,
            from_date: formatDate(dateFrom),
            to_date: formatDate(dateTo),
            limit: "10",
          }
        );
        setApiData(resp?.data?.data?.graphData);
        setXData(
          resp?.data?.data?.graphData?.map((data) => {
            return data.title;
          })
        );
        const newYData = resp?.data?.data?.graphData?.map((data) => {
          return data.data;
        });
        setYData(newYData);
        const maxYValue = Math.max(...newYData);
        const adjustedMaxYValue = maxYValue > 0 ? maxYValue * 1.1 : 5;
        setMaxYAxisValue(adjustedMaxYValue);
      } catch (error) {
        console.log(error);
      }
    };
    getGraphData();
  }, [dateFrom, dateTo, customer_code, userType]);

  //   const options = {
  //     chart: {
  //       id: "basic-bar",
  //       toolbar: {
  //         show: false,
  //       },
  //       margin: {
  //         top: 20,
  //         right: 20,
  //         bottom: 100, // Increase bottom margin to accommodate rotated labels
  //         left: 20,
  //       },
  //     },
  //     stroke: {
  //       curve: "straight",
  //       width: "2",
  //     },
  //     // dataLabels: {
  //     //   enabled: true,
  //     //   hideOverflowingLabels: true,

  //     //   offsetY: -20,
  //     //   style: {
  //     //     fontSize: "12px",
  //     //     colors: themeMode.theme === "DARK" ? ["white"] : ["black"],
  //     //     padding: {
  //     //       top: 30
  //     //     },
  //     //   },

  //     // },
  //     colors: themeMode.theme === "DARK" ? ["#dfed40"] : ["#033e8d"],
  //     xaxis: {
  //       categories: xData,
  //       labels: {
  //         style: {
  //           fontSize: "12px",
  //           colors: themeMode.theme === "DARK" ? "white" : "black",
  //         },
  //         offsetX: 0,
  //         offsetY: 0,
  //         rotate: -30,
  //         rotateAlways: true,
  //         hideOverlappingLabels: false,
  //         showDuplicates: true,
  //         show: true,
  //       },
  //     },
  //     yaxis: {
  //       labels: {
  //         offsetX: -10,
  //         offsetY: 5,
  //         style: {
  //           fontSize: "12px", // Reduce the font size as needed
  //           colors: themeMode.theme === "DARK" ? "white" : "black",
  //         },
  //       },
  //     },
  //   };
  const options = {
    chart: {
      height: 350,
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    colors: themeMode.theme === "DARK" ? ["#dfed40"] : ["#033e8d"],
    plotOptions: {
      bar: {
        columnWidth: "50%",
        distributed: true,
        dataLabels: {
          position: "top", // Positions data labels at the top of each bar
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val;
      },
      offsetY: -18,
      style: {
        fontSize: "10px",
        colors:
          themeMode.theme === "DARK" ? ["#ffffff"] : ["rgba(0, 0, 0, 0.87)"],
      },
    },
    legend: {
      show: false,
    },

    xaxis: {
      categories: xData,
      labels: {
        rotate: -55,
        rotateAlways: true,
        style: {
          colors: themeMode.theme === "DARK" ? "white" : "black",
          fontSize: "10px",
        },
      },
    },
    yaxis: {
      max: maxYAxisValue,
      labels: {
        formatter: function (value) {
          return isFinite(value) ? Math.round(value) : 0; // Round the tick values to integers
        },
        style: {
          colors: themeMode.theme === "DARK" ? "white" : "black",
        },
      },
    },
  };

  const series = [
    {
      name: "Data",
      data: yData,
    },
  ];

  return (
    <>
      <DS.ActivityCardParent>
        <DS.Heading style={{ padding: "0rem 0.5rem" }}>
          Top Cost Center
        </DS.Heading>
        <div className="subheading">
          Top 10 cost centers in that viewed ADM Summary 
        </div>
        <div className="mixed-chart" style={{ height: "100%" }}>
          <ReactApexChart series={series} type="bar" options={options} />
        </div>
      </DS.ActivityCardParent>
    </>
  );
};

export default TopCostCenterGraph;
