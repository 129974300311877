import React, { useEffect, useState } from "react";
import "./index.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ErrorMessage, Formik, Form as FormikForm } from "formik";
import { toast } from "react-toastify";
import axios from "../../axios";
import { API_CALL_URL_PRIVATE, DOMAIN_URL } from "../../constants/compensate";
import Texterror from "../Texterror";
import { AdminValidation } from "../../utils/validator";
import ReactDatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import CROSS from "../../../src/assets/img/close.png";
import { setLoadingPage } from "../../store/actions";

function AddAdminUserForm() {
  const dispatch = useDispatch();
  const { employeeID } = useParams();
  const customer_code = useSelector((state) => state.compensate.customerCode);
  const loading_page = useSelector((state) => state.compensate.LoadingPage);
  console.log(customer_code, "customercode");
  const navigate = useNavigate();
  const [FormInitialData, setFormInitialData] = useState();
  const [selectedImage, setSelectedImage] = useState(null);
  const [initialImageUrl, setInitialImageUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [costCenterMaster, setCostCenterMaster] = useState(null);
  const [costCenterData, setCostCenterData] = useState(null);
  const [costCenterFilteredData, setCostCenterFilteredData] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [facilityMaster, setFacilityMaster] = useState(null);
  const [applications, setApplications] = useState(null);
  const [selectAllApplication, setSelectAllApplication] = useState(false);
  const [DeselectAllApplication, setDeselectAllApplication] = useState(false);
  const [selectAllCostCenter, setSelectAllCostCenter] = useState(false);
  const [DeselectAllCostCenter, setDeselectAllCostCenter] = useState(false);
  const [hireDate, setHireDate] = useState(null);
  const [terminateDate, setTerminateDate] = useState(null);
  const [isInitialData, setISInitialData] = useState(false);

  const [initialValues, setInitialValues] = useState({
    employee_id: "",
    employee_name: "",
    employee_email: "",
    active_status: 0,
    username: "",
    password: "",
    sid: "",
    sid_email: "",
    theme_mode: 0,
    user_type: "0",
    applications: [],
    cost_center_masters: [],
  });

  // const getFormatDate = (val) => {
  //   const inputDate = new Date(`${val}`);
  //   const year = inputDate.getFullYear();
  //   const month = String(inputDate.getMonth() + 1).padStart(2, "0");
  //   const day = String(inputDate.getDate()).padStart(2, "0");
  //   return val ? `${day}/${month}/${year}` : "";
  // };

  const getFormatDate = (val) => {
    if (!val) return ""; // Handle empty or undefined input

    let inputDate;

    if (typeof val === "string") {
      // If the input is a string, attempt to parse it
      const dateComponents = val.split("/");
      if (dateComponents.length !== 3) return ""; // Handle invalid date format

      const [month, day, year] = dateComponents.map((component) =>
        parseInt(component, 10)
      );

      // Check if the parsed components are valid numbers
      if (isNaN(month) || isNaN(day) || isNaN(year)) {
        return ""; // Handle invalid numeric values
      }

      inputDate = new Date(year, month - 1, day); // Note: months are zero-based in JavaScript dates
    } else if (val instanceof Date) {
      // If the input is a Date object, use it directly
      inputDate = val;
    } else {
      return ""; // Handle unsupported input type
    }

    const formattedYear = inputDate.getFullYear();
    const formattedMonth = String(inputDate.getMonth() + 1).padStart(2, "0");
    const formattedDay = String(inputDate.getDate()).padStart(2, "0");

    return `${formattedDay}/${formattedMonth}/${formattedYear}`;
  };

  const getApplications = async () => {
    const resp = await axios.post(
      `${API_CALL_URL_PRIVATE}/application/applicationList`,
      {
        limit: "100000",
      }
    );
    setApplications(
      resp?.data?.data?.data?.docs?.map((e) => {
        return {
          application_id: e.application_id,
          title: e.title,
        };
      })
    );
    setInitialValues((prev) => {
      return {
        ...prev,
        applications: resp?.data?.data?.data?.docs?.map(
          (e) => e.application_id
        ),
      };
    });
    setSelectAllApplication(true);
    setISInitialData(true);
    console.log(resp);
  };

  const getCostCenter = async () => {
    const resp = await axios.post(
      `${API_CALL_URL_PRIVATE}/department/departmentList`,
      {
        customer_code: parseInt(customer_code),
        limit: "100000",
      }
    );
    setCostCenterMaster(
      resp?.data?.data?.data?.docs?.map((e) => {
        return {
          cost_center_name: e.cost_center_name,
          cost_center_number: e.cost_center_number,
          realtime: e.realtime,
        };
      })
    );
    setCostCenterFilteredData(
      resp?.data?.data?.data?.docs?.map((e) => {
        return {
          cost_center_name: e.cost_center_name,
          cost_center_number: e.cost_center_number,
          realtime: e.realtime,
        };
      })
    );
    setInitialValues((prev) => {
      return {
        ...prev,
        cost_center_masters: resp?.data?.data?.data?.docs?.map(
          (e) => e.cost_center_number
        ),
      };
    });
    setSelectAllCostCenter(true);
    setISInitialData(true);
    dispatch(setLoadingPage(false));
    console.log(resp);
  };
  useEffect(() => {
    getApplications();
    // getCostCenter();
  }, [customer_code]);

  const onSubmit = (values, onSubmittingProps) => {
    console.log("form Values", values);
    formSubmit(values);
    // setTimeout(() => {
    //   onSubmittingProps.setSubmitting(false);
    // }, [2000]);
  };
  const formSubmit = async (values) => {
    // var formData = new FormData();
    // formData.append("_id", values.customer_id);
    // formData.append("customer_code", values.customer_code);
    // formData.append("customer_name", values.customer_name);
    // formData.append("customer_number", values.customer_number);
    // formData.append("customer_email", values.customer_email);
    // formData.append("address", values.customer_address);
    // formData.append("status", values.customer_status);
    // formData.append("logo", values.logo);

    const obj = {
      employee_id: parseInt(values.employee_id),
      employee_name: values.employee_name,
      employee_email: values.employee_email,
      active_status: values.active_status,
      username: values.username,
      password: values.password,
      sid: values.sid,
      sid_email: values.sid_email,
      theme_mode: values.theme_mode,
      user_type: parseInt(values.user_type),
      customer_code: customer_code,
      application_ids: values.applications,
      // cost_center_nos: values.cost_center_masters,
    };
    try {
      setIsLoading(true);
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/adminUser/userAddUpdate`,
        obj
      );
      console.log(resp);
      toast.success(resp?.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
      navigate("/admin/admin-user");
    } catch (error) {
      toast.error(error.response, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });

      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  const performSearch = (data, query) => {
    if (query.trim() === "") {
      return data || [];
    } else {
      return (data || []).filter((e) =>
        e.cost_center_name.toLowerCase().includes(query.toLowerCase())
      );
    }
  };
  return (
    <>
      {isInitialData ? (
        <>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validate={AdminValidation}
            enableReinitialize
            validateOnChange
          >
            {(formik) => (
              <div className="main-panel">
                {console.log(formik)}
                <div className="content-wrapper">
                  <div className="page-header">
                    <h3 className="page-title">
                      <a href="#" className="badge badge-dark"></a>
                    </h3>
                    <div className></div>
                  </div>
                  <div className="row">
                    <div class="col-12 grid-margin stretch-card">
                      <div class="card">
                        <FormikForm className="forms-sample">
                          <div class="card-body">
                            <div className="row align-items-center">
                              <div className="col-3">
                                <Link
                                  to={`/admin/admin-user`}
                                  className="f-14 white-text-dark-mode"
                                >
                                  <FontAwesomeIcon icon="fa-solid fa-arrow-left " />{" "}
                                  Back
                                </Link>
                              </div>
                              <div className="col-6">
                                <h4 class="card-title text-center">
                                  Add Admin User
                                </h4>
                              </div>
                              <div className="col-3 text-end">
                                <Button
                                  className="ms-3"
                                  variant="success"
                                  type="submit"
                                  disabled={isLoading}
                                >
                                  {isLoading ? "Saving..." : "Save"}
                                </Button>
                              </div>
                            </div>

                            <div class="row">
                              <Form.Group
                                className="col-md-3 mb-3"
                                controlId="exampleForm.c_code"
                              >
                                <Form.Label>Employee Id</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder=""
                                  name="employee_id"
                                  {...formik.getFieldProps("employee_id")}
                                />
                                <ErrorMessage
                                  name="employee_id"
                                  component={Texterror}
                                />
                              </Form.Group>
                              <Form.Group
                                className="col-md-3 mb-3"
                                controlId="exampleForm.c_name"
                              >
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder=""
                                  name="employee_name"
                                  {...formik.getFieldProps("employee_name")}
                                />
                                <ErrorMessage
                                  name="employee_name"
                                  component={Texterror}
                                />
                              </Form.Group>
                              <Form.Group
                                className="col-md-3 mb-3"
                                controlId="exampleForm.c_number"
                              >
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                  type="email"
                                  placeholder=""
                                  name="employee_email"
                                  {...formik.getFieldProps("employee_email")}
                                />
                                <ErrorMessage
                                  name="employee_email"
                                  component={Texterror}
                                />
                              </Form.Group>

                              <Form.Group
                                className="col-md-3 mb-3"
                                controlId="exampleForm.c_email"
                              >
                                <Form.Label>Username</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder=""
                                  name="username"
                                  {...formik.getFieldProps("username")}
                                />
                                <ErrorMessage
                                  name="username"
                                  component={Texterror}
                                />
                              </Form.Group>
                              <Form.Group
                                className="col-md-3 mb-3"
                                controlId="exampleForm.c_email"
                              >
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder=""
                                  name="password"
                                  {...formik.getFieldProps("password")}
                                />
                                <ErrorMessage
                                  name="password"
                                  component={Texterror}
                                />
                              </Form.Group>
                              {/* <Form.Group
                            className="col-md-6 mb-3"
                            controlId="exampleForm.c_email"
                          >
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                              type="password"
                              placeholder=""
                              name="password"
                              {...formik.getFieldProps("password")}
                            />
                            <ErrorMessage
                              name="password"
                              component={Texterror}
                            />
                          </Form.Group> */}
                              <Form.Group
                                className="col-md-3 mb-3"
                                controlId="exampleForm.c_email"
                              >
                                <Form.Label>Sid</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder=""
                                  name="sid"
                                  {...formik.getFieldProps("sid")}
                                />
                                <ErrorMessage
                                  name="sid"
                                  component={Texterror}
                                />
                              </Form.Group>
                              <Form.Group
                                className="col-md-3 mb-3"
                                controlId="exampleForm.c_email"
                              >
                                <Form.Label>Sid Email</Form.Label>
                                <Form.Control
                                  type="email"
                                  placeholder=""
                                  name="sid_email"
                                  {...formik.getFieldProps("sid_email")}
                                />
                                <ErrorMessage
                                  name="sid_email"
                                  component={Texterror}
                                />
                              </Form.Group>

                              {/* <Form.Group
                            className="col-md-6 mb-3"
                            controlId="exampleForm.c_email"
                          >
                            <Form.Label>Cost centre number</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              name="cost_center_number"
                              {...formik.getFieldProps("cost_center_number")}
                            />
                            <ErrorMessage
                              name="cost_center_number"
                              component={Texterror}
                            />
                          </Form.Group> */}

                              <Form.Group
                                className="col-md-3 mb-3"
                                controlId="exampleForm.c_email"
                              >
                                <Form.Label>Active Status</Form.Label>
                                <Form.Select
                                  aria-label="Default select example"
                                  className="white"
                                  name="active_status"
                                  value={`${formik.values.active_status}`}
                                  onChange={(e) => {
                                    // formik.setFieldTouched(
                                    //   "customer_status",
                                    //   true,
                                    //   true
                                    // );
                                    formik.setFieldValue(
                                      "active_status",
                                      e.target.value
                                    );
                                    console.log(e.target.value);
                                  }}
                                  // onChange={formik.handleChange}
                                >
                                  <option value="1">Active</option>
                                  <option value="0">InActive</option>
                                </Form.Select>
                                <ErrorMessage
                                  name="active_status"
                                  component={Texterror}
                                />
                              </Form.Group>

                              <Form.Group
                                className="col-md-3 mb-3"
                                controlId="exampleForm.c_email"
                              >
                                <Form.Label>Theme Mode</Form.Label>
                                <Form.Select
                                  aria-label="Default select example"
                                  className="white"
                                  name="customer_status"
                                  value={`${formik.values.theme_mode}`}
                                  onChange={(e) => {
                                    // formik.setFieldTouched(
                                    //   "customer_status",
                                    //   true,
                                    //   true
                                    // );
                                    formik.setFieldValue(
                                      "theme_mode",
                                      e.target.value
                                    );
                                    console.log(e.target.value);
                                  }}
                                  // onChange={formik.handleChange}
                                >
                                  <option value="1">Dark</option>
                                  <option value="0">Light</option>
                                </Form.Select>
                                <ErrorMessage
                                  name="theme_mode"
                                  component={Texterror}
                                />
                              </Form.Group>

                              <Form.Group
                                className="col-md-3 mb-3"
                                controlId="exampleForm.c_email"
                              >
                                <Form.Label>User Type</Form.Label>
                                <Form.Select
                                  aria-label="Default select example"
                                  className="white"
                                  name="user_type"
                                  value={`${formik.values.user_type}`}
                                  onChange={(e) => {
                                    // formik.setFieldTouched(
                                    //   "customer_status",
                                    //   true,
                                    //   true
                                    // );
                                    formik.setFieldValue(
                                      "user_type",
                                      e.target.value
                                    );
                                    console.log(e.target.value);
                                  }}
                                  // onChange={formik.handleChange}
                                >
                                  <option value="0" selected>
                                    Admin User
                                  </option>
                                </Form.Select>
                                <ErrorMessage
                                  name="user_type"
                                  component={Texterror}
                                />
                              </Form.Group>

                              <div class="col-md-12 form-group my-4">
                                <Form.Label>Applications</Form.Label>
                                {applications?.length > 0 ? (
                                  <>
                                    <div class="checkbox-group my-3">
                                      <div class="checkbox-item">
                                        <input
                                          type="checkbox"
                                          class="custom-checkbox"
                                          checked={selectAllApplication}
                                          onChange={(p) => {
                                            if (p.target.checked) {
                                              setSelectAllApplication(true);
                                              setDeselectAllApplication(false);
                                              const allIds = applications?.map(
                                                (e) => e.application_id
                                              );
                                              formik.setFieldValue(
                                                "applications",
                                                allIds
                                              );
                                            } else {
                                              setSelectAllApplication(false);
                                            }
                                          }}
                                        />
                                        Select all
                                      </div>
                                      <div class="checkbox-item">
                                        <input
                                          type="checkbox"
                                          class="custom-checkbox"
                                          checked={DeselectAllApplication}
                                          onChange={(p) => {
                                            if (p.target.checked) {
                                              setSelectAllApplication(false);
                                              setDeselectAllApplication(true);
                                              formik.setFieldValue(
                                                "applications",
                                                []
                                              );
                                            } else {
                                              setSelectAllApplication(false);
                                              setDeselectAllApplication(false);
                                            }
                                          }}
                                        />
                                        Deselect all
                                      </div>
                                    </div>
                                    <div class="checkbox-group">
                                      {applications?.map((e) => {
                                        const person =
                                          formik.values.applications?.find(
                                            (p) => p === e.application_id
                                          );
                                        const checked = person !== undefined;

                                        return (
                                          <div class="checkbox-item">
                                            <input
                                              type="checkbox"
                                              class="custom-checkbox"
                                              checked={checked}
                                              onChange={(p) => {
                                                if (p.target.checked) {
                                                  setSelectAllApplication(
                                                    false
                                                  );
                                                  setDeselectAllApplication(
                                                    false
                                                  );
                                                  formik.setFieldValue(
                                                    "applications",
                                                    [
                                                      ...formik.values
                                                        .applications,
                                                      e.application_id,
                                                    ]
                                                  );
                                                } else {
                                                  setSelectAllApplication(
                                                    false
                                                  );
                                                  setDeselectAllApplication(
                                                    false
                                                  );
                                                  formik.setFieldValue(
                                                    "applications",
                                                    formik.values.applications.filter(
                                                      (v) =>
                                                        v !== e.application_id
                                                    )
                                                  );
                                                }
                                              }}
                                            />
                                            {e?.title}
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </>
                                ) : (
                                  <p>No Application Available</p>
                                )}
                              </div>
                              {/* <div class="col-md-12 form-group my-5">
                                {costCenterMaster?.length > 0 ? (
                                  <>
                                    <div className="row justify-content-between align-items-center">
                                      <div className="col-md-3">
                                        {" "}
                                        <Form.Label className="my-3">
                                          Cost center
                                        </Form.Label>
                                      </div>
                                      <div className="col-md-4">
                                        <div class="checkbox-group red-checkbox my-3">
                                          <div class="checkbox-item">
                                            <input
                                              type="checkbox"
                                              class="custom-checkbox"
                                              checked={selectAllCostCenter}
                                              onChange={(p) => {
                                                if (p.target.checked) {
                                                  setSelectAllCostCenter(true);
                                                  setDeselectAllCostCenter(
                                                    false
                                                  );
                                                  const allIds =
                                                    costCenterMaster?.map(
                                                      (e) =>
                                                        e.cost_center_number
                                                    );
                                                  formik.setFieldValue(
                                                    "cost_center_masters",
                                                    allIds
                                                  );
                                                } else {
                                                  setSelectAllCostCenter(false);
                                                }
                                              }}
                                            />
                                            Select all
                                          </div>
                                          <div class="checkbox-item">
                                            <input
                                              type="checkbox"
                                              class="custom-checkbox"
                                              checked={DeselectAllCostCenter}
                                              onChange={(p) => {
                                                if (p.target.checked) {
                                                  setSelectAllCostCenter(false);
                                                  setDeselectAllCostCenter(
                                                    true
                                                  );
                                                  formik.setFieldValue(
                                                    "cost_center_masters",
                                                    []
                                                  );
                                                } else {
                                                  setDeselectAllCostCenter(
                                                    false
                                                  );
                                                }
                                              }}
                                            />
                                            Deselect all
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-3 mb-3 position-relative">
                                        <input
                                          placeholder="Search here"
                                          name=""
                                          type="search"
                                          id=""
                                          class="form-control"
                                          value={searchKeyword}
                                          onChange={(e) => {
                                            setSearchKeyword(e.target.value);
                                            setCostCenterFilteredData(() => {
                                              return performSearch(
                                                costCenterMaster,
                                                e.target.value
                                              );
                                            });
                                          }}
                                        />
                                        <img
                                          src={CROSS}
                                          alt="cross_btn"
                                          className="close-cost-center"
                                          onClick={() => {
                                            setSearchKeyword("");
                                            setCostCenterFilteredData(() => {
                                              return performSearch(
                                                costCenterMaster,
                                                ""
                                              );
                                            });
                                          }}
                                        />
                                      </div>
                                    </div>

                                    <div className="checkbox-group box">
                                      {costCenterFilteredData?.map((e) => {
                                        const person =
                                          formik.values.cost_center_masters?.find(
                                            (p) => p === e.cost_center_number
                                          );
                                        const checked = person !== undefined;

                                        return e?.cost_center_name ? (
                                          <div
                                            key={e.cost_center_id}
                                            className="checkbox-item"
                                          >
                                            <input
                                              type="checkbox"
                                              className="custom-checkbox"
                                              checked={checked}
                                              onChange={(p) => {
                                                if (p.target.checked) {
                                                  setSelectAllCostCenter(false);
                                                  setDeselectAllCostCenter(
                                                    false
                                                  );
                                                  formik.setFieldValue(
                                                    "cost_center_masters",
                                                    [
                                                      ...formik.values
                                                        .cost_center_masters,
                                                      e.cost_center_number,
                                                    ]
                                                  );
                                                } else {
                                                  setSelectAllCostCenter(false);
                                                  setDeselectAllCostCenter(
                                                    false
                                                  );
                                                  formik.setFieldValue(
                                                    "cost_center_masters",
                                                    formik.values.cost_center_masters.filter(
                                                      (v) =>
                                                        v !==
                                                        e.cost_center_number
                                                    )
                                                  );
                                                }
                                              }}
                                            />
                                            <span>{`${e.cost_center_name} `}</span>
                                            <span className="fw-bold">
                                              {e.realtime === 1
                                                ? "(RealTime)"
                                                : ""}
                                            </span>
                                          </div>
                                        ) : null;
                                      })}
                                    </div>
                                  </>
                                ) : (
                                  <p className="text-center">
                                    No cost center available
                                  </p>
                                )}
                              </div> */}

                              <Form.Group className="col-md-12 mb-3 d-flex justify-content-end">
                                {/* <Button variant="primary" type="submit">
                              Reset
                            </Button> */}
                                <Button
                                  className="ms-3"
                                  variant="success"
                                  type="submit"
                                  disabled={isLoading}
                                >
                                  {isLoading ? "Saving..." : "Save"}
                                </Button>
                              </Form.Group>
                            </div>
                          </div>
                        </FormikForm>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Formik>
        </>
      ) : (
        <div className="d-flex align-items-center justify-content-center w-100vw h-100vh">
          <span class="loader" style={{ width: "50px", height: "50px" }}></span>
        </div>
      )}
    </>
  );
}

export default AddAdminUserForm;
