import Chart from "react-apexcharts";
import moment from 'moment';
import { useContext } from "react";
import { ThemeContext } from "../../../providers/ThemeProvider";



function AreaChart() {
    const [themeMode, setTheme] = useContext(ThemeContext);
    const options = {
        chart: {
            color: themeMode.theme === "DARK" ? "white" : "black",
            height: 140,
            type: "area",
            toolbar: {
                show: false,
                tools: {
                    download: false
                }
            }
        },
        dataLabels: {
            enabled: false
        },
        
        fill: {
            color: '#141438',
            //    type: "gradient",
            //    gradient: {
            //      shadeIntensity: 1,
            //      opacityFrom: 0.7,
            //      opacityTo: 0.9,
            //      stops: [0, 90, 100]
            //    }
        },
        xaxis: {
            categories: [
                "2010",
                "2011",
                "2012",
                "2013",
                "2014",
                "2015",
                "2016"
            ],
            labels: {
                show: true,
                style: {
                    colors: themeMode.theme === "DARK" ? ["#FFF", "#FFF", "#FFF", "#FFF", "#FFF", "#FFF", "#FFF"] : ["#191C24", "#191C24", "#191C24", "#191C24", "#191C24", "#191C24", "#191C24"], 
                },
            },    
        },
        yaxis: {
            // position: 'bottom',
             labels: {
               show: true,
               style: {
                   colors: themeMode.theme === "DARK" ? ["#FFF", "#FFF", "#FFF", "#FFF", "#FFF", "#FFF"] : ["#191C24", "#191C24", "#191C24", "#191C24", "#191C24", "#191C24"],
                  
               },
              
             
           },
           
           },
    };

    return (
        <div className=" mt-2 pb-0">
            <Chart
                options={options}
                series={[{
                    name: "Flexing Effective Score",
                    data: [5, 20, 38, 45, 19, 23, 60]
                  }]}
                type="area"
                width={'100%'}
                height={250}
            />
        </div>
    );
}

export default AreaChart;