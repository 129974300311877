import styled from "styled-components";

export const Parent = styled.div`
  width: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 25px;
`;
export const SectionOne = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-template-rows: repeat(auto);
  gap: 25px;
`;
export const CardOneParent = styled.div`
  border-radius: 5px;
  padding: 2rem 2rem;
  background-color: rgb(25, 28, 36);
  display: flex;
  align-items: center;
`;
export const CardOneRow = styled.div``;
export const SectionTwo = styled.div`
  width: 100%;

  display: flex;

  gap: 25px;
`;
export const NotificationCardParent = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem 4rem 1rem;
  background-color: rgb(25, 28, 36);
`;
export const ActivityCardParent = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background-color: rgb(25, 28, 36);
`;
export const Heading = styled.div`
  font-size: 1rem;
  padding: 1rem 0.5rem;
  color: white;
`;
export const NotificationBox = styled.div`
  width: 100%;
  padding: 0.5rem;
  display: flex;
  gap: 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
`;
export const ActivityBox = styled.div`
  width: 100%;
  padding: 0.5rem;
  display: flex;
  gap: 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
`;
export const ContentBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
export const ContentRow = styled.div`
  width: 100%;
`;
export const ContentRowOne = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const Value = styled.div`
  font-size: 1rem;
  color: white;
`;
export const SubValue = styled.span`
  font-size: 0.8rem;
  color: #636c91;
`;
export const IconBox = styled.div`
  width: 40px;
  height: 40px;
  background-color: #00d25b;
  display: grid;
  place-items: center;
`;
export const SectionThree = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  grid-template-rows: repeat(auto);
  gap: 25px;
`;
export const CardTwoParent = styled.div`
  border-radius: 5px;

  padding: 2rem 2rem;
  background-color: rgb(25, 28, 36);
  display: flex;
  align-items: center;
  color: white;
  cursor: pointer;
  &:hover {
    transform: translateY(-5px);
  }
`;
export const CardTwoRow = styled.div``;
export const SectionFour = styled.div`
  width: 100%;
`;
