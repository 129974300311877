import "./index.css";
import { useState, useEffect } from "react";
import Realtime from './realtime';
import Currentperiod from './currentperiod';
import { useParams } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { API_CALL_URL_PUBLIC } from "../../constants/";
import { API_CALL_URL_PRIVATE } from "../../constants/";
import axios from "../../axios";
import "font-awesome/css/font-awesome.min.css";


function DirectEntry(props) {
  const { idlabel } = useParams();
  const { urltype } = useParams();
  const [id, setId] = useState(null);

  useEffect(() => {
    getCostCenterNumber();
    // autoFillgetDirectDataById()
  }, []);

  const getCostCenterNumber = async () => {
    const para = {
      slug: idlabel,
      facility_id: 1,
    };
    const resp = await axios.post(
      API_CALL_URL_PRIVATE + "/department/getCostCenterData",
      para
    );
    let { data } = await resp;
    const ccn = data.data?.cost_center?.cost_center_number;
    setId(ccn);
    //fetchDetails();
  };


  if(urltype === "realtime"){
    return (
      <div>
        <Realtime urlparam={idlabel} id={id}  />
      </div>
    );
  } 
  if(urltype === "currentperiod" || urltype === "previousperiod"){
    return (
      <div>
        <Currentperiod urlparam={idlabel} id={id} urltype={urltype} />
      </div>
    );
  }
  
}

export default DirectEntry;
